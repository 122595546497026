<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("receipt.receipt") }}
        <small>{{ $t("receipt.addNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :receipt="receipt" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./receiveform.vue";

export default {
  data() {
    return {
      receipt: {
        fiscal_year_id: "",
        date: "",
        type: 1,
        narration: "",
        amount: "",
        payment_mode: "",
        reference_no: "",
        task_id: "",
        expenses_id: "",
        expenses_type: "",
        dr: [
          {
            type: "",
            dr_id: "",
            amount: "",
          },
        ],
        cr: [
          {
            type: "",
            cr_id: "",
            source_method_id: "",
            amount: "",
          },
        ],
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
