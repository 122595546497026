<template>
  <div class="list-search-form">
    <form ref="searchForm" @submit.prevent="submitForm">
      <div class="form-group required">
        <input
          v-model="v$.query.$model"
          :class="{
            'is-invalid': v$.query.$error,
          }"
          type="text"
          class="form-control"
          :placeholder="$t('search')+'...'"
        />

        <div class="invalid-feedback">Query cannot be blank</div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-info">{{$t('search')}}</button>
        <button type="reset" class="btn btn-warning" @click.prevent="reset">
          {{$t('reset')}}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      query: null,
    };
  },
  validations() {
    return {
      query: { required },
    };
  },
  methods: {
    submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$emit("search", this.query);
      }
    },

    reset() {
      this.$refs.searchForm.reset();
      this.query = null;
      this.v$.$reset();
      this.$emit("resetSearch");
    },
  },
};
</script>
