import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./axios";
import VueToastr from "vue-toastr";
import { store } from "./store";
import i18n from './i18n'
import Lightbox from "vue-easy-lightbox"

Vue.config.productionTip = false;


Vue.prototype.$hostname = process.env.NODE_ENV=="development"
  ? "http://localhost:8000/vueworkspace/web/"
  : "https://workspaceadmin.kasturisanjaal.com/";

Vue.use(VueToastr);
Vue.use(Lightbox);

router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'np'
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
});

new Vue({
  router,
  i18n,
  store:store,
  render: (h) => h(App),
}).$mount("#app");
