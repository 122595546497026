<template>
  <div class="table-container">
    <div class="export-container">
      <button class="btn btn-info btn-sm" @click="exportPdf">
        {{ $t("printDownload") }}
      </button>
    </div>
    <div id="toprint" style="font-size: 12px">
      <div class="report-heading">
        <h2>{{$t('projectreport.taskReport')}}</h2>
        <div>
          {{
            $t("projectreport.from") +
            " : " +
            (date.from == null ? "" : date.from) +
            " " +
            $t("projectreport.to") +
            " : " +
            (date.to == null ? "" : date.to)
          }}
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm">
          <thead>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("projectreport.status") }}</th>
              <th>{{ $t("projectreport.issuedDate") }}</th>
              <th>{{ $t("projectreport.completionDate") }}</th>
              <th>{{ $t("projectreport.project") }}</th>
              <th>{{ $t("projectreport.task") }}</th>
              <th>{{ $t("projectreport.reportedBy") }}</th>
            </tr>
          </thead>
          <tbody v-if="list.length > 0">
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ ++index }}</td>
              <td>
                {{
                  item.completion_status == 1
                    ? $t("projectreport.completed")
                    : $t("projectreport.pending")
                }}
              </td>
              <td>{{ convertedDate(item.date_of_issue) }}</td>
              <td>{{ convertedDate(item.date_of_completion) }}</td>
              <td>{{ item.task_title }}</td>
              <td>{{ item.task_name }}</td>
              <td>{{ item.full_name + " (" + item.phone + ")" }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span class="no-record">{{ $t("norecord") }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "landscape",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
  },
};
</script>
