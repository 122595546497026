<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("project.map") }}
        <small>{{ $t("project.map") }}</small>
      </h2>
    </div>
    <div class="body">
      <div id="container">
        <div id="mapContainer"></div>
        <div class="form-group" v-if="status == 0">
          <button
            @click="saveLocation"
            type="submit"
            class="btn btn-danger btn-block submitButton"
            name="login-button"
            v-if="!isSubmitting"
          >
            Save
          </button>
          <button
            class="btn btn-danger btn-block submitButton loading"
            disabled
            v-else
          >
            <div class="spinner-border text-warning"></div>
            <div>{{ $t("loading") }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      status: this.$route.params.status,
      latitude: this.$route.params.latitude,
      longitude: this.$route.params.longitude,
      editableFlag: false,
      isSubmitting: false,
      mapData: {
        latitude: null,
        longitude: null,
      },
    };
  },
  mounted() {
    this.setupLeafletMap(this.mapData, this.status);
  },
  methods: {
    saveLocation() {
      if (this.mapData.latitude && this.mapData.longitude) {
        this.isSubmitting = true;
        const argument = {
          id: this.id,
          latitude: this.mapData.latitude,
          longitude: this.mapData.longitude,
        };
        axios
          .post("project/savelocation", argument, {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.$toastr.s("Location successfully saved.");
                this.$router.push("/" + this.$i18n.locale + "/project/list");
              } else {
                this.$toastr.e("Something went wrong. Try again later.");
                this.isSubmitting = false;
              }
            } else {
              this.$toastr.e("Login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toastr.e("Please select location");
      }
    },

    setupLeafletMap(mapData, status) {
      var marker = null;
      var myIcon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 30],
        iconAnchor: [2, 28],
        draggable: false,
      });
      var mymap = L.map("mapContainer").setView([27.5291, 84.3542], 10);
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mymap);
      //   if new then make map clickable
      if (status == "0") {
        mymap.on("click", function (e) {
          if (marker !== null) {
            mymap.removeLayer(marker);
          }
          mapData.latitude = e.latlng.lat;
          mapData.longitude = e.latlng.lng;
          marker = new L.marker(e.latlng, {
            draggable: true,
            icon: myIcon,
          }).addTo(mymap);
          marker.on("drag", function (e) {
            mapData.latitude = e.latlng.lat;
            mapData.longitude = e.latlng.lng;
          });
        });
      } else {
        marker = new L.marker([this.latitude, this.longitude], {
          icon: myIcon,
          draggable: false,
        }).addTo(mymap);
        marker.on("drag", function (e) {
          mapData.latitude = e.latlng.lat;
          mapData.longitude = e.latlng.lng;
        });
      }
    },
  },
};
</script>
