<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('bailVoucher.voucher')}}
            <small>{{$t('bailVoucher.addNote')}}</small>
          </h2>
        </div>
        <div class="body">
          <div class="source-heading-form">
              <Form :bail="bail" :type="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      bail: {
        fiscal_year_id: null,
        date:null,
        type:3,
        bail_type:null,
        narration:null,
        amount:null,
        reference_no:null,
        payment_mode:null,
        expenses_type:2,
        bail_voucher_no:null,
        dr:[
            {
                type:null,
                dr_id:null,
                dr_meta_id:null,
                dr_meta_meta_id:null,
                amount:null
            }
        ],
        cr:[
            {
                type:null,
                cr_id:null,
                cr_meta_id:null,
                cr_meta_meta_id:null,
                amount:null,
            }
        ]
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
