<template>
  <div>
    <div class="card kasturi_workspace_card">
      <div class="header" :class="'bg-' + $store.state.skin">
        <h2>
          {{ $t("report.workingarea") }}
          <small>{{ $t("report.workingareaNote") }}</small>
        </h2>
      </div>
      <div class="body">
        <div class="export-container">
          <button class="btn btn-info btn-sm" @click="exportPdf">{{$t('printDownload')}}</button>
        </div>
        <div class="department-form">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-dark"></div>
          </div>
          <div v-else>
            <div id="toprint">
              <div style="text-align: center">
                <div style="font-size: 20px; font-weight: bold">
                  {{ loggedInUser.organization_name }}
                </div>
                <div style="font-size: 16px; font-weight: bold">
                  {{ loggedInUser.organization_address }}
                </div>
                <div>{{ $t("report.fiscalYear") + fiscal.fiscal_year }}</div>
                <div>{{ $t("report.workingarea") }}</div>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-bordered reportTable"
                  style="font-size: 12px"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("sn") }}</th>
                      <th>{{ $t("report.workingarea") }}</th>
                      <th>{{ $t("report.appropriationAmount") }}</th>
                      <th>{{ $t("report.expensesAmount") }}</th>
                      <th>{{ $t("report.expensesPercentage") }}</th>
                      <th>{{ $t("report.stock") }}</th>
                    </tr>
                  </thead>
                  <tbody v-for="(area, index) in workingareaList" :key="index">
                    <tr style="background: #d9d9d9">
                      <td>{{ generateCounter(index) }}</td>
                      <td colspan="5">{{ area.working_area }}</td>
                    </tr>
                    <tr v-for="(task, jindex) in area.list" :key="jindex">
                      <td>{{ generateCounter(jindex) }}</td>
                      <td>{{ task.task_title }}</td>
                      <td>{{ $t("rs") + task.appropriation }}</td>
                      <td>{{ $t("rs") + task.expenses }}</td>
                      <td>
                        {{
                          isNaN(
                            (parseFloat(task.expenses) /
                              parseFloat(task.appropriation)) *
                              100
                          )
                            ? 0 + "%"
                            : (
                                (parseFloat(task.expenses) /
                                  parseFloat(task.appropriation)) *
                                100
                              ).toFixed(2) + "%"
                        }}
                      </td>
                      <td>
                        {{
                          $t("rs") +
                          (parseFloat(task.appropriation) -
                            parseFloat(task.expenses))
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"><strong>TOTAL</strong></td>
                      <td>
                        <strong>{{ $t("rs") + area.appropriation }}</strong>
                      </td>
                      <td>
                        <strong>{{ $t("rs") + area.expenses }}</strong>
                      </td>
                      <td>
                        <strong>{{ findPercentage(area) + "%" }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          $t("rs") +
                          (parseFloat(area.appropriation) -
                            parseFloat(area.expenses))
                        }}</strong>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"><strong>GRAND TOTAL</strong></td>
                      <td>
                        <strong>{{
                          "Rs." + findGrandAppropriation(workingareaList)
                        }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          "Rs." + findGrandExpenses(workingareaList)
                        }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          findGrandPercentage(workingareaList) + "%"
                        }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          "Rs." + findGrandRemaining(workingareaList)
                        }}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      isLoading: true,
      workingareaList: [],
      fiscal: {},
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    findGrandAppropriation(data) {
      var sum = 0;
      data.forEach((item) => {
        sum += parseFloat(item.appropriation);
      });
      return sum;
    },

    findGrandExpenses(data) {
      var sum = 0;
      data.forEach((item) => {
        sum += parseFloat(item.expenses);
      });
      return sum;
    },

    findGrandPercentage(data) {
      var sum = 0;
      var expenses = 0;
      data.forEach((item) => {
        sum += parseFloat(item.appropriation);
        expenses += parseFloat(item.expenses);
      });
      var percentage = ((expenses / sum) * 100).toFixed(2);
      return isNaN(percentage) ? 0 : percentage;
    },

    findPercentage(data) {
      var percentage = (
        (parseFloat(data.expenses) / parseFloat(data.appropriation)) *
        100
      ).toFixed(2);
      return isNaN(percentage) ? 0 : percentage;
    },

    findGrandRemaining(data) {
      var app = 0;
      var expenses = 0;
      data.forEach((item) => {
        app += parseFloat(item.appropriation);
        expenses += parseFloat(item.expenses);
      });
      return app - expenses;
    },

    generateCounter(counter) {
      return parseInt(counter) + 1;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    getReport() {
      axios
        .post(
          "report/workarea",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.workingareaList = res.data.workingarea;
            this.fiscal = res.data.fiscal;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.reportTable tbody tr {
  cursor: pointer;
}

.reportTable tbody tr:hover {
  background: #f1f1f1;
}
</style>
