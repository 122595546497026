<template>
  <div class="table-container">
    <table class="table table-bordered table-hover table-sm">
      <thead>
        <tr>
          <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="tbody-shimmer">
        <tr v-for="i in 10" :key="i">
          <td :colspan="heading.length + 1"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    heading: {
      type: Array,
      required: true,
    },
  },
};
</script>
