<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("payrollCategory.category") }}
        <small>{{ $t("payrollCategory.createNote") }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/category/list`"
          class="btn btn-info btn-sm"
          >{{ $t("payrollCategory.list") }}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :category="category" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      category: {
        designation: null,
        maximum_grade: null,
        grade: [],
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
