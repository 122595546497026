<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ heading }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-sm">
              <tbody>
                <tr>
                  <td>
                    <strong>{{ $t("staff.identity_number") }}</strong>
                  </td>
                  <td>{{ data.identity_number }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.name") }}</b>
                  </td>
                  <td>{{ data.full_name }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.gender") }}</b>
                  </td>
                  <td>
                    {{
                      data.gender == 1
                        ? $t("staff.male")
                        : data.gender == 0
                        ? $t("staff.female")
                        : $t("staff.thirdGender")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.maritalStatus") }}</b>
                  </td>
                  <td>
                    {{
                      data.marital_status == 1
                        ? $t("staff.married")
                        : $t("staff.single")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.disabledStatus") }}</b>
                  </td>
                  <td>
                    {{ data.disabled_status == 1 ? $t("yes") : $t("no") }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.paddress") }}</b>
                  </td>
                  <td>{{ data.permanent_address }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.taddress") }}</b>
                  </td>
                  <td>{{ data.temporary_address }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.pan") }}</b>
                  </td>
                  <td>{{ data.pan }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.phone") }}</b>
                  </td>
                  <td>{{ data.phone }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.email") }}</b>
                  </td>
                  <td>{{ data.email }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.doa") }}</b>
                  </td>
                  <td>
                    {{
                      this.$i18n.locale == "np" ? doa : data.date_of_appointment
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.department") }}</b>
                  </td>
                  <td>{{ data.department_name }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.shift") }}</b>
                  </td>
                  <td>{{ data.shift_name }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.designation") }}</b>
                  </td>
                  <td>{{ data.designationName }}</td>
                </tr>
                <tr>
                  <td>
                    <b>{{ $t("staff.employeeType") }}</b>
                  </td>
                  <td>
                    {{
                      data.employee_type == 1
                        ? $t("staff.permanent")
                        : data.employee_type == 2
                        ? $t("staff.temporary")
                        : $t("staff.serviceAgreement")
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th colspan="5">{{ $t("staffbank.staffBank") }}</th>
                </tr>
                <tr>
                  <th>{{ $t("staffbank.bank") }}</th>
                  <th>{{ $t("staffbank.branch") }}</th>
                  <th>{{ $t("staffbank.accountName") }}</th>
                  <th>{{ $t("staffbank.accountNumber") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody v-if="data.bankinfo.length > 0">
                <tr
                  v-for="(bank, index) in data.bankinfo"
                  :key="'bank' + index"
                >
                  <td>{{ bank.bank }}</td>
                  <td>{{ bank.branch }}</td>
                  <td>{{ bank.account_name }}</td>
                  <td>{{ bank.account_number }}</td>
                  <td>
                    <router-link
                      :to="`/${$i18n.locale}/dashboard/setup/staff/bank/update/${bank.id}`"
                      class="btn btn-warning btn-sm actionButton"
                      >{{ $t("update") }}</router-link
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5">
                    <span class="no-record">{{ $t("norecord") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{{ $t("staffeducation.examination") }}</th>
                  <th>{{ $t("staffeducation.board") }}</th>
                  <th>{{ $t("staffeducation.year") }}</th>
                  <th>{{ $t("staffeducation.symbol") }}</th>
                  <th>{{ $t("staffeducation.gpa_percentage") }}</th>
                  <th>{{ $t("staffeducation.school_college") }}</th>
                </tr>
              </thead>
              <tbody v-if="data.education.length > 0">
                <tr
                  v-for="(edu, index) in data.education"
                  :key="'education' + index"
                >
                  <td>{{ edu.examination }}</td>
                  <td>{{ edu.board }}</td>
                  <td>{{ edu.year }}</td>
                  <td>{{ edu.symbol }}</td>
                  <td>{{ edu.gpa_percentage }}</td>
                  <td>{{ edu.school_college }}</td>
                  <td>
                    <router-link
                      :to="`/${$i18n.locale}/dashboard/setup/staff/education/update/${edu.id}`"
                      class="btn btn-warning btn-sm actionButton"
                      >{{ $t("update") }}</router-link
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="no-record">{{ $t("norecord") }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Designation</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="data.experience.length > 0">
                <tr
                  v-for="(exp, index) in data.experience"
                  :key="'experience' + index"
                >
                  <td>{{ exp.organization_name }}</td>
                  <td>{{ exp.designation }}</td>
                  <td>{{ exp.from_date }}</td>
                  <td>{{ exp.to_date }}</td>
                  <td>
                    <router-link
                      :to="`/${$i18n.locale}/dashboard/setup/staff/workexperience/update/${exp.id}`"
                      class="btn btn-warning btn-sm actionButton"
                      >{{ $t("update") }}</router-link
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="no-record">{{ $t("norecord") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  data() {
    return {
      doa: null,
    };
  },
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.$store.state.staffBankList = this.data.bankinfo;
    this.$store.state.staffEducationList = this.data.education;
    this.$store.state.staffExperienceList = this.data.experience;
    var converter = adbs.ad2bs(
      moment(this.data.date_of_appointment).format("YYYY/MM/DD")
    ).ne;
    this.doa = converter.year + "-" + converter.month + "-" + converter.day;
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
