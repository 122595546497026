<template>
  <section>
    <aside
      id="leftsidebar"
      class="sidebar"
      :class="'theme-' + $store.state.skin"
    >
      <div class="user-info">
        <div class="profile-background bg0"></div>
        <div class="profile-background bg1"></div>
        <div class="profile-background bg2"></div>
        <div class="profile-background bg3"></div>
        <div class="profile-background bg4"></div>
        <div class="image">
          <img
            :src="$hostname + loggedInUser.logo"
            width="48"
            height="48"
            alt="User"
          />
        </div>
        <div class="info-container">
          <div
            class="name"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ loggedInUser.organization_name }}
          </div>
          <div class="email">{{ loggedInUser.email }}</div>
        </div>
      </div>
      <div class="menu">
        <div class="slimScrollDiv">
          <ul class="list">
            <li class="header">{{ $t("leftnav").mainNavigation }}</li>
            <li :class="$store.state.activeLink == 'home' ? 'active' : ''">
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/home`"
                ><i class="material-icons">home</i
                ><span>{{ $t("leftnav").dashboard }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'setup' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/setup/home`"
                ><i class="material-icons">build</i
                ><span>{{ $t("leftnav.setup") }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'payroll' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/payroll/home`"
                ><i class="material-icons">payment</i
                ><span>{{ $t("payheads.payroll") }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'project' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/project/home`"
                ><i class="material-icons">work</i
                ><span>{{ $t("leftnav.project") }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'budget' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/budget/home`"
                ><i class="material-icons">account_balance</i
                ><span>{{ $t("leftnav.budget") }}</span></router-link
              >
            </li>
            <li
              :class="
                $store.state.activeLink == 'appropriation' ? 'active' : ''
              "
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/appropriation/home`"
                ><i class="material-icons">work</i
                ><span>{{ $t("leftnav.appropriation") }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'bail' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/bail/home`"
                ><i class="material-icons">class</i
                ><span>{{ $t("bail.bail") }}</span></router-link
              >
            </li>
            <li
              :class="$store.state.activeLink == 'report' ? 'active' : ''"
              v-if="accessFlag"
            >
              <router-link
                class="toggled waves-effect waves-block"
                :to="`/${$i18n.locale}/dashboard/report/home`"
                ><i class="material-icons">pie_chart</i
                ><span>{{ $t("leftnav.report") }}</span></router-link
              >
            </li>
            <li class="header">LABELS</li>
            <li>
              <a href="javascript:void(0)" class="waves-effect waves-block"
                ><i class="material-icons col-red">help</i><span>Help</span></a
              >
            </li>
            <li>
              <a href="javascript:void(0)" class="waves-effect waves-block"
                ><i class="material-icons col-red">update</i
                ><span>What next ?</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="legal">
        <div class="copyright" :class="'footer-theme-' + $store.state.skin">
          © 2022
          <a href="https://www.kasturisanjaal.com" target="_blank"
            >Kasturi Sanjaal Pvt. Ltd.</a
          >
        </div>
      </div>
    </aside>
  </section>
</template>
<script>
export default {
  data() {
    return {
      developmentFlag: true,
      accessFlag: true,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.checkAccessFlag();
    this.developmentFlag = process.env.NODE_ENV == "development" ? true : false;
  },
  methods: {
    checkAccessFlag() {
      if (this.loggedInUser.trial_status == 2) {
        this.accessFlag = false;
      }
    },

    toggleAccordian(e) {
      var clickedDropDown = e.target.parentElement.getAttribute("data-item");
      e.target.parentElement.parentElement.parentElement.childNodes.forEach(
        (value) => {
          if (
            value.childNodes[0].childNodes.length != 0 &&
            clickedDropDown != value.firstChild.getAttribute("data-item")
          ) {
            value.classList.remove("show");
          }
        }
      );
      e.target.parentElement.parentElement.classList.toggle("show");
    },
  },
};
</script>

<style>
.sidebar {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Roboto", sans-serif;
  background: #fdfdfd;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  height: calc(100vh - 62px);
  position: fixed;
  top: 62px;
  left: 0;
  -webkit-box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  z-index: 11 !important;
}

.sidebar .user-info {
  padding: 13px 15px 12px 15px;
  white-space: nowrap;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  height: 135px;
  overflow: hidden;
}

.sidebar .user-info .image {
  margin-right: 12px;
  display: inline-block;
  position: absolute;
  z-index: 6;
  top: 5px;
}

.sidebar .user-info .image img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.sidebar .user-info .info-container {
  cursor: default;
  display: block;
  position: absolute;
  bottom: 15px;
  z-index: 6;
  font-weight: bold;
  text-shadow: 0px 0px 3px black;
}

.sidebar .user-info .info-container .name {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  max-width: 200px;
  color: #fff;
}

.sidebar .user-info .info-container .email {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  max-width: 200px;
  color: #fff;
}

.sidebar .menu .slimScrollDiv {
  overflow-y: auto;
  height: calc(100vh - 247px);
}

.sidebar .menu .list {
  list-style: none;
  padding-left: 0;
}

.sidebar .menu .list .header {
  background: #eee;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
}

.sidebar .menu .list li.active {
  background-color: transparent;
}

.sidebar .menu .list a {
  color: #747474;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  padding: 10px 13px;
  text-decoration: none;
  cursor: pointer;
}

.sidebar .menu .list i.material-icons {
  margin-top: 4px;
}

.sidebar .menu .list li.active > :first-child span {
  font-weight: bold;
}

.sidebar .menu .list a span {
  margin: 7px 0 7px 12px;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
}

.sidebar .legal {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 15px;
  overflow: hidden;
}

.sidebar .legal .copyright {
  font-size: 13px;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.theme-red .sidebar .legal .copyright a {
  color: #f44336 !important;
}

.sidebar .legal .copyright a {
  font-weight: bold;
  text-decoration: none;
}

.sidebar .menu .list .menu-toggle:after,
.sidebar .menu .list .menu-toggle:before {
  position: absolute;
  top: calc(50% - 14px);
  right: 17px;
  font-size: 19px;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar .menu .list .menu-toggle:before {
  content: "+";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sidebar .menu .list li.show > .menu-toggle:after {
  content: "–";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 1s;
}

.sidebar .menu .list li.show > .menu-toggle:before {
  content: "–";
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 1s;
}

.sidebar .menu .list .ml-menu {
  list-style: none;
  display: none;
  padding-left: 0;
}

.sidebar .menu .list li.show > ul.ml-menu {
  display: block;
}

.sidebar .menu .list .ml-menu > li > a {
  padding-left: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.sidebar .menu .list .ml-menu > li > ul.ml-menu {
  padding-left: 20px;
}

.sidebar .menu .list .ml-menu > li > a.sub-child {
  padding-left: 40px;
}

@media (max-width: 767px) {
  #leftsidebar {
    display: none;
  }

  .sidebar {
    top: 56px;
    z-index: 1001 !important;
  }
}

.profile-background {
  position: absolute;
  box-shadow: -1px 13px 2px 2px #6a6464;
}

.bg0 {
  height: 200px;
  width: 100px;
  transform: rotate(-18deg);
  right: -48px;
  top: 16px;
  z-index: 5;
}

.bg1 {
  height: 200px;
  width: 250px;
  background: red;
  right: -144px;
  top: -20px;
  z-index: 1;
  transform: rotate(-29deg);
  z-index: 4;
}

.bg2 {
  height: 250px;
  width: 250px;
  background: red;
  transform: rotate(-38deg);
  right: -92px;
  top: -36px;
  z-index: 3;
}

.bg3 {
  z-index: 2;
  height: 300px;
  width: 300px;
  background: red;
  transform: rotate(-48deg);
  top: -74px;
  right: -74px;
}

.bg4 {
  height: 200px;
  width: 200px;
  background: red;
  top: 0px;
  left: 0px;
  z-index: 1;
}
</style>
