<template>
  <div class="calendar-search-form">
    <div v-if="isLoading">
      <div class="spinner-border text-dark"></div>
    </div>
    <form ref="searchForm" @submit.prevent="submitForm" v-else>
      <div class="form-group required">
        <label class="control-label">{{$t('taskcalendar.project')}}</label>
        <select
          class="form-select"
          v-model="v$.projectId.$model"
          :class="{
            'is-invalid': v$.projectId.$error,
          }"
        >
          <option value="null">{{$t('select')}}</option>
          <option v-for="(project,index) in projectList" :key="index" :value="project.id">{{project.task_title}}</option>
        </select>

        <div class="invalid-feedback">{{$t('taskcalendar.projectError')}}</div>
      </div>
      <div class="form-group required">
        <label class="control-label">{{$t('taskcalendar.status')}}</label>
        <select
          class="form-select"
          v-model="v$.status.$model"
          :class="{
            'is-invalid': v$.status.$error,
          }"
        >
          <option value="null">{{$t('select')}}</option>
          <option value="2">{{$t('taskcalendar.all')}}</option>
          <option value="1">{{$t('taskcalendar.complete')}}</option>
          <option value="0">{{$t('taskcalendar.incomplete')}}</option>
        </select>

        <div class="invalid-feedback">{{$t('taskcalendar.statusError')}}</div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-info">{{$t('search')}}</button>
        <button type="reset" class="btn btn-warning" @click.prevent="reset">
          {{$t('reset')}}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isLoading:true,
      projectId: null,
      status: null,
      projectList:[],
    };
  },
  validations() {
    return {
      projectId: { required },
      status: { required },
    };
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    async getProjects() {
      await axios
        .post(
          "task/getproject",
          { },
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.projectList = res.data.project;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reset() {
      this.$refs.searchForm.reset();
      this.v$.$reset();
      this.projectId = null;
      this.status = null;
    },

    submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$emit('projectwisetask',{projectId:this.projectId,status:this.status});
      }
    },
  },
};
</script>
<style>
.calendar-search-form form button:first-child {
  margin-right: 5px;
}
</style>
