<template>
  <div class="login-body">
    <div class="container">
      <div class="login_container">
        <form @submit.prevent="submitForm">
          <div class="signup-content-container">
            <h2 class="text-center">{{$t("registration").registrationForm}}</h2>
            <div class="signup-contents">
              <div class="signup_form">
                <div class="form-group required">
                  <label>{{$t('registration').organization}}</label>
                  <input
                    v-model="v$.organization.organizationName.$model"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': v$.organization.organizationName.$error,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{$t('registration').organizationError}}
                  </div>
                </div>
                <div class="form-group field-organization-pan_vat required">
                  <label>{{$t('registration').panvat}}</label>
                  <input
                    v-model="v$.organization.panVat.$model"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': v$.organization.panVat.$error }"
                  />

                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.panVat.required.$response"
                  >
                    {{$t('registration').panvatEmptyError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.panVat.minLength.$response"
                  >
                    {{$t('registration').panvatCountError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.panVat.isUnique.$response"
                  >
                    {{$t('registration').panvatAlreadyError}}
                  </div>
                </div>
                <div class="form-group field-organization-email required">
                  <label>{{$t('registration').email}}</label>
                  <input
                    v-model="v$.organization.email.$model"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': v$.organization.email.$error }"
                  />

                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.email.required.$response"
                  >
                    {{$t('registration').emailEmptyError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.email.email.$response"
                  >
                    {{$t('registration').emailInvalidError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.email.minLength.$response"
                  >
                    {{$t('registration').emailCountError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.email.isUnique.$response"
                  >
                    {{$t('registration').emailAlreadyError}}
                  </div>
                </div>
                <div class="form-group field-organization-phone1 required">
                  <label>{{$t('registration').phone}}</label>
                  <div
                    class="input-group"
                    :class="{ 'is-invalid': v$.organization.phone1.$error }"
                  >
                    <span class="input-group-text">+977</span>
                    <input
                      v-model="v$.organization.phone1.$model"
                      type="number"
                      class="form-control"
                    />
                  </div>

                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.phone1.required.$response"
                  >
                    {{$t('registration').phoneEmptyError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.phone1.minLength.$response"
                  >
                    {{$t('registration').phoneCountError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.phone1.isUnique.$response"
                  >
                    {{$t('registration').phoneAlreadyError}}
                  </div>
                </div>
                <div class="form-group field-organization-password required">
                  <label>{{$t('registration').password}}</label>
                  <input
                    v-model="v$.organization.password.$model"
                    type="password"
                    class="form-control"
                    :class="{ 'is-invalid': v$.organization.password.$error }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.password.required.$response"
                  >
                    {{$t('registration').passwordEmptyError}}
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!v$.organization.password.minLength.$response"
                  >
                    {{$t('registration').passwordCountError}}
                  </div>
                </div>
              </div>
              <div class="organization_map">
                <div
                  class="form-group field-organization-organization_address required"
                >
                  <label>{{$t('registration').address}}</label>
                  <input
                    v-model="v$.organization.organizationAddress.$model"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': v$.organization.organizationAddress.$error,
                    }"
                  />
                  <div class="invalid-feedback">{{$t('registration').addressError}}</div>
                </div>
                <div class="required">
                  <label>{{$t('registration').map}}</label>
                </div>
                <div id="container">
                  <div id="mapContainer"></div>
                </div>

                <div class="form-group field-organization-lattitude required">
                  <input
                    v-model="v$.organization.lattitude.$model"
                    type="hidden"
                    id="organization-lattitude"
                    class="form-control"
                    :class="{ 'is-invalid': v$.organization.lattitude.$error }"
                  />

                  <div class="invalid-feedback">{{$t('registration').latitute}}</div>
                </div>
                <div class="form-group field-organization-longitute required">
                  <input
                    v-model="v$.organization.longitute.$model"
                    type="hidden"
                    id="organization-longitute"
                    :class="{ 'is-invalid': v$.organization.longitute.$error }"
                  />
                  <div class="invalid-feedback">{{$t('registration').longitute}}</div>
                </div>
              </div>

              <div class="organization_logo">
                <div class="required"><label>{{$t('registration').logo}}</label></div>
                <div class="form-group field-organization-file required">
                  <label id="logo_label" for="organization-file">
                    <div class="company-logo-container" v-if="logoFlag">
                      <img :src="logoUrl" alt="Logo" />
                    </div>
                    <span v-else>{{$t('registration').logo}}</span>
                  </label>
                  <input
                    @change="handleFile"
                    type="file"
                    id="organization-file"
                    class="form-control-file"
                    accept="image/*"
                    :class="{ 'is-invalid': v$.organization.logo.$error }"
                  />

                  <div class="invalid-feedback">{{$t('registration').logoError}}</div>
                </div>
                <vue-recaptcha
                  @verify="verifiedCaptcha"
                  @expired="resetCaptcha"
                  @error="resetCaptcha"
                  ref="recaptcha"
                  sitekey="6LcVNmoeAAAAABV63N2i6dTtd01KhCidFvFEAq3F"
                />
                <div
                  class="invalid-captcha-feedback"
                  v-if="!organization.recaptchaVerified"
                  id="captcha-error-message"
                ></div>
                <br />
                <div class="form-group">
                  <button
                    ref="submitButton"
                    type="submit"
                    class="btn btn-danger btn-block submitButton"
                    name="login-button"
                    v-if="!isSubmitting"
                  >
                    <div>{{$t('registration').register}}</div>
                  </button>
                  <button
                    class="btn btn-danger btn-block submitButton loading"
                    disabled
                    v-else
                  >
                    <div class="spinner-border text-warning"></div>
                    <div>{{$t('loading')}}</div>
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center">
              <router-link
                :to="`/${$i18n.locale}/login`"
                class="login_signup_link"
                >{{$t('registration').login}}</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  requiredIf,
  helpers,
  minLength,
} from "@vuelidate/validators";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
const { withAsync } = helpers;

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      logoFlag: false,
      logoUrl: null,
      organization: {
        organizationName: null,
        panVat: null,
        email: null,
        phone1: null,
        password: null,
        organizationAddress: null,
        lattitude: null,
        longitute: null,
        recaptchaVerified: false,
        logo: [],
      },
    };
  },
  validations() {
    return {
      organization: {
        organizationName: { required },
        panVat: {
          required,
          minLength: minLength(9),
          isUnique: withAsync(this.isUnique),
        },
        email: {
          required,
          email,
          minLength: minLength(10),
          isUnique: withAsync(this.isUnique),
        },
        phone1: {
          required,
          minLength: minLength(6),
          isUnique: withAsync(this.isUnique),
        },
        password: { required, minLength: minLength(6) },
        organizationAddress: { required },
        lattitude: { required },
        longitute: { required },
        logo: {
          required: requiredIf(function () {
            return this.organization.logo.length == 0;
          }),
        },
      },
    };
  },

  mounted() {
    this.setupLeafletMap(this.organization);
  },
  components: {
    VueRecaptcha,
  },
  methods: {
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },

    setupLeafletMap(organization) {
      var marker = null;
      var myIcon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 30],
        iconAnchor: [2, 28],
      });
      var mymap = L.map("mapContainer").setView([27.5291, 84.3542], 10);
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mymap);
      mymap.on("click", function (e) {
        if (marker !== null) {
          mymap.removeLayer(marker);
        }
        organization.lattitude = e.latlng.lat;
        organization.longitute = e.latlng.lng;
        document.getElementById("organization-lattitude").value = e.latlng.lat;
        document.getElementById("organization-longitute").value = e.latlng.lng;
        marker = new L.marker(e.latlng, {
          draggable: true,
          icon: myIcon,
        }).addTo(mymap);
        marker.on("drag", function (e) {
          document.getElementById("organization-lattitude").value =
            e.latlng.lat;
          document.getElementById("organization-longitute").value =
            e.latlng.lng;
          organization.lattitude = e.latlng.lat;
          organization.longitute = e.latlng.lng;
        });
      });
    },

    async isUnique(value) {
      if (value) {
        try {
          const response = await axios.post("organization/uniquecheck", {
            value: value,
          });
          return !response.data.flag;
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    },

    verifiedCaptcha() {
      document.getElementById("captcha-error-message").innerText = "";
      this.organization.recaptchaVerified = true;
    },

    resetCaptcha() {
      this.organization.recaptchaVerified = false;
    },

    handleFile(e) {
      this.logoFlag = true;
      this.logoUrl = URL.createObjectURL(e.target.files[0]);
      this.organization.logo.push(e.target.files[0]);
    },

    async submitForm() {
      if (this.organization.recaptchaVerified == false) {
        document.getElementById("captcha-error-message").innerText =
          "Captcha is not verified";
      }
      this.v$.$touch();
      if (
        !this.v$.$invalid &&
        this.organization.recaptchaVerified &&
        this.logoFlag
      ) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.organization) {
          formData.set(key, this.organization[key]);
        }
        formData.append("fileList", []);
        formData.append("fileList[0]", this.organization.logo[0]);
        await axios
          .post("organization/signup", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              this.$toastr.s("Enter the code sent in your email.");
              this.$router.push(
                "/"+this.$i18n.locale+"/signup/accountverification/" + this.organization.email
              );
            } else {
              this.$toastr.e(
                "Alert!",
                "Something went wrong. Try again later."
              );
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
<style>
.login_container .signup-content-container {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  box-shadow: 15px 20px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 2%;
}

.login_container .content-container .login_form > h2,
.login_container .signup-content-container > h2 {
  margin-bottom: 15px;
  font-weight: 800;
  font-size: 30px;
  color: #de6262;
}

.login_container .content-container .login_form > h2:after,
.login_container .signup-content-container > h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #feb58a;
  display: block;
  margin-top: 6px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.login_container .signup-content-container .signup-contents {
  display: flex;
}

.signup_form,
.organization_map,
.organization_logo {
  flex: 1;
  padding: 0% 2%;
  border-right: 1px solid #e1e0e0;
}

#logo_label {
  border: 1px solid #ced4da;
  height: 200px;
  width: 200px;
  position: relative;
  cursor: pointer;
}

#logo_label > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo_label:after {
  content: "" !important;
}

input#organization-file {
  display: none;
}

.company-logo-container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.company-logo-container > img {
  height: 100%;
  width: 100%;
}

.organization_logo {
  border-right: none;
}

.invalid-captcha-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

#mapContainer {
  height: 300px;
  width: 100%;
  margin-bottom: 10px;
}
</style>
