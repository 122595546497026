<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("kharcha.heading") }}
        <small>{{ $t("kharcha.addNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="source-heading-form">
        <Form :expensesdetermination="expensesdetermination" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  created() {
    this.$emit('close');
  },
  data() {
    return {
      expensesdetermination: {
        fiscal_year_id: null,
        task_id: null,
        task_meta_id: null,
        expenses_id: null,
        expenses_meta_id:null,
        qty: null,
        unit:null,
        rate: null,
        amount: null,
        description: null,
        type:null,
        source: [
          {
            source_id: null,
            amount: null,
          },
        ],
      },
    };
  },
};
</script>
