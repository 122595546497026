<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("chequecancel.cancel") }}
        <small>{{ $t("chequecancel.cancelNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :cheque="cheque" :type="1" @displayCheque="displayCheque" :bankType="0"/>
        <hr />
        <Table
          :list="chequeList"
          :heading="tableHeading"
          @removeItem="removeItem"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";
import Table from "./table.vue";

export default {
  data() {
    return {
      isLoading: false,
      chequeList: [],
      tableHeading: [this.$t("sn"), this.$t("chequecancel.cheque")],
      cheque: {
        bankId: null,
        type: null,
      },
    };
  },
  components: {
    Form,
    Table,
  },
  created() {
    this.$emit('close');
  },
  methods: {
    displayCheque(data) {
      this.chequeList = data;
    },

    removeItem(data) {
      this.chequeList.forEach((item, index) => {
        if (item.id == data.id) {
          this.chequeList.splice(index, 1);
          return false;
        }
        return true;
      });
    },
  },
};
</script>
