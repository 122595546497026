<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t('payrollCategory.category') }}</label>
      <input
        v-model="v$.category.designation.$model"
        :class="{
          'is-invalid': v$.category.designation.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t('payrollCategory.categoryGrade') }}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{ $t('payrollCategory.maxGrade') }}</label>
      <input
        v-model="v$.category.maximum_grade.$model"
        :class="{
          'is-invalid': v$.category.maximum_grade.$error,
        }"
        @keyup="cloneGrade"
        type="number"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t('payrollCategory.maxGradeError') }}</div>
    </div>
    <div class="table-responsive" v-if="category.grade.length>0">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>{{$t('payrollCategory.category')}}</th>
            <th>{{$t('payrollCategory.basicSalary')}}</th>
            <th>{{$t('payrollCategory.gradeNo')}}</th>
            <th>{{$t('payrollCategory.gradeRate')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cat, index) in category.grade" :key="index">
            <td>
              <div class="form-group required">
                <input
                  v-model="cat.grade"
                  :class="{
                    'is-invalid':
                      v$.category.grade.$each.$response.$errors[index].grade
                        .length,
                  }"
                  type="text"
                  class="form-control"
                />

                <div class="invalid-feedback">
                  {{ $t('payrollCategory.gradeError') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="cat.basic_salary"
                  :class="{
                    'is-invalid':
                      v$.category.grade.$each.$response.$errors[index]
                        .basic_salary.length,
                  }"
                  type="number"
                  step="0.01"
                  class="form-control"
                />

                <div class="invalid-feedback">
                  {{ $t('payrollCategory.basicSalaryError') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="cat.grade_no"
                  :class="{
                    'is-invalid':
                      v$.category.grade.$each.$response.$errors[index].grade_no
                        .length,
                  }"
                  type="number"
                  class="form-control"
                />

                <div class="invalid-feedback">
                  {{ $t('payrollCategory.gradeNoError') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="cat.grade_rate"
                  :class="{
                    'is-invalid':
                      v$.category.grade.$each.$response.$errors[index]
                        .grade_rate.length,
                  }"
                  type="number"
                  step="0.01"
                  class="form-control"
                />

                <div class="invalid-feedback">
                  {{ $t('payrollCategory.gradeRateError') }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("department").add : $t("department").update }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      category: {
        designation: { required },
        maximum_grade: { required },
        grade: {
          $each: helpers.forEach({
            grade: { required },
            grade_no: { required },
            basic_salary: { required },
            grade_rate: { required },
          }),
        },
      },
    };
  },
  methods: {
    cloneGrade() {
      this.category.grade = [];
      for (var i = 0; i < this.category.maximum_grade; i++) {
        this.category.grade.push({
          grade: null,
          grade_no: null,
          basic_salary: null,
          grade_rate: null,
        });
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.category) {
          formData.set(key, this.category[key]);
        }
        formData.append("grade", JSON.stringify(this.category.grade));
        var url =
          this.type == 1 ? "payrollcategory/add" : "payrollcategory/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Category successfully added."
                    : "Category successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/payroll/category/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
