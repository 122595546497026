<template>
  <div>
    <Topnav />
    <div class="info-card">
      <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{ $t("updateinfo").heading }}
            <small>{{ $t("updateinfo").note }}</small>
          </h2>
        </div>
        <div class="body">
          <div class="department-form">
            <form @submit.prevent="submitForm">
              <div class="form-group required">
                <label>{{ $t("updateinfo").organization }}</label>
                <input
                  v-model="v$.organization.organizationName.$model"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.organization.organizationName.$error,
                  }"
                />
                <div class="invalid-feedback">
                  {{ $t("updateinfo").organizationError }}
                </div>
              </div>
              <div class="form-group field-organization-pan_vat required">
                <label>Pan Vat</label>
                <input
                  v-model="v$.organization.panVat.$model"
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': v$.organization.panVat.$error }"
                />

                <div
                  class="invalid-feedback"
                  v-if="!v$.organization.panVat.required.$response"
                >
                  {{ $t("updateinfo").panvat }}
                </div>
                <div
                  class="invalid-feedback"
                  v-if="!v$.organization.panVat.minLength.$response"
                >
                  {{ $t("updateinfo").panvatCountError }}
                </div>
              </div>
              <div class="form-group field-organization-phone1 required">
                <label>{{ $t("updateinfo").phone }}</label>
                <div
                  class="input-group"
                  :class="{ 'is-invalid': v$.organization.phone1.$error }"
                >
                  <span class="input-group-text">+977</span>
                  <input
                    v-model="v$.organization.phone1.$model"
                    type="number"
                    class="form-control"
                  />
                </div>

                <div
                  class="invalid-feedback"
                  v-if="!v$.organization.phone1.required.$response"
                >
                  {{ $t("updateinfo").phoneEmptyError }}
                </div>
                <div
                  class="invalid-feedback"
                  v-if="!v$.organization.phone1.minLength.$response"
                >
                  {{ $t("updateinfo").phoneCountError }}
                </div>
              </div>
              <div class="form-group required">
                <label>{{ $t("updateinfo").address }}</label>
                <input
                  v-model="v$.organization.organizationAddress.$model"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.organization.organizationAddress.$error,
                  }"
                />
                <div class="invalid-feedback">
                  {{ $t("updateinfo").addressError }}
                </div>
              </div>
              <div class="required">
                <label>{{ $t("updateinfo").map }}</label>
              </div>
              <div id="container">
                <div id="mapContainer"></div>
              </div>
              <div class="form-group">
                <input
                  v-model="v$.organization.lattitude.$model"
                  type="hidden"
                  id="organization-lattitude"
                  :class="{ 'is-invalid': v$.organization.lattitude.$error }"
                />

                <div class="invalid-feedback">
                  {{ $t("updateinfo").latitute }}
                </div>
              </div>
              <div class="form-group">
                <input
                  v-model="v$.organization.longitute.$model"
                  type="hidden"
                  id="organization-longitute"
                  :class="{ 'is-invalid': v$.organization.longitute.$error }"
                />
                <div class="invalid-feedback">
                  {{ $t("updateinfo").longitute }}
                </div>
              </div>
              <div class="required">
                <label>{{ $t("updateinfo").logo }}</label>
              </div>
              <div class="form-group field-organization-file required">
                <label id="logo_label" for="organization-file">
                  <div class="company-logo-container">
                    <img :src="logoUrl" alt="Logo" />
                  </div>
                </label>
                <input
                  @change="handleFile"
                  type="file"
                  id="organization-file"
                  class="form-control-file"
                  accept="image/*"
                />
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-danger btn-block submitButton"
                  name="login-button"
                  v-if="!isSubmitting"
                >
                  {{ $t("updateinfo").update }}
                </button>
                <button
                  class="btn btn-danger btn-block submitButton loading"
                  disabled
                  v-else
                >
                  <div class="spinner-border text-warning"></div>
                  <div>{{ $t("loading") }}</div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topnav from "../dashboard/topnav.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import L from "leaflet";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      logoUrl:
        this.$hostname + JSON.parse(localStorage.getItem("loggedInInfo")).logo,
      isSubmitting: false,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      organization: {
        organizationName: JSON.parse(localStorage.getItem("loggedInInfo"))
          .organization_name,
        panVat: JSON.parse(localStorage.getItem("loggedInInfo")).pan_vat,
        phone1: JSON.parse(localStorage.getItem("loggedInInfo")).phone1,
        lattitude: JSON.parse(localStorage.getItem("loggedInInfo")).lattitude,
        longitute: JSON.parse(localStorage.getItem("loggedInInfo")).longitute,
        organizationAddress: JSON.parse(localStorage.getItem("loggedInInfo"))
          .organization_address,
        logo: [],
      },
    };
  },
  components: {
    Topnav,
  },
  validations() {
    return {
      organization: {
        organizationName: { required },
        panVat: {
          required,
          minLength: minLength(9),
        },
        phone1: {
          required,
          minLength: minLength(6),
        },
        organizationAddress: { required },
        lattitude: { required },
        longitute: { required },
      },
    };
  },
  mounted() {
    this.setupLeafletMap(this.organization);
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.organization) {
          formData.set(key, this.organization[key]);
        }
        formData.set("email", this.loggedInUser.email);
        formData.append("fileList", []);
        formData.append("fileList[0]", this.organization.logo[0]);
        await axios
          .post("organization/updateinfo", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              this.$toastr.s("Organization info successfully updated.");
              localStorage.setItem(
                "loggedInInfo",
                JSON.stringify(res.data.userinfo)
              );
              this.$router.push("/" + this.$i18n.locale + "/dashboard");
            } else {
              this.$toastr.e(
                "Alert!",
                "Something went wrong. Try again later."
              );
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },

    handleFile(e) {
      this.logoFlag = true;
      this.logoUrl = URL.createObjectURL(e.target.files[0]);
      this.organization.logo.push(e.target.files[0]);
    },

    setupLeafletMap(organization) {
      var marker = null;
      var myIcon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 30],
        iconAnchor: [2, 28],
      });
      var mymap = L.map("mapContainer").setView([27.5291, 84.3542], 10);
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mymap);
      marker = new L.marker(
        [this.loggedInUser.lattitude, this.loggedInUser.longitute],
        { draggable: true, icon: myIcon }
      ).addTo(mymap);
      marker.on("drag", function (e) {
        document.getElementById("organization-lattitude").value = e.latlng.lat;
        document.getElementById("organization-longitute").value = e.latlng.lng;
        organization.lattitude = e.latlng.lat;
        organization.longitute = e.latlng.lng;
      });
      mymap.on("click", function (e) {
        if (marker !== null) {
          mymap.removeLayer(marker);
        }
        organization.lattitude = e.latlng.lat;
        organization.longitute = e.latlng.lng;
        document.getElementById("organization-lattitude").value = e.latlng.lat;
        document.getElementById("organization-longitute").value = e.latlng.lng;
        marker = new L.marker(e.latlng, {
          draggable: true,
          icon: myIcon,
        }).addTo(mymap);
        marker.on("drag", function (e) {
          document.getElementById("organization-lattitude").value =
            e.latlng.lat;
          document.getElementById("organization-longitute").value =
            e.latlng.lng;
          organization.lattitude = e.latlng.lat;
          organization.longitute = e.latlng.lng;
        });
      });
    },
  },
};
</script>
