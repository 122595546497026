<template>
  <form @submit.prevent="submitForm">
    <div class="signup-content-container">
      <div class="signup-contents">
        <div class="signup_form">
          <div class="form-group required">
            <label>{{ $t("registration").organization }}</label>
            <input
              v-model="v$.organization.organizationName.$model"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': v$.organization.organizationName.$error,
              }"
            />
            <div class="invalid-feedback">
              {{ $t("registration").organizationError }}
            </div>
          </div>
          <div class="form-group field-organization-pan_vat required">
            <label>{{ $t("registration").panvat }}</label>
            <input
              v-model="v$.organization.panVat.$model"
              type="number"
              class="form-control"
              :class="{ 'is-invalid': v$.organization.panVat.$error }"
            />

            <div
              class="invalid-feedback"
              v-if="!v$.organization.panVat.required.$response"
            >
              {{ $t("registration").panvatEmptyError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.panVat.minLength.$response"
            >
              {{ $t("registration").panvatCountError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.panVat.isUnique.$response"
            >
              {{ $t("registration").panvatAlreadyError }}
            </div>
          </div>
          <div class="form-group field-organization-email required">
            <label>{{ $t("registration").email }}</label>
            <input
              v-model="v$.organization.email.$model"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.organization.email.$error }"
            />

            <div
              class="invalid-feedback"
              v-if="!v$.organization.email.required.$response"
            >
              {{ $t("registration").emailEmptyError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.email.email.$response"
            >
              {{ $t("registration").emailInvalidError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.email.minLength.$response"
            >
              {{ $t("registration").emailCountError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.email.isUnique.$response"
            >
              {{ $t("registration").emailAlreadyError }}
            </div>
          </div>
          <div class="form-group field-organization-phone1 required">
            <label>{{ $t("registration").phone }}</label>
            <div
              class="input-group"
              :class="{ 'is-invalid': v$.organization.phone1.$error }"
            >
              <span class="input-group-text">+977</span>
              <input
                v-model="v$.organization.phone1.$model"
                type="number"
                class="form-control"
              />
            </div>

            <div
              class="invalid-feedback"
              v-if="!v$.organization.phone1.required.$response"
            >
              {{ $t("registration").phoneEmptyError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.phone1.minLength.$response"
            >
              {{ $t("registration").phoneCountError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.phone1.isUnique.$response"
            >
              {{ $t("registration").phoneAlreadyError }}
            </div>
          </div>
          <div
            class="form-group field-organization-password required"
            v-if="type == 1"
          >
            <label>{{ $t("registration").password }}</label>
            <input
              v-model="v$.organization.password.$model"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': v$.organization.password.$error }"
            />
            <div
              class="invalid-feedback"
              v-if="!v$.organization.password.required.$response"
            >
              {{ $t("registration").passwordEmptyError }}
            </div>
            <div
              class="invalid-feedback"
              v-if="!v$.organization.password.minLength.$response"
            >
              {{ $t("registration").passwordCountError }}
            </div>
          </div>
        </div>
        <div class="organization_map">
          <div
            class="form-group field-organization-organization_address required"
          >
            <label>{{ $t("registration").address }}</label>
            <input
              v-model="v$.organization.organizationAddress.$model"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': v$.organization.organizationAddress.$error,
              }"
            />
            <div class="invalid-feedback">
              {{ $t("registration").addressError }}
            </div>
          </div>
          <div class="required">
            <label>{{ $t("registration").map }}</label>
          </div>
          <div id="container">
            <div id="mapContainer"></div>
          </div>

          <div class="form-group field-organization-lattitude required">
            <input
              v-model="v$.organization.lattitude.$model"
              type="hidden"
              id="organization-lattitude"
              class="form-control"
              :class="{ 'is-invalid': v$.organization.lattitude.$error }"
            />

            <div class="invalid-feedback">
              {{ $t("registration").latitute }}
            </div>
          </div>
          <div class="form-group field-organization-longitute required">
            <input
              v-model="v$.organization.longitute.$model"
              type="hidden"
              id="organization-longitute"
              :class="{ 'is-invalid': v$.organization.longitute.$error }"
            />
            <div class="invalid-feedback">
              {{ $t("registration").longitute }}
            </div>
          </div>
        </div>

        <div class="organization_logo">
          <div class="required">
            <label>{{ $t("registration").logo }}</label>
          </div>
          <div class="form-group field-organization-file">
            <label id="logo_label" for="organization-file">
              <div class="company-logo-container" v-if="logoFlag">
                <img :src="logoUrl" alt="Logo" />
              </div>
              <span v-else>{{ $t("registration").logo }}</span>
            </label>
            <input
              @change="handleFile"
              type="file"
              id="organization-file"
              class="form-control-file"
              accept="image/*"
            />
          </div>
          <div class="form-group">
            <button
              ref="submitButton"
              type="submit"
              class="btn btn-danger btn-block submitButton"
              name="login-button"
              v-if="!isSubmitting"
            >
              <div>{{ type == 1 ? $t("add") : $t("update") }}</div>
            </button>
            <button
              class="btn btn-danger btn-block submitButton loading"
              disabled
              v-else
            >
              <div class="spinner-border text-warning"></div>
              <div>{{ $t("loading") }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
const { withAsync } = helpers;

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      logoFlag: false,
      isSubmitting: false,
    };
  },
  validations() {
    return {
      organization: {
        organizationName: { required },
        panVat: {
          required,
          minLength: minLength(9),
          isUnique: this.type == 1 ? withAsync(this.isUnique) : true,
        },
        email: {
          required,
          email,
          minLength: minLength(10),
          isUnique: this.type == 1 ? withAsync(this.isUnique) : true,
        },
        phone1: {
          required,
          minLength: minLength(6),
          isUnique: this.type == 1 ? withAsync(this.isUnique) : true,
        },
        password: { required, minLength: minLength(6) },
        organizationAddress: { required },
        lattitude: { required },
        longitute: { required },
      },
    };
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.setupLeafletMap(this.organization);
  },
  methods: {
    setupLeafletMap(organization) {
      var marker = null;
      var myIcon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 30],
        iconAnchor: [2, 28],
      });
      var mymap = L.map("mapContainer").setView([27.5291, 84.3542], 10);
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mymap);
      if (this.type == 2) {
        marker = new L.marker(
          [this.organization.lattitude, this.organization.longitute],
          { draggable: true, icon: myIcon }
        ).addTo(mymap);
        marker.on("drag", function (e) {
          document.getElementById("organization-lattitude").value =
            e.latlng.lat;
          document.getElementById("organization-longitute").value =
            e.latlng.lng;
          organization.lattitude = e.latlng.lat;
          organization.longitute = e.latlng.lng;
        });
      }
      mymap.on("click", function (e) {
        if (marker !== null) {
          mymap.removeLayer(marker);
        }
        organization.lattitude = e.latlng.lat;
        organization.longitute = e.latlng.lng;
        document.getElementById("organization-lattitude").value = e.latlng.lat;
        document.getElementById("organization-longitute").value = e.latlng.lng;
        marker = new L.marker(e.latlng, {
          draggable: true,
          icon: myIcon,
        }).addTo(mymap);
        marker.on("drag", function (e) {
          document.getElementById("organization-lattitude").value =
            e.latlng.lat;
          document.getElementById("organization-longitute").value =
            e.latlng.lng;
          organization.lattitude = e.latlng.lat;
          organization.longitute = e.latlng.lng;
        });
      });
    },

    async isUnique(value) {
      if (value) {
        try {
          const response = await axios.post("organization/uniquecheck", {
            value: value,
          });
          return !response.data.flag;
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    },

    handleFile(e) {
      this.logoFlag = true;
      this.logoUrl = URL.createObjectURL(e.target.files[0]);
      this.organization.logo.push(e.target.files[0]);
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.organization) {
          formData.set(key, this.organization[key]);
        }
        formData.append("fileList", []);
        formData.append("fileList[0]", this.organization.logo[0]);
        var url = this.type == 1 ? "branch/create" : "branch/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.type == 1
                  ? this.$toastr.s("Ward or branch successfully created.")
                  : this.$toastr.s("Ward or branch successfully updated.");
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/setup/branch/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            } else {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
