var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("employeemapping.staff")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.employee.staff_id.$error,
      }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.employee.staff_id.$model),expression:"v$.employee.staff_id.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.employee.staff_id.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.employee.staff_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.staffList),function(staff,index){return _c('option',{key:'Staff' + index,domProps:{"value":staff.id}},[_vm._v(" "+_vm._s(staff.full_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("employeemapping.staffError")))])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("employeemapping.designation")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.employee.payroll_category_id.$error,
      }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.employee.payroll_category_id.$model),expression:"v$.employee.payroll_category_id.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.employee.payroll_category_id.$error,
        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.employee.payroll_category_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.updateGrade()}]}},_vm._l((_vm.categoryList),function(cat,index){return _c('option',{key:'Category_' + index,domProps:{"value":cat.id}},[_vm._v(" "+_vm._s(cat.designation)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("employeemapping.designationError"))+" ")])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("employeemapping.grade")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.employee.payroll_grade_id.$error,
      }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.employee.payroll_grade_id.$model),expression:"v$.employee.payroll_grade_id.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.employee.payroll_grade_id.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.employee.payroll_grade_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.gradeList),function(grade,index){return _c('option',{key:'Grade_' + index,domProps:{"value":grade.id}},[_vm._v(" "+_vm._s(grade.grade)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("employeemapping.gradeError")))])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("employeemapping.inaccessibleStatus")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.employee.inaccessible_tax_status.$error,
      }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.employee.inaccessible_tax_status.$model),expression:"v$.employee.inaccessible_tax_status.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.employee.inaccessible_tax_status.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.employee.inaccessible_tax_status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("yes")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("no")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("employeemapping.inaccessibleStatusError"))+" ")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("employeemapping.inaccessibleAllowance")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.employee.inaccessible_id.$error,
      }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.employee.inaccessible_id.$model),expression:"v$.employee.inaccessible_id.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.employee.inaccessible_id.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.employee.inaccessible_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.inaccessibleList),function(inaccess,index){return _c('option',{key:'Inaccess_' + index,domProps:{"value":inaccess.id}},[_vm._v(" "+_vm._s(inaccess.type_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("employeemapping.inaccessibleAllowanceError"))+" ")])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("employeemapping.particular")))]),_c('th',[_vm._v(_vm._s(_vm.$t("action")))])])]),_c('tbody',_vm._l((_vm.employee.services),function(service,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.employee.services.$each.$response.$errors[index]
                      .payroll_payhead_id.length,
                }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(service.payroll_payhead_id),expression:"service.payroll_payhead_id"}],staticClass:"form-select",class:{
                    'is-invalid':
                      _vm.v$.employee.services.$each.$response.$errors[index]
                        .payroll_payhead_id.length,
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(service, "payroll_payhead_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.payheadList),function(payhead,index){return _c('option',{key:'Payhead_' + index,domProps:{"value":payhead.id}},[_vm._v(" "+_vm._s(payhead.headings)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("employeemapping.particularError"))+" ")])])]),_c('td',[_c('div',{staticClass:"voucher-dynamic-form-button-container"},[_c('div',{staticClass:"btn btn-success btn-xs",on:{"click":_vm.clonePayheadItem}},[_vm._v(" + ")]),(index != 0)?_c('div',{staticClass:"btn btn-warning btn-xs",on:{"click":function($event){return _vm.deletePayheadClone(index)}}},[_vm._v(" - ")]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? "Add" : "Update")+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }