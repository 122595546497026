<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{$t('receipt.approveHeading')}}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-group required">
              <label class="control-label">{{$t('receipt.preparedBy')}}</label>
              <div
                class="input-group"
                :class="{
                  'is-invalid': v$.vapprove.prepared_by.$error,
                }"
              >
                <span class="input-group-text" v-if="isDropDownLoading">
                  <div class="spinner-border"></div>
                </span>
                <select class="form-select" v-if="isDropDownLoading" disabled>
                  <option value="null">{{$t('loading')}}</option>
                </select>
                <select
                  v-else
                  class="form-select"
                  v-model="v$.vapprove.prepared_by.$model"
                  :class="{
                    'is-invalid': v$.vapprove.prepared_by.$error,
                  }"
                >
                  <option
                    v-for="(staff, index) in staffs"
                    :key="index"
                    :value="staff.id"
                  >
                    {{ staff.full_name }}
                  </option>
                </select>
              </div>

              <div class="invalid-feedback">{{$t('receipt.preparedByError')}}</div>
            </div>

            <div class="form-group required">
              <label class="control-label">{{$t('receipt.submittedBy')}}</label>
              <div
                class="input-group"
                :class="{
                  'is-invalid': v$.vapprove.submitted_by.$error,
                }"
              >
                <span class="input-group-text" v-if="isDropDownLoading">
                  <div class="spinner-border"></div>
                </span>
                <select class="form-select" v-if="isDropDownLoading" disabled>
                  <option value="null">{{$t('loading')}}</option>
                </select>
                <select
                  v-else
                  class="form-select"
                  v-model="v$.vapprove.submitted_by.$model"
                  :class="{
                    'is-invalid': v$.vapprove.submitted_by.$error,
                  }"
                >
                  <option
                    v-for="(staff, index) in staffs"
                    :key="index"
                    :value="staff.id"
                  >
                    {{ staff.full_name }}
                  </option>
                </select>
              </div>

              <div class="invalid-feedback">{{$t('receipt.submittedByError')}}</div>
            </div>

            <div class="form-group required">
              <label class="control-label">{{$t('receipt.approvedBy')}}</label>
              <div
                class="input-group"
                :class="{
                  'is-invalid': v$.vapprove.approved_by.$error,
                }"
              >
                <span class="input-group-text" v-if="isDropDownLoading">
                  <div class="spinner-border"></div>
                </span>
                <select class="form-select" v-if="isDropDownLoading" disabled>
                  <option value="null">{{$t('loading')}}</option>
                </select>
                <select
                  v-else
                  class="form-select"
                  v-model="v$.vapprove.approved_by.$model"
                  :class="{
                    'is-invalid': v$.vapprove.approved_by.$error,
                  }"
                >
                  <option
                    v-for="(staff, index) in staffs"
                    :key="index"
                    :value="staff.id"
                  >
                    {{ staff.full_name }}
                  </option>
                </select>
              </div>

              <div class="invalid-feedback">{{$t('receipt.approvedByError')}}</div>
            </div>

            <div class="form-group">
              <button
                type="submit"
                class="btn btn-danger btn-block submitButton"
                name="login-button"
                v-if="!isSubmitting"
              >
                {{$t('receipt.approve')}}
              </button>
              <button
                class="btn btn-danger btn-block submitButton loading"
                disabled
                v-else
              >
                <div class="spinner-border text-warning"></div>
                <div>{{$t('loading')}}</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      vapprove: {
        approved_by: { required },
        submitted_by: { required },
        prepared_by: { required },
      },
    };
  },
  data() {
    return {
      staffs: [],
      isDropDownLoading: true,
      isSubmitting: false,
      vapprove: {
        voucher_id: this.data.id,
        approved_by: null,
        submitted_by: null,
        prepared_by: null,
      },
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getStaff();
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    getStaff() {
      axios
        .post(
          "helper/getstaff",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.staffs = res.data.staff;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.vapprove) {
          formData.set(key, this.vapprove[key]);
        }
        await axios
          .post("voucher/approve", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/"+this.$i18n.locale+"/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Voucher successfully approved.");
                this.$emit('confirmApprove',this.data);
                this.close();
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
