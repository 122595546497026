<template>
  <div>
    <div class="search-option">
      <Searchform :searchOption="searchOption" :npDate="npDate" :type="2" @displayRecord="displayRecord"/>
    </div>
    <hr />
    <Staffreporttable :list="taskList"  :date="$i18n.locale == 'np'?npDate:searchOption"/>
  </div>
</template>
<script>
import Searchform from "./searchform.vue";
import Staffreporttable from "./staffreporttable.vue";
export default {
  components: { Searchform, Staffreporttable },
  data() {
    return {
      taskList: [],
      searchOption: {
        from: null,
        to: null,
      },
      npDate:{
        from:null,
        to:null,
      }
    };
  },
  created() {
      this.$emit("close");
  },
  methods: {
    displayRecord(data){
      this.taskList = data;
    }
  },
};
</script>
