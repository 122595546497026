<template>
  <form @submit.prevent="submitForm" ref="imageForm">
    <div class="row">
      <div class="col-md-7">
        <div class="form-group required">
          <label class="control-label">{{ $t("task.task") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.images.taskId.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.images.taskId.$model"
              :class="{
                'is-invalid': v$.images.taskId.$error,
              }"
            >
              <option
                v-for="(task, index) in taskList"
                :key="'k' + index"
                :value="task.id"
              >
                {{ task.task_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("task.nameError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label> Images </label><br />
          <input
            @change="handleFile"
            type="file"
            accept="image/*"
            multiple="true"
            ref="file"
            :class="{ 'is-invalid': v$.images.images.$error }"
          />

          <div class="invalid-feedback">{{ $t("project.imageError") }}</div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for=""></label>
          <button
            type="submit"
            class="btn btn-danger btn-block submitButton"
            name="login-button"
            v-if="!isSubmitting"
          >
            {{ $t("add") }}
          </button>
          <button
            class="btn btn-danger btn-block submitButton loading"
            disabled
            v-else
          >
            <div class="spinner-border text-warning"></div>
            <div>{{ $t("loading") }}</div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      isDropDownLoading: true,
      taskList: [],
      images: {
        taskId: null,
        images: [],
      },
    };
  },
  validations() {
    return {
      images: {
        taskId: { required },
        images: {
          required: requiredIf(function () {
            return this.images.images.length == 0;
          }),
        },
      },
    };
  },
  created() {
    this.changeProject();
  },
  methods: {
    changeProject() {
      this.taskList = [];
      var formData = new FormData();
      formData.set("id", this.id);
      axios
        .post("helper/gettaskmeta", formData, {
          onUploadProgress: (uploadEvent) => {
            console.log(
              "Upload progress" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                "%"
            );
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.taskList = res.data.task;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleFile(e) {
      console.log(e.target.files.length);
      for (var i = 0; i < e.target.files.length; i++) {
        this.images.images.push(e.target.files[i]);
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.images) {
          formData.set(key, this.images[key]);
        }
        formData.append("imagesList", []);
        this.images.images.forEach((element, index) => {
          formData.append("imagesList[" + index + "]", element);
        });

        await axios
          .post("project/addimages", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Images successfully added.");
                this.$refs.imageForm.reset();
                this.v$.$reset();
                this.images.projectId = null;
                this.images.taskId = null;
                this.images.images = [];
                this.$emit('updateImage',res.data.list);
                this.isSubmitting = false;
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
