<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ $t("kharcha.edetail") }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td>
                  <b>{{ $t("kharcha.fiscal") }}</b>
                </td>
                <td>{{ data.fiscal_year }}</td>
              </tr>
              <tr v-if="data.type == 2">
                <td>
                  <b>{{ $t("kharcha.project") }}</b>
                </td>
                <td>{{ data.task_title }}</td>
              </tr>
              <tr v-if="data.type == 2">
                <td>
                  <b>{{ $t("kharcha.task") }}</b>
                </td>
                <td>{{ data.task_name }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.ehead") }}</b>
                </td>
                <td>{{ data.headings }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.qty") }}</b>
                </td>
                <td>{{ data.qty + " " + data.unit }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.rate") }}</b>
                </td>
                <td>Rs. {{ data.rate }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.amount") }}</b>
                </td>
                <td>Rs. {{ data.amount }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.supervisor") }}</b>
                </td>
                <td>
                  {{
                    data.type == 1
                      ? "-"
                      : data.full_name + " (Ph. " + data.phone + ")"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("kharcha.description") }}</b>
                </td>
                <td>{{ data.description }}</td>
              </tr>
            </tbody>
          </table>
          <div class="card">
            <div class="card-header">{{ $t("kharcha.budget") }}</div>
            <div class="card-body">
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>{{ $t("kharcha.source") }}</th>
                    <th>{{ $t("kharcha.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in data.source" :key="i">
                    <td>{{ item.headings }}</td>
                    <td>Rs. {{ item.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
