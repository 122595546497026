<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.dts_type }}</td>
            <td>
              <router-link
                v-if="pageType == 1"
                :to="
                  '/' +
                  $i18n.locale +
                  '/dashboard/appropriation/dts/update/' +
                  item.id
                "
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
              <router-link
                v-if="pageType == 2"
                :to="
                  '/' + $i18n.locale + '/dashboard/bail/dts/update/' + item.id
                "
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm actionButton"
              >
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalFlag: false,
      modalData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
    pageType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
      }
    },
  },
};
</script>
