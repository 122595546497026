<template>
  <form @submit.prevent="submitForm" ref="imageForm">
    <div class="row">
      <div class="col-md-7">
        <div class="form-group required">
          <label class="control-label">{{ $t("dpr.dpr") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.dpr.dprId.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.dpr.dprId.$model"
              :class="{
                'is-invalid': v$.dpr.dprId.$error,
              }"
            >
              <option
                v-for="(dpr, index) in dprList"
                :key="'k' + index"
                :value="dpr.id"
              >
                {{ dpr.dpr_title }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("dpr.headError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label> Documents </label><br />
          <input
            @change="handleFile"
            type="file"
            accept="application/pdf"
            ref="file"
            :class="{ 'is-invalid': v$.dpr.document.$error }"
          />

          <div class="invalid-feedback">{{ $t("dpr.documentError") }}</div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for=""></label>
          <button
            type="submit"
            class="btn btn-danger btn-block submitButton"
            name="login-button"
            v-if="!isSubmitting"
          >
            {{ $t("add") }}
          </button>
          <button
            class="btn btn-danger btn-block submitButton loading"
            disabled
            v-else
          >
            <div class="spinner-border text-warning"></div>
            <div>{{ $t("loading") }}</div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      isDropDownLoading: true,
      dprList: [],
      dpr: {
        dprId: null,
        document: [],
      },
    };
  },
  validations() {
    return {
      dpr: {
        dprId: { required },
        document: {
          required: requiredIf(function () {
            return this.dpr.document.length == 0;
          }),
        },
      },
    };
  },
  created() {
    this.getDpr();
  },
  methods: {
    getDpr() {
      this.dprList = [];
      var formData = new FormData();
      formData.set("id", this.id);
      axios
        .post("helper/getdpr", formData, {
          onUploadProgress: (uploadEvent) => {
            console.log(
              "Upload progress" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                "%"
            );
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.loginFlag) {
            this.dprList = res.data.dpr;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleFile(e) {
      for (var i = 0; i < e.target.files.length; i++) {
        this.dpr.document.push(e.target.files[i]);
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("id", this.id);
        for (var key in this.dpr) {
          formData.set(key, this.dpr[key]);
        }
        formData.append("document", []);
        this.dpr.document.forEach((element, index) => {
          formData.append("document[" + index + "]", element);
        });

        await axios
          .post("project/adddpr", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("DPR successfully added.");
                this.$refs.imageForm.reset();
                this.v$.$reset();
                this.dpr.dprId = null;
                this.dpr.document = [];
                this.$emit("updateList", res.data.list);
                this.isSubmitting = false;
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
