<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("project.project") }}
        <small>{{ $t("project.addNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :project="project" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../project/form.vue";

export default {
  components: {
    Form,
  },
  created() {
    this.$emit('close');
  },
  data() {
    return {
      project: {
        working_area_id: null,
        task_title: null,
        description: null,
        color: "#ffffff",
        date_of_issue: null,
        date_of_completion: null,
      },
    };
  },
};
</script>
