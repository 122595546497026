<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ heading }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td><b>{{$t('project.name')}}</b></td>
                <td>{{ data.task_title }}</td>
              </tr>
              <tr>
                <td><b>{{$t('project.doi')}}</b></td>
                <td>{{ convertedDate(data.date_of_issue) }}</td>
              </tr>
              <tr>
                <td><b>{{$t('project.doc')}}</b></td>
                <td>{{ convertedDate(data.date_of_completion) }}</td>
              </tr>
              <tr>
                <td><b>{{$t('project.status')}}</b></td>
                <td>
                  {{ data.complete_status == 1 ? "Completed" : "Pending" }}
                </td>
              </tr>
              <tr>
                <td><b>{{$t('project.description')}}</b></td>
                <td>{{ data.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
