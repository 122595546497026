<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">Cheque Settings Info</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td><b>{{$t('csetting.bank')}}</b></td>
                <td>{{ data.bank_name }}</td>
              </tr>
              <tr>
                <td><b>{{$t('bank.branch')}}</b></td>
                <td>{{ data.branch }}</td>
              </tr>
              <tr>
                <td><b>{{$t('bank.phone')}}</b></td>
                <td>{{ data.phone }}</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.height')}}</b></td>
                <td>{{ data.height }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.width')}}</b></td>
                <td>{{ data.width }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.dtm')}}</b></td>
                <td>{{ data.date_top }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.dlm')}}</b></td>
                <td>{{ data.date_left }} cms(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.ntm')}}</b></td>
                <td>{{ data.benificiary_top }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.nlm')}}</b></td>
                <td>{{ data.benificiary_left }} cms(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.atm')}}</b></td>
                <td>{{ data.amount_number_top }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.alm')}}</b></td>
                <td>{{ data.amount_number_left }} cms(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.awtm')}}</b></td>
                <td>{{ data.amount_letter_top }} cm(s)</td>
              </tr>
              <tr>
                <td><b>{{$t('csetting.awlm')}}</b></td>
                <td>{{ data.amount_letter_left }} cms(s)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
