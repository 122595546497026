<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('staffeducation.education') }}
        <small>{{ $t('staffeducation.createNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :education="education" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      education: {
        staff_id: null,
        examination: null,
        board: null,
        year: null,
        symbol: null,
        gpa_percentage:null,
        school_college:null
      },
    };
  },
  components: {
    Form,
  },
  created() {
    this.$emit("close");
  },
};
</script>
