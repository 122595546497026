<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ $t("chequecancel.cancelReason") }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-group required">
              <label class="control-label">{{
                $t("chequecancel.reason")
              }}</label>
              <textarea
                v-model="v$.reason.reason.$model"
                :class="{
                  'is-invalid': v$.reason.reason.$error,
                }"
                rows="5"
                type="text"
                class="form-control"
              />

              <div class="invalid-feedback">
                {{ $t("chequecancel.reasonError") }}
              </div>
            </div>

            <div class="form-group">
              <button
                type="submit"
                class="btn btn-danger btn-block submitButton"
                name="login-button"
                v-if="!isSubmitting"
              >
                {{ $t("receipt.approve") }}
              </button>
              <button
                class="btn btn-danger btn-block submitButton loading"
                disabled
                v-else
              >
                <div class="spinner-border text-warning"></div>
                <div>{{ $t("loading") }}</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      reason: {
        reason: { required },
      },
    };
  },
  data() {
    return {
      isSubmitting: false,
      reason: {
        id: this.data.id,
        reason: null,
      },
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.reason) {
          formData.set(key, this.reason[key]);
        }
        await axios
          .post("bank/cancelcheque", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Cheque successfully cancelled.");
                this.$emit("confirmCancel", this.data);
                this.close();
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
