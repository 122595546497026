<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('payhead.payheads') }}
        <small>{{ $t('payhead.addNote') }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/payheads/list`"
          class="btn btn-info btn-sm"
          >{{$t('payhead.list')}}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :payheads="payheads" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      payheads: {
        headings: null,
        type: null,
        payment_type:null,
        value_type:null,
        value:null,
        tax_status:null,
        heads:[],
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
