<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('payrolltax.tax') }}
        <small>{{ $t('payrolltax.addNote') }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/tax/list`"
          class="btn btn-info btn-sm"
          >{{$t('payrolltax.list')}}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :tax="tax" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      tax: {
        start_range: null,
        end_range: null,
        charge:[
            {
                type:1,
                tax_percentage:null,
                extension_range:null,
            },
            {
                type:2,
                tax_percentage:null,
                extension_range:null,
            },
            {
                type:3,
                tax_percentage:null,
                extension_range:null,
            },
        ]
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
