<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t('staffbank.staff') }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.bank.staff_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.bank.staff_id.$model"
          :class="{
            'is-invalid': v$.bank.staff_id.$error,
          }"
        >
          <option
            v-for="(staff, index) in staffList"
            :key="index"
            :value="staff.id"
          >
            {{ staff.full_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t('staffbank.staffError') }}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{ $t('staffbank.bank') }}</label>
      <input
        v-model="v$.bank.bank.$model"
        :class="{
          'is-invalid': v$.bank.bank.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t('staffbank.bankError') }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t('staffbank.branch') }}</label>
      <input
        v-model="v$.bank.branch.$model"
        :class="{
          'is-invalid': v$.bank.branch.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t('staffbank.branchError') }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t('staffbank.accountName') }}</label>
      <input
        v-model="v$.bank.account_name.$model"
        :class="{
          'is-invalid': v$.bank.account_name.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t('staffbank.accountNameError') }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t('staffbank.accountNumber') }}</label>
      <input
        v-model="v$.bank.account_number.$model"
        :class="{
          'is-invalid': v$.bank.account_number.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t('staffbank.accountNumberError') }}
      </div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isDropDownLoading: true,
      isSubmitting: false,
      staffList: [],
    };
  },
  props: {
    bank: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      bank: {
        staff_id: { required },
        bank: { required },
        branch: { required },
        account_name: { required },
        account_number: { required },
      },
    };
  },
  created() {
    this.getDropDown();
  },
  methods: {
    getDropDown() {
      axios
        .post(
          "helper/getstaffbankdropdown",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.staffList = res.data.staff;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.bank) {
          formData.set(key, this.bank[key]);
        }
        var url = this.type == 1 ? "staffbank/add" : "staffbank/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Staff bank successfully linked."
                    : "Staff bank info successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/setup/staff/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
