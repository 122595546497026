var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").name))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.full_name.$model),expression:"v$.staff.full_name.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.full_name.$error,
          },attrs:{"type":"text"},domProps:{"value":(_vm.v$.staff.full_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.full_name, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").nameError))])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.identity_number")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.identity_number.$model),expression:"v$.staff.identity_number.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.identity_number.$error,
          },attrs:{"type":"text"},domProps:{"value":(_vm.v$.staff.identity_number.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.identity_number, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff.identityNumberError"))+" ")])])]),_c('div',{staticClass:"col-md-4"},[(_vm.$i18n.locale == 'np')?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").doj))]),_c('VNepaliDatePicker',{class:{
            'is-invalid': _vm.v$.staff.date_of_join.$error,
          },attrs:{"classValue":"form-control","calenderType":"Nepali","placeholder":"YYYY/MM/DD","format":"YYYY/MM/DD","value":_vm.type == 2 ? _vm.doj : '',"yearSelect":true,"monthSelect":true},on:{"change":function($event){return _vm.supplyValue(2, 1, $event)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").dojError))])],1):_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").doj))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.date_of_join.$model),expression:"v$.staff.date_of_join.$model"}],staticClass:"form-control",attrs:{"type":"date"},domProps:{"value":(_vm.v$.staff.date_of_join.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.date_of_join, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").dojError))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").paddress))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.permanent_address.$model),expression:"v$.staff.permanent_address.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.permanent_address.$error,
          },attrs:{"type":"text"},domProps:{"value":(_vm.v$.staff.permanent_address.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.permanent_address, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").paddressError))])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").taddress))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.temporary_address),expression:"staff.temporary_address"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.staff.temporary_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "temporary_address", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.gender")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.gender.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.gender.$model),expression:"v$.staff.gender.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.gender.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("staff.male")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("staff.female")))]),_c('option',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t("staff.thirdGender")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff.genderError")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").phone))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.phone.$model),expression:"v$.staff.phone.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.phone.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.v$.staff.phone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.phone, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").phoneError))])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").email))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.email.$model),expression:"v$.staff.email.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.email.$error,
          },attrs:{"type":"text"},domProps:{"value":(_vm.v$.staff.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.email, "$model", $event.target.value)}}}),(!_vm.v$.staff.email.required.$response)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff").emailError)+" ")]):_vm._e(),(!_vm.v$.staff.email.email.$response)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff").emailInvalidError)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").pan))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.pan),expression:"staff.pan"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.staff.pan)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.staff, "pan", $event.target.value)}}})])])]),(_vm.type == 1)?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").password))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.password.$model),expression:"v$.staff.password.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.staff.password.$error,
      },attrs:{"type":"password"},domProps:{"value":(_vm.v$.staff.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.password, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").passwordError))])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.maritalStatus")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.marital_status.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.marital_status.$model),expression:"v$.staff.marital_status.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.marital_status.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.marital_status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("staff.married")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("staff.single")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff.maritalStatusError"))+" ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.disabledStatus")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.disabled_status.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.disabled_status.$model),expression:"v$.staff.disabled_status.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.disabled_status.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.disabled_status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("yes")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("no")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff.disabledStatusError"))+" ")])])]),_c('div',{staticClass:"col-md-4"},[(_vm.$i18n.locale == 'np')?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label",attrs:{"for":"doa-date"}},[_vm._v(_vm._s(_vm.$t("staff.doa")))]),_c('VNepaliDatePicker',{class:{
            'is-invalid': _vm.v$.staff.date_of_appointment.$error,
          },attrs:{"classValue":"form-control","calenderType":"Nepali","placeholder":"YYYY/MM/DD","format":"YYYY/MM/DD","value":_vm.type == 2 ? _vm.doa : '',"yearSelect":true,"monthSelect":true},on:{"change":function($event){return _vm.supplyValue(1, 1, $event)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").doaError))])],1):_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").doa))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.date_of_appointment.$model),expression:"v$.staff.date_of_appointment.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.staff.date_of_appointment.$error,
          },attrs:{"type":"date"},domProps:{"value":(_vm.v$.staff.date_of_appointment.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.staff.date_of_appointment, "$model", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff").department))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.department_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.department_id.$model),expression:"v$.staff.department_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.department_id.$error,
            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.department_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.getShift]}},_vm._l((_vm.departmentList),function(department,index){return _c('option',{key:index,domProps:{"value":department.id}},[_vm._v(" "+_vm._s(department.department_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff").departmentError))])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.shift")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.shift_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.shift_id.$model),expression:"v$.staff.shift_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.shift_id.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.shift_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.shiftList),function(shift,index){return _c('option',{key:index,domProps:{"value":shift.id}},[_vm._v(" "+_vm._s(shift.shift_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("staff.shiftError")))])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.designation")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.designation.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.designation.$model),expression:"v$.staff.designation.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.designation.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.designation, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.designationList),function(des,index){return _c('option',{key:index,domProps:{"value":des.id}},[_vm._v(" "+_vm._s(des.designation)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff.designationError"))+" ")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("staff.employeeType")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.staff.employee_type.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.staff.employee_type.$model),expression:"v$.staff.employee_type.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.staff.employee_type.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.staff.employee_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(" "+_vm._s(_vm.$t("staff.permanent"))+" ")]),_c('option',{attrs:{"value":"2"}},[_vm._v(" "+_vm._s(_vm.$t("staff.temporary"))+" ")]),_c('option',{attrs:{"value":"3"}},[_vm._v(" "+_vm._s(_vm.$t("staff.serviceAgreement"))+" ")])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("staff.employeeTypeError"))+" ")])])])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("staff").enroll : _vm.$t("staff").update)+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }