<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t('projectreport.from') }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, $event)"
            :class="{
              'is-invalid': v$.searchOption.from.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">
            {{ $t('projectreport.fromError') }}
          </div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t('projectreport.from') }}</label>
          <input
            v-model="v$.searchOption.from.$model"
            type="date"
            class="form-control"
            :class="{
              'is-invalid': v$.searchOption.from.$error,
            }"
          />

          <div class="invalid-feedback">
            {{ $t('projectreport.fromError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t('projectreport.to') }}</label>
          <VNepaliDatePicker
            @change="supplyValue(2, $event)"
            :class="{
              'is-invalid': v$.searchOption.to.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">
            {{ $t('projectreport.toError') }}
          </div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t('projectreport.to') }}</label>
          <input
            v-model="v$.searchOption.to.$model"
            type="date"
            class="form-control"
            :class="{
              'is-invalid': v$.searchOption.to.$error,
            }"
          />

          <div class="invalid-feedback">
            {{ $t('projectreport.toError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group searchOptionButton">
          <button
            type="submit"
            class="btn btn-danger btn-block submitButton"
            name="login-button"
            v-if="!isSubmitting"
          >
            {{ "SEARCH" }}
          </button>
          <button
            class="btn btn-danger btn-block submitButton loading"
            disabled
            v-else
          >
            <div class="spinner-border text-warning"></div>
            <div>{{ $t("loading") }}</div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VNepaliDatePicker from "v-nepalidatepicker";
import axios from "axios";
import adbs from "ad-bs-converter";
export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
      return {
          isSubmitting: false,
      }
  },
  props:{
      searchOption:{
          type:Object,
          required:true,
      },
      npDate:{
        type:Object,
        required:true,
      },
      type:{
          type:Number,
          required:true,
      }
  },
  validations() {
    return {
      searchOption: {
        from: { required },
        to: { required },
      },
    };
  },
  methods: {
    supplyValue(updateType, e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      if (updateType == 1) {
        this.npDate.from = e;
        this.searchOption.from = formatedDate;
      }else{
        this.npDate.to = e;
        this.searchOption.to = formatedDate;
      } 
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.searchOption) {
          formData.set(key, this.searchOption[key]);
        }
        var url = this.type==1?"projectreport/taskreport":"projectreport/staffreport";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$emit('displayRecord',res.data.list);
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
              }
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
<style>
.searchOptionButton {
  margin-top: 22px;
}
</style>
