<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{$t('bank.name')}}</label>
      <input
        v-model="v$.bank.bank_name.$model"
        :class="{
          'is-invalid': v$.bank.bank_name.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('bank.nameError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.type')}}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.bank.account_type.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.bank.account_type.$model"
          :class="{
            'is-invalid': v$.bank.account_type.$error,
          }"
        >
          <option value="1">Current</option>
          <option value="2">Saving</option>
        </select>
      </div>

      <div class="invalid-feedback">{{$t('bank.typeError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.branch')}}</label>
      <input
        v-model="v$.bank.branch.$model"
        :class="{
          'is-invalid': v$.bank.branch.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('bank.branchError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.accnumber')}}</label>
      <input
        v-model="v$.bank.account_no.$model"
        :class="{
          'is-invalid': v$.bank.account_no.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('bank.accnumberError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.accname')}}</label>
      <input
        v-model="v$.bank.account_name.$model"
        :class="{
          'is-invalid': v$.bank.account_name.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('bank.accnameError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.phone')}}</label>
      <input
        v-model="v$.bank.phone.$model"
        :class="{
          'is-invalid': v$.bank.phone.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('bank.phoneError')}}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{$t('bank.email')}}</label>
      <input
        v-model="v$.bank.email.$model"
        :class="{
          'is-invalid': v$.bank.email.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback" v-if="!v$.bank.email.required.$response">
        {{$t('bank.emailError')}}
      </div>
      <div
        class="invalid-feedback"
        v-if="!v$.bank.email.email.$response"
      >
        {{$t('emailInvalidError')}}
      </div>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t('add') : $t('update') }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>Loading...</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      bank: {
        bank_name: { required },
        branch: { required },
        account_no: { required },
        account_name: { required },
        phone: { required },
        email: { required, email },
        account_type: { required },
      },
    };
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  props: {
    bank: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    bankType:{
      type:Number,
      required:true,
    }
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.bank) {
          formData.set(key, this.bank[key]);
        }
        formData.set('bankType',this.bankType);
        var url =
          this.type == 1
            ? "bank/create"
            : "bank/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/"+this.$i18n.locale+"/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Bank successfully linked."
                    : "Bank successfully updated."
                );
                if(this.bankType==0)
                  this.$router.push("/"+this.$i18n.locale+"/dashboard/appropriation/bank/list");
                else
                  this.$router.push("/"+this.$i18n.locale+"/dashboard/bail/bank/list");
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
