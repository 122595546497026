<template>
  <div class="link-home-page">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">{{ "Appropriation Report" }}</div>
          <div class="card-body">
            <apexcharts
              v-if="!isLoading"
              width="100%"
              type="bar"
              height="380"
              :options="chartOptions"
              :series="series"
            ></apexcharts>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped table-bordered" v-if="!isLoading">
            <thead>
              <tr>
                <th>{{ $t("sn") }}</th>
                <th>{{$t('appropriation.particulars')}}</th>
                <th>{{ $t("appropriation.expectedBudget") }}</th>
                <th>{{ $t("appropriation.budget") }}</th>
                <th>{{ $t("appropriation.expenses") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <td>{{ ++i }}</td>
                <td>{{ d.heading }}</td>
                <td>{{ $t("rs") + d.expectedExpenses * 100000 }}</td>
                <td>{{ $t("rs") + d.budget * 100000 }}</td>
                <td>{{ $t("rs") + d.expenses * 100000 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      data: [],
      series: [],
      chartOptions: {},
    };
  },
  created() {
    this.$emit("close");
    this.getData();
  },
  methods: {
    getData() {
      axios
        .post(
          "home/appropriation",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.data = res.data.data;
            this.series = [
              {
                name: this.$t("appropriation.expectedBudget"),
                data: res.data.expectedBudget,
              },
              {
                name: this.$t("appropriation.budget"),
                data: res.data.budget,
              },
              {
                name: this.$t("appropriation.expenses"),
                data: res.data.expenses,
              },
            ];
            this.chartOptions = {
              chart: {
                type: "bar",
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: res.data.heading,
              },
              yaxis: {
                title: {
                  text: this.$t("appropriation.inRs"),
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return "Rs. " + val + " lakhs";
                  },
                },
              },
            };
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
