<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr
            v-for="(item, index) in list"
            :key="index"
            :class="item.complete_status == 1 ? 'bg-light-green' : ''"
          >
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{item.working_area}}</td>
            <td>{{ item.task_title }}</td>
            <td>{{ convertedDate(item.date_of_issue) }}</td>
            <td>{{ convertedDate(item.date_of_completion) }}</td>
            <td>
              <router-link :to="{name:'projectmap',params:{id:item.id,status:item.locationStatus,latitude:item.latitude,longitude:item.longitude}}">Map</router-link><br>
              <router-link :to="'/'+$i18n.locale+'/dashboard/project/project/images/'+item.id">Images</router-link><br>
              <router-link :to="'/'+$i18n.locale+'/dashboard/project/project/dpr/'+item.id">DPR</router-link>
            </td>
            <td>
              <button
                @click.prevent="viewProject(index)"
                class="btn btn-primary btn-sm actionButton"
              >
                {{ $t("view") }}
              </button>
              <router-link
                :to="'/'+$i18n.locale+'/dashboard/project/project/update/' + item.id"
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm actionButton"
              >
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">
              <span class="no-record">{{$t('norecord')}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{$t('prev')}}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{$t('next')}}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Listview
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @closeModal="closeModal"
      :heading="$t('report.workAreaDetail')"
    />
  </div>
</template>
<script>
import Listview from "../project/listview.vue";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  data() {
    return {
      modalFlag: false,
      modalData: {},
    };
  },
  components: {
    Listview,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
      }
    },

    viewProject(index) {
      this.modalData = this.$store.state.projectList[index];
      this.modalFlag = true;
    },

    closeModal() {
      this.modalFlag = !this.modalFlag;
    },
  },
};
</script>
