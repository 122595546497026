<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.staffIdError") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.education.staff_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.education.staff_id.$model"
          :class="{
            'is-invalid': v$.education.staff_id.$error,
          }"
        >
          <option
            v-for="(staff, index) in staffList"
            :key="index"
            :value="staff.id"
          >
            {{ staff.full_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("staffeducation.staffIdError") }}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.examination") }}</label>
      <input
        v-model="v$.education.examination.$model"
        :class="{
          'is-invalid': v$.education.examination.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("staffeducation.examinationError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.board") }}</label>
      <input
        v-model="v$.education.board.$model"
        :class="{
          'is-invalid': v$.education.board.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("staffeducation.boardError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.year") }}</label>
      <input
        v-model="v$.education.year.$model"
        :class="{
          'is-invalid': v$.education.year.$error,
        }"
        type="number"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("staffeducation.yearError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.symbol") }}</label>
      <input
        v-model="v$.education.symbol.$model"
        :class="{
          'is-invalid': v$.education.symbol.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t("staffeducation.symbolError") }}
      </div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.gpa_percentage") }}</label>
      <input
        v-model="v$.education.gpa_percentage.$model"
        :class="{
          'is-invalid': v$.education.gpa_percentage.$error,
        }"
        type="number"
        step="0.01"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t("staffeducation.gpaPercentageError") }}
      </div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("staffeducation.school_college") }}</label>
      <input
        v-model="v$.education.school_college.$model"
        :class="{
          'is-invalid': v$.education.school_college.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t("staffeducation.schoolCollegeError") }}
      </div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isDropDownLoading: true,
      isSubmitting: false,
      staffList: [],
    };
  },
  props: {
    education: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      education: {
        staff_id: { required },
        examination: { required },
        board: { required },
        year: { required },
        symbol: { required },
        gpa_percentage: { required },
        school_college: { required },
      },
    };
  },
  created() {
    this.getDropDown();
  },
  methods: {
    getDropDown() {
      axios
        .post(
          "helper/getstaffbankdropdown",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.staffList = res.data.staff;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.education) {
          formData.set(key, this.education[key]);
        }
        var url = this.type == 1 ? "staffeducation/create" : "staffeducation/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Staff education successfully added."
                    : "Staff education info successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/setup/staff/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
