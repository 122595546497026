var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("holidaySetup.title")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.holiday.heading.$model),expression:"v$.holiday.heading.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.holiday.heading.$error,
      },attrs:{"type":"text"},domProps:{"value":(_vm.v$.holiday.heading.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.holiday.heading, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("holidaySetup.titleError")))])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("holidaySetup.maximumHoliday")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.holiday.maximum_holiday.$model),expression:"v$.holiday.maximum_holiday.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.holiday.maximum_holiday.$error,
      },attrs:{"type":"number"},domProps:{"value":(_vm.v$.holiday.maximum_holiday.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.holiday.maximum_holiday, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("holidaySetup.maximumHolidayError"))+" ")])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("holidaySetup.accumulatedStatus")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.holiday.accumulated_status.$error,
      }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.holiday.accumulated_status.$model),expression:"v$.holiday.accumulated_status.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.holiday.accumulated_status.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.holiday.accumulated_status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("yes")))]),_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("no")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("holidaySetup.accumulatedStatusError"))+" ")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("holidaySetup.description")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.holiday.description),expression:"holiday.description"}],staticClass:"form-control",attrs:{"type":"text","rows":"5"},domProps:{"value":(_vm.holiday.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.holiday, "description", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("add") : _vm.$t("update"))+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }