<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ heading }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td>
                  <b>{{ $t("party.type") }}</b>
                </td>
                <td>{{ determineType(parseInt(data.type)) }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.dtsType") }}</b>
                </td>
                <td>{{ data.dts_type }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.name") }}</b>
                </td>
                <td>{{ data.name }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.panVat") }}</b>
                </td>
                <td>{{ data.pan_vat }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.phone") }}</b>
                </td>
                <td>{{ data.phone }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.mobile") }}</b>
                </td>
                <td>{{ data.mobile }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.email") }}</b>
                </td>
                <td>{{ data.email }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.address") }}</b>
                </td>
                <td>
                  {{ data.address }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.citizenship") }}</b>
                </td>
                <td>{{ data.citizenship_registration_no }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.contactPersonName") }}</b>
                </td>
                <td>{{ data.cperson.name }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.designation") }}</b>
                </td>
                <td>{{ data.designation }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.contactPersonPhone") }}</b>
                </td>
                <td>{{ data.cperson.phone }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.contactPersonMobile") }}</b>
                </td>
                <td>{{ data.cperson.mobile }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t("party.contactPersonEmail") }}</b>
                </td>
                <td>{{ data.cperson.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    determineType(type) {
      var typeName;
      switch (type) {
        case 1:
          typeName = "Contractor";
          break;
        case 2:
          typeName = "Institutional";
          break;
        case 3:
          typeName = "Consumer Committee";
          break;
        case 4:
          typeName = "Personal";
          break;
        case 5:
          typeName = "Supplier";
          break;
        case 6:
          typeName = "Consultant";
          break;
        default:
          typeName = "Wrong Input";
          break;
      }
      return typeName;
    },
  },
};
</script>
