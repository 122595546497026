<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.fiscal") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.receipt.fiscal_year_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.receipt.fiscal_year_id.$model"
              :class="{
                'is-invalid': v$.receipt.fiscal_year_id.$error,
              }"
            >
              <option
                v-for="(fiscal, a) in fiscalyears"
                :key="a"
                :value="fiscal.id"
              >
                {{ fiscal.fiscal_year }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.fiscalError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t("receipt.date") }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, $event)"
            :class="{
              'is-invalid': v$.receipt.date.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="type == 2 ? date : ''"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("receipt.dateError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("receipt.date") }}</label>
          <input
            v-model="v$.receipt.date.$model"
            type="date"
            class="form-control"
            :class="{
              'is-invalid': v$.receipt.date.$error,
            }"
          />

          <div class="invalid-feedback">{{ $t("receipt.dateError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.refNo") }}</label>
          <input
            v-model="v$.receipt.reference_no.$model"
            type="number"
            class="form-control"
            :class="{
              'is-invalid': v$.receipt.reference_no.$error,
            }"
          />

          <div class="invalid-feedback">{{ $t("receipt.refNoError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.mode") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.receipt.payment_mode.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.receipt.payment_mode.$model"
              :class="{
                'is-invalid': v$.receipt.payment_mode.$error,
              }"
            >
              <option value="1">{{ $t("receipt.cheque") }}</option>
              <option value="2">{{ $t("receipt.cash") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.modeError") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("voucher.expensesType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.receipt.expenses_type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.receipt.expenses_type.$model"
              @change="getExpenses"
              :class="{
                'is-invalid': v$.receipt.expenses_type.$error,
              }"
            >
              <option value="0">{{ $t("voucher.current") }}</option>
              <option value="1">{{ $t("voucher.capital") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("voucher.expensesTypeError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.expenses") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.receipt.expenses_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.receipt.expenses_id.$model"
              :class="{
                'is-invalid': v$.receipt.expenses_id.$error,
              }"
            >
              <option v-for="(exp, c) in expenses" :key="c" :value="exp.id">
                {{ exp.headings }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("receipt.expensesError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.project") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.receipt.task_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              :disabled="receipt.expenses_type == 0"
              v-model="v$.receipt.task_id.$model"
              :class="{
                'is-invalid': v$.receipt.task_id.$error,
              }"
            >
              <option
                v-for="(project, b) in projects"
                :key="b"
                :value="project.id"
              >
                {{ project.task_title }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.projectError") }}</div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered voucherTable">
        <thead>
          <tr>
            <th>{{ $t("receipt.type") }}</th>
            <th>{{ $t("voucher.type") }}</th>
            <th colspan="2">{{ $t("receipt.particular") }}</th>
            <th>{{ $t("receipt.amount") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(bank, c) in receipt.dr" :key="'dr' + c">
            <td>{{ $t("receipt.dr") }}</td>
            <td>
              <div class="form-group clone-form-group required">
                <div class="input-group">
                  <select
                    class="form-select"
                    v-model="bank.type"
                    :class="{
                      'is-invalid':
                        v$.receipt.dr.$each.$response.$errors[c].type.length,
                    }"
                  >
                    <option value="1">{{ $t("voucher.bank") }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("voucher.typeError") }}
                </div>
              </div>
            </td>
            <td colspan="2">
              <div class="form-group clone-form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.receipt.dr.$each.$response.$errors[c].dr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select v-else class="form-select" v-model="bank.dr_id">
                    <option v-for="(bnk, d) in banks" :key="d" :value="bnk.id">
                      {{ bnk.bank_name }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("receipt.bankError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group clone-form-group required">
                <input
                  v-model="bank.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.receipt.dr.$each.$response.$errors[c].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.amountError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneDrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteDrClone(c)"
                  v-if="c != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(source, e) in receipt.cr" :key="e">
            <td>{{ $t("receipt.cr") }}</td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.receipt.cr.$each.$response.$errors[e].type.length,
                  }"
                >
                  <select class="form-select" v-model="source.type">
                    <option value="2">{{ $t("voucher.release") }}</option>
                    <option value="3">{{ $t("voucher.incomeReturn") }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("voucher.typeError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.receipt.cr.$each.$response.$errors[e].cr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="source.cr_id"
                    @change="updateMethod(e)"
                  >
                    <option
                      v-for="(src, f) in sources"
                      :key="f"
                      :value="src.id"
                    >
                      {{ src.headings }}
                    </option>
                  </select>
                </div>
                <div
                  :id="'receipt-dr-info-' + e"
                  class="font-red font-small"
                ></div>
                <div class="invalid-feedback">
                  {{ $t("receipt.sourceError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.receipt.cr.$each.$response.$errors[e].source_method_id
                        .length,
                  }"
                >
                  <select class="form-select" v-model="source.source_method_id">
                    <option
                      v-for="(mthd, f) in methods[e]"
                      :key="'Cr' + f"
                      :value="mthd.id"
                    >
                      {{ mthd.headings }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("voucher.sourceAndMethodError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group">
                <input
                  v-model="source.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.receipt.cr.$each.$response.$errors[e].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.amountError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneCrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteCrClone(e)"
                  v-if="e != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!tallyFlag">
          <tr>
            <td colspan="4" class="text-red">
              {{ $t("receipt.drcrbalance") }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("receipt.narration") }}</label>
      <textarea
        v-model="v$.receipt.narration.$model"
        rows="5"
        type="text"
        class="form-control"
        :class="{
          'is-invalid': v$.receipt.narration.$error,
        }"
      />
      <div class="invalid-feedback">{{ $t("receipt.narrationError") }}</div>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      receipt: {
        fiscal_year_id: { required },
        date: { required },
        narration: { required },
        amount: { required },
        payment_mode: { required },
        reference_no: { required },
        task_id: {
          required: requiredIf((value, item) => {
            return item.expenses_type == 1;
          }),
        },
        expenses_id: { required },
        expenses_type: { required },
        dr: {
          $each: helpers.forEach({
            type: { required },
            dr_id: { required },
            amount: { required },
          }),
        },
        cr: {
          $each: helpers.forEach({
            type: { required },
            cr_id: { required },
            source_method_id: { required },
            amount: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      date: "",
      isDropDownLoading: true,
      fiscalyears: [],
      projects: [],
      banks: [],
      sources: [],
      methods: [],
      expensesList: [],
      expenses: [],
      isSubmitting: false,
      tallyFlag: true,
      budgetPlan: {
        budget: null,
        expenses: null,
        remaining: null,
      },
    };
  },
  props: {
    receipt: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.$i18n.locale == "np") {
      this.supplyValue(2, this.receipt.date);
    }
    this.getDropDown();
  },
  methods: {
    getExpenses() {
      if (this.receipt.expenses_type) {
        if (this.receipt.expenses_type == 0) {
          this.receipt.task_id = "";
        }
        this.expenses = this.expensesList.filter(
          (item) => item.type == (this.receipt.expenses_type == 0 ? 1 : 2)
        );
      }
    },

    supplyValue(updateType, e) {
      if (updateType == 1) {
        var converter = adbs.bs2ad(e);
        var month =
          converter.month < 10 ? "0" + converter.month : converter.month;
        var day = converter.day < 10 ? "0" + converter.day : converter.day;
        var formatedDate = converter.year + "-" + month + "-" + day;
        this.receipt.date = formatedDate;
      } else {
        var converter1 = adbs.ad2bs(moment(e).format("YYYY/MM/DD")).en;
        var month1 =
          converter1.month < 10 ? "0" + converter1.month : converter1.month;
        var day1 = converter1.day < 10 ? "0" + converter1.day : converter1.day;
        var formatedDate1 = converter1.year + "-" + month1 + "-" + day1;
        this.date = formatedDate1;
      }
    },

    cloneDrItem() {
      this.receipt.dr.push({
        type: null,
        dr_id: null,
        amount: null,
      });
    },

    cloneCrItem() {
      this.receipt.cr.push({
        type: null,
        cr_id: null,
        amount: null,
        source_method_id: null,
      });
    },

    deleteDrClone(index) {
      this.receipt.dr.splice(index, 1);
      this.calculate();
    },

    deleteCrClone(index) {
      this.receipt.cr.splice(index, 1);
      this.calculate();
    },

    updateMethod(e) {
      if (this.receipt.cr[e].cr_id) {
        var source = this.sources.filter((item) => {
          return item.id.includes(this.receipt.cr[e].cr_id);
        })[0];
        document.getElementById("receipt-dr-info-" + e).innerText =
          this.$t("receipt.expected") +
          " : " +
          this.$t("rs") +
          " " +
          source.expectedAmount +
          " " +
          this.$t("receipt.released") +
          " : " +
          this.$t("rs") +
          " " +
          source.receivedAmount;
        this.methods.splice(e, 1, source.method);
      }
    },

    calculate() {
      var totalDrAmount = 0;
      var totalCrAmount = 0;
      this.receipt.dr.forEach((item) => {
        totalDrAmount += parseInt(item.amount);
      });
      this.receipt.cr.forEach((item) => {
        totalCrAmount += parseInt(item.amount);
      });
      if (totalDrAmount == totalCrAmount) {
        this.tallyFlag = true;
        this.receipt.amount = totalDrAmount;
      } else {
        this.tallyFlag = false;
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/receiptvoucherdropdown",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.fiscalyears = res.data.fiscal;
            this.banks = res.data.bank;
            this.projects = res.data.project;
            this.sources = res.data.source;
            this.expensesList = res.data.expenses;
            if (this.type == 2) {
              this.receipt.cr.forEach((element, index) => {
                this.updateMethod(index);
              });
              this.getExpenses();
            } else {
              this.receipt.reference_no = res.data.referenceNo
                ? parseInt(res.data.referenceNo.reference_no) + 1
                : null;
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.tallyFlag) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.receipt) {
          formData.set(key, this.receipt[key]);
        }
        formData.append("dr", JSON.stringify(this.receipt.dr));
        formData.append("cr", JSON.stringify(this.receipt.cr));
        var url = this.type == 1 ? "voucher/receipt" : "voucher/receiptupdate";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Receipt voucher successfully created."
                    : "Receipt voucher successfully updated."
                );
                this.$router.push(
                  "/" +
                    this.$i18n.locale +
                    "/dashboard/appropriation/voucherapproval/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
