<template>
  <div>
    <div class="list-search-form">
      <form ref="searchForm" @submit.prevent="submitForm">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate($event)"
            :class="{
              'is-invalid': v$.date.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">
            {{ $t('attendance.dateError') }}
          </div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.date.$model"
            :class="{
              'is-invalid': v$.date.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">{{$t('attendance.dateError')}}</div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-info">{{ $t("search") }}</button>
        </div>
      </form>
    </div>
    <div class="report-data-container">
      <div class="export-container">
        <button class="btn btn-info btn-sm" @click="exportPdf">PDF</button>
      </div>
      <form @submit.prevent="submitAttendanceForm">
        <div id="toprint">
          <h2>{{$t('attendance.attendanceReport')}}</h2>
          <h5>{{$t('attendance.date')}} : {{ convertedDate(date) }}</h5>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th  data-html2canvas-ignore="true">
                    <input type="checkbox" @click="parentCheckbox" checked />
                  </th>
                  <th>{{$t('sn')}}</th>
                  <th>{{$t('attendance.name')}}</th>
                  <th>{{$t('attendance.date')}}</th>
                  <th>{{$t('attendance.checkIn')}}</th>
                  <th>{{$t('attendance.checkOut')}}</th>
                </tr>
              </thead>
              <tbody v-if="report.length > 0">
                <tr v-for="(att, index) in report" :key="index">
                  <td  data-html2canvas-ignore="true">
                    <input
                      type="checkbox"
                      v-model="att.flag"
                      @click="selectStaff(index)"
                    />
                  </td>
                  <td>{{ getSn(index) }}</td>
                  <td>{{ att.full_name }}</td>
                  <td>{{ convertedDate(att.date) }}</td>
                  <td>{{ att.check_in }}</td>
                  <td>{{ att.check_out }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="no-record">{{ $t("norecord") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-danger btn-block submitButton"
          name="login-button"
          v-if="!isSubmitting"
        >
          {{$t('attendance.cancelAttendance')}}
        </button>
        <button
          class="btn btn-danger btn-block submitButton loading"
          disabled
          v-else
        >
          <div class="spinner-border text-warning"></div>
          <div>{{ $t("loading") }}</div>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";
import axios from "axios";
import html2pdf from "html2pdf.js";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: moment(new Date()).format("YYYY-MM-DD"),
      isLoading: true,
      report: [],
      isSubmitting: false,
    };
  },
  validations() {
    return {
      date: { required },
    };
  },
  created() {
    this.$emit("close");
    this.getAttendance();
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    getSn(index) {
      return ++index;
    },

    parentCheckbox() {
      this.report.forEach((item, index) => {
        this.selectStaff(index);
      });
    },

    selectStaff(index) {
      this.report[index].flag = !this.report[index].flag;
    },

    supplyDate(e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      this.date = formatedDate;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf(element, {
        margin: 10,
        filename: "Attendance_report_" + new Date().getTime(),
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      });
    },

    submitAttendanceForm() {
      if (confirm("Are you sure?")) {
        var items = this.report.filter((item) => {
          if (item.flag || item.flag == 1) return true;
          else return false;
        });
        this.isSubmitting = true;
        var formData = new FormData();
        formData.append("attendance", JSON.stringify(items));
        axios
          .post("attendance/cancelattendance", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Attendance successfully cancelled.");
                this.isSubmitting = false;
                this.getAttendance();
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },

    submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.getAttendance();
      }
    },

    async getAttendance() {
      this.isLoading = true;
      const argument = {
        date: this.date,
      };
      await axios
        .post("attendance/getattendance", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.report = res.data.data;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
