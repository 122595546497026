<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('shift.shift') }}
        <small>{{ $t('shift.addNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :shift="shift" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../shift/form.vue";

export default {
  data() {
    return {
      shift: {
        shift_name: null,
        shift_code: null,
        department_id: null,
        start_from:null,
        end_at:null,
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
