<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog searchedProjectTaskDialog">
      <div class="modal-content project-wise-modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">
            {{
              project.status == 1
                ? "Completed Tasks"
                : project.status == 0
                ? "Incompleted Tasks"
                : "All tasks"
            }}
          </h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="project-task-spinner-container" v-if="isLoading">
            <div class="spinner-border text-dark"></div>
          </div>
          <div class="table-responsive" v-else>
            <table
              id="gridView"
              class="table table-bordered table-hover table-sm"
            >
              <thead>
                <tr>
                  <th>{{$t('sn')}}</th>
                  <th>{{$t('task.name')}}</th>
                  <th>{{$t('task.staff')}}</th>
                  <th>{{$t('task.dos')}}</th>
                  <th>{{$t('task.doe')}}</th>
                  <th>{{$t('actions')}}</th>
                </tr>
              </thead>
              <tbody v-if="list.length > 0">
                <tr v-for="(item, index) in list" :key="index" :style="item.completion_status==1?'background:#6fdf6f':''">
                  <td>{{ ++index }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.staff }}</td>
                  <td>{{ convertedDate(item.start) }}</td>
                  <td>{{ convertedDate(item.end) }}</td>
                  <td>
                    <router-link
                    v-if="item.completion_status==0"
                      class="btn btn-warning btn-sm actionButton"
                      :to="'/'+$i18n.locale+'/dashboard/project/task/edittask/' + item.id"
                      >{{$t('update')}}</router-link
                    >
                    <button
                    v-if="item.completion_status==0"
                      @click="deleteItem(item)"
                      class="btn btn-danger btn-sm actionButton"
                    >
                      {{$t('delete')}}
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">
                    <span class="no-record">{{$t('norecord')}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      modalFlag: true,
      list: [],
    };
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getProjectTask();
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    close() {
      this.$emit("closeProjectTask");
    },

    async getProjectTask() {
      await axios
        .post("task/getprojecttask", this.project, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.loginFlag) {
            this.list = res.data.task;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item){
      this.$emit("deleteItem",item);
    }
  },
};
</script>
<style>
.project-task-spinner-container {
  text-align: center;
}

.project-wise-modal-content{
  width: fit-content !important;
}

.searchedProjectTaskDialog{
  max-width: fit-content;
}
</style>
