<template>
  <div class="table-container">
    <div class="table-responsive">
      <Search
        @search="search"
        @changePage="changePage"
        @resetSearch="resetSearch"
      />
      <Tableshimmer :heading="tableHeading" v-if="isLoading" />
      <Table
        :list="chequeList"
        :currentPage="currentPage"
        :perPage="perPage"
        :heading="tableHeading"
        :totalRow="totalRow"
        @changePage="changePage"
        v-else
      />
    </div>
  </div>
</template>
<script>
import Tableshimmer from "../../../helper/tableshimmer.vue";
import Search from "../../../helper/search.vue";
import Table from "./table.vue";
import axios from "axios";

export default {
  components: { Search, Tableshimmer, Table },
  props: {
    bankId: {
      type: Number,
      required: true,
    },
    chequeList:{
      type:Array,
      required:true,
    }
  },
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      tableHeading:[this.$t('sn'), this.$t('bank.chequeNumber'), this.$t('bank.status')],
      searchQuery: null,
    };
  },
  created() {
    this.getData(null);
  },
  methods: {
    async getData(query) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
        bankId: this.bankId,
      };
      await axios
        .post("cheque/chequelist", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.$emit('addChequeList',{list:res.data.list,type:1});
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.$emit('resetCheque');
      this.currentPage = data.page;
      this.getData(this.searchQuery);
    },

    resetSearch() {
      this.searchQuery = null;
      this.$emit('resetCheque');
      this.currentPage = 1;
      this.getData(this.searchQuery);
    },

    search(query) {
      this.currentPage = 1;
      this.searchQuery = query;
      this.getData(this.searchQuery);
    },
  },
};
</script>
