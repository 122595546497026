<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("kharcha.heading") }}
        <small>{{ $t("kharcha.updateNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :expensesdetermination="expensesdetermination" :type="2" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      expensesdetermination: this.$store.state.projectExpensesList.filter(
        (item) => {
          return item.id==this.$route.params.id;
        }
      )[0],
    };
  },
  components: {
    Form,
  },
};
</script>
