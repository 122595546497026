<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.main_head }}</td>
            <td>{{ item.headings }}</td>
            <td>{{ item.fiscal_year }}</td>
            <td>{{ "Rs. " + item.amount }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm actionButton"
                @click="viewSourceExpenses(index)"
              >
                {{ $t("sources.allocation") }}
              </button>
              <a
                class="btn btn-info btn-sm actionButton"
                @click.prevent="viewDetail(item.id)"
                >{{ $t("view") }}</a
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <span class="no-record">{{$t('norecord')}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Expenseslist
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @closeModal="closeModal"
    />
    <Detail
      v-if="detailFlag"
      :modalFlag="detailFlag"
      :data="detailData"
      @closeDetail="closeDetail"
      @deleteItem="deleteItem"
    />
  </div>
</template>
<script>
import Expenseslist from "./expenseslist.vue";
import Detail from "./detail.vue";

export default {
  components: { Expenseslist, Detail },
  data() {
    return {
      modalData: null,
      modalFlag: false,
      detailFlag: false,
      detailData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    viewSourceExpenses(index) {
      this.modalData = this.list[index];
      this.modalFlag = true;
    },

    deleteItem(item){
      this.$emit("deleteItem", item);
    },

    viewDetail(id){
      this.detailData = this.list.filter((item)=>{
        return item.id.includes(id);
      })[0].detail;
      this.detailFlag=true;
    },

    closeModal() {
      this.modalFlag = false;
    },

    closeDetail() {
      this.detailFlag = false;
    },
  },
};
</script>
