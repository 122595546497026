<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payhead.headings") }}</label>
          <input
            v-model="v$.payheads.headings.$model"
            :class="{
              'is-invalid': v$.payheads.headings.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("payhead.headingsError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payheads.taxStatus") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payheads.tax_status.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.payheads.tax_status.$model"
              :class="{
                'is-invalid': v$.payheads.tax_status.$error,
              }"
            >
              <option value="1">{{ $t("payheads.taxable") }}</option>
              <option value="0">{{ $t("payheads.noTax") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("payheads.taxStatusError") }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payhead.type") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payheads.type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.payheads.type.$model"
              :class="{
                'is-invalid': v$.payheads.type.$error,
              }"
            >
              <option value="1">{{ $t("payheads.getMonthly") }}</option>
              <option value="0">{{ $t("payheads.deduceMonthly") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("payhead.typeError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payhead.paymentType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payheads.payment_type.$error,
            }"
          >
            <select
              class="form-select"
              @change="changePaymentType"
              v-model="v$.payheads.payment_type.$model"
              :class="{
                'is-invalid': v$.payheads.payment_type.$error,
              }"
            >
              <option value="1">{{ $t("payheads.fixed") }}</option>
              <option value="0">{{ $t("payheads.calculated") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("payhead.paymentTypeError") }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payhead.valueType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payheads.value_type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.payheads.value_type.$model"
              :class="{
                'is-invalid': v$.payheads.value_type.$error,
              }"
            >
              <option value="1">{{ $t("payheads.percentage") }}</option>
              <option value="2">{{ $t("payheads.amount") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("payhead.valueTypeError") }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("payhead.value") }}</label>
          <input
            v-model="v$.payheads.value.$model"
            :class="{
              'is-invalid': v$.payheads.value.$error,
            }"
            type="number"
            step="0.01"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("payhead.valueError") }}</div>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-if="payheads.heads.length > 0">
      <table class="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>{{ $t("payhead.particular") }}</th>
            <th>{{ $t("payhead.type") }}</th>
            <th>{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(head, index) in payheads.heads" :key="'heads' + index">
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payheads.heads.$each.$response.$errors[index].head_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    ref="dropDownHeadings"
                    @change="displayNote"
                    v-model="head.head_id"
                    :class="{
                      'is-invalid':
                        v$.payheads.heads.$each.$response.$errors[index].head_id
                          .length,
                    }"
                  >
                    <option v-for="(hd, j) in headings" :key="j" :value="hd.id">
                      {{ hd.headings }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("payhead.particularError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payheads.heads.$each.$response.$errors[index].type
                        .length,
                  }"
                >
                  <select
                    :disabled="index == 0"
                    class="form-select"
                    ref="dropDownType"
                    v-model="head.type"
                    @change="displayNote"
                    :class="{
                      'is-invalid':
                        v$.payheads.heads.$each.$response.$errors[index].type
                          .length,
                    }"
                  >
                    <option value="1">
                      {{ index == 0 ? "" : $t("payhead.addMath") }}
                    </option>
                    <option value="2">{{ $t("payhead.subtractMath") }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("payhead.valueError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneHeadItem">
                  +
                </div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteHeadClone(index)"
                  v-if="index != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">
              <span class="no-record">NOTE : {{ note }}</span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? "Add" : "Update" }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      isDropDownLoading: false,
      note: "",
      headings: [],
    };
  },
  props: {
    payheads: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      payheads: {
        headings: { required },
        type: { required },
        payment_type: { required },
        value_type: { required },
        value: { required },
        tax_status: { required },
        heads: {
          $each: helpers.forEach({
            head_id: { required },
            type: { required },
          }),
        },
      },
    };
  },
  created() {
    this.getDropDown();
  },
  methods: {
    cloneHeadItem() {
      this.payheads.heads.push({
        head_id: null,
        type: null,
      });
    },

    deleteHeadClone(index) {
      this.payheads.heads.splice(index, 1);
      this.displayNote();
    },

    displayNote() {
      var headings = [];
      this.$refs.dropDownHeadings.forEach((item, index) => {
        var type =
          index == 0
            ? ""
            : this.$refs.dropDownType[index].options.selectedIndex == 1
            ? "-"
            : "+";
        headings.push(
          type + " " + item.options[item.options.selectedIndex].text
        );
      });
      var text = headings.join(" ");
      this.note =
        this.payheads.headings +
        " = " +
        this.payheads.value +
        (this.payheads.value_type == 1 ? "%" : "") +
        " ( " +
        text +
        " )";
    },

    changePaymentType() {
      if (this.payheads.payment_type) {
        if (this.payheads.payment_type == 1) {
          this.payheads.heads = [];
        } else {
          this.cloneHeadItem();
          this.payheads.heads[0].type = 1;
        }
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/getpayheadsdropdown",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.headings = res.data.list;
            this.headings.unshift({
              id: 0,
              headings: this.$t("payheads.basicSalary"),
            });
            this.headings.unshift({
              id: -1,
              headings: this.$t("payheads.totalSalary"),
            });
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.payheads) {
          formData.set(key, this.payheads[key]);
        }
        formData.append("heads", JSON.stringify(this.payheads.heads));
        var url =
          this.type == 1 ? "payrollpayhead/create" : "payrollpayhead/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Payhead successfully added."
                    : "Payhead successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/payroll/payheads/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
