<template>
  <div class="right-sidebar">
    <ul class="nav nav-tabs tab-nav-right" role="tablist">
      <li role="presentation" class="active">
        <a
          href="javascript:void(0)"
          class="active organizationSetting"
          data-toggle="tab"
          >{{ $t("setting").setting }}</a
        >
      </li>
    </ul>
    <div class="demo-settings">
      <ul class="setting-list">
        <li>
          <a
            @click.prevent="toggleDropDown($event)"
            data-item="skin"
            class="menu-toggle"
          >
            <i class="material-icons">color_lens</i>
            <span>{{ $t("setting").skin }}</span>
          </a>
          <ul class="ml-menu setting-menu">
            <li
              @click="changeSkin"
              data-theme="red"
              :class="$store.state.skin == 'red' ? 'active' : ''"
            >
              <div class="red"></div>
              <span>Red</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="pink"
              :class="$store.state.skin == 'pink' ? 'active' : ''"
            >
              <div class="pink"></div>
              <span>Pink</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="purple"
              :class="$store.state.skin == 'purple' ? 'active' : ''"
            >
              <div class="purple"></div>
              <span>Purple</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="deep-purple"
              :class="$store.state.skin == 'deep-purple' ? 'active' : ''"
            >
              <div class="deep-purple"></div>
              <span>Deep Purple</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="indigo"
              :class="$store.state.skin == 'indigo' ? 'active' : ''"
            >
              <div class="indigo"></div>
              <span>Indigo</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="blue"
              :class="$store.state.skin == 'blue' ? 'active' : ''"
            >
              <div class="blue"></div>
              <span>Blue</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="light-blue"
              :class="$store.state.skin == 'light-blue' ? 'active' : ''"
            >
              <div class="light-blue"></div>
              <span>Light Blue</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="cyan"
              :class="$store.state.skin == 'cyan' ? 'active' : ''"
            >
              <div class="cyan"></div>
              <span>Cyan</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="teal"
              :class="$store.state.skin == 'teal' ? 'active' : ''"
            >
              <div class="teal"></div>
              <span>Teal</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="green"
              :class="$store.state.skin == 'green' ? 'active' : ''"
            >
              <div class="green"></div>
              <span>Green</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="light-green"
              :class="$store.state.skin == 'light-green' ? 'active' : ''"
            >
              <div class="light-green"></div>
              <span>Light Green</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="lime"
              :class="$store.state.skin == 'lime' ? 'active' : ''"
            >
              <div class="lime"></div>
              <span>Lime</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="yellow"
              :class="$store.state.skin == 'yellow' ? 'active' : ''"
            >
              <div class="yellow"></div>
              <span>Yellow</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="amber"
              :class="$store.state.skin == 'amber' ? 'active' : ''"
            >
              <div class="amber"></div>
              <span>Amber</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="orange"
              :class="$store.state.skin == 'orange' ? 'active' : ''"
            >
              <div class="orange"></div>
              <span>Orange</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="deep-orange"
              :class="$store.state.skin == 'deep-orange' ? 'active' : ''"
            >
              <div class="deep-orange"></div>
              <span>Deep Orange</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="brown"
              :class="$store.state.skin == 'brown' ? 'active' : ''"
            >
              <div class="brown"></div>
              <span>Brown</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="grey"
              :class="$store.state.skin == 'grey' ? 'active' : ''"
            >
              <div class="grey"></div>
              <span>Grey</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="blue-grey"
              :class="$store.state.skin == 'blue-grey' ? 'active' : ''"
            >
              <div class="blue-grey"></div>
              <span>Blue Grey</span>
            </li>
            <li
              @click="changeSkin"
              data-theme="black"
              :class="$store.state.skin == 'black' ? 'active' : ''"
            >
              <div class="black"></div>
              <span>Black</span>
            </li>
          </ul>
        </li>
        <li>
          <a
            @click.prevent="toggleDropDown($event)"
            data-item="system"
            class="menu-toggle"
          >
            <i class="material-icons">email</i>
            <span>{{ $t("setting").system }}</span>
          </a>
          <ul class="ml-menu setting-slider-menu">
            <li class="checkbox_container">
              <span>Task Calendar</span>
              <div class="switch">
                <label
                  ><input
                    class="setting-checkbox"
                    name="task_calendar_flag"
                    type="checkbox"
                    @click="changeCalendarFlag"
                    v-if="calendarFlag == 1"
                    :checked="calendarFlag" />
                  <input
                    v-else
                    class="setting-checkbox"
                    name="task_calendar_flag"
                    type="checkbox"
                    @click="changeCalendarFlag" /><span class="lever"></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>SMS Features</span>
              <div class="switch">
                <label>
                  <input
                    class="setting-checkbox"
                    name="sms_feature_flag"
                    type="checkbox"
                    @click="changeSMSFlag"
                    v-if="smsFlag == 1"
                    :checked="smsFlag" />
                  <input
                    v-else
                    class="setting-checkbox"
                    name="sms_feature_flag"
                    type="checkbox"
                    @click="changeSMSFlag" />
                  <span class="lever"></span
                ></label>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <a
            @click.prevent="toggleDropDown($event)"
            data-item="email"
            class="menu-toggle"
          >
            <i class="material-icons">email</i>
            <span>{{ $t("setting").email }}</span>
          </a>
          <ul class="ml-menu setting-slider-menu">
            <li class="checkbox_container">
              <span>{{ $t("setting").taskAssign }}</span>
              <div class="switch">
                <label
                  ><input type="checkbox" checked="" /><span
                    class="lever"
                  ></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>{{ $t("setting").taskCompletion }}</span>
              <div class="switch">
                <label
                  ><input type="checkbox" checked="" /><span
                    class="lever"
                  ></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>{{ $t("setting").taskIncomplete }}</span>
              <div class="switch">
                <label
                  ><input type="checkbox" checked="" /><span
                    class="lever"
                  ></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>{{ $t("setting").taskReport }}</span>
              <div class="switch">
                <label
                  ><input type="checkbox" checked="" /><span
                    class="lever"
                  ></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>Day Summary</span>
              <div class="switch">
                <label
                  ><input type="checkbox" checked="" /><span
                    class="lever"
                  ></span
                ></label>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <a
            @click.prevent="toggleDropDown($event)"
            data-item="account"
            class="menu-toggle"
          >
            <i class="material-icons">account_circle</i>
            <span>{{ $t("setting").account }}</span>
          </a>
          <ul class="ml-menu account-menu">
            <li>
              <router-link :to="`/${$i18n.locale}/setting/updateinfo`">{{
                $t("setting").update
              }}</router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/setting/verifyaccount`">{{
                $t("setting").changeEmail
              }}</router-link>
            </li>
            <li>
              <router-link
                :to="
                  '/' +
                  $i18n.locale +
                  '/setting/changepassword/' +
                  loggedInUser.email
                "
                >{{ $t("setting").changePassword }}</router-link
              >
            </li>
            <li>
              <a href="#" @click.prevent="logout">{{ $t("setting").logout }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      smsFlag: this.$store.state.smsFlag,
      calendarFlag: this.$store.state.calendarFlag,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  methods: {
    changeCalendarFlag() {
      if (this.calendarFlag == 0) {
        this.calendarFlag = 1;
      } else {
        this.calendarFlag = 0;
      }
      this.$store.state.calendarFlag = this.calendarFlag;
      localStorage.setItem("kasturiWorkSpaceCalendarFlag", this.calendarFlag);
    },

    changeSMSFlag() {
      if (this.smsFlag == 0) {
        this.smsFlag = 1;
      } else {
        this.smsFlag = 0;
      }
      this.$store.state.smsFlag = this.smsFlag;
      localStorage.setItem("kasturiWorkSpaceSMSFlag", this.smsFlag);
    },

    toggleDropDown(e) {
      var clickedDropDown = e.target.parentElement.getAttribute("data-item");
      e.target.parentElement.parentElement.parentElement.childNodes.forEach(
        (value) => {
          if (value.firstChild.getAttribute("data-item") != clickedDropDown) {
            value.classList.remove("active");
          }
        }
      );
      e.target.parentElement.parentElement.classList.toggle("active");
    },

    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loggedInInfo");
      this.$router.push("/" + this.$i18n.locale + "/login");
    },

    changeSkin(e) {
      localStorage.setItem("skin", e.currentTarget.getAttribute("data-theme"));
      this.$store.state.skin = e.currentTarget.getAttribute("data-theme");
    },
  },
};
</script>
<style>
.workspace-navbar-collapse {
  justify-content: flex-end;
}

.right-nav-container {
  position: fixed;
  width: 280px;
  background: #fdfdfd;
  box-shadow: -2px 2px 5px rgb(0 0 0 / 10%);
  right: 0px;
  overflow: hidden;
  height: calc(100vh - 62px);
  z-index: 1001;
  margin-top: 62px;
}

.rightNavToggled {
  animation: showRightNavAnimation 1s;
}

@keyframes showRightNavAnimation {
  0% {
    right: -250px;
  }
  100% {
    right: 0px;
  }
}

.right-sidebar .nav-tabs {
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  margin-left: 2px;
}
.right-sidebar .nav-tabs li:last-child {
  width: 100%;
  text-align: center;
}
.right-sidebar .nav-tabs li > a {
  text-decoration: none;
}

.right-sidebar .setting-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0px;
}

.right-sidebar .setting-list > li {
  padding: 10px 15px;
  position: relative;
  border-bottom: 1px solid #eee;
}

.right-sidebar .setting-list > li > a {
  cursor: pointer;
}

.right-sidebar .setting-list li .menu-toggle {
  position: relative;
  display: block;
  color: dimgrey;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  width: 100%;
}

.right-sidebar .setting-list li a .material-icons {
  font-size: 16px;
  position: absolute;
  margin-top: 3px;
}

.right-sidebar .setting-list li a > span {
  margin-left: 20px;
}

.right-sidebar .setting-list li ul.ml-menu {
  display: none;
}

.right-sidebar .setting-list li.active ul.ml-menu {
  display: block;
}

.right-sidebar .setting-list li .menu-toggle:after,
.right-sidebar .setting-list li .menu-toggle:before {
  position: absolute;
  top: calc(50% - 14px);
  right: 17px;
  font-size: 19px;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.right-sidebar .setting-list li .menu-toggle:before {
  content: "+";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.right-sidebar .setting-list li.active .menu-toggle:after {
  content: "-";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 1s;
}

.right-sidebar .setting-list li.active .menu-toggle:before {
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 1s;
}

.right-sidebar .setting-list > li {
  padding: 15px;
  position: relative;
  border-top: none;
}

ul.setting-menu li.active {
  background-color: #eee;
}

.right-sidebar .setting-list li ul.ml-menu {
  list-style: none;
  padding: 0px;
}

ul.setting-menu li {
  list-style: none;
  padding: 15px;
  border-top: none !important;
  position: relative;
  cursor: pointer;
}

ul.setting-menu li:hover {
  background: #eee;
}

ul.setting-menu li div {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

ul.setting-menu li > span {
  position: relative;
  bottom: 7px;
  left: 5px;
}

ul.setting-menu li.active:after {
  font-family: "Material Icons";
  position: absolute;
  top: 15px;
  right: 10px;
  content: "\E876";
  font-size: 18px;
  font-weight: bold;
  color: #a5a2a2;
}

.demo-settings {
  height: calc(100vh - 82px);
  overflow-y: auto;
  overflow-x: hidden;
}

ul.setting-menu,
ul.setting-slider-menu,
ul.account-menu {
  list-style: none;
  padding: 0px;
}

.right-sidebar .setting-list li .switch {
  position: absolute;
  top: 15px;
  right: 5px;
}

.switch label {
  font-weight: normal;
  font-size: 13px;
}
.switch label {
  cursor: pointer;
}

.switch label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #84c7c1;
}

.switch label .lever {
  margin: 0 14px;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #26a69a;
  left: 24px;
}

.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: #f1f1f1;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 40%);
  left: -5px;
  top: -3px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}

.right-sidebar .setting-list li {
  padding: 15px;
  position: relative;
  border-top: none;
}

ul.account-menu li > a {
  color: #212529;
  text-decoration: none;
}

@media (max-width: 767px) {
  .right-nav-container {
    margin-top: 87px;
  }
}
</style>
