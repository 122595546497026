<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('bailVoucher.fiscalYear') }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.bail.fiscal_year_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.bail.fiscal_year_id.$model"
              :class="{
                'is-invalid': v$.bail.fiscal_year_id.$error,
              }"
            >
              <option
                v-for="(fiscal, a) in fiscalyears"
                :key="a"
                :value="fiscal.id"
              >
                {{ fiscal.fiscal_year }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{$t('bailVoucher.fiscalYearError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t('bailVoucher.date') }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, $event)"
            :class="{
              'is-invalid': v$.bail.date.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="type == 2 ? date : ''"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t('bailVoucher.dateError') }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t('bailVoucher.date') }}</label>
          <input
            v-model="v$.bail.date.$model"
            type="date"
            class="form-control"
            :class="{
              'is-invalid': v$.bail.date.$error,
            }"
          />

          <div class="invalid-feedback">{{ $t('bailVoucher.dateError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('bailVoucher.bailType') }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.bail.bail_type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.bail.bail_type.$model"
              :class="{
                'is-invalid': v$.bail.bail_type.$error,
              }"
            >
              <option value="1">Auction</option>
              <option value="2">Bail</option>
              <option value="3">Judicial</option>
              <option value="4">Others</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t('bailVoucher.bailTypeError') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('bailVoucher.referenceNo') }}</label>
          <input
            v-model="v$.bail.reference_no.$model"
            type="number"
            class="form-control"
            :class="{
              'is-invalid': v$.bail.reference_no.$error,
            }"
          />

          <div class="invalid-feedback">
            {{ $t('bailVoucher.referenceNoError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('bailVoucher.paymentMode') }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.bail.payment_mode.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.bail.payment_mode.$model"
              :class="{
                'is-invalid': v$.bail.payment_mode.$error,
              }"
            >
              <option value="1">{{ $t("receipt.cheque") }}</option>
              <option value="2">{{ $t("receipt.cash") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t('bailVoucher.paymentModeError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{
            $t('bailVoucher.bailVoucherNo')
          }}</label>
          <input
            v-model="v$.bail.bail_voucher_no.$model"
            type="number"
            class="form-control"
            :class="{
              'is-invalid': v$.bail.bail_voucher_no.$error,
            }"
          />

          <div class="invalid-feedback">
            {{ $t('bailVoucher.bailVoucherNoError') }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-striped bailVoucherTable">
        <thead>
          <tr>
            <th style="width: 5%">{{ $t("receipt.type") }}</th>
            <th style="width: 15%">{{ $t("voucher.type") }}</th>
            <th colspan="3" style="width: 55%">
              {{ $t("receipt.particular") }}
            </th>
            <th style="width: 15%">{{ $t("receipt.amount") }}</th>
            <th style="width: 10%">{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(debit, index) in bail.dr" :key="'Dr' + index">
            <td>{{ $t("receipt.dr") }}</td>
            <td>
              <div class="form-group required">
                <div class="input-group">
                  <select
                    class="form-select"
                    v-model="debit.type"
                    :class="{
                      'is-invalid':
                        v$.bail.dr.$each.$response.$errors[index].type.length,
                    }"
                    @change="changeDrType(index, 1)"
                  >
                    <option value="1">{{ "BANK" }}</option>
                    <option value="2">{{ "BAIL" }}</option>
                    <option value="3">{{ "Last Year's Bail" }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.dr.$each.$response.$errors[index].dr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="debit.dr_id"
                    @change="changeDrDrId(index, 1)"
                  >
                    <option
                      v-for="(dr, d) in drList[index]"
                      :key="d"
                      :value="dr.id"
                    >
                      {{ dr.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.dr.$each.$response.$errors[index].dr_meta_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="debit.dr_meta_id"
                    @change="changeDrMetaId(index)"
                  >
                    <option
                      v-for="(drMeta, e) in drMetaList[index]"
                      :key="'drMeta' + e"
                      :value="drMeta.id"
                    >
                      {{ drMeta.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.dr.$each.$response.$errors[index].dr_meta_meta_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="debit.dr_meta_meta_id"
                  >
                    <option
                      v-for="(drMetaMeta, f) in drMetaMetaList[index]"
                      :key="'drMetaMeta' + f"
                      :value="drMetaMeta.id"
                    >
                      {{ drMetaMeta.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="debit.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.bail.dr.$each.$response.$errors[index].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneDrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteDrClone(index)"
                  v-if="index != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(credit, jindex) in bail.cr" :key="'Credit' + jindex">
            <td>{{ $t("receipt.cr") }}</td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.cr.$each.$response.$errors[jindex].type.length,
                  }"
                  @change="changeCrType(jindex, 1)"
                >
                  <select class="form-select" v-model="credit.type">
                    <option value="1">{{ "Bank" }}</option>
                    <option value="2">{{ "Bail" }}</option>
                    <option value="3">{{ "Last Year's Bail" }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error')}}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.cr.$each.$response.$errors[jindex].cr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="credit.cr_id"
                    @change="changeCrCrId(jindex, 1)"
                  >
                    <option
                      v-for="(cr, d) in crList[jindex]"
                      :key="'Cr' + d"
                      :value="cr.id"
                    >
                      {{ cr.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error')}}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.cr.$each.$response.$errors[jindex].cr_meta_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="credit.cr_meta_id"
                    @change="changeCrMetaId(jindex)"
                  >
                    <option
                      v-for="(crMeta, e) in crMetaList[jindex]"
                      :key="'crMeta' + e"
                      :value="crMeta.id"
                    >
                      {{ crMeta.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.bail.cr.$each.$response.$errors[jindex].cr_meta_meta_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="credit.cr_meta_meta_id"
                  >
                    <option
                      v-for="(crMetaMeta, f) in crMetaMetaList[jindex]"
                      :key="'crMetaMeta' + f"
                      :value="crMetaMeta.id"
                    >
                      {{ crMetaMeta.particular }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="credit.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.bail.cr.$each.$response.$errors[jindex].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t('bailVoucher.error') }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneCrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteCrClone(jindex)"
                  v-if="jindex != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!tallyFlag">
          <tr>
            <td colspan="7" class="text-red">
              {{ $t("receipt.drcrbalance") }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t('bailVoucher.narration') }}</label>
      <textarea
        v-model="v$.bail.narration.$model"
        rows="5"
        type="text"
        class="form-control"
        :class="{
          'is-invalid': v$.bail.narration.$error,
        }"
      />
      <div class="invalid-feedback">{{ $t('bailVoucher.narrationError') }}</div>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      bail: {
        fiscal_year_id: { required },
        date: { required },
        bail_type: { required },
        narration: { required },
        amount: { required },
        payment_mode: { required },
        reference_no: { required },
        bail_voucher_no: { required },
        dr: {
          $each: helpers.forEach({
            type: { required },
            dr_id: { required },
            dr_meta_id: {
              required: requiredIf((value, item) => {
                return item.type == 2 || item.type == 3;
              }),
            },
            dr_meta_meta_id: {
              required: requiredIf((value, item) => {
                return item.type == 2 || item.type == 3;
              }),
            },
            amount: { required },
          }),
        },
        cr: {
          $each: helpers.forEach({
            type: { required },
            cr_id: { required },
            cr_meta_id: { required },
            cr_meta_meta_id: {
              required: requiredIf((value, item) => {
                return item.type == 2 || item.type == 3;
              }),
            },
            amount: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      date: "",
      isDropDownLoading: true,
      fiscalyears: [],
      drList: [],
      drMetaList: [],
      drMetaMetaList: [],
      crList: [],
      crMetaList: [],
      crMetaMetaList: [],
      fiscal: [],
      banks: [],
      chequeIds: [],
      payer: [],
      bails: [],
      isSubmitting: false,
      tallyFlag: true,
    };
  },
  props: {
    bail: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.type == 2) {
      this.bail.cr.forEach((element) => {
        if (element.type == 1) {
          this.chequeIds.push(element.cr_meta_id);
        }
      });
    }
    this.getDropDown();
    if (this.$i18n.locale == "np") {
      this.supplyValue(2, this.bail.date);
    }
  },
  methods: {
    changeDrType(index, type) {
      this.drList[index] = [];
      this.drMetaList[index] = [];
      this.drMetaMetaList[index] = [];
      if (type == 1) {
        //change value only in new form
        this.bail.dr[index].dr_id = null;
        this.bail.dr[index].dr_meta_id = null;
        this.bail.dr[index].dr_meta_meta_id = null;
      }
      if (this.bail.dr[index].type) {
        if (this.bail.dr[index].type == 1) {
          this.drList[index] = this.banks;
        } else {
          this.drList[index] = this.bails;
        }
      }
      if (type == 2) {
        this.changeDrDrId(index, type);
      }
    },

    changeDrDrId(index, type) {
      this.drMetaList[index] = [];
      if (this.bail.dr[index].type != 1 && this.bail.dr[index].dr_id) {
        this.loadMetaList(index, 1);
      }
      if (type == 2) {
        this.changeDrMetaId(index);
      }
    },

    changeDrMetaId(index) {
      this.drMetaMetaList[index] = [];
      if (this.bail.dr[index].dr_meta_id && this.bail.dr[index].type != 1) {
        var payer = this.payer.filter((item) => {
          return item.type.includes(this.bail.dr[index].dr_meta_id);
        });
        this.drMetaMetaList[index] = payer;
      }
    },

    changeCrType(index, type) {
      this.crList[index] = [];
      this.crMetaList[index] = [];
      this.crMetaMetaList[index] = [];
      if (type == 1) {
        this.bail.cr[index].cr_id = null;
        this.bail.cr[index].cr_meta_id = null;
        this.bail.cr[index].cr_meta_meta_id = null;
      }
      if (this.bail.cr[index].type) {
        if (this.bail.cr[index].type == 1) {
          this.crList[index] = this.banks;
        } else {
          this.crList[index] = this.bails;
        }
      }
      if (type == 2) {
        this.changeCrCrId(index, type);
      }
    },

    changeCrCrId(index, type) {
      this.crMetaList[index] = [];
      if (this.bail.cr[index].cr_id) {
        if (this.bail.cr[index].type == 1) {
          var cheques = this.banks.filter((item) => {
            return item.id.includes(this.bail.cr[index].cr_id);
          })[0].cheque;
          this.crMetaList[index] = cheques;
        } else {
          this.loadMetaList(index, 2);
        }
      }
      if (type == 2) {
        this.changeCrMetaId(index);
      }
    },

    changeCrMetaId(index) {
      this.crMetaMetaList[index] = [];
      if (this.bail.cr[index].cr_meta_id && this.bail.cr[index].type != 1) {
        var payer = this.payer.filter((item) => {
          return item.type.includes(this.bail.cr[index].cr_meta_id);
        });
        this.crMetaMetaList[index] = payer;
      }
    },

    loadMetaList(index, type) {
      var metaList = [
        {
          id: 1,
          particular: "Contractor",
        },
        {
          id: 2,
          particular: "Institutional",
        },
        {
          id: 3,
          particular: "Consumer Committee",
        },
        {
          id: 4,
          particular: "Personal",
        },
        {
          id: 5,
          particular: "Supplier",
        },
        {
          id: 6,
          particular: "Consultant",
        },
        {
          id: 7,
          particular: "Internal body",
        },
        {
          id: 8,
          particular: "Office bearers",
        },
        {
          id: 9,
          particular: "Apparent letter",
        },
        {
          id: 10,
          particular: "Personal",
        },
      ];
      if (type == 1) {
        this.drMetaList[index] = metaList;
      } else {
        this.crMetaList[index] = metaList;
      }
    },

    cloneDrItem() {
      this.bail.dr.push({
        type: null,
        dr_id: null,
        dr_meta_id: null,
        dr_meta_meta_id: null,
        amount: null,
      });
    },

    cloneCrItem() {
      this.bail.cr.push({
        type: null,
        cr_id: null,
        cr_meta_id: null,
        cr_meta_meta_id: null,
        amount: null,
      });
    },

    deleteDrClone(index) {
      this.bail.dr.splice(index, 1);
      this.calculate();
    },

    deleteCrClone(index) {
      this.bail.cr.splice(index, 1);
      this.calculate();
    },

    calculate() {
      var totalDrAmount = 0;
      var totalCrAmount = 0;
      this.bail.dr.forEach((item) => {
        totalDrAmount += parseInt(item.amount);
      });
      this.bail.cr.forEach((item) => {
        totalCrAmount += parseInt(item.amount);
      });
      if (totalDrAmount == totalCrAmount) {
        this.tallyFlag = true;
        this.bail.amount = totalDrAmount;
      } else {
        this.tallyFlag = false;
      }
    },

    supplyValue(updateType, e) {
      if (updateType == 1) {
        var converter = adbs.bs2ad(e);
        var month =
          converter.month < 10 ? "0" + converter.month : converter.month;
        var day = converter.day < 10 ? "0" + converter.day : converter.day;
        var formatedDate = converter.year + "-" + month + "-" + day;
        this.bail.date = formatedDate;
      } else {
        var converter1 = adbs.ad2bs(moment(e).format("YYYY/MM/DD")).en;
        var month1 =
          converter1.month < 10 ? "0" + converter1.month : converter1.month;
        var day1 = converter1.day < 10 ? "0" + converter1.day : converter1.day;
        var formatedDate1 = converter1.year + "-" + month1 + "-" + day1;
        this.date = formatedDate1;
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/bailvoucherdropdown",
          { type: this.type, chequeIds: JSON.stringify(this.chequeIds) },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.fiscalyears = res.data.fiscal;
            this.banks = res.data.bank;
            this.payer = res.data.payer;
            this.bails = res.data.bailrecord;
            if (this.type == 2) {
              this.bail.dr.forEach((element, index) => {
                this.changeDrType(index, 2);
              });
              this.bail.cr.forEach((element, index) => {
                this.changeCrType(index, 2);
              });
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.tallyFlag) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.bail) {
          formData.set(key, this.bail[key]);
        }
        formData.append("dr", JSON.stringify(this.bail.dr));
        formData.append("cr", JSON.stringify(this.bail.cr));
        var url = this.type == 1 ? "bailvoucher/create" : "bailvoucher/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Voucher successfully created."
                    : "Voucher successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/bail/voucher/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
<style>
.bailVoucherTable td {
  max-width: 100px;
}
</style>
