import Vue from "vue";
import Router from "vue-router";
import About from "./components/promo/about.vue";
import Dashboard from "./components/dashboard/dashboard.vue";
import Login from "./components/login/login.vue";
import Register from "./components/registration/registration.vue";
import Finduser from "./components/login/finduser.vue";
import accountverificationVue from "./components/registration/accountverification.vue";
import accoutverifyforpasswordchangeVue from "./components/login/accoutverifyforpasswordchange.vue";
import changepasswordVue from "./components/login/changepassword.vue";
import Solution from "./components/promo/solution.vue";
import Verifyaccountforemailchange from "./components/setting/verifyaccountforemailchange.vue";
import Newemail from "./components/setting/newemail.vue";
import Confirmnewemail from "./components/setting/confirmnewemail.vue";
import Updateinfo from "./components/setting/updateinfo.vue";
import Departmentadd from "./components/setup/department/add.vue";
import Departmentlist from "./components/setup/department/list.vue";
import Staffenroll from "./components/setup/staff/enroll.vue";
import Stafflist from "./components/setup/staff/list.vue";
import Staffupdate from "./components/setup/staff/update.vue";
import Projectcreate from "./components/projects/project/create.vue";
import Projectlist from "./components/projects/project/list.vue";
import Projectupdate from "./components/projects/project/update.vue";
import Projectmap from "./components/projects/project/document/map.vue";
import Projectimage from "./components/projects/project/images/images.vue";
import Projectdpr from "./components/projects/project/document/dpr.vue";
import Departmentupdate from "./components/setup/department/update.vue";
import Taskcalendar from "./components/projects/task/taskcalendar.vue";
import Tasklist from "./components/projects/task/list.vue";
import Taskcreate from "./components/projects/task/create.vue";
import Taskupdate from "./components/projects/task/update.vue";
import Budgetsourcelist from "./components/budget/income/list.vue";
import Budgetsourcecreate from "./components/budget/income/create.vue";
import Budgetsourceupdate from "./components/budget/income/update.vue";
import Budgetheadlist from "./components/budget/budgethead/list.vue";
import Budgetheadcreate from "./components/budget/budgethead/create.vue";
import Budgetheadupdate from "./components/budget/budgethead/update.vue";
import Budgetexpenseslist from "./components/budget/expenses/list.vue";
import Budgetexpensescreate from "./components/budget/expenses/create.vue";
import Budgetexpensesupdate from "./components/budget/expenses/update.vue";
import Budgetexpensesdetermination from "./components/budget/expenses_determination/list.vue";
import Budgetexpensesdeterminationcreate from "./components/budget/expenses_determination/create.vue";
import Budgetexpensesdeterminationupdate from "./components/budget/expenses_determination/update.vue";
import Budgetsourcedetermination from "./components/budget/source_determination/list.vue";
import Budgetsourcedeterminationcreate from "./components/budget/source_determination/create.vue";
import Budgetsourcedeterminationupdate from "./components/budget/source_determination/update.vue";
import Budgetsourcedeterminationapproval from "./components/budget/source_determination/approvallist.vue";
import Budgetfiscalyearlist from "./components/budget/fiscal_year/list.vue";
import Budgetfiscalyearcreate from "./components/budget/fiscal_year/create.vue";
import Budgetfiscalyearupdate from "./components/budget/fiscal_year/update.vue";
import Appropriationbanklist from "./components/appropriation/bank/list.vue";
import Appropriationbankcreate from "./components/appropriation/bank/create.vue";
import Appropriationbankupdate from "./components/appropriation/bank/update.vue";
import Appropriationchequelist from "./components/appropriation/cheque/list.vue";
import Appropriationchequecreate from "./components/appropriation/cheque/create.vue";
import Appropriationchequeupdate from "./components/appropriation/cheque/update.vue";
import Appropriationchequecancel from "./components/appropriation/cancelcheque/container.vue";
import Appropriationpartylist from "./components/appropriation/party/list.vue";
import Appropriationpartycreate from "./components/appropriation/party/create.vue";
import Appropriationpartyupdate from "./components/appropriation/party/update.vue";
import Receiptvouchercreate from "./components/appropriation/voucher/recievecreate.vue";
import Receiptvoucherupdate from "./components/appropriation/voucher/receiveupdate.vue";
import Paymentvouchercreate from "./components/appropriation/voucher/paymentcreate.vue";
import Paymentvoucherupdate from "./components/appropriation/voucher/paymentupdate.vue";
import Voucherlist from "./components/appropriation/voucher/list.vue";
import Printinglist from "./components/appropriation/printing/list.vue";
import Workingarealist from "./components/projects/workingarea/list.vue";
import Workingareaadd from "./components/projects/workingarea/create.vue";
import Workingareaupdate from "./components/projects/workingarea/update.vue";
import Workingareareport from "./components/report/workspace.vue";
import Budgetreport from "./components/report/budget.vue";
import Appropriationreport from "./components/report/appropriation.vue";
import Projectreport from "./components/report/project.vue";
import Incomexpensereport from "./components/report/incomexpense.vue";
import Branchlist from "./components/setup/branch/list.vue";
import Branchadd from "./components/setup/branch/create.vue";
import Branchupdate from "./components/setup/branch/update.vue";
import Shiftadd from "./components/setup/shift/create.vue";
import Shiftlist from "./components/setup/shift/list.vue";
import Shiftupdate from "./components/setup/shift/update.vue";
import Reportbranchworkarea from "./components/report/branch/workarea.vue";
import Reportbranchbudget from "./components/report/branch/budget.vue";
import Reportbranchproject from "./components/report/branch/project.vue";
import Reportbranchappropriation from "./components/report/branch/appropriation.vue";
import Reportbranchincomexpense from "./components/report/branch/incomexpense.vue";
import Dprcreate from "./components/projects/dpr/create.vue";
import Dprlist from "./components/projects/dpr/list.vue";
import Dprupdate from "./components/projects/dpr/update.vue";
import Dprview from "./components/projects/project/document/viewdpr.vue";
import Layoutsetup from "./components/layout/setup.vue";
import Layoutbail from "./components/bail/dashboard/dashboard.vue";
import Bailhome from "./components/bail/dashboard/home.vue";
import Bailbanklist from "./components/bail/bank/list.vue";
import Bailbankcreate from "./components/bail/bank/create.vue";
import Bailbankupdate from "./components/bail/bank/update.vue";
import Setuphome from "./components/layout/setuphome.vue";
import Layoutproject from "./components/layout/project.vue";
import Payroll from "./components/payroll/dashboard.vue";
import Payrolldashboard from "./components/payroll/welcome.vue";
import Payrollattendance from "./components/payroll/attendance/attendance.vue";
import Payrollattendancereport from "./components/payroll/attendance/attendancereport.vue";
import Payrollattendanceindividual from "./components/payroll/attendance/attendanceindreport.vue";
import Projecthome from "./components/layout/projecthome.vue";
import Layoutbudget from "./components/layout/budget.vue";
import Budgethome from "./components/layout/budgethome.vue";
import Layoutappropriation from "./components/layout/appropriation.vue";
import Appropriationhome from "./components/layout/appropriationhome.vue";
import Layoutreport from "./components/layout/report.vue";
import Reporthome from "./components/layout/reporthome.vue";
import Bankreport from "./components/report/bank.vue";
import Branchbankreport from "./components/report/branch/bank.vue";
import Expensesreport from "./components/report/expenses.vue";
import Branchexpensesreport from "./components/report/branch/expenses.vue";
import Expensesdetailreport from "./components/report/expensesdetail.vue";
import Voucherprint from "./components/appropriation/voucher/print.vue";
import Budgetliabilitycreate from "./components/budget/liability/create.vue";
import Budgetliabilitylist from "./components/budget/liability/list.vue";
import Budgetliabilityupdate from "./components/budget/liability/update.vue";
import Baildtscreate from "./components/bail/dts/create.vue";
import Baildtslist from "./components/bail/dts/list.vue";
import Baildtsupdate from "./components/bail/dts/update.vue";
import Bailpayercreate from "./components/bail/payer/create.vue";
import Bailpayerlist from "./components/bail/payer/list.vue";
import Bailpayerupdate from "./components/bail/payer/update.vue";
import Bailrecordcreate from "./components/bail/record/create.vue";
import Bailrecordlist from "./components/bail/record/list.vue";
import Bailrecordupdate from "./components/bail/record/update.vue";
import Bailvouchercreate from "./components/bail/voucher/create.vue";
import Bailvoucherlist from "./components/bail/voucher/list.vue";
import Bailvoucherupdate from "./components/bail/voucher/update.vue";
import Appropriationdtscreate from "./components/appropriation/dts/create.vue";
import Appropriationdtslist from "./components/appropriation/dts/list.vue";
import Appropriationdtsupdate from "./components/appropriation/dts/update.vue";
import Bailchequeprint from "./components/bail/cheque/chequelist.vue";
import Bailchequecancel from "./components/bail/cheque/chequecancel.vue";
import Bailchequesettingcreate from "./components/bail/chequesetting/create.vue";
import Bailchequesettinglist from "./components/bail/chequesetting/list.vue";
import Bailchequesettingupdate from "./components/bail/chequesetting/update.vue";
import Home from "./components/dashboard/home.vue";
import Budgetincomesourcecreate from "./components/budget/incomesource/create.vue";
import Budgetincomesourcelist from "./components/budget/incomesource/list.vue";
import Budgetincomesourceupdate from "./components/budget/incomesource/update.vue";
import ProjectTaskReport from "./components/projects/project/report/taskreportcontainer.vue";
import ProjectStaffReport from "./components/projects/project/report/staffreportcontainer.vue";
import Designationcreate from "./components/setup/designation/create.vue";
import Designationlist from "./components/setup/designation/list.vue";
import Designationupdate from "./components/setup/designation/update.vue";
import Payrollcategorycreate from "./components/payroll/category/create.vue";
import Payrollcategoryupdate from "./components/payroll/category/update.vue";
import Payrollcategorylist from "./components/payroll/category/list.vue";
import Payheadscreate from "./components/payroll/payheads/create.vue";
import Payheadslist from "./components/payroll/payheads/list.vue";
import Payheadsupdate from "./components/payroll/payheads/update.vue";
import Payrolltaxcreate from "./components/payroll/tax/create.vue";
import Payrolltaxlist from "./components/payroll/tax/list.vue";
import Payrolltaxupdate from "./components/payroll/tax/update.vue";
import Payrollmappingadd from "./components/payroll/mapping/create.vue";
import Payrollmappinglist from "./components/payroll/mapping/list.vue";
import Payrollmappingupdate from "./components/payroll/mapping/update.vue";
import Payrollinaccessiblecreate from "./components/payroll/inaccessible/create.vue";
import Payrollinaccessiblelist from "./components/payroll/inaccessible/list.vue";
import Payrollinaccessibleupdate from "./components/payroll/inaccessible/update.vue";
import Payrollreportattendance from "./components/payroll/report/attendance.vue";
import Payrollreportpayroll from "./components/payroll/report/payroll.vue";
import Payrolladdholiday from "./components/payroll/setting/holiday/create.vue";
import Payrollupdateholiday from "./components/payroll/setting/holiday/update.vue";
import Payrollholidaylist from "./components/payroll/setting/holiday/list.vue";
import Payrollweekendcreate from "./components/payroll/setting/weekend/create.vue";
import Payrollweekendlist from "./components/payroll/setting/weekend/list.vue";
import Payrollweekendupdate from "./components/payroll/setting/weekend/udpate.vue";
import Payrollholidaysettinglist from "./components/payroll/holiday/list.vue";
import Payrollholidaysettingcreate from "./components/payroll/holiday/create.vue";
import Payrollholidaysettingupdate from "./components/payroll/holiday/update.vue";
import Staffbankadd from "./components/setup/staff/bank/create.vue";
import Staffbankupdate from "./components/setup/staff/bank/update.vue";
import Staffeducationadd from "./components/setup/staff/education/create.vue";
import Staffeducationupdate from "./components/setup/staff/education/update.vue";
import Staffworkexperiencecreate from "./components/setup/staff/workexperience/create.vue";
import Staffworkexperienceupdate from "./components/setup/staff/workexperience/update.vue";
import i18n from "./i18n";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: `/${i18n.locale}`,
    },
    {
      path: "/:lang",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          component: About,
        },
        {
          path: "login",
          component: Login,
        },
        {
          path: "dashboard",
          component: Dashboard,
          children: [
            {
              path: "home",
              component: Home,
            },
            {
              path: "setup",
              component: Layoutsetup,
              children: [
                {
                  path: "home",
                  component: Setuphome,
                },
                {
                  path: "branch/create",
                  component: Branchadd,
                },
                {
                  path: "branch/list",
                  component: Branchlist,
                },
                {
                  path: "branch/update/:id",
                  component: Branchupdate,
                },
                {
                  path: "department/add",
                  component: Departmentadd,
                },
                {
                  path: "department/list",
                  component: Departmentlist,
                },
                {
                  path: "department/update/:id",
                  component: Departmentupdate,
                },
                {
                  path: "shift/add",
                  component: Shiftadd,
                },
                {
                  path: "shift/list",
                  component: Shiftlist,
                },
                {
                  path: "shift/update/:id",
                  component: Shiftupdate,
                },
                {
                  path: "staff/enroll",
                  component: Staffenroll,
                },
                {
                  path: "staff/list",
                  component: Stafflist,
                },
                {
                  path: "staff/update/:id",
                  component: Staffupdate,
                },
                {
                  path: "staff/bank/create",
                  component: Staffbankadd,
                },
                {
                  path: "staff/bank/update/:id",
                  component: Staffbankupdate,
                },
                {
                  path: "staff/education/create",
                  component: Staffeducationadd,
                },
                {
                  path: "staff/education/update/:id",
                  component: Staffeducationupdate,
                },
                {
                  path: "staff/workexperience/create",
                  component: Staffworkexperiencecreate,
                },
                {
                  path: "staff/workexperience/update/:id",
                  component: Staffworkexperienceupdate,
                },
                {
                  path: "designation/list",
                  component: Designationlist,
                },
                {
                  path: "designation/create",
                  component: Designationcreate,
                },
                {
                  path: "designation/update/:id",
                  component: Designationupdate,
                },
              ],
            },
            {
              path: "payroll",
              component: Payroll,
              children: [
                {
                  path: "home",
                  component: Payrolldashboard,
                },
                {
                  path: "attendance/take",
                  component: Payrollattendance,
                },
                {
                  path: "attendance/report",
                  component: Payrollattendancereport,
                },
                {
                  path: "attendance/individualreport",
                  component: Payrollattendanceindividual,
                },
                {
                  path: "category/create",
                  component: Payrollcategorycreate,
                },
                {
                  path: "category/list",
                  component: Payrollcategorylist,
                },
                {
                  path: "category/update/:id",
                  component: Payrollcategoryupdate,
                },
                {
                  path: "payheads/create",
                  component: Payheadscreate,
                },
                {
                  path: "payheads/list",
                  component: Payheadslist,
                },
                {
                  path: "payheads/update/:id",
                  component: Payheadsupdate,
                },
                {
                  path: "tax/create",
                  component: Payrolltaxcreate,
                },
                {
                  path: "tax/list",
                  component: Payrolltaxlist,
                },
                {
                  path: "tax/update/:id",
                  component: Payrolltaxupdate,
                },
                {
                  path: "holiday/create",
                  component: Payrollholidaysettingcreate,
                },
                {
                  path: "holiday/update/:id",
                  component: Payrollholidaysettingupdate,
                },
                {
                  path: "holiday/list",
                  component: Payrollholidaysettinglist,
                },
                {
                  path: "mapping/add",
                  component: Payrollmappingadd,
                },
                {
                  path: "mapping/list",
                  component: Payrollmappinglist,
                },
                {
                  path: "mapping/update/:id",
                  component: Payrollmappingupdate,
                },
                {
                  path: "inaccessible/create",
                  component: Payrollinaccessiblecreate,
                },
                {
                  path: "inaccessible/list",
                  component: Payrollinaccessiblelist,
                },
                {
                  path: "inaccessible/update/:id",
                  component: Payrollinaccessibleupdate,
                },
                {
                  path: "report/attendance",
                  component: Payrollreportattendance,
                },
                {
                  path: "report/payroll",
                  component: Payrollreportpayroll,
                },
                {
                  path: "setting/addholiday",
                  component: Payrolladdholiday,
                },
                {
                  path: "setting/updateholiday/:id",
                  component: Payrollupdateholiday,
                },
                {
                  path: "setting/holidaylist",
                  component: Payrollholidaylist,
                },
                {
                  path: "setting/weekendlist",
                  component: Payrollweekendlist,
                },
                {
                  path: "setting/weekendcreate",
                  component: Payrollweekendcreate,
                },
                {
                  path: "setting/weekendupdate/:id",
                  component: Payrollweekendupdate,
                },
              ],
            },
            {
              path: "project",
              component: Layoutproject,
              children: [
                {
                  path: "home",
                  component: Projecthome,
                },
                {
                  path: "workingarea/create",
                  component: Workingareaadd,
                },
                {
                  path: "workingarea/list",
                  component: Workingarealist,
                },
                {
                  path: "workingarea/update/:id",
                  component: Workingareaupdate,
                },
                {
                  path: "dpr/create",
                  component: Dprcreate,
                },
                {
                  path: "dpr/list",
                  component: Dprlist,
                },
                {
                  path: "dpr/update/:id",
                  component: Dprupdate,
                },
                {
                  path: "project/create",
                  component: Projectcreate,
                },
                {
                  path: "project/list",
                  component: Projectlist,
                },
                {
                  path: "project/update/:id",
                  component: Projectupdate,
                },
                {
                  path: "project/map",
                  name: "projectmap",
                  component: Projectmap,
                },
                {
                  path: "project/images/:id",
                  component: Projectimage,
                },
                {
                  path: "project/dpr/:id",
                  component: Projectdpr,
                },
                {
                  path: "task/taskcalendar",
                  component: Taskcalendar,
                },
                {
                  path: "task/tasklist",
                  component: Tasklist,
                },
                {
                  path: "task/assigntask",
                  name: "assigntask",
                  component: Taskcreate,
                },
                {
                  path: "task/edittask/:id",
                  component: Taskupdate,
                },
                {
                  path: "report/taskreport",
                  component: ProjectTaskReport,
                },
                {
                  path: "report/staffreport",
                  component: ProjectStaffReport,
                },
              ],
            },
            {
              path: "budget",
              component: Layoutbudget,
              children: [
                {
                  path: "home",
                  component: Budgethome,
                },
                {
                  path: "fiscal/list",
                  component: Budgetfiscalyearlist,
                },
                {
                  path: "fiscal/create",
                  component: Budgetfiscalyearcreate,
                },
                {
                  path: "fiscal/update/:id",
                  component: Budgetfiscalyearupdate,
                },
                {
                  path: "budgethead/list",
                  component: Budgetheadlist,
                },
                {
                  path: "budgethead/create",
                  component: Budgetheadcreate,
                },
                {
                  path: "budgethead/update/:id",
                  component: Budgetheadupdate,
                },
                {
                  path: "source/list",
                  component: Budgetsourcelist,
                },
                {
                  path: "source/create",
                  component: Budgetsourcecreate,
                },
                {
                  path: "source/update/:id",
                  component: Budgetsourceupdate,
                },
                {
                  path: "incomesource/create",
                  component: Budgetincomesourcecreate,
                },
                {
                  path: "incomesource/list",
                  component: Budgetincomesourcelist,
                },
                {
                  path: "incomesource/update/:id",
                  component: Budgetincomesourceupdate,
                },
                {
                  path: "expenses/list",
                  component: Budgetexpenseslist,
                },
                {
                  path: "expenses/create",
                  component: Budgetexpensescreate,
                },
                {
                  path: "expenses/update/:id",
                  component: Budgetexpensesupdate,
                },
                {
                  path: "liability/create",
                  component: Budgetliabilitycreate,
                },
                {
                  path: "liability/list",
                  component: Budgetliabilitylist,
                },
                {
                  path: "liability/update/:id",
                  component: Budgetliabilityupdate,
                },
                {
                  path: "sourcedetermination/list",
                  component: Budgetsourcedetermination,
                },
                {
                  path: "sourcedetermination/approval",
                  component: Budgetsourcedeterminationapproval,
                },
                {
                  path: "sourcedetermination/create",
                  component: Budgetsourcedeterminationcreate,
                },
                {
                  path: "sourcedetermination/update/:id",
                  component: Budgetsourcedeterminationupdate,
                },
                {
                  path: "expensesapproval/list/:type",
                  component: Budgetexpensesdetermination,
                },
                {
                  path: "expensesdetermination/list/:type",
                  component: Budgetexpensesdetermination,
                },
                {
                  path: "expensesdetermination/create",
                  component: Budgetexpensesdeterminationcreate,
                },
                {
                  path: "expensesdetermination/update/:id",
                  component: Budgetexpensesdeterminationupdate,
                },
              ],
            },
            {
              path: "appropriation",
              component: Layoutappropriation,
              children: [
                {
                  path: "home",
                  component: Appropriationhome,
                },
                {
                  path: "bank/list",
                  component: Appropriationbanklist,
                },
                {
                  path: "bank/create",
                  component: Appropriationbankcreate,
                },
                {
                  path: "bank/update/:id",
                  component: Appropriationbankupdate,
                },
                {
                  path: "cheque/list",
                  component: Appropriationchequelist,
                },
                {
                  path: "cheque/create",
                  component: Appropriationchequecreate,
                },
                {
                  path: "cheque/update/:id",
                  component: Appropriationchequeupdate,
                },
                {
                  path: "dts/list",
                  component: Appropriationdtslist,
                },
                {
                  path: "dts/create",
                  component: Appropriationdtscreate,
                },
                {
                  path: "dts/update/:id",
                  component: Appropriationdtsupdate,
                },
                {
                  path: "party/list",
                  component: Appropriationpartylist,
                },
                {
                  path: "party/create",
                  component: Appropriationpartycreate,
                },
                {
                  path: "party/update/:id",
                  component: Appropriationpartyupdate,
                },
                {
                  path: "voucher/receiptcreate",
                  component: Receiptvouchercreate,
                },
                {
                  path: "voucher/receiptupdate/:id",
                  component: Receiptvoucherupdate,
                },
                {
                  path: "voucher/paymentcreate",
                  component: Paymentvouchercreate,
                },
                {
                  path: "voucher/paymentupdate/:id",
                  component: Paymentvoucherupdate,
                },
                {
                  path: "voucherapproval/list",
                  component: Voucherlist,
                },
                {
                  path: "voucherapproval/list/:id/print",
                  component: Voucherprint,
                },
                {
                  path: "printing/list",
                  component: Printinglist,
                },
                {
                  path: "chequecancel/cancel",
                  component: Appropriationchequecancel,
                },
              ],
            },
            {
              path: "bail",
              component: Layoutbail,
              children: [
                {
                  path: "home",
                  component: Bailhome,
                },
                {
                  path: "bank/list",
                  component: Bailbanklist,
                },
                {
                  path: "bank/create",
                  component: Bailbankcreate,
                },
                {
                  path: "bank/update/:id",
                  component: Bailbankupdate,
                },
                {
                  path: "chequesetting/list",
                  component: Bailchequesettinglist,
                },
                {
                  path: "chequesetting/create",
                  component: Bailchequesettingcreate,
                },
                {
                  path: "chequesetting/update/:id",
                  component: Bailchequesettingupdate,
                },
                {
                  path: "dts/create",
                  component: Baildtscreate,
                },
                {
                  path: "dts/list",
                  component: Baildtslist,
                },
                {
                  path: "dts/update/:id",
                  component: Baildtsupdate,
                },
                {
                  path: "payer/create",
                  component: Bailpayercreate,
                },
                {
                  path: "payer/list",
                  component: Bailpayerlist,
                },
                {
                  path: "payer/update/:id",
                  component: Bailpayerupdate,
                },
                {
                  path: "record/create",
                  component: Bailrecordcreate,
                },
                {
                  path: "record/list",
                  component: Bailrecordlist,
                },
                {
                  path: "record/update/:id",
                  component: Bailrecordupdate,
                },
                {
                  path: "voucher/create",
                  component: Bailvouchercreate,
                },
                {
                  path: "voucher/list",
                  component: Bailvoucherlist,
                },
                {
                  path: "voucher/update/:id",
                  component: Bailvoucherupdate,
                },
                {
                  path: "cheque/print",
                  component: Bailchequeprint,
                },
                {
                  path: "cheque/cancel",
                  component: Bailchequecancel,
                },
              ],
            },
            {
              path: "report",
              component: Layoutreport,
              children: [
                {
                  path: "home",
                  component: Reporthome,
                },
                {
                  path: "workareareport",
                  component: Workingareareport,
                },
                {
                  path: "budgetreport",
                  component: Budgetreport,
                },
                {
                  path: "appropriationreport",
                  component: Appropriationreport,
                },
                {
                  path: "projectreport",
                  component: Projectreport,
                },
                {
                  path: "incomexpense",
                  component: Incomexpensereport,
                },
                {
                  path: "bank",
                  component: Bankreport,
                },
                {
                  path: "expenses",
                  component: Expensesreport,
                },
                {
                  path: "expensesdetail",
                  component: Expensesdetailreport,
                },
                {
                  path: "branch/workarea",
                  component: Reportbranchworkarea,
                },
                {
                  path: "branch/budget",
                  component: Reportbranchbudget,
                },
                {
                  path: "branch/project",
                  component: Reportbranchproject,
                },
                {
                  path: "branch/appropriation",
                  component: Reportbranchappropriation,
                },
                {
                  path: "branch/incomexpense",
                  component: Reportbranchincomexpense,
                },
                {
                  path: "branch/bank",
                  component: Branchbankreport,
                },
                {
                  path: "branch/expenses",
                  component: Branchexpensesreport,
                },
              ],
            },
          ],
        },
        {
          path: "login/accountverification/:email",
          component: accoutverifyforpasswordchangeVue,
        },
        {
          path: "setting/changepassword/:email",
          component: changepasswordVue,
        },
        {
          path: "setting/verifyaccount",
          component: Verifyaccountforemailchange,
        },
        {
          path: "setting/newemail",
          component: Newemail,
        },
        {
          path: "setting/confirmnewemail/:email",
          component: Confirmnewemail,
        },
        {
          path: "setting/updateinfo",
          component: Updateinfo,
        },
        {
          path: "login/changepassword/:email",
          component: changepasswordVue,
        },
        {
          path: "signup",
          component: Register,
        },
        {
          path: "signup/accountverification/:email",
          component: accountverificationVue,
        },
        {
          path: "finduser/:type",
          component: Finduser,
        },
        {
          path: "/about",
          component: About,
        },
        {
          path: "dashboard",
          component: Dashboard,
        },
        {
          path: "/solution",
          component: Solution,
        },
        {
          path: "dpr/view/:id",
          component: Dprview,
        },
      ],
    },
  ],
});
