<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('staffworkexperience.workexperience') }}
        <small>{{ $t('staffworkexperience.createNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :experience="experience" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      experience: {
        staff_id: null,
        organization_name: null,
        designation: null,
        from_date: null,
        to_date: null,
        month: null,
      },
    };
  },
  components: {
    Form,
  },
  created() {
    this.$emit("close");
  },
};
</script>
