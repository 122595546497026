<template>
  <div>
    <Topnav />
    <div class="info-card">
      <router-view></router-view>
    </div>
    <div class="sms-logo" v-if="$store.state.smsFlag == 1">
      <a href="http://sms.kasturisanjaal.com/" target="_blank">
        <img class="sms" src="../../assets/sms_logo.png" alt="SMS LOGO" />
      </a>
    </div>
  </div>
</template>
<script>
import Topnav from "../dashboard/topnav.vue";

export default {
  components: {
    Topnav,
  },
};
</script>
<style>
.sms-logo {
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.sms {
  width: 100px;
  margin: 10px;
  /* position: fixed; */
}

.dashboard-header {
  text-align: center;
  background: whitesmoke;
  padding: 20px 0px 50px 0px;
  box-shadow: 0px 2px 3px 1px grey;
  position: relative;
}

.dashboard-header > .branding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-header .branding .logo > img {
  height: 90px;
  width: 90px;
  margin-right: 10px;
}

.dashboard-header .content > div:first-child {
  font-size: 25px;
  font-weight: bold;
  line-height: initial;
}

.dashboard-header .content > div:nth-child(2) {
  font-size: 15px;
  font-weight: bold;
  line-height: initial;
}

.dashboard-header .content > div:nth-child(3),
.dashboard-header .content > div:last-child {
  font-size: 10px;
  line-height: initial;
}

.dashboard-header .icon-container {
  position: absolute;
  background: white;
  padding: 10px 50px;
  left: 50%;
  transform: translateX(-51%);
  bottom: -15px;
  box-shadow: 0px 0px 5px black;
  border-radius: 10px;
}

.dashboard-body > .downward-animation {
  position: relative;
  text-align: center;
  margin-top: 15px;
}

.dashboard-body > .downward-animation > span {
  position: absolute;
}

.dashboard-body > .downward-animation > span:first-child {
  bottom: -20px;
}

.dashboard-body > .downward-animation {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: arrowAnimation;
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.summary-links {
  padding-top: 30px;
}

.summary-links a {
  text-decoration: none;
}

.info-box {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.bg-pink {
  background-color: #e91e63 !important;
  color: #fff;
}

.bg-orange {
  background-color: #ff9800 !important;
  color: #fff;
}

.bg-light-green {
  background-color: #8bc34a !important;
  color: #fff;
}

.bg-cyan {
  background-color: #00bcd4 !important;
  color: #fff;
}

.info-box.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 80px;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s;
}

.info-box.hover-expand-effect:hover:after {
  width: 100%;
}

.info-box .icon {
  display: inline-block;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.12);
  width: 80px;
}

.info-box .icon i {
  color: #fff;
  font-size: 50px;
  line-height: 80px;
}

.info-box .content {
  display: inline-block;
  padding: 7px 10px;
}

.info-box .content .text {
  font-size: 13px;
  margin-top: 11px;
  color: #555;
}

.info-box .content .number {
  font-weight: normal;
  font-size: 26px;
  margin-top: -4px;
  color: #555;
}

.bg-pink .content .text,
.bg-pink .content .number {
  color: #fff !important;
}

.bg-cyan .content .text,
.bg-cyan .content .number {
  color: #fff !important;
}

.bg-light-green .content .text,
.bg-light-green .content .number {
  color: #fff !important;
}

.bg-orange .content .text,
.bg-orange .content .number {
  color: #fff !important;
}

.dashboard-graph {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dashboard-graph > div.card {
  width: 460px;
  margin: 2px;
}

@media (max-width: 767px) {
  .sms-logo {
    left: 82%;
    top: 92%;
  }
  .sms {
    width: 50px;
    margin: 10px;
  }
}
</style>
