var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t('payrolltax.startRange')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.tax.start_range.$model),expression:"v$.tax.start_range.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.tax.start_range.$error,
          },attrs:{"type":"number","step":"0.01"},domProps:{"value":(_vm.v$.tax.start_range.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.tax.start_range, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrolltax.startRangeError'))+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t('payrolltax.endRange')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.tax.end_range.$model),expression:"v$.tax.end_range.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.tax.end_range.$error,
          },attrs:{"type":"number","step":"0.01"},domProps:{"value":(_vm.v$.tax.end_range.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.tax.end_range, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('payrolltax.endRangeError')))])])])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('payrolltax.type')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payrolltax.taxPercentage')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payrolltax.extensionRange')))])])]),_c('tbody',_vm._l((_vm.tax.charge),function(hd,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.tax.charge.$each.$response.$errors[index].type.length,
                }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(hd.type),expression:"hd.type"}],ref:"dropDownHeadings",refInFor:true,staticClass:"form-select",class:{
                    'is-invalid':
                      _vm.v$.tax.charge.$each.$response.$errors[index].type
                        .length,
                  },attrs:{"disabled":"true"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(hd, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('payrolltax.single')))]),_c('option',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('payrolltax.married')))]),_c('option',{attrs:{"value":"3"}},[_vm._v(_vm._s(_vm.$t('payrolltax.disabled')))])])])])]),_c('td',[_c('div',{staticClass:"input-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(hd.tax_percentage),expression:"hd.tax_percentage"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.v$.tax.start_range.$error,
                },attrs:{"type":"number","step":"0.01"},domProps:{"value":(hd.tax_percentage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(hd, "tax_percentage", $event.target.value)}}}),_c('span',{staticClass:"input-group-text"},[_vm._v("%")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrolltax.taxPercentageError'))+" ")])])]),_c('td',[_c('div',{staticClass:"input-group required"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('rs')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(hd.extension_range),expression:"hd.extension_range"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.v$.tax.start_range.$error,
                },attrs:{"type":"number","step":"0.01"},domProps:{"value":(hd.extension_range)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(hd, "extension_range", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrolltax.extensionRangeError'))+" ")])])])])}),0)])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? "Add" : "Update")+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }