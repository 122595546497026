<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('branch.branch')}}
            <small>{{$t('branch.addNote')}}</small>
          </h2>
        </div>
        <div class="body">
          <div class="department-form">
            <Form :organization="organization" :type="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      organization: {
        organizationName: null,
        panVat: null,
        email: null,
        phone1: null,
        password: null,
        organizationAddress: null,
        lattitude: null,
        longitute: null,
        logo: [],
      },
    };
  },
  components: {
    Form,
  },
  created() {
    this.$emit('close');
  },
};
</script>
