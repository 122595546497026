<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("employeemapping.mapping") }}
        <small>{{ $t("employeemapping.addNote") }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/mapping/list`"
          class="btn btn-info btn-sm"
          >{{ $t("employeemapping.list") }}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :employee="employee" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      employee: {
        staff_id: null,
        payroll_category_id: null,
        payroll_grade_id: null,
        inaccessible_tax_status: null,
        inaccessible_id: null,
        services: [
          {
            payroll_payhead_id: null,
          },
        ],
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
