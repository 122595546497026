<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.cheque_number }}</td>
            <td>
              <button
                class="btn btn-danger btn-sm actionButton"
                @click="showModal(item)"
              >
                {{ $t("chequecancel.cancel") }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Cancelreason
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @confirmCancel="confirmCancel"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import Cancelreason from "./cancelreason.vue";

export default {
  components: { Cancelreason },
  data() {
    return {
      modalFlag: false,
      modalData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showModal(item) {
      this.modalData = item;
      this.modalFlag = true;
    },

    closeModal() {
      this.modalFlag = false;
    },

    confirmCancel(data) {
      this.$emit('removeItem',data);
    },
  },
};
</script>
