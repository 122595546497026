<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.date }}</td>
            <td>
              {{ item.purpose }}
              <!-- <div class="small">{{ item.task_name }}</div> -->
            </td>
            <td>
              {{ item.heading }}
              <div class="small">{{ "Acc. Name :" + item.account_name }}</div>
            </td>
            <td>
              {{ item.bank_name }}
              <div class="small">Chq. No. {{ item.cheque_number }}</div>
            </td>
            <td>Rs. {{ item.amount }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm actionButton"
                @click="printCheque(index)"
              >
                Print
              </button>
              <button
                class="btn btn-success btn-sm actionButton"
                @click="completed(index)"
              >
                Done
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    async completed(index) {
      if (confirm("Are you sure?")) {
        const argument = {
          id: this.list[index].id,
        };
        await axios
          .post("printing/bailprintingcompleted", argument, {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (res.data.loginFlag) {
              this.$emit("completedPrinting", { index: index });
              this.isLoading = false;
            } else {
              this.$toastr.e("Your login session expire.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    },

    printCheque(index) {
      if (this.list[index].settingFlag) {
        var setting = this.list[index].setting;
        const numWords = require("num-words");
        const amountInWords = numWords(this.list[index].amount).toUpperCase();
        var innerContent =
          '<div style="height:' +
          setting.height +
          "cm;width:" +
          setting.width +
          'cm;position:relative">';
        innerContent +=
          '<div style="position:absolute;left:' +
          setting.date_left +
          "cm;top:" +
          setting.date_top +
          'cm">' +
          this.list[index].date +
          "</div>";
        innerContent +=
          '<div style="position:absolute;left:' +
          setting.benificiary_left +
          "cm;top:" +
          setting.benificiary_top +
          'cm">' +
          this.list[index].account_name +
          "</div>";
        innerContent += "</div>";
        innerContent +=
          '<div style="position:absolute;left:' +
          setting.amount_number_left +
          "cm;top:" +
          setting.amount_number_top +
          'cm">' +
          this.list[index].amount +
          "</div>";
        innerContent += "</div>";

        innerContent +=
          '<div style="position:absolute;left:' +
          setting.amount_letter_left +
          "cm;top:" +
          setting.amount_letter_top +
          'cm">' +
          amountInWords +
          "</div>";
        innerContent += "</div>";

        var mywindow = window.open("", "PRINT", "height=400,width=600");

        mywindow.document.write(
          "<html><head><title>" + document.title + "</title>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write(innerContent);
        mywindow.document.write("</body></html>");

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
      } else {
        this.$toastr.e("Bank cheque settting is not set");
        this.$router.push(
          "/" + this.$i18n.locale + "/dashboard/appropriation/cheque/create"
        );
      }
    },
  },
};
</script>
