<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.bank_name }}</td>
            <td>{{ item.branch }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.account_no }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>
              <button
                class="btn btn-info btn-sm actionButton"
                @click="openCheque(index)"
              >
                {{ $t("bank.cheques") }}
              </button>
              <router-link
                v-if="bankType == 0"
                :to="
                  '/' +
                  $i18n.locale +
                  '/dashboard/appropriation/bank/update/' +
                  item.id
                "
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
              <router-link
                v-else
                :to="
                  '/' + $i18n.locale + '/dashboard/bail/bank/update/' + item.id
                "
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm actionButton"
              >
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Cheques
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import Cheques from "./cheque/container.vue";

export default {
  components: { Cheques },
  data() {
    return {
      modalFlag: false,
      modalData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
    bankType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
      }
    },

    openCheque(index) {
      if (this.bankType == 0)
        this.modalData = this.$store.state.bankList[index];
      else this.modalData = this.$store.state.bailBankList[index];
      this.modalFlag = true;
    },

    closeModal() {
      this.modalFlag = false;
    },
  },
};
</script>
