<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("csetting.head") }}
        <small>{{ $t("csetting.updateNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :cheque="cheque" :type="2" :bankType="1"/>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../../appropriation/cheque/form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      id: this.$route.params.id,
      cheque: this.$store.state.chequeSettingList.filter((item) => {
        return item.id==this.$route.params.id;
      })[0],
    };
  },
};
</script>
