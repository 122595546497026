<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('holidaySetup.holidaySetup') }}
        <small>{{ $t('holidaySetup.createNote') }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/holiday/list`"
          class="btn btn-info btn-sm"
          >{{ $t('holidaySetup.list') }}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :holiday="holiday" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      holiday: {
        heading: null,
        maximum_holiday: null,
        accumulated_status:null,
        description: null,
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
