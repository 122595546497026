<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required" v-if="type == 1">
      <label class="control-label">{{ $t("csetting.bank") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.cheque.bank_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.cheque.bank_id.$model"
          :class="{
            'is-invalid': v$.cheque.bank_id.$error,
          }"
        >
          <option v-for="(bank, index) in banks" :key="index" :value="bank.id">
            {{ bank.bank_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("csetting.bankError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("csetting.chequeType") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.cheque.type.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.cheque.type.$model"
          :class="{
            'is-invalid': v$.cheque.type.$error,
          }"
        >
          <option value="1">Without tearable sides</option>
          <option value="0">With tearable sides</option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("bank.chequeTypeError") }}</div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.height") }}</label>
          <input
            v-model="v$.cheque.height.$model"
            :class="{
              'is-invalid': v$.cheque.height.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("csetting.heightError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.width") }}</label>
          <input
            v-model="v$.cheque.width.$model"
            :class="{
              'is-invalid': v$.cheque.width.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("csetting.widthError") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.dtm") }}</label>
          <input
            v-model="v$.cheque.date_top.$model"
            :class="{
              'is-invalid': v$.cheque.date_top.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.dtmError") }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.dlm") }}</label>
          <input
            v-model="v$.cheque.date_left.$model"
            :class="{
              'is-invalid': v$.cheque.date_left.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.dlmError") }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.ntm") }}</label>
          <input
            v-model="v$.cheque.benificiary_top.$model"
            :class="{
              'is-invalid': v$.cheque.benificiary_top.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.ntmError") }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.nlm") }}</label>
          <input
            v-model="v$.cheque.benificiary_left.$model"
            :class="{
              'is-invalid': v$.cheque.benificiary_left.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.nlmError") }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.atm") }}</label>
          <input
            v-model="v$.cheque.amount_number_top.$model"
            :class="{
              'is-invalid': v$.cheque.amount_number_top.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("csetting.atmError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.alm") }}</label>
          <input
            v-model="v$.cheque.amount_number_left.$model"
            :class="{
              'is-invalid': v$.cheque.amount_number_left.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("csetting.almError") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.awtm") }}</label>
          <input
            v-model="v$.cheque.amount_letter_top.$model"
            :class="{
              'is-invalid': v$.cheque.amount_letter_top.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.awtmError") }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("csetting.awlm") }}</label>
          <input
            v-model="v$.cheque.amount_letter_left.$model"
            :class="{
              'is-invalid': v$.cheque.amount_letter_left.$error,
            }"
            step="0.1"
            placeholder="in cm"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("csetting.awlmError") }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("csetting.set") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      cheque: {
        bank_id: { required },
        type: { required },
        height: { required },
        width: { required },
        date_top: { required },
        date_left: { required },
        benificiary_top: { required },
        benificiary_left: { required },
        amount_number_left: { required },
        amount_number_top: { required },
        amount_letter_left: { required },
        amount_letter_top: { required },
      },
    };
  },
  data() {
    return {
      isDropDownLoading: true,
      banks: [],
      isSubmitting: false,
    };
  },
  props: {
    cheque: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    bankType: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getBanks();
  },
  methods: {
    async getBanks() {
      await axios
        .post(
          "helper/getbank",
          { type: this.bankType },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (!res.data.loginFlag) {
            this.$toastr.e("Alert", "Your login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          } else {
            this.banks = res.data.bank;
            if (this.type == 1 && res.data.bank.length > 0) {
              this.cheque.bank_id = res.data.bank[0].id;
            }
            this.isDropDownLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSubmitting = false;
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.cheque) {
          formData.set(key, this.cheque[key]);
        }
        var url = this.type == 1 ? "cheque/create" : "cheque/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Cheque settings successfully set."
                    : "Cheque settings successfully updated."
                );
                if (this.bankType == 0)
                  this.$router.push(
                    "/" +
                      this.$i18n.locale +
                      "/dashboard/appropriation/cheque/list"
                  );
                else
                  this.$router.push(
                    "/" +
                      this.$i18n.locale +
                      "/dashboard/bail/chequesetting/list"
                  );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
