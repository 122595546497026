<template>
  <div class="link-home-page">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">{{ $t("leftnav.sourceDetermination") }}</div>
          <div class="card-body">
            <apexcharts
              v-if="!isLoading"
              width="100%"
              type="bar"
              height="380"
              :options="chartOptions"
              :series="series"
            ></apexcharts>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            {{ $t("leftnav.expensesDetermination") }}
          </div>
          <div class="card-body">
            <apexcharts
              v-if="!isLoading"
              width="100%"
              type="bar"
              height="380"
              :options="chartOptions1"
              :series="expensesSeries"
            ></apexcharts>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            {{ $t("dashboard.expensesReport") }}
          </div>
          <div class="card-body">
            <apexcharts
              v-if="!isLoading"
              width="100%"
              type="pie"
              :options="pieOptions"
              :series="pieSeries"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">{{ $t("dashboard.totalReport") }}</div>
          <div class="card-body">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{{ $t("dashboard.particular") }}</th>
                  <th>{{ $t("dashboard.amount") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("dashboard.revenueExpectation") }}</td>
                  <td>
                    {{ $t("rs") + " " + sumArray(this.revenueArray) * 100000 }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("dashboard.expensesExpectation") }}</td>
                  <td>{{ $t("rs") + " " + sumArray(this.pieSeries) }}</td>
                </tr>
                <tr>
                  <td>{{ $t("dashboard.currentExpectation") }}</td>
                  <td>
                    {{
                      $t("rs") +
                      " " +
                      (this.pieSeries.length > 0 ? this.pieSeries[0] : 0)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("dashboard.assetExpectation") }}</td>
                  <td>
                    {{
                      $t("rs") +
                      " " +
                      (this.pieSeries.length > 1 ? this.pieSeries[1] : 0)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      revenueArray: [],
      series: [],
      expensesSeries: [],
      pieSeries: [],
    };
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          height: 380,
        },
        xaxis: {
          type: "Revenue",
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: this.$t("dashboard.revenueInRs"),
        },
        tooltip: {
          x: {
            formatter: (val) => {
              return val;
            },
          },
          y: {
            formatter: (val) => {
              return this.$t("rs") + " " + val * 100000;
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
      };
    },
    chartOptions1: function () {
      return {
        chart: {
          type: "bar",
          height: 380,
        },
        xaxis: {
          type: "Amount",
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: this.$t("dashboard.expensesInRs"),
        },
        tooltip: {
          x: {
            formatter: (val) => {
              return val;
            },
          },
          y: {
            formatter: (val) => {
              return this.$t("rs") + " " + val * 100000;
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
      };
    },
    pieOptions: function () {
      return {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            // if you want to display percentage;
            // return val + "%";
            return this.$t("rs") + " " + opts.w.config.series[opts.seriesIndex];
          },
        },
        labels: [this.$t("dashboard.chalu"), this.$t("dashboard.pujigat")],
      };
    },
  },
  created() {
    this.$emit("close");
    this.getData();
  },
  methods: {
    sumArray(data) {
      let sum = 0;
      for (let i = 0; i < data.length; i++) {
        sum += parseInt(data[i]);
      }
      return sum;
    },

    getData() {
      axios
        .post(
          "home/budget",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.revenueArray = res.data.revenueArray;
            res.data.expensesType.forEach((item) => {
              this.pieSeries.push(parseInt(item));
            });
            this.series = [
              {
                name: this.$t("dashboard.amount"),
                data: res.data.budget,
              },
            ];
            this.expensesSeries = [
              {
                name: this.$t("dashboard.amount"),
                data: res.data.expenses,
              },
            ];
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
