<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-red">
    <div class="container-fluid">
      <a class="navbar-brand" href="javascript:void(0)">Kasturi Workspace</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mynavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse dil-navbar-collapse" id="mynavbar">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              >Products</a
            >
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="http://sms.kasturisanjaal.com/index.php"
                  target="_blank"
                  ><span>Kasturi SMS</span>
                  <div class="dropdown-item-description">
                    Send bulk SMS on single click
                  </div></a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="https://gyansansaar.com/"
                  target="_blank"
                  ><span>Gyansansaar</span>
                  <div class="dropdown-item-description">
                    Enchance education system and make better result oriented
                  </div></a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="https://play.google.com/store/apps/details?id=com.kasturisanjaal.karobar"
                  target="_blank"
                  ><span>Kasturi Karobar</span>
                  <div class="dropdown-item-description">
                    Digitize business transaction
                  </div></a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              >Solution</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/solution">
                  <span>Efficiency Tracking</span>
                  <div class="dropdown-item-description">
                    Track each employee efficiency
                  </div></router-link
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" target="_blank"
                  ><span>Trace work</span>
                  <div class="dropdown-item-description">
                    Trace your business work
                  </div></a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" target="_blank"
                  ><span>Business Forecast</span>
                  <div class="dropdown-item-description">
                    Forecast your business with different perspective reports
                  </div></a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href="javascript:void(0)">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)"
              >Contact And Support</a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              >{{$t('language')}}</a
            >
            <ul class="dropdown-menu">
              <li v-if="$i18n.locale!='en'">
                <a href="#" @click.prevent="setLocale('en')" class="dropdown-item">EN</a>
              </li>
              <li  v-if="$i18n.locale!='np'">
                <a href="#" @click.prevent="setLocale('np')" class="dropdown-item">ने</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link btn login_button" :to="`/${$i18n.locale}/login`"
              >LOGIN</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
     setLocale(locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale }
      })
      // this.hideDropdown()
    }
  },
}
</script>
<style>
.bg-red {
  background: #dc4146;
}

.bg-pink {
  background-color: #e91e63;
}

.bg-purple {
  background-color: #9c27b0;
}

.bg-deep-purple {
  background-color: #673ab7;
}

.bg-indigo {
  background-color: #3f51b5;
}

.bg-blue {
  background-color: #2196f3;
}

.bg-light-blue {
  background-color: #03a9f4;
}

.bg-blue {
  background-color: #03a9f4;
}

.bg-cyan {
  background-color: #00bcd4;
}

.bg-teal {
  background-color: #009688;
}

.bg-green {
  background-color: #4caf50;
}

.bg-light-green {
  background-color: #8bc34a;
}

.bg-lime {
  background-color: #cddc39;
}

.bg-yellow {
  background-color: #ffeb3b;
}

.bg-amber {
  background-color: #ffc107;
}

.bg-orange {
  background-color: #ff9800;
}

.bg-deep-orange {
  background-color: #ff5722;
}

.bg-brown {
  background-color: #795548;
}

.bg-grey {
  background-color: #9e9e9e;
}

.bg-blue-grey {
  background-color: #607d8b;
}

.bg-black {
  background-color: #000;
}

.dil-navbar-collapse {
  justify-content: space-between;
}

.dropdown-menu {
  border-radius: 0px;
  margin-top: 10px !important;
  box-shadow: 2px 2px 4px 0px grey;
  min-width: 15rem;
}

.dropdown-menu a.dropdown-item {
  white-space: inherit;
  padding: 0.25rem 0.5rem;
}

.dropdown-menu > li > a.dropdown-item > span {
  font-weight: bold;
  color: crimson;
}

.dropdown-menu > li > a.dropdown-item > div.dropdown-item-description {
  font-size: 11px;
  color: #726767;
}

.login_button {
  background: white;
  padding: 7px 20px;
  color: #404040 !important;
  font-weight: bold;
  box-shadow: 2px 2px 1px 1px #443232;
}

.login_button:hover {
  color: white !important;
  background-color: #404040;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255 / 84%);
}

.navbar-nav > li.active {
  position: relative;
  font-weight: bold;
  text-shadow: 2px 2px 3px darkslategrey;
}

.navbar-nav > li.active:after {
  content: " ";
  background: #970303;
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: -7px;
}
</style>
