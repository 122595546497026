<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.no_of_weekend }}</td>
            <td>{{ getDays() }}</td>
            <td>
              <router-link
                :to="`/${$i18n.locale}/dashboard/payroll/setting/weekendupdate/${item.id}`"
                class="btn btn-warning btn-sm actionButton"
                >{{ $t("update") }}</router-link
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getDays() {
      var days = [];
      this.list[0].days.forEach((item) => {
        switch (item.day) {
          case "0":
            days.push(this.$t("weekend.sunday"));
            break;
          case "1":
            days.push(this.$t("weekend.moday"));
            break;
          case "2":
            days.push(this.$t("weekend.tuesday"));
            break;
          case "3":
            days.push(this.$t("weekend.wednesday"));
            break;
          case "4":
            days.push(this.$t("weekend.thursday"));
            break;
          case "5":
            days.push(this.$t("weekend.friday"));
            break;
          default:
            days.push(this.$t("weekend.saturday"));
            break;
        }
      });
      var dayText = days.join(", ");
      return dayText;
    },

    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
      }
    },
  },
};
</script>
