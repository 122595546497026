<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="setupUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/bail/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'setup' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('bail.setup') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/bank/list`"
                  class="dropdown-item"
                  >{{ $t('bail.bank') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/chequesetting/list`"
                  class="dropdown-item"
                  >{{ $t('bail.bankChequeSetting') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/dts/list`"
                  class="dropdown-item"
                  >{{ $t('bail.dts') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/payer/list`"
                  class="dropdown-item"
                  >{{ $t('bail.payer') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/record/list`"
                  class="dropdown-item"
                  >{{ $t('bail.record') }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'entry' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('bail.entry') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/voucher/create`"
                  class="dropdown-item"
                  >{{ $t('bail.voucher') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/voucher/list`"
                  class="dropdown-item"
                  >{{ $t('bail.voucherApproval') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/cheque/print`"
                  class="dropdown-item"
                  >{{ $t('bail.chequePrint') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/bail/cheque/cancel`"
                  class="dropdown-item"
                  >{{ $t('bail.chequeCancel') }}</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/individualreport`"
                  class="dropdown-item"
                  >{{ 'Bank Guarantee' }}</router-link
                >
              </li> -->
            </ul>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'setup' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ 'Report' }}</a>
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/take`"
                  class="dropdown-item"
                  >{{ 'Bank Guarantee' }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/individualreport`"
                  class="dropdown-item"
                  >{{ 'Bail' }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/report`"
                  class="dropdown-item"
                  >{{ 'Cheque' }}</router-link
                >
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  methods: {
    close() {
      this.$refs.setupUl.children.forEach((item, index) => {
        item.childNodes[0].classList.remove("show");
        if (index > 0) {
          item.childNodes[1].classList.remove("show");
        }
      });
      this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      var currentTab = e.target.parentNode;
      this.$refs.setupUl.children.forEach((item, index) => {
        if (!item.contains(currentTab)) {
          item.childNodes[0].classList.remove("show");
          if (index > 0) {
            item.childNodes[1].classList.remove("show");
          }
        }
      });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
