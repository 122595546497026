<template>
  <div class="login-body">
    <div class="container">
      <div class="login_container">
        <div class="find_account_container">
          <div class="find_account_form">
            <h2 class="text-center">{{$t('accountverification').verification}}</h2>
            <p style="color: #de6262">
              {{$t('accountverification').note}}
            </p>
            <div class="row">
              <div class="col-lg-12">
                <form @submit.prevent="submitForm">
                  <div class="form-group">
                    <label for="passwordresetrequestform-email"
                      >{{$t('accountverification').pin}}</label
                    >
                    <input
                      v-model="v$.verificationPin.$model"
                      type="number"
                      class="form-control"
                      autofocus="true"
                      :class="{
                        'is-invalid': v$.verificationPin.$error,
                      }"
                    />

                    <div class="invalid-feedback">
                      {{$t('accountverification').pinError}}
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-danger btn-block submitButton"
                      name="login-button"
                      v-if="!isSubmitting"
                    >
                      <div>{{$t('accountverification').verify}}</div>
                    </button>
                    <button
                      class="btn btn-danger btn-block submitButton loading"
                      disabled
                      v-else
                    >
                      <div class="spinner-border text-warning"></div>
                      <div>{{$t('loading')}}</div>
                    </button>
                  </div>
                </form>
                <br />
                <div class="text-center resend_container">
                  <div>{{$t('accountverification').donot}}</div>
                  <div>
                    <a
                      @click.prevent="resendCode"
                      class="resendCode"
                      v-if="!isLoading"
                      >{{$t('accountverification').resend}}</a
                    >
                    <div class="spinner-border text-dark" v-else></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      verificationPin: null,
      email: this.$route.params.email,
    };
  },
  validations() {
    return {
      verificationPin: { required },
    };
  },
  methods: {
    async resendCode() {
      this.isLoading = true;
      var formData = new FormData();
      formData.set("email", this.email);

      await axios
        .post("organization/resendcode", formData, {
          onUploadProgress: (uploadEvent) => {
            console.log(
              "Upload progress" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                "%"
            );
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.flag) {
            this.$toastr.s("Email successfully sent.");
          } else {
            this.$toastr.e("Something went wrong. Try again later.");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("verificationPin", this.verificationPin);
        formData.set("email", this.email);
        await axios
          .post("organization/verifyaccount", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              this.$toastr.s("Change your password.");
              this.$router.push("/"+this.$i18n.locale+"/login/changepassword/" + this.email);
            } else {
              this.$toastr.e("Alert!", "Verification pin do not match.");
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
<style>
.resendCode {
  color: teal;
  cursor: pointer;
  text-decoration: none;
}

.resend_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resend_container > div:first-child {
  margin-right: 5px;
}
</style>
