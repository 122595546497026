<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('bailRecord.bailRecord')}}
            <small>{{$t('bailRecord.addNote')}}</small>
          </h2>
          <div>
              <router-link :to="`/${$i18n.locale}/dashboard/bail/record/list`" class="btn btn-info btn-sm">{{$t('bailRecord.bailRecordList')}}</router-link>
          </div>
        </div>
        <div class="body">
          <div class="source-heading-form">
              <Form :record="record" :type="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      record: {
        fiscal_year_id: null,
        type:null,
        purpose:null,
        reference_no:null,
        start_date:null,
        end_date:null,
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
