<template>
  <div class="link-home-page">
    <div class="row">
      <div class="col-md-6">
        <apexcharts
          v-if="!isLoading"
          width="100%"
          type="pie"
          :options="dataOptions"
          :series="dataSeries"
        ></apexcharts>
      </div>
      <div class="col-md-6">
        <apexcharts
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      dataSeries: [],
      series: [],
    };
  },
  created() {
    this.$emit("close");
    this.getData();
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          height: 350,
          type: "radialBar",
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#1dd51d", "#4c6bc5", "#f70303"],
        labels: [
          this.$t("dashboard.totalProject"),
          this.$t("dashboard.completedProject"),
          this.$t("dashboard.pendingProject"),
          this.$t("dashboard.deadlineProject"),
        ],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "left",
          offsetX: 35,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3,
          },
        },
      };
    },
    dataOptions: function () {
      return {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
          events: {
            legendClick: (chartContext, seriesIndex, config) => {
              this.jumpRoute(seriesIndex);
            },
            // dataPointSelection: (event, chartContext, config) => {
            //   this.jumpRoute(config.dataPointIndex);
            // },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            // if you want to display percentage;
            // return val + "%";
            return opts.w.config.series[opts.seriesIndex];
          },
        },
        labels: [
          this.$t("dashboard.workingArea"),
          this.$t("dashboard.project"),
          this.$t("dashboard.task"),
        ],
      };
    },
  },
  methods: {
    jumpRoute(index) {
      switch (index) {
        case 0:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/project/workingarea/list"
          );
          break;
        case 1:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/project/project/list"
          );
          break;
        default:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/project/task/taskcalendar"
          );
          break;
      }
    },

    getData() {
      axios
        .post(
          "home/project",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.dataSeries = res.data.data;
            this.series = res.data.radarData;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
