<template>
  <div class="header">
    <div class="export-container">
      <button class="btn btn-info btn-sm" @click="exportPdf">PDF</button>
    </div>
    <div id="toprint1">
      <h2>{{ list.name }}</h2>
      <h5>{{ $t("attendance.attendanceReport") }}</h5>
      <!-- <h6>From : {{ search.from }} : {{ search.to }}</h6> -->
      <div class="table-reponsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("attendance.date") }}</th>
              <th>{{ $t("attendance.checkIn") }}</th>
              <th>{{ $t("attendance.checkOut") }}</th>
            </tr>
          </thead>
          <tbody v-if="list.attendance.length > 0">
            <tr v-for="(res, index) in list.attendance" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ convertedDate(res.date) }}</td>
              <td>{{ res.check_in }}</td>
              <td>{{ res.check_out }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="no-record">{{ $t("norecord") }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedDate(date) {
      if (this.$i18n.locale == "np") {
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      } else return date;
    },

    exportPdf() {
      var element = document.getElementById("toprint1");
      html2pdf(element, {
        margin: 10,
        filename:
          this.list.name + "_attendance_report__" + new Date().getTime(),
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>
