<template>
  <div>
    <div class="card kasturi_workspace_card">
      <div class="header" :class="'bg-' + $store.state.skin">
        <h2>
          {{ $t("branchReport.workarea") }}
          <small>{{ $t("report.workingareaNote") }}</small>
        </h2>
      </div>
      <div class="body">
        <div class="export-container">
          <button class="btn btn-info btn-sm" @click="exportPdf">{{$t('printDownload')}}</button>
        </div>
        <div class="department-form">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-dark"></div>
          </div>
          <div v-else>
            <div id="toprint">
              <div style="text-align: center">
                <div style="font-size: 20px; font-weight: bold">
                  {{ loggedInUser.organization_name }}
                </div>
                <div style="font-size: 16px; font-weight: bold">
                  {{ loggedInUser.organization_address }}
                </div>
                <div>{{ $t("branchReport.workarea") }}</div>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-bordered reportTable"
                  v-for="(branch, i) in workingareaList"
                  :key="'branch' + i"
                  style="font-size: 11px"
                >
                  <thead>
                    <tr>
                      <th colspan="6">{{ branch.organization_name }}</th>
                    </tr>
                    <tr>
                      <th>{{ $t("sn") }}</th>
                      <th>{{ $t("report.workingarea") }}</th>
                      <th>{{ $t("report.appropriationAmount") }}</th>
                      <th>{{ $t("report.expensesAmount") }}</th>
                      <th>{{ $t("report.expensesPercentage") }}</th>
                      <th>{{ $t("report.stock") }}</th>
                    </tr>
                  </thead>
                  <tbody v-for="(area, j) in branch.area" :key="'Area' + j">
                    <tr style="background: #d9d9d9">
                      <td>{{ generateCounter(j) }}</td>
                      <td colspan="5">{{ area.working_area }}</td>
                    </tr>
                    <tr v-for="(task, jindex) in area.list" :key="jindex">
                      <td>{{ generateCounter(jindex) }}</td>
                      <td>{{ task.task_title }}</td>
                      <td>{{ $t("rs") + task.appropriation }}</td>
                      <td>{{ $t("rs") + task.expenses }}</td>
                      <td>
                        {{
                          isNaN(
                            (parseFloat(task.expenses) /
                              parseFloat(task.appropriation)) *
                              100
                          )
                            ? 0 + "%"
                            : (
                                (parseFloat(task.expenses) /
                                  parseFloat(task.appropriation)) *
                                100
                              ).toFixed(2) + "%"
                        }}
                      </td>
                      <td>
                        {{
                          $t("rs") +
                          (parseFloat(task.appropriation) -
                            parseFloat(task.expenses))
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="branch.area.length == 0">
                    <tr>
                      <td colspan="6" class="no-record">
                        {{ $t("norecord") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import axios from "axios";

export default {
  data() {
    return {
      isLoading: true,
      workingareaList: [],
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      modalData: {},
      modalFlag: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    generateCounter(counter) {
      return parseInt(counter) + 1;
    },

    getData() {
      axios
        .post(
          "branchreport/workarea",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.workingareaList = res.data.branch;
            console.log(this.workingareaList);
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeModal() {
      this.modalFlag = !this.modalFlag;
    },
  },
};
</script>
