<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("task.task") }}
        <small>{{ $t("task.listNote") }}</small>
      </h2>
      <ul class="header-dropdown m-r--5">
        <li>
          <router-link
            :to="'/' + $i18n.locale + '/dashboard/project/task/taskcalendar'"
            class="btn btn-light"
            >{{ $t("task.calendarView") }}</router-link
          >
        </li>
      </ul>
    </div>
    <div class="body">
      <Search
        @search="search"
        @changePage="changePage"
        @resetSearch="resetSearch"
      />
      <Tableshimmer :heading="tableHeading" v-if="isLoading" />
      <Table
        :list="taskList"
        :currentPage="currentPage"
        :perPage="perPage"
        :heading="tableHeading"
        :totalRow="totalRow"
        @changePage="changePage"
        @deleteItem="deleteItem"
        @completeTask="completeTask"
        v-else
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Table from "./table.vue";
import Search from "../../helper/search.vue";
import Tableshimmer from "../../helper/tableshimmer.vue";

export default {
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      taskList: [],
      tableHeading: [
        this.$t("sn"),
        this.$t("task.project"),
        this.$t("task.name"),
        this.$t("task.staff"),
        this.$t("task.dos"),
        this.$t("task.doe"),
      ],
      searchQuery: null,
    };
  },
  components: {
    Search,
    Table,
    Tableshimmer,
  },
  created() {
    this.getData(null, 2);
    this.$emit("close");
  },
  methods: {
    async getData(query, status) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
        status: status,
      };
      await axios
        .post("task/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.taskList = res.data.list;
            this.$store.state.taskList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.departmentList = [];
      this.currentPage = data.page;
      this.getData(this.searchQuery, 2);
    },

    resetSearch() {
      this.searchQuery = null;
      this.departmentList = [];
      this.currentPage = 1;
      this.getData(this.searchQuery, 2);
    },

    search(query) {
      this.currentPage = 1;
      this.searchQuery = query;
      this.getData(this.searchQuery, 2);
    },

    async deleteItem(data) {
      axios
        .post(
          "task/deletetask",
          { id: data.id },
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            if (res.data.flag) {
              this.$toastr.s("Successfully deleted.");
              this.taskList.splice(data.index, 1);
            } else {
              this.$toastr.e("Something went wrong. Try again later.");
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    completeTask(data) {
      if (confirm("Are you sure?")) {
        axios
          .post(
            "task/completetask",
            { id: data.id },
            {
              headers: {
                Authorization:
                  "Kasturiworkspace " + localStorage.getItem("accessToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.taskList[data.index].completion_status = 1;
              } else {
                this.$toastr.e("Something went wrong. Try again later.");
              }
            } else {
              this.$toastr.e("Login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
            this.closeModal();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
