<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("workingarea.head") }}
        <small>{{ $t("workingarea.updateNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :workingarea="workingarea" :type="2" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      workingarea: this.$store.state.workingAreaList[this.$route.params.id],
    };
  },
  components: {
    Form,
  },
};
</script>
