<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ heading }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td>
                  <b>{{ $t('revenuecollection.revenueSource') }}</b>
                </td>
                <td>{{ data.headings }}</td>
              </tr>
              <tr>
                <td>
                  <b>{{ $t('leftnav.sourceOfReceipt') }}</b>
                </td>
                <td>{{ data.income_source }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th colspan="2">{{$t('revenuecollection.method')}}</th>
              </tr>
              <tr>
                <th>{{$t('sn')}}</th>
                <th>{{$t('revenuecollection.method')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(m, e) in data.method" :key="'method' + e">
                <td>{{ ++e }}</td>
                <td>{{ m.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>
