<template>
  <div class="card kasturi_workspace_card">
    <div class="header calendar-heading" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("taskcalendar.task") }}
        <small>{{ $t("taskcalendar.note") }}</small>
      </h2>
      <ul class="calendar-setting">
        <li class="dropdown">
          <a
            class="btn btn-light calendar-dropdown-button"
            href="#"
            role="button"
            @click.prevent="toggleSetting"
            >{{ $t("taskcalendar.settings") }}</a
          >
          <ul class="dropdown-menu calendar-dropdown-menu">
            <li class="checkbox_container">
              <span>{{ $t("taskcalendar.dateSelectable") }}</span>
              <div class="switch">
                <label
                  ><input
                    @change.prevent="settingTrigger(0, $event)"
                    name="selectable"
                    type="checkbox"
                    :checked="setting.selectable ? true : false"
                    class="setting-checkbox" /><span class="lever"></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>{{ $t("taskcalendar.eventEditable") }}</span>
              <div class="switch">
                <label
                  ><input
                    @change.prevent="settingTrigger(1, $event)"
                    name="editable"
                    data-item="editable"
                    type="checkbox"
                    :checked="setting.editable ? true : false"
                    class="setting-checkbox" /><span class="lever"></span
                ></label>
              </div>
            </li>
            <!-- <li class="checkbox_container">
                  <span>Show Weekend</span>
                  <div class="switch">
                    <label
                      ><input
                        @change.prevent="settingTrigger(2, $event)"
                        name="weekends"
                        data-item="weekends"
                        type="checkbox"
                        :checked="setting.weekends ? true : false"
                        class="setting-checkbox" /><span class="lever"></span
                    ></label>
                  </div>
                </li> -->
            <li class="checkbox_container">
              <span>{{ $t("taskcalendar.mergeEvent") }}</span>
              <div class="switch">
                <label
                  ><input
                    @change.prevent="settingTrigger(3, $event)"
                    name="dayMaxEventRows"
                    data-item="dayMaxEventRows"
                    type="checkbox"
                    :checked="setting.merge ? true : false"
                    class="setting-checkbox" /><span class="lever"></span
                ></label>
              </div>
            </li>
            <li class="checkbox_container">
              <span>{{ $t("taskcalendar.saturday") }}</span>
              <div class="switch">
                <label
                  ><input
                    @change.prevent="settingTrigger(4, $event)"
                    name="saturdayFlag"
                    data-item="saturdayFlag"
                    type="checkbox"
                    :checked="setting.saturdayFlag ? true : false"
                    class="setting-checkbox" /><span class="lever"></span
                ></label>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="body">
      <div class="row">
        <div class="col-md-4">
          <div class="card calendar-option-button-container">
            <div class="card-body">
              <button
                class="btn btn-info btn-block"
                @click="
                  $router.push(
                    '/' + $i18n.locale + '/dashboard/project/task/tasklist'
                  )
                "
              >
                {{ $t("taskcalendar.tabularView") }}
              </button>
              <button class="btn btn-success btn-block" @click="filterTask(1)">
                {{ $t("taskcalendar.completed") }}
              </button>
              <button class="btn btn-warning btn-block" @click="filterTask(0)">
                {{ $t("taskcalendar.incompleted") }}
              </button>
              <button
                class="btn btn-secondary btn-block"
                @click="filterTask(2)"
              >
                {{ $t("taskcalendar.showall") }}
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t("taskcalendar.search") }}</h4>
              <Search @projectwisetask="projectwisetask" />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="calendar-title">{{ title }}</div>
          <div class="task-calendar">
            <div class="calendar-overlay" v-if="isLoading"></div>
            <div class="spinner-border text-muted" v-if="isLoading"></div>
            <FullCalendar :options="calendarOptions" ref="fullCalendar" />
          </div>
        </div>
      </div>
    </div>
    <Taskview
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @closeModal="closeModal"
      @deleteItem="deleteItem"
      @completeTask="completeTask"
      :heading="$t('task.info')"
    />
    <Projecttask
      @closeProjectTask="closeProjectTask"
      v-if="projectwiseFlag"
      @deleteItem="deleteItem"
      :project="individualProject"
    />
  </div>
</template>
<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import moment from "moment";
import Taskview from "../task/taskview.vue";
import Search from "../task/search.vue";
import Projecttask from "../task/projecttask.vue";
import adbs from "ad-bs-converter";

export default {
  components: {
    FullCalendar,
    Taskview,
    Search,
    Projecttask,
  },
  data() {
    return {
      title: null,
      modalFlag: false,
      modalData: {},
      isLoading: false,
      projectwiseFlag: false,
      individualProject: {
        projectId: null,
        status: null,
      },
      setting: {
        selectable: false,
        editable: false,
        weekends: false,
        merge: false,
        saturdayFlag: true,
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        locale: this.$i18n.locale == "en" ? "en" : "hi",
        customButtons: {
          prev: {
            text: this.$t("task.prev"),
            click: () => {
              // it overrides prev button
              this.$refs.fullCalendar.getApi().prev();
              this.getTask(2);
            },
          },
          next: {
            text: this.$t("task.next"),
            click: () => {
              // it overrides next button
              this.$refs.fullCalendar.getApi().next();
              this.getTask(2);
            },
          },
          today: {
            text: this.$t("task.today"),
          },
          dayGridMonth: {
            text: this.$t("task.month"),
          },
          timeGridWeek: {
            text: this.$t("task.week"),
          },
          timeGridDay: {
            text: this.$t("task.day"),
          },
        },
        headerToolbar: {
          left: "prev,next today",
          center: this.$i18n.locale == "np" ? "" : "title",
          right: "dayGridMonth",
        },
        initialView: "dayGridMonth",
        selectable: true,
        editable: true,
        weekends: true,
        dayMaxEventRows: true,
        views: {
          timeGrid: {
            dayMaxEventRows: 3, // it only shows 3 event in calendar
          },
        },
        dayCellContent: this.dateConvert,
        hiddenDays: [],
        events: [],
        selectAllow: this.selectAllow,
        select: this.selectDate,
        eventClick: this.eventClicked,
        eventResize: this.eventDateUpdate,
        eventDrop: this.eventDateUpdate,
      },
    };
  },
  created() {
    this.$emit("close");
  },
  mounted() {
    this.getTask(2);
  },
  methods: {
    toggleSetting(e) {
      e.target.classList.toggle("show");
      e.target.parentElement.childNodes[1].classList.toggle("show");
    },

    filterTask(status) {
      this.calendarOptions.events = [];
      this.$store.state.taskList = [];
      this.getTask(status);
    },

    projectwisetask(info) {
      this.individualProject.projectId = info.projectId;
      this.individualProject.status = info.status;
      this.projectwiseFlag = true;
    },

    async getTask(status) {
      this.isLoading = true;
      var startFrom = moment(
        this.$refs.fullCalendar.getApi().currentData.dateProfile.activeRange
          .start
      ).format("YYYY/MM/DD");
      var endAt = moment(
        this.$refs.fullCalendar.getApi().currentData.dateProfile.activeRange.end
      ).format("YYYY/MM/DD");
      if (this.$i18n.locale == "np") {
        var convertedStart = adbs.ad2bs(startFrom);
        var convertedEnd = adbs.ad2bs(endAt);
        this.title =
          convertedStart.ne.day +
          " " +
          convertedStart.ne.strMonth +
          "  देखि  " +
          convertedEnd.ne.day +
          " " +
          convertedEnd.ne.strMonth +
          " सम्म";
      }
      await axios
        .post(
          "task/gettask",
          { startFrom: startFrom, endAt: endAt, status: status },
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            res.data.task.forEach((event) => {
              if (
                this.calendarOptions.events.filter((item) => {
                  return item.id == event.id ? true : false;
                }).length == 0
              ) {
                this.calendarOptions.events.push(event);
                this.$store.state.taskList.push(event);
              }
            });
            for (var key in res.data.setting) {
              switch (key) {
                case "selectable":
                  this.updateSetting(0, res.data.setting.selectable, 0);
                  break;
                case "editable":
                  this.updateSetting(1, res.data.setting.editable, 0);
                  break;
                case "weekends":
                  this.updateSetting(2, res.data.setting.weekends, 0);
                  break;
                case "merge":
                  this.updateSetting(3, res.data.setting.merge, 0);
                  break;
                case "saturdayFlag":
                  this.updateSetting(4, res.data.setting.saturdayFlag, 0);
                  break;
                default:
                  this.updateSetting(5, true, 0);
                  break;
              }
            }
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateSetting(index, flag, type) {
      switch (index) {
        case 0:
          this.$refs.fullCalendar
            .getApi()
            .setOption("selectable", flag == 1 ? true : false);
          this.setting.selectable = flag;
          break;
        case 1:
          this.$refs.fullCalendar
            .getApi()
            .setOption("editable", flag == 1 ? true : false);
          this.$refs.fullCalendar
            .getApi()
            .currentData.calendarOptions.events.forEach((event) => {
              if (event.completion_status == "1") {
                event.editable = false;
                event.durationEditable = false;
              } else {
                event.editable = flag;
                event.durationEditable = flag;
              }
            });
          this.setting.editable = flag;
          break;
        case 2:
          this.$refs.fullCalendar
            .getApi()
            .setOption("weekends", flag == 1 ? true : false);
          this.setting.weekends = flag;
          break;
        case 3:
          this.$refs.fullCalendar
            .getApi()
            .setOption("dayMaxEventRows", flag == 1 ? true : false);
          this.setting.merge = flag;
          this.$refs.fullCalendar.getApi().currentData.options.views.timeGrid.dayMaxEventRows =
            flag ? 3 : 1;
          break;
        case 4:
          if (flag)
            this.$refs.fullCalendar
              .getApi()
              .currentData.calendarOptions.hiddenDays.splice(0, 1);
          else
            this.$refs.fullCalendar
              .getApi()
              .currentData.calendarOptions.hiddenDays.push(6);

          this.setting.saturdayFlag = flag;
          break;
        default:
          console.log("Unwanted triggered");
          break;
      }
      if (type == 1) {
        this.updateSettingInServer();
      }
    },

    updateSettingInServer() {
      axios
        .post("task/updatesetting", this.setting, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log(res.data.flag);
        })
        .catch((err) => {
          console.log(err);
          this.$toastr.e("Something went wrong. Try again later.");
        });
    },

    settingTrigger(index, e) {
      this.updateSetting(index, e.target.checked, 1);
    },

    dateConvert: function (info, create) {
      if (this.$i18n.locale == "np") {
        var recentDate = moment(info.date).format("YYYY/MM/DD");
        var convertedDate = adbs.ad2bs(recentDate);
        const element = create("span", {}, convertedDate.ne.day);
        return element;
      } else {
        const element = create("span", {}, info.dayNumberText);
        return element;
      }
    },

    selectAllow: function (info) {
      var startDate = info.startStr;
      var limitDate = new Date().toISOString().slice(0, 10);
      if (startDate < limitDate) {
        return false;
      }
      return true;
    },

    selectDate: function (arg) {
      // var start = arg.start.toString().replace("00", "09");
      // var end = arg.end.toString().replace("00", "17");
      this.$router.push({
        name: "assigntask",
        params: {
          start: arg.start,
          end: arg.end,
        },
      });
    },

    eventClicked: function (arg) {
      this.modalData.projectName = arg.event.extendedProps.projectName;
      this.modalData.staff = arg.event.extendedProps.staff;
      this.modalData.title = arg.event.title;
      this.modalData.start = arg.event.start;
      this.modalData.end = arg.event.end;
      this.modalData.date_of_issue = arg.event.extendedProps.date_of_issue;
      this.modalData.phone = arg.event.extendedProps.phone;
      this.modalData.completionStatus =
        arg.event.extendedProps.completion_status;
      this.modalData.description = arg.event.extendedProps.description;
      this.modalData.id = arg.event.id;
      this.modalFlag = true;
    },

    eventDateUpdate: function (arg) {
      axios
        .post(
          "task/updatedate",
          {
            id: arg.event.id,
            start: moment(arg.event.start).format("yyyy-MM-DDThh:mm"),
            end: moment(arg.event.end).format("yyyy-MM-DDThh:mm"),
          },
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            if (res.data.flag) {
              this.$toastr.s("Successfully updated.");
            } else {
              this.$toastr.e("Something went wrong. Try again later.");
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    completeTask(data) {
      if (confirm("Are you sure?")) {
        axios
          .post(
            "task/completetask",
            { id: data.id },
            {
              headers: {
                Authorization:
                  "Kasturiworkspace " + localStorage.getItem("accessToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.calendarOptions.events.filter((item, index) => {
                  if (item.id.includes(data.id)) {
                    this.calendarOptions.events[index].backgroundColor =
                      "green";
                    this.calendarOptions.events[index].borderColor = "green";
                    this.calendarOptions.events[index].completionStatus = 1;
                  }
                  return false;
                });
              } else {
                this.$toastr.e("Something went wrong. Try again later.");
              }
            } else {
              this.$toastr.e("Login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
            this.closeModal();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deleteItem(data) {
      if (confirm("Are you sure?")) {
        axios
          .post(
            "task/deletetask",
            { id: data.id },
            {
              headers: {
                Authorization:
                  "Kasturiworkspace " + localStorage.getItem("accessToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.calendarOptions.events.filter((item, index) => {
                  if (item.id.includes(data.id)) {
                    this.calendarOptions.events.splice(index, 1);
                  }
                  return false;
                });
              } else {
                this.$toastr.e("Something went wrong. Try again later.");
              }
            } else {
              this.$toastr.e("Login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
            this.closeModal();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    closeModal() {
      this.modalFlag = false;
      this.projectwiseFlag = false;
    },

    closeProjectTask() {
      this.projectwiseFlag = false;
    },
  },
};
</script>
<style>
.calendar-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.calendar-heading {
  display: flex;
  justify-content: space-between;
}

.calendar-heading .calendar-setting {
  list-style: none;
}

.calendar-setting > li > ul > li.checkbox_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.calendar-dropdown-menu {
  inset: 0px 0px auto auto;
  margin-top: 45px !important;
  min-width: 15rem !important;
}

.calendar-dropdown-button:focus {
  box-shadow: none;
}

.task-calendar {
  position: relative;
}

.calendar-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: grey;
  z-index: 2;
  opacity: 0.6;
}

.task-calendar > .spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  color: black !important;
  z-index: 5;
}

.calendar-option-button-container {
  margin-bottom: 5px;
}

.calendar-option-button-container > div > button {
  width: 100%;
  margin-bottom: 5px;
}
</style>
