<template>
  <div>
    <div class="export-container">
      <button class="btn btn-info btn-sm" @click="exportPdf">
        {{ $t("printDownload") }}
      </button>
    </div>
    <div id="toprint">
      <div class="voucher-content">
        <div class="top-info">
          <div class="organization-logo">
            <img
              :src="'data:image/jpeg;base64, '+ organization.base64Image"
              :alt="organization.organization_name"
            />
          </div>
          <div class="print_organization_name">
            <center>{{ organization.organization_name }}</center>
          </div>
          <div class="print_organization_address">
            <center>{{ organization.organization_address }}</center>
          </div>
          <div class="print_voucher_type">
            <center>
              {{ voucher.type == 1 ? "Receipt Voucher" : "Payment Voucher" }}
            </center>
          </div>
        </div>
        <div class="print_general_info">
          <div>
            {{ "Fiscal Year :" + voucher.fiscal_year }}
          </div>
          <div class="print_voucher_info">
            <div class="print_date">Date : {{ voucher.date }}</div>
            <div class="print_voucher_number">
              {{ "Voucher No. " + voucher.reference_no }}
            </div>
          </div>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>SN</th>
                <th>Description</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dr, index) in voucher.dr" :key="'dr' + index">
                <td>{{ ++index }}</td>
                <td>{{ dr.headings }}</td>
                <td>{{ $t("rs") + dr.amount }}</td>
                <td>{{ $t("rs") + "0" }}</td>
              </tr>
              <tr v-for="(cr, jindex) in voucher.cr" :key="'cr' + jindex">
                <td>{{ voucher.dr.length + 1 }}</td>
                <td>{{ cr.heading }}</td>
                <td>{{ $t("rs") + "0" }}</td>
                <td>{{ $t("rs") + cr.amount }}</td>
              </tr>
              <tr>
                <td colspan="2">TOTAL</td>
                <td>{{ $t("rs") + calculateDr() }}</td>
                <td>{{ $t("rs") + calculateCr() }}</td>
              </tr>
              <tr>
                <td colspan="4">
                  <u>Narration</u> <br />{{ voucher.narration }}
                </td>
              </tr>
              <tr>
                <td>Prepared By : <br />{{ voucher.detail.preparedBy }}</td>
                <td>Submitted By : <br />{{ voucher.detail.submittedBY }}</td>
                <td colspan="2">
                  Approved By : <br />{{ voucher.detail.approvedBy }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      counter: 0,
      organization: JSON.parse(localStorage.getItem("loggedInInfo")),
      voucher: null,
    };
  },
  created() {
    this.voucher = this.$store.state.voucherList.filter((item) => {
      return item.id.includes(this.$route.params.id);
    })[0];
  },
  methods: {
    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          allowTaint: true,
          useCORS: true,
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpg", quality: 0.98 },
          html2canvas: {
            // scale: 2,
            dpi: 192,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            unit: "pt",
            format: "letter",
            orientation: "portrait",
            // compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    calculateDr() {
      var drAmount = 0.0;
      this.voucher.dr.forEach((item) => {
        drAmount = parseFloat(drAmount) + parseFloat(item.amount);
      });
      return drAmount;
    },

    calculateCr() {
      var crAmount = 0.0;
      this.voucher.cr.forEach((item) => {
        crAmount = parseFloat(crAmount) + parseFloat(item.amount);
      });
      return crAmount;
    },
  },
};
</script>
<style>
.print_organization_name {
  font-size: 20px;
  font-weight: bold;
}

.print_organization_address {
  font-size: 18px;
}

.print_voucher_type {
  font-size: 13px;
  color: darkgrey;
}

.print_general_info {
  font-size: 12px;
}

.print_voucher_info {
  display: flex;
  justify-content: space-between;
}

.top-info {
  position: relative;
}

.organization-logo {
  position: absolute;
  top: -5px;
  left: 15px;
}

.organization-logo img {
  height: 80px;
  width: 80px;
}
</style>
