// 1 means forget password 2 means need new verification code
<template>
  <div class="login-body">
    <div class="container">
      <div class="login_container">
        <div class="find_account_container">
          <div class="find_account_form">
            <h2 class="text-center">
              {{ type == 1 ? $t('findUser').forget : $t('findUser').find }}
            </h2>
            <p style="color: #de6262">
              {{
                type == 1
                  ? $t('findUser').forgetNote
                  : $t('findUser').findNote
              }}
            </p>
            <div class="row">
              <div class="col-lg-12">
                <form @submit.prevent="submitForm">
                  <div class="form-group">
                    <label for="passwordresetrequestform-email">{{$t('findUser').email}}</label>
                    <input
                      v-model="v$.email.$model"
                      type="text"
                      autofocus="true"
                      class="form-control"
                      placeholder="abc@****.com"
                      :class="{
                        'is-invalid': v$.email.$error,
                      }"
                    />

                    <div class="invalid-feedback">{{$t('findUser').emailError}}</div>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-danger btn-block submitButton"
                      name="login-button"
                      v-if="!isSubmitting"
                    >
                      {{$t('findUser').search}}
                    </button>
                    <button
                      class="btn btn-danger btn-block submitButton loading"
                      disabled
                      v-else
                    >
                      <div class="spinner-border text-warning"></div>
                      <div>{{$t('loading')}}</div>
                    </button>
                  </div>
                </form>
                <br />
                <div class="text-center">
                  <router-link :to="`/${$i18n.locale}/login`" class="login_signup_link"
                    >{{$t('findUser').login}}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      type: this.$route.params.type,
      email: null,
    };
  },
  validations() {
    return {
      email: { required },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("type", this.type);
        formData.set("email", this.email);
        await axios
          .post("organization/finduser", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.existFlag) {
              if (this.type == 1) {
                res.data.contradictionFlag
                  ? this.$toastr.s(
                      "Account reset link has been sent to your email."
                    )
                  : this.$toastr.w("Account not verified yet.");
                if (res.data.contradictionFlag) this.$router.push('/'+this.$i18n.locale+"/login/accountverification/"+this.email);
              } else {
                res.data.contradictionFlag
                  ? this.$toastr.s("Account verification pin has been sent.")
                  : this.$toastr.w("Account already verified.");
                if (res.data.contradictionFlag)
                  this.$router.push(
                    "/"+this.$i18n.locale+"/signup/accountverification/" + this.email
                  );
              }
              this.isSubmitting = false;
            } else {
              this.$toastr.e(
                "Alert!",
                "No organization with this email address."
              );
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>

<style>
.login_container .find_account_container {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 15px 20px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  display: flex;
}

.login_container .find_account_container .find_account_form {
  padding: 2%;
  flex: 1;
}

.login_container .find_account_container .find_account_form > h2 {
  margin-bottom: 15px;
  font-weight: 800;
  font-size: 30px;
  color: #de6262;
}

.login_container .content-container .find_account_form > h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #feb58a;
  display: block;
  margin-top: 6px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .login_container .find_account_container {
    width: 100% !important;
  }
}
</style>
