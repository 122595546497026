<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("report.budget") }}
        <small>{{ $t("report.budgetNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="export-container">
        <button class="btn btn-info btn-sm" @click="exportPdf">{{$t('printDownload')}}</button>
      </div>
      <div class="department-form">
        <div v-if="isLoading" class="text-center">
          <div class="spinner-border text-dark"></div>
        </div>
        <div id="toprint" v-else>
          <div>
            <div style="text-align: center">
              <div style="font-size: 20px; font-weight: bold">
                {{ loggedInUser.organization_name }}
              </div>
              <div style="font-size: 16px; font-weight: bold">
                {{ loggedInUser.organization_address }}
              </div>
              <div>{{ $t("report.fiscalYear") + fiscal.fiscal_year }}</div>
              <div>{{ $t("leftnav.budgetSourceReport") }}</div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered reportTable" style="font-size:12px">
                <thead>
                  <tr>
                    <th>{{ $t("sn") }}</th>
                    <th>{{ $t("report.incomeSourceHeading") }}</th>
                    <th>{{ $t("report.incomeSourceSubHeading") }}</th>
                    <th>{{$t("report.expected")}}</th>
                    <th>{{$t("report.released")}}</th>
                    <th>{{ $t("report.appropriationAmount") }}</th>
                  </tr>
                </thead>
                <tbody v-if="budgetList.length > 0">
                  <tr v-for="(budget, index) in budgetList" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ budget.main_head }}</td>
                    <td>{{ budget.headings }}</td>
                    <td>{{ $t("rs") + budget.expected }}</td>
                    <td>{{ $t("rs") + budget.released }}</td>
                    <td>{{ $t("rs") + budget.appropriation }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="no-record">
                      {{ $t("norecord") }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3"><strong>TOTAL</strong></td>
                    <td><strong>{{$t('rs')+findBudget(budgetList)}}</strong></td>
                    <td><strong>{{$t('rs')+findExpenses(budgetList)}}</strong></td>
                    <td><strong>{{$t('rs')+findAppropriation(budgetList)}}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      isLoading: true,
      budgetList: [],
      fiscal: {},
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    findBudget(data){
      var sum = 0;
      data.forEach((item)=>{
        sum+=parseFloat(item.expected);
      });
      return sum;
    },

    findExpenses(data){
      var sum = 0;
      data.forEach((item)=>{
        sum+=parseFloat(item.released);
      });
      return sum;
    },

    findAppropriation(data){
      var sum = 0;
      data.forEach((item)=>{
        sum+=parseFloat(item.appropriation);
      });
      return sum;
    }, 

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    getReport() {
      axios
        .post(
          "report/budget",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.budgetList = res.data.data;
            this.fiscal = res.data.fiscal;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
