<template>
  <div class="pdf-container">
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border"></div>
    </div>
    <div v-else>
      <embed :src="pdfUrl" width="100%" style="min-height: 100vh" />
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      pdfUrl: null,
      isLoading: true,
      id: this.$route.params.id,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .post(
          "project/getdprurl",
          { id: this.id },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.pdfUrl = this.$hostname + res.data.data[0].attachment;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.pdf-container {
  overflow: hidden;
}
</style>
