<template>
  <div class="link-home-page">
    <div class="row">
      <div class="col-md-6">
        <apexcharts
          v-if="!isLoading"
          width="100%"
          type="pie"
          :options="dataOptions"
          :series="dataSeries"
        ></apexcharts>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      dataSeries: [],
    };
  },
  created() {
    this.$emit("close");
    this.getData();
  },
  computed: {
    dataOptions: function () {
      return {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
          events: {
            legendClick: (chartContext, seriesIndex, config) => {
              this.jumpRoute(seriesIndex);
            },
            // dataPointSelection: (event, chartContext, config) => {
            //   this.jumpRoute(config.dataPointIndex);
            // },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            // if you want to display percentage;
            // return val + "%";
            return opts.w.config.series[opts.seriesIndex];
          },
        },
        labels: [
          this.$t("dashboard.department"),
          this.$t("dashboard.shift"),
          this.$t("dashboard.designation"),
          this.$t("dashboard.staff"),
        ],
      };
    },
  },
  methods: {
    jumpRoute(index) {
      switch (index) {
        case 0:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/setup/department/list"
          );
          break;
        case 1:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/setup/shift/list"
          );
          break;
        case 2:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/setup/designation/list"
          );
          break;
        default:
          this.$router.push(
            "/" + this.$i18n.locale + "/dashboard/setup/staff/list"
          );
          break;
      }
    },

    getData() {
      axios
        .post(
          "home/setup",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.dataSeries = res.data.data;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
