<template>
  <div class="table-container">
    <div class="table-responsive">
      <table class="table table-stripped table-bordered" v-if="list.length < 1">
        <thead>
          <tr>
            <th>{{ $t("sn") }}</th>
            <th>{{ $t("receipt.date") }}</th>
            <th>{{ $t("receipt.refNo") }}</th>
            <th>{{ $t("receipt.particular") }}</th>
            <th>{{ $t("receipt.dr") }}</th>
            <th>{{ $t("receipt.cr") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7" class="no-record">{{ $t("norecord") }}</td>
          </tr>
        </tbody>
      </table>
      <div class="voucher-table" v-else>
        <div class="table-header">
          <div class="table-head">{{ $t("sn") }}</div>
          <div class="table-head">{{ $t("receipt.date") }}</div>
          <div class="table-head">{{ $t("receipt.refNo") }}</div>
          <div class="table-colspan">
            <div class="table-head">{{ $t("receipt.particular") }}</div>
            <div class="table-head">{{ $t("receipt.dr") }}</div>
            <div class="table-head">{{ $t("receipt.cr") }}</div>
          </div>
          <div class="table-head">{{ $t("actions") }}</div>
        </div>
        <div class="table-body" v-for="(item, index) in list" :key="index">
          <div class="table-data">
            {{ (currentPage - 1) * perPage + (index + 1) }}
          </div>
          <div class="table-data">{{ convertedDate(item.date) }}</div>
          <div class="table-data">{{ item.reference_no }}</div>
          <div class="table-colspans">
            <div class="table-rows" v-for="(dr, i) in item.dr" :key="'Dr' + i">
              <div>{{ dr.headings }}</div>
              <div>{{ dr.amount }}</div>
              <div>-</div>
            </div>
            <div class="table-rows" v-for="(cr, k) in item.cr" :key="'Cr' + k">
              <div>{{ cr.heading }}</div>
              <div>-</div>
              <div>{{ cr.amount }}</div>
            </div>
          </div>
          <div class="table-data voucher-table-action-button-container">
            <router-link
              class="btn btn-primary btn-sm actionButton"
              v-if="item.approved_status == 1"
              :to="
                '/' +
                $i18n.locale +
                '/dashboard/appropriation/voucherapproval/list/' +
                item.id +
                '/print'
              "
              >PRINT</router-link
            >
            <router-link
              :to="
                '/' +
                $i18n.locale +
                '/dashboard/bail/voucher/update/' +
                item.id
              "
              class="btn btn-warning btn-sm actionButton"
              v-if="item.type == 3 && item.approved_status == 0"
              >{{ $t("update") }}</router-link
            >
            <router-link
              :to="
                '/' +
                $i18n.locale +
                '/dashboard/appropriation/voucher/receiptupdate/' +
                item.id
              "
              class="btn btn-warning btn-sm actionButton"
              v-if="item.type == 1 && item.approved_status == 0"
              >{{ $t("update") }}</router-link
            >
            <router-link
              :to="
                '/' +
                $i18n.locale +
                '/dashboard/appropriation/voucher/paymentupdate/' +
                item.id
              "
              class="btn btn-warning btn-sm actionButton"
              v-if="item.type == 2 && item.approved_status == 0"
              >{{ $t("update") }}</router-link
            >
            <button
              class="btn btn-info btn-sm actionButton"
              v-if="item.approved_status == 1"
              @click="viewDetail(index)"
            >
              {{ $t("view") }}
            </button>
            <button
              class="btn btn-success btn-sm actionButton"
              @click="approveVoucher(index)"
              v-if="item.approved_status == 0"
            >
              {{ $t("receipt.approve") }}
            </button>
          </div>
        </div>
      </div>
      <br />
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Voucherdetail
      v-if="detailFlag"
      :modalFlag="modalFlag"
      :data="detail"
      @closeDetail="closeDetail"
    />

    <Approvevoucher
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @confirmApprove="confirmApprove"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import Approvevoucher from "./approve.vue";
import Voucherdetail from "./voucherdetail.vue";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: { Approvevoucher, Voucherdetail },
  data() {
    return {
      detail: null,
      modalFlag: false,
      detailFlag: false,
      modalData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
  },
  methods: {
    convertedDate(date) {
      if (this.$i18n.locale == "np") {
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      } else return date;
    },

    closeDetail() {
      this.detailFlag = false;
    },

    closeModal() {
      this.modalFlag = false;
    },

    approveVoucher(index) {
      if (confirm("Are you sure?")) {
        this.modalData = this.$store.state.voucherList[index];
        this.modalFlag = true;
      }
    },

    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    confirmApprove(data) {
      this.$emit("confirmApprove", data);
    },

    viewDetail(index) {
      this.detail = this.$store.state.voucherList[index].detail;
      this.detailFlag = true;
    },
  },
};
</script>
<style>
.table-header,
.table-body {
  display: flex;
  justify-content: space-between;
}

.table-header > div.table-head {
  padding: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-weight: bold;
}

.table-body > div.table-data {
  padding: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  /* margin-right: 2px; */
}

.table-body > div:last-child {
  border-right: 1px solid black;
}

.table-header > div.table-colspan {
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.table-header > div:last-child {
  border-right: 1px solid black;
}

.table-header > div.table-head,
.table-body > div.table-data {
  flex: 1;
}

.table-header > div.table-colspan {
  flex: 5;
  display: flex;
  justify-content: space-between;
}

.table-body > div.table-colspans {
  flex: 5;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.table-header > div:last-child,
.table-body > div:last-child {
  flex: 2;
}

.table-colspans > .table-rows {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.table-colspans > .table-rows:last-child {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
}

.table-colspans > .table-rows > div {
  padding: 5px;
}

.table-colspans > .table-rows > div:nth-last-child(2) {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.table-colspans > .table-rows > div:first-child {
  flex: 3;
}

.table-colspans > .table-rows > div:last-child,
.table-colspans > .table-rows > div:nth-last-child(2) {
  flex: 1;
}

.table-header > div.table-colspan > div:first-child {
  flex: 3;
}

.table-header > div.table-colspan > div:last-child,
.table-header > div.table-colspan > div:nth-last-child(2) {
  flex: 1;
}

.table-header > div.table-colspan > div {
  padding: 5px;
}

.table-header > div.table-colspan > div:nth-last-child(2) {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.table-body > .table-data {
  padding: 5px;
}

.voucher-table-action-button-container > a:first-child {
  margin-right: 5px;
}
</style>
