<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("receipt.list") }}
        <small>{{ $t("receipt.listNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <Tableshimmer :heading="tableHeading" v-if="isLoading" />
      <Table
        :list="voucherList"
        :currentPage="currentPage"
        :perPage="perPage"
        :heading="tableHeading"
        :totalRow="totalRow"
        @changePage="changePage"
        @confirmApprove="confirmApprove"
        v-else
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Table from "./table.vue";
import Tableshimmer from "../../helper/tableshimmer.vue";

export default {
  components: { Tableshimmer, Table },
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      voucherList: [],
      tableHeading: [
        this.$t("sn"),
        this.$t("receipt.date"),
        this.$t("receipt.refNo"),
        this.$t("receipt.particular"),
        "Dr",
        "Cr",
      ],
      searchQuery: null,
    };
  },
  created() {
    this.$emit('close');
    this.getData(null);
  },
  methods: {
    async getData(query) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
        type:1    //1 is for receipt and payment and 2 is for bail
      };
      await axios
        .post("voucher/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.voucherList = res.data.list;
            this.$store.state.voucherList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.voucherList = [];
      this.currentPage = data.page;
      this.getData(this.searchQuery);
    },

    confirmApprove(data) {
      this.voucherList.forEach((item, index) => {
        if (item.id == data.id) {
          this.voucherList.splice(index, 1);
          return false;
        }
        return true;
      });
    },
  },
};
</script>
