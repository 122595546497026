<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("task.task") }}
        <small>{{ $t("task.createNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="staff-form">
        <Form :task="task" :type="1" :autoSuppliedFlag="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../task/form.vue";
import moment from "moment";

export default {
  data() {
    return {
      isSubmitting: false,
      task: {
        staff_id: null,
        task_name: null,
        description: null,
        date_of_start:
          this.$i18n.locale == "np"
            ? moment(String(this.$route.params.start)).format("yyyy-MM-DD")
            : moment(String(this.$route.params.start)).format(
                "yyyy-MM-DDThh:mm"
              ),
        date_of_end:
          this.$i18n.locale == "np"
            ? moment(String(this.$route.params.end)).format("yyyy-MM-DD")
            : moment(String(this.$route.params.end)).format("yyyy-MM-DDThh:mm"),
        task_id: null,
      },
    };
  },
  components: {
    Form,
  },
};
</script>
