<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("expenses.expenses") }}
        <small>{{ $t("expenses.addNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="source-heading-form">
        <Form :expenses="expenses" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  created() {
    this.$emit('close');
  },
  data() {
    return {
      expenses: {
        headings: null,
        description: null,
        type:null,
        meta:[
          {
            headings:null,
          }
        ]
      },
    };
  },
};
</script>
