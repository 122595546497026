<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t("chequecancel.bank") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.cheque.bankId.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.cheque.bankId.$model"
          :class="{
            'is-invalid': v$.cheque.bankId.$error,
          }"
        >
          <option
            v-for="(bank, index) in bankList"
            :key="index"
            :value="bank.id"
          >
            {{ bank.bank_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("chequecancel.bankError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("chequecancel.type") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.cheque.type.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.cheque.type.$model"
          :class="{
            'is-invalid': v$.cheque.type.$error,
          }"
        >
          <option value="1">{{ $t("chequecancel.used") }}</option>
          <option value="2">{{ $t("chequecancel.unused") }}</option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("chequecancel.typeError") }}</div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ $t("chequecancel.search") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{$t('loading')}}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      cheque: {
        bankId: { required },
        type: { required },
      },
    };
  },
  data() {
    return {
      bankList: [],
      chequeList: [],
      isSubmitting: false,
    };
  },
  props: {
    cheque: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    bankType:{
      type:Number,
      required:true,
    }
  },
  created() {
    this.getBank();
  },
  methods: {
    getBank() {
      axios
        .post(
          "helper/getallbank",
          {type:this.bankType},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (!res.data.loginFlag) {
            this.$toastr.e("Alert", "Your login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          } else {
            this.bankList = res.data.bank;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSubmitting = false;
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.cheque) {
          formData.set(key, this.cheque[key]);
        }
        await axios
          .post("bank/findcheque", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$emit('displayCheque',res.data.cheque);
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
              }
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
