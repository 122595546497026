var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.project")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.task.task_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.task_id.$model),expression:"v$.task.task_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.task.task_id.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.task.task_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.projectList),function(project,index){return _c('option',{key:index,domProps:{"value":project.id}},[_vm._v(" "+_vm._s(project.task_title)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.projectError")))])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.staff")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.task.staff_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.staff_id.$model),expression:"v$.task.staff_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.task.staff_id.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.task.staff_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.staffList),function(staff,index){return _c('option',{key:index,domProps:{"value":staff.id}},[_vm._v(" "+_vm._s(staff.full_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.staffError")))])])])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.name")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.task_name.$model),expression:"v$.task.task_name.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.task.task_name.$error,
      },attrs:{"type":"text"},domProps:{"value":(_vm.v$.task.task_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.task.task_name, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.nameError")))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[(_vm.$i18n.locale == 'np')?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.dos")))]),_c('VNepaliDatePicker',{class:{
            'is-invalid': _vm.v$.task.date_of_start.$error,
          },attrs:{"classValue":"form-control","calenderType":"Nepali","placeholder":"YYYY/MM/DD","format":"YYYY/MM/DD","value":_vm.dos,"yearSelect":true,"monthSelect":true},on:{"change":function($event){return _vm.supplyValue(1, 1, $event)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.dosError")))])],1):_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.dos")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.date_of_start.$model),expression:"v$.task.date_of_start.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.task.date_of_start.$error,
          },attrs:{"readonly":_vm.autoSuppliedFlag == 1 ? true : false,"type":"datetime-local"},domProps:{"value":(_vm.v$.task.date_of_start.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.task.date_of_start, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.dosError")))])])]),_c('div',{staticClass:"col-md-6"},[(_vm.$i18n.locale == 'np')?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.doe")))]),_c('VNepaliDatePicker',{class:{
            'is-invalid': _vm.v$.task.date_of_end.$error,
          },attrs:{"classValue":"form-control","calenderType":"Nepali","placeholder":"YYYY/MM/DD","format":"YYYY/MM/DD","value":_vm.doe,"yearSelect":true,"monthSelect":true},on:{"change":function($event){return _vm.supplyValue(2, 1, $event)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.doeError")))])],1):_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.doe")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.date_of_end.$model),expression:"v$.task.date_of_end.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.task.date_of_end.$error,
          },attrs:{"readonly":_vm.autoSuppliedFlag == 1 ? true : false,"type":"datetime-local"},domProps:{"value":(_vm.v$.task.date_of_end.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.task.date_of_end, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.doeError")))])])])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("task.description")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.task.description.$model),expression:"v$.task.description.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.task.description.$error,
      },attrs:{"type":"text","rows":"5"},domProps:{"value":(_vm.v$.task.description.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.task.description, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("task.descriptionError")))])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("task.assign") : _vm.$t("task.update"))+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }