<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("branchReport.project") }}
        <small>{{ $t("branchReport.project") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="export-container">
        <button class="btn btn-info btn-sm" @click="exportPdf">{{$t('printDownload')}}</button>
      </div>
      <div class="department-form">
        <div v-if="isLoading" class="text-center">
          <div class="spinner-border text-dark"></div>
        </div>
        <div id="toprint" v-else>
          <div>
            <div style="text-align: center">
              <div style="font-size: 20px; font-weight: bold">
                {{ loggedInUser.organization_name }}
              </div>
              <div style="font-size: 16px; font-weight: bold">
                {{ loggedInUser.organization_address }}
              </div>
              <div>{{ $t("branchReport.project") }}</div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered reportTable"
                style="font-size: 12px"
                v-for="(branch, index) in appropriationList"
                :key="index"
              >
                <thead>
                  <tr>
                    <th
                      :colspan="
                        6 +
                        (branch.hasOwnProperty('source')
                          ? branch.source.length
                          : 1)
                      "
                    >
                      {{ branch.organization_name }}
                    </th>
                  </tr>
                  <tr>
                    <th rowspan="2">{{ $t("sn") }}</th>
                    <th rowspan="2">{{ $t("report.project") }}</th>
                    <th rowspan="2">{{ $t("report.dos") }}</th>
                    <th rowspan="2">{{ $t("report.doc") }}</th>
                    <th rowspan="2">{{ $t("report.appropriationAmount") }}</th>
                    <th
                      :colspan="
                        branch.hasOwnProperty('source')
                          ? branch.source.length
                          : 1
                      "
                    >
                      {{ $t("report.source") }}
                    </th>
                    <th rowspan="2">{{ $t("report.expensesAmount") }}</th>
                    <th rowspan="2">{{ $t("report.stock") }}</th>
                  </tr>
                  <tr>
                    <th v-for="(source, jindex) in branch.source" :key="jindex">
                      {{ source.main_head }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="branch.hasOwnProperty('list')">
                  <tr v-for="(app, index) in branch.list" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ app.task_title }}</td>
                    <td>{{ convertedDate(app.date_of_issue) }}</td>
                    <td>{{ convertedDate(app.date_of_completion) }}</td>
                    <td>{{ $t("rs") + app.appropriation }}</td>
                    <td v-for="(src, i) in app.source" :key="i">
                      {{ $t("rs") + src }}
                    </td>
                    <td>{{ $t("rs") + app.expenses }}</td>
                    <td>
                      {{
                        $t("rs") +
                        (parseFloat(app.appropriation) -
                          parseFloat(app.expenses))
                      }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="8" class="no-record">
                      {{ $t("norecord") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      appropriationList: [],
      fiscal: {},
      perPage: 20,
      currentPage: 1,
      totalRow: null,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "landscape",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    convertedDate(date) {
      if (this.$i18n.locale == "np") {
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      } else return date;
    },

    getReport() {
      axios
        .post(
          "branchreport/appropriation",
          {
            perPage: this.perPage,
            currentPage: this.currentPage,
          },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.appropriationList = res.data.branch;
            console.log(this.appropriationList);
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
