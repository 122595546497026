<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ heading }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="task-button-container" v-if="data.completionStatus==0">
            <button class="btn btn-success" @click="completeTask">{{$t('task.complete')}}</button>
            <router-link
              class="btn btn-warning"
              :to="'/'+$i18n.locale+'/dashboard/project/task/edittask/' + data.id"
              >{{$t('update')}}</router-link
            >
            <button class="btn btn-danger" @click="deleteItem">{{$t('delete')}}</button>
          </div>

          <table class="table table-bordered table-striped table-sm">
            <tbody>
              <tr>
                <td><b>{{$t('task.project')}}</b></td>
                <td>{{ data.projectName }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.name')}}</b></td>
                <td>{{ data.title }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.staff')}}</b></td>
                <td>{{ data.staff+ ' (Ph. '+data.phone+')' }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.doi')}}</b></td>
                <td>{{ convertedDate(data.date_of_issue) }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.dos')}}</b></td>
                <td>{{ convertedDate(data.start) }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.doe')}}</b></td>
                <td>{{ convertedDate(data.end) }}</td>
              </tr>
              <tr>
                <td><b>{{$t('task.status')}}</b></td>
                <td>
                  {{ data.completionStatus == "0" ? $t('task.pending') : $t('task.complete') }}
                </td>
              </tr>
              <tr>
                <td><b>{{$t('task.description')}}</b></td>
                <td>{{ data.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    close() {
      this.$emit("closeModal");
    },

    deleteItem() {
      this.$emit("deleteItem",this.data);
    },

    completeTask(){
      this.$emit("completeTask",this.data);
    }
  },
};
</script>
<style>
.task-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.task-button-container > a:first-child {
  margin-right: 5px;
}
</style>
