<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("report.budget") }}
        <small>{{ $t("report.budgetNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="export-container">
        <button class="btn btn-info btn-sm" @click="exportPdf">{{$t('printDownload')}}</button>
      </div>
      <div class="department-form">
        <div v-if="isLoading" class="text-center">
          <div class="spinner-border text-dark"></div>
        </div>
        <div id="toprint" v-else>
          <div>
            <div style="text-align: center">
              <div style="font-size: 20px; font-weight: bold">
                {{ loggedInUser.organization_name }}
              </div>
              <div style="font-size: 16px; font-weight: bold">
                {{ loggedInUser.organization_address }}
              </div>
              <div>{{ $t("leftnav.incomexpense") }}</div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered reportTable"
                v-for="(branch, i) in reportList"
                :key="'report' + i"
              >
                <thead>
                  <tr>
                    <th>{{ branch.organization_name }}</th>
                  </tr>
                  <tr>
                    <td>
                      <table
                        class="table table-striped table-bordered reportTable"
                        style="font-size: 10px"
                      >
                        <thead>
                          <tr>
                            <td colspan="6">
                              {{ $t("report.incomes") }}
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("sn") }}</th>
                            <th>{{ $t("report.incomeSourceHeading") }}</th>
                            <th>{{ $t("report.incomeSourceSubHeading") }}</th>
                            <th>{{ $t("report.expected") }}</th>
                            <th>{{ $t("report.released") }}</th>
                            <th>{{ $t("report.stock") }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="branch.hasOwnProperty('income')">
                          <tr
                            v-for="(budget, index) in branch.income"
                            :key="index"
                          >
                            <td>{{ ++index }}</td>
                            <td>{{ budget.main_head }}</td>
                            <td>{{ budget.headings }}</td>
                            <td>{{ $t("rs") + budget.expected }}</td>
                            <td>{{ $t("rs") + budget.released }}</td>
                            <td>
                              {{
                                $t("rs") +
                                (parseFloat(budget.expected) -
                                  parseFloat(budget.released))
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="6" class="no-record">
                              {{ $t("norecord") }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        class="table table-striped table-bordered"
                        style="font-size: 10px"
                      >
                        <thead>
                          <tr>
                            <th colspan="5">{{ $t("report.expnese") }}</th>
                          </tr>
                          <tr>
                            <th>{{ $t("sn") }}</th>
                            <th>{{ $t("report.project") }}</th>
                            <th>{{ $t("report.appropriationAmount") }}</th>
                            <th>{{ $t("report.expensesAmount") }}</th>
                            <th>{{ $t("report.stock") }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="branch.hasOwnProperty('expenses')">
                          <tr
                            v-for="(app, index) in branch.expenses"
                            :key="index"
                          >
                            <td>{{ ++index }}</td>
                            <td>{{ app.task_title }}</td>
                            <td>{{ $t("rs") + app.appropriation }}</td>
                            <td v-for="(src, i) in app.source" :key="i">
                              {{ $t("rs") + src }}
                            </td>
                            <td>{{ $t("rs") + app.expenses }}</td>
                            <td>
                              {{
                                $t("rs") +
                                (parseFloat(app.appropriation) -
                                  parseFloat(app.expenses))
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td :colspan="5" class="no-record">
                              {{ $t("norecord") }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      isLoading: true,
      reportList: [],
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },

    getReport() {
      axios
        .post(
          "branchreport/incomexpense",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.reportList = res.data.branch;
            console.log(this.reportList);
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
