<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("csetting.head") }}
        <small>{{ $t("csetting.addNote") }}</small>
      </h2>
      <div>
              <router-link :to="`/${$i18n.locale}/dashboard/bail/chequesetting/list`" class="btn btn-info btn-sm">{{$t('bail.chequeSettingList')}}</router-link>
          </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :cheque="cheque" :type="1" bankType="1"/>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../../appropriation/cheque/form.vue";

export default {
  data() {
    return {
      cheque: {
        bank_id: null,
        type: null,
        height: null,
        width: null,
        date_top: null,
        date_left: null,
        benificiary_top: null,
        benificiary_left: null,
        amount_number_left: null,
        amount_number_top: null,
        amount_letter_left: null,
        amount_letter_top: null,
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
