<template>
  <div class="modal" id="myModal" :class="modalFlag ? 'show fade' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{ $t("revenueestimation.detail") }}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th>{{ $t("sn") }}</th>
                <th>{{ $t("leftnav.sourceOfReceipt") }}</th>
                <th>{{ $t("leftnav.methodOfReceipt") }}</th>
                <th>{{ $t("sources.amount") }}</th>
                <th>{{ $t("action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.income_source }}</td>
                <td>{{ item.paymentType }}</td>
                <td>{{ item.amount }}</td>
                <td>
                  <router-link
                    v-if="item.approval_status == 0"
                    :to="
                      '/' +
                      $i18n.locale +
                      '/dashboard/budget/sourcedetermination/update/' +
                      item.id
                    "
                    class="btn btn-warning btn-sm actionButton"
                    >{{ $t("update") }}</router-link
                  >
                  <button
                    v-if="item.approval_status == 0"
                    @click="deleteItem(item.id, index)"
                    class="btn btn-danger btn-sm actionButton"
                  >
                    {{ $t("delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modalFlag: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.$store.state.projectSourceList = this.data;
  },
  methods: {
    close() {
      this.$emit("closeDetail");
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
        this.$emit("closeDetail");
      }
    },
  },
};
</script>
