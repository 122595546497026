<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{$t('sources.detail')}}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="table-container">
            <div class="table-responsive">
              <Tableshimmer :heading="tableHeading" v-if="isLoading" />
              <Table
                :list="expensesList"
                :currentPage="currentPage"
                :perPage="perPage"
                :heading="tableHeading"
                :totalRow="totalRow"
                @changePage="changePage"
                v-else
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Tableshimmer from "../../helper/tableshimmer.vue";
import Table from "./expensestable.vue";

export default {
  components: { Tableshimmer,Table },
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      tableHeading:[this.$t('sn'), this.$t('sources.project'), this.$t('sources.task'), this.$t('sources.budget')],
      expensesList: [],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    async getData() {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        source_id: this.data.source_id,
        fiscal_year_id: this.data.fiscal_year_id,
      };
      await axios
        .post("budgetprojectsource/sourceexpenses", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.expensesList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.currentPage = data.page;
      this.getData();
    },
  },
};
</script>
