<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t("budgethead.head") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.source.head_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.source.head_id.$model"
          :class="{
            'is-invalid': v$.source.head_id.$error,
          }"
        >
          <option
            v-for="(head, index) in headList"
            :key="index"
            :value="head.id"
          >
            {{ head.main_head }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("income.typeError") }}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{ $t("income.heading") }}</label>
      <input
        v-model="v$.source.headings.$model"
        :class="{
          'is-invalid': v$.source.headings.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("income.headingError") }}</div>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{ $t("revenuecollection.method") }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-for="(met, e) in source.method" :key="e">
        <tr>
          <td>
            <div class="form-group required">
              <input
                v-model="met.type"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.source.method.$each.$response.$errors[e].type.length,
                }"
              />

              <div class="invalid-feedback">
                {{ $t("revenuecollection.methodError") }}
              </div>
            </div>
          </td>
          <td>
            <div class="voucher-dynamic-form-button-container">
              <div class="btn btn-success btn-xs" @click="cloneItem">+</div>
              <div
                class="btn btn-warning btn-xs"
                @click="deleteClone(e)"
                v-if="e != 0"
              >
                -
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-group">
      <label class="control-label">{{ $t("income.description") }}</label>
      <textarea
        v-model="source.description"
        rows="5"
        type="text"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      source: {
        headings: { required },
        head_id: { required },
        method: {
          $each: helpers.forEach({
            type: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      isDropDownLoading: true,
      isSubmitting: false,
      headList: [],
    };
  },
  created() {
    this.getDropDown();
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  methods: {
    cloneItem() {
      this.source.method.push({
        type: null,
      });
    },

    deleteClone(index) {
      this.source.method.splice(index, 1);
    },

    getDropDown() {
      axios
        .post(
          "helper/getbudgethead",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.headList = res.data.list;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.source) {
          formData.set(key, this.source[key]);
        }
        formData.append("method", JSON.stringify(this.source.method));
        var url =
          this.type == 1
            ? "budgetsourceheading/create"
            : "budgetsourceheading/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Income source successfully added."
                    : "Income source successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/budget/source/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
