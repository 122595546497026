<template>
  <div>
    <div class="list-search-form">
      <form ref="searchForm" @submit.prevent="submitForm">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(1, $event)"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("attendance.fromError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.from.$model"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">{{ $t("attendance.fromError") }}</div>
        </div>
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(2, $event)"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("attendance.toError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.to.$model"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">{{ $t("attendance.toError") }}</div>
        </div>
        <div class="form-group required">
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.search.staffId.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.search.staffId.$model"
              :class="{
                'is-invalid': v$.search.staffId.$error,
              }"
            >
              <option
                v-for="(stf, index) in staff"
                :key="index"
                :value="stf.id"
              >
                {{ stf.full_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("attendance.staffError") }}
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-info">{{ $t("search") }}</button>
        </div>
      </form>
    </div>
    <div class="report-data-container">
      <div class="header" v-if="searchFlag">
        <div class="export-container">
          <button class="btn btn-info btn-sm" @click="exportPdf">PDF</button>
        </div>
        <div id="toprint">
          <h2>{{ fullName }}</h2>
          <h5>{{ $t("attendance.attendanceReport") }}</h5>
          <h6>
            {{ $t("attendance.from") }} : {{ convertedDate(search.from) }} :
            {{ convertedDate(search.to) }}
          </h6>
          <div class="table-reponsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{{ $t("sn") }}</th>
                  <th>{{ $t("attendance.date") }}</th>
                  <th>{{ $t("attendance.checkIn") }}</th>
                  <th>{{ $t("attendance.checkOut") }}</th>
                </tr>
              </thead>
              <tbody v-if="report.length > 0">
                <tr v-for="(res, index) in report" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ convertedDate(res.date) }}</td>
                  <td>{{ res.check_in }}</td>
                  <td>{{ res.check_out }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="no-record">{{ $t("norecord") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import html2pdf from "html2pdf.js";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isLoading: true,
      report: [],
      staff: [],
      searchFlag: false,
      isDropDownLoading: false,
      currentPage: 1,
      perPage: 100000,
      fullName: null,
      search: {
        from: null,
        to: null,
        staffId: null,
      },
    };
  },
  created() {
    this.$emit("close");
    this.getData(null);
  },
  validations() {
    return {
      search: {
        from: { required },
        to: { required },
        staffId: { required },
      },
    };
  },
  methods: {
    convertedDate(date) {
      if (this.$i18n.locale == "np") {
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      } else return date;
    },

    supplyDate(type, e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      if (type == 1) this.search.from = formatedDate;
      else this.search.to = formatedDate;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf(element, {
        margin: 10,
        filename:
          "Attendance_report_" +
          this.report[0].full_name +
          "_" +
          new Date().getTime(),
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      });
    },

    submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.getAttendance();
      }
    },

    async getAttendance() {
      this.isLoading = true;
      const argument = {
        from: this.search.from,
        to: this.search.to,
        staffId: this.search.staffId,
      };
      await axios
        .post("attendance/getindividualattendance", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.report = res.data.data;
            this.searchFlag = true;
            this.fullName = res.data.full_name;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    async getData(query) {
      this.isDropDownLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
      };
      await axios
        .post("staff/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.staff = res.data.list;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
