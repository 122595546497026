var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t('payrollCategory.category')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.category.designation.$model),expression:"v$.category.designation.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.category.designation.$error,
      },attrs:{"type":"text"},domProps:{"value":(_vm.v$.category.designation.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.category.designation, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('payrollCategory.categoryGrade')))])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t('payrollCategory.maxGrade')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.category.maximum_grade.$model),expression:"v$.category.maximum_grade.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.category.maximum_grade.$error,
      },attrs:{"type":"number"},domProps:{"value":(_vm.v$.category.maximum_grade.$model)},on:{"keyup":_vm.cloneGrade,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.category.maximum_grade, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('payrollCategory.maxGradeError')))])]),(_vm.category.grade.length>0)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('payrollCategory.category')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payrollCategory.basicSalary')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payrollCategory.gradeNo')))]),_c('th',[_vm._v(_vm._s(_vm.$t('payrollCategory.gradeRate')))])])]),_c('tbody',_vm._l((_vm.category.grade),function(cat,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(cat.grade),expression:"cat.grade"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.category.grade.$each.$response.$errors[index].grade
                      .length,
                },attrs:{"type":"text"},domProps:{"value":(cat.grade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(cat, "grade", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrollCategory.gradeError'))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(cat.basic_salary),expression:"cat.basic_salary"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.category.grade.$each.$response.$errors[index]
                      .basic_salary.length,
                },attrs:{"type":"number","step":"0.01"},domProps:{"value":(cat.basic_salary)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(cat, "basic_salary", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrollCategory.basicSalaryError'))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(cat.grade_no),expression:"cat.grade_no"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.category.grade.$each.$response.$errors[index].grade_no
                      .length,
                },attrs:{"type":"number"},domProps:{"value":(cat.grade_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(cat, "grade_no", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrollCategory.gradeNoError'))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(cat.grade_rate),expression:"cat.grade_rate"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.category.grade.$each.$response.$errors[index]
                      .grade_rate.length,
                },attrs:{"type":"number","step":"0.01"},domProps:{"value":(cat.grade_rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(cat, "grade_rate", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('payrollCategory.gradeRateError'))+" ")])])])])}),0)])]):_vm._e(),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("department").add : _vm.$t("department").update)+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }