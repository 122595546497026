import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    smsFlag:
      localStorage.getItem("kasturiWorkSpaceSMSFlag") === null
        ? 1
        : localStorage.getItem("kasturiWorkSpaceSMSFlag"),
    calendarFlag:
      localStorage.getItem("kasturiWorkSpaceCalendarFlag") === null
        ? 1
        : localStorage.getItem("kasturiWorkSpaceCalendarFlag"),
    activeLink: window.location.pathname.split("/")[3],
    language: localStorage.getItem("language") == null ? 1 : 0,
    skin:
      localStorage.getItem("skin") == null
        ? "indigo"
        : localStorage.getItem("skin"),
    departmentList: [],
    staffList: [],
    projectList: [],
    taskList: [],
    fiscalyearList: [],
    sourceList: [],
    expensesList: [],
    projectSourceList: [],
    projectExpensesList: [],
    bankList: [],
    chequeSettingList: [],
    chequeList: [],
    partyList: [],
    voucherList: [],
    workingAreaList: [],
    branchList: [],
    dprList: [],
    headList: [],
    shiftList: [],
    bailBankList: [],
    bailChequeList: [],
    budgetSourceList: [],
    liabilityList: [],
    bailDtsList: [],
    bailPayerList: [],
    bailRecordList: [],
    designationList: [],
    categoryList: [],
    payheadsList: [],
    payrollTaxList: [],
    mappingList: [],
    inaccessibleList: [],
    holidayList: [],
    weekendList: [],
    staffBankList: [],
    staffEducationList: [],
    holidaysetupList: [],
    staffExperienceList: [],
  },
});
