<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t("employeemapping.staff") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.employee.staff_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.employee.staff_id.$model"
          :class="{
            'is-invalid': v$.employee.staff_id.$error,
          }"
        >
          <option
            v-for="(staff, index) in staffList"
            :key="'Staff' + index"
            :value="staff.id"
          >
            {{ staff.full_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("employeemapping.staffError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{
        $t("employeemapping.designation")
      }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.employee.payroll_category_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          @change="updateGrade()"
          v-model="v$.employee.payroll_category_id.$model"
          :class="{
            'is-invalid': v$.employee.payroll_category_id.$error,
          }"
        >
          <option
            v-for="(cat, index) in categoryList"
            :key="'Category_' + index"
            :value="cat.id"
          >
            {{ cat.designation }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">
        {{ $t("employeemapping.designationError") }}
      </div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("employeemapping.grade") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.employee.payroll_grade_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.employee.payroll_grade_id.$model"
          :class="{
            'is-invalid': v$.employee.payroll_grade_id.$error,
          }"
        >
          <option
            v-for="(grade, index) in gradeList"
            :key="'Grade_' + index"
            :value="grade.id"
          >
            {{ grade.grade }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">{{ $t("employeemapping.gradeError") }}</div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{
        $t("employeemapping.inaccessibleStatus")
      }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.employee.inaccessible_tax_status.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.employee.inaccessible_tax_status.$model"
          :class="{
            'is-invalid': v$.employee.inaccessible_tax_status.$error,
          }"
        >
          <option value="1">{{ $t("yes") }}</option>
          <option value="0">{{ $t("no") }}</option>
        </select>
      </div>

      <div class="invalid-feedback">
        {{ $t("employeemapping.inaccessibleStatusError") }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label">{{
        $t("employeemapping.inaccessibleAllowance")
      }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.employee.inaccessible_id.$error,
        }"
      >
        <span class="input-group-text" v-if="isDropDownLoading">
          <div class="spinner-border"></div>
        </span>
        <select class="form-select" v-if="isDropDownLoading" disabled>
          <option value="null">{{ $t("loading") }}</option>
        </select>
        <select
          v-else
          class="form-select"
          v-model="v$.employee.inaccessible_id.$model"
          :class="{
            'is-invalid': v$.employee.inaccessible_id.$error,
          }"
        >
          <option
            v-for="(inaccess, index) in inaccessibleList"
            :key="'Inaccess_' + index"
            :value="inaccess.id"
          >
            {{ inaccess.type_name }}
          </option>
        </select>
      </div>

      <div class="invalid-feedback">
        {{ $t("employeemapping.inaccessibleAllowanceError") }}
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>{{ $t("employeemapping.particular") }}</th>
            <th>{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in employee.services" :key="index">
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.employee.services.$each.$response.$errors[index]
                        .payroll_payhead_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="service.payroll_payhead_id"
                    :class="{
                      'is-invalid':
                        v$.employee.services.$each.$response.$errors[index]
                          .payroll_payhead_id.length,
                    }"
                  >
                    <option
                      v-for="(payhead, index) in payheadList"
                      :key="'Payhead_' + index"
                      :value="payhead.id"
                    >
                      {{ payhead.headings }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("employeemapping.particularError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="clonePayheadItem">
                  +
                </div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deletePayheadClone(index)"
                  v-if="index != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? "Add" : "Update" }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      staffList: [],
      categoryList: [],
      gradeList: [],
      payheadList: [],
      inaccessibleList: [],
      isSubmitting: false,
      isDropDownLoading: true,
    };
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      employee: {
        staff_id: { required },
        payroll_category_id: { required },
        payroll_grade_id: { required },
        inaccessible_tax_status: { required },
        inaccessible_id: {
          required: requiredIf((value, item) => {
            return item.inaccessible_tax_status == 1;
          }),
        },
        services: {
          $each: helpers.forEach({
            payroll_payhead_id: { required },
          }),
        },
      },
    };
  },
  created() {
    this.getDropDown();
  },
  methods: {
    updateGrade() {
      console.log(this.employee.payroll_category_id);
      if (this.employee.payroll_category_id) {
        this.gradeList = this.categoryList.filter((item) => {
          return item.id.includes(this.employee.payroll_category_id);
        })[0].grade;
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/getpayrollmappingdropdown",
          { type: this.type },
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.staffList = res.data.staff;
            this.categoryList = res.data.category;
            this.payheadList = res.data.payhead;
            this.inaccessibleList = res.data.inaccess;
            this.isDropDownLoading = false;
            if (this.type == 2) {
              this.updateGrade();
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clonePayheadItem() {
      this.employee.services.push({
        payroll_payhead_id: null,
      });
    },

    deletePayheadClone(index) {
      this.employee.services.splice(index, 1);
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.employee) {
          formData.set(key, this.employee[key]);
        }
        formData.append("services", JSON.stringify(this.employee.services));
        var url =
          this.type == 1 ? "payrollmapping/add" : "payrollmapping/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Employee mapping successfully added."
                    : "Employee mapping successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/payroll/mapping/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
