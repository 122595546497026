<template>
  <div>
    <Topnav />
    <div class="info-card">
      <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('newemail').heading}}
            <small>{{$t('newemail').note}}</small>
          </h2>
        </div>
        <div class="body">
          <div class="department-form">
            <div class="text-center">
              {{$t('newemail').alert}}
            </div>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="control-label">{{$t('newemail').new}}</label>
                <input
                  v-model="v$.email.$model"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.email.$error,
                  }"
                />

                <div
                  class="invalid-feedback"
                  v-if="!v$.email.required.$response"
                >
                  {{$t('newemail').newError}}
                </div>
                <div class="invalid-feedback" v-if="!v$.email.email.$response">
                  {{$t('newemail').newInvalidError}}
                </div>
              </div>
              <div class="form-group">
                <label>{{$t('newemail').confirm}}</label>
                <input
                  v-model="v$.cemail.$model"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.cemail.$error,
                  }"
                />
                <div
                  class="invalid-feedback"
                  v-if="!v$.cemail.required.$response"
                >
                  {{$t('newemail').confirmError}}
                </div>
                <div class="invalid-feedback">
                  {{$t('newemail').confirmIdenticalError}}
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-danger btn-block submitButton"
                  name="login-button"
                  v-if="!isSubmitting"
                >
                  {{$t('newemail').change}}
                </button>
                <button
                  class="btn btn-danger btn-block submitButton loading"
                  disabled
                  v-else
                >
                  <div class="spinner-border text-warning"></div>
                  <div>{{$t('loading')}}</div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topnav from "../dashboard/topnav.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      isSubmitting: false,
      email: null,
      cemail: null,
    };
  },
  components: {
    Topnav,
  },
  validations() {
    return {
      email: { required, email },
      cemail: {
        required,
        sameAsEmail: (value) => {
          if (this.email === value) return true;
          else return false;
        },
      },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("newemail", this.email);
        formData.set("email", this.loggedInUser.email);
        await axios
          .post("organization/sendcodetonewemail", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              this.$toastr.s("Verification pin successfully sent.");
              this.$router.push("/"+this.$i18n.locale+"/setting/confirmnewemail/"+this.email);
            } else {
              this.$toastr.e(
                "Alert!",
                "Something went wrong. Try again later."
              );
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
