<template>
  <div>
    <div class="list-search-form">
      <form ref="searchForm" @submit.prevent="submitForm">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(1, $event)"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="startDate"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("attendance.fromError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.from.$model"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">From cannot be blank</div>
        </div>
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(2, $event)"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("attendance.toError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.to.$model"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">To cannot be blank</div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-info">{{ $t("search") }}</button>
        </div>
      </form>
    </div>
    <div class="report-data-container">
      <div class="header" v-if="searchFlag">
        <div class="export-container">
          <button class="btn btn-info btn-sm" @click="exportPdf">PDF</button>
        </div>
        <div id="toprint">
          <h2>{{ $t("attendance.staffAttendanceReport") }}</h2>
          <h5>{{ $t("attendance.attendanceReport") }}</h5>
          <h6>
            {{ $t("attendance.from") }} : {{ convertedDate(search.from) }} : {{ convertedDate(search.to) }}
          </h6>
          <div class="table-reponsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{{ $t("sn") }}</th>
                  <th>{{ $t("attendance.staff") }}</th>
                  <th>{{ $t("attendance.totalDays") }}</th>
                  <th>{{ $t("attendance.weekend") }}</th>
                  <th>{{ $t("attendance.holidays") }}</th>
                  <th>{{ $t("attendance.attendanceDays") }}</th>
                  <th>{{ $t("attendance.presentDays") }}</th>
                  <th>{{ $t("attendance.leaveDays") }}</th>
                  <th data-html2canvas-ignore="true">{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody v-if="report.list.length > 0">
                <tr v-for="(res, index) in report.list" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ res.name }}</td>
                  <td>{{ report.totalDays }}</td>
                  <td>{{ report.saturday }}</td>
                  <td>{{ report.totalholiday }}</td>
                  <td>{{ report.attendanceDays }}</td>
                  <td>{{ res.present }}</td>
                  <td>{{ report.attendanceDays - res.present }}</td>
                  <td data-html2canvas-ignore="true">
                    <button
                      @click.prevent="viewDetail(index)"
                      class="btn btn-primary btn-sm actionButton"
                    >
                      {{ "VEIW DETAIL" }}
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="no-record">{{ $t("norecord") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal show fade" id="myModal" v-if="modalFlag">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" :class="'bg-' + $store.state.skin">
            <h4 class="modal-title">{{ "Staff Attendance Detail" }}</h4>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="modal-body">
            <Table :list="modalData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import html2pdf from "html2pdf.js";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";
import Table from "./attendancedetail.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    VNepaliDatePicker,
    Table,
  },
  data() {
    return {
      isLoading: true,
      report: [],
      staff: [],
      searchFlag: false,
      isDropDownLoading: false,
      currentPage: 1,
      perPage: 100000,
      modalData: null,
      modalFlag: false,
      startDate: "",
      endDate: "",
      search: {
        from: null,
        to: null,
      },
    };
  },
  validations() {
    return {
      search: {
        from: { required },
        to: { required },
      },
    };
  },
  created() {
    this.$emit("close");
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    supplyDate(type, e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      if (type == 1) this.search.from = formatedDate;
      else this.search.to = formatedDate;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf(element, {
        margin: 10,
        filename: "General_attendance_report__" + new Date().getTime(),
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      });
    },

    viewDetail(index) {
      this.modalData = this.report.list[index];
      this.modalFlag = true;
    },

    close() {
      this.modalFlag = false;
    },

    submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.getAttendance();
      }
    },

    async getAttendance() {
      this.isLoading = true;
      const argument = {
        from: this.search.from,
        to: this.search.to,
      };
      await axios
        .post("attendance/getgeneralattendance", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.report = res.data;
            this.searchFlag = true;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
