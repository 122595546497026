<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('staffbank.staffBank') }}
        <small>{{ $t('staffbank.addNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :bank="bank" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      bank: {
        staff_id: null,
        bank: null,
        branch: null,
        account_number: null,
        account_name: null,
      },
    };
  },
  components: {
    Form,
  },
  created() {
    this.$emit("close");
  },
};
</script>
