<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('party.updateParty') }}
        <small>{{ $t('party.updatePartyNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :payer="payer" :type="2" :pageType="0"/>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../../bail/payer/form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      id: this.$route.params.id,
      payer: this.$store.state.bailPayerList.filter((item) => {
        return item.id==this.$route.params.id;
      })[0],
    };
  },
};
</script>
