<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("kharcha.heading") }}
        <small>{{ $t("kharcha.listNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <Search
        @search="search"
        @changePage="changePage"
        @resetSearch="resetSearch"
      />
      <Tableshimmer :heading="tableHeading" v-if="isLoading" />
      <Table
        :list="projectExpensesList"
        :currentPage="currentPage"
        :perPage="perPage"
        :heading="tableHeading"
        :totalRow="totalRow"
        :type="type"
        @changePage="changePage"
        @deleteItem="deleteItem"
        @approveBudget="approveBudget"
        v-else
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Table from "./table.vue";
import Search from "../../helper/search.vue";
import Tableshimmer from "../../helper/tableshimmer.vue";

export default {
  components: {  Search, Tableshimmer, Table },
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      projectExpensesList: [],
      tableHeading: [
        this.$t("sn"),
        this.$t("kharcha.fiscal"),
        this.$t("income.type"),
        this.$t("kharcha.ehead"),
        this.$t("kharcha.task"),
        this.$t("kharcha.amount"),
      ],
      searchQuery: null,
      type: parseInt(this.$route.params.type),
    };
  },
  watch: {
    "$route.params": {
      handler(newValue, oldValue) {
        this.projectExpensesList = [];
        this.type = parseInt(newValue.type);
        this.$emit('close');
        if (oldValue) {
          if (oldValue.type != newValue.type) this.getData(null);
        } else {
          this.getData(null);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getData(null);
  },
  mounted() {
    this.$emit('close');
  },
  methods: {
    async getData(query) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
      };
      await axios
        .post("budgetprojectexpenses/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.projectExpensesList = res.data.list;
            this.$store.state.projectExpensesList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.projectExpensesList = [];
      this.currentPage = data.page;
      this.getData(this.searchQuery);
    },

    resetSearch() {
      this.searchQuery = null;
      this.projectExpensesList = [];
      this.currentPage = 1;
      this.getData(this.searchQuery);
    },

    search(query) {
      this.currentPage = 1;
      this.searchQuery = query;
      this.getData(this.searchQuery);
    },

    async approveBudget(data) {
      await axios
        .post("budgetprojectexpenses/approve", data, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            if (res.data.flag) {
              this.$toastr.s("Successfully approved.");
              this.projectExpensesList[data.index].approve_status = 1;
            } else {
              this.$toastr.e("Something went wrong. Try again later.");
            }
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    async deleteItem(data) {
      const argument = {
        id: data.id,
      };
      await axios
        .post("budgetprojectexpenses/delete", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            if (res.data.flag) {
              this.$toastr.s("Successfully deleted.");
              this.projectExpensesList.splice(data.index, 1);
            } else {
              this.$toastr.e("Something went wrong. Try again later.");
            }
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
