<template>
  <div class="card">
    <div class="card-body">
      <form @submit.prevent="submitForm">
        <div class="cheque-request-form-container">
          <div class="form-group required">
            <label class="control-label">{{$t('bank.chequeFrom')}}</label>
            <input
              v-model="v$.cheque.start_from.$model"
              :class="{
                'is-invalid': v$.cheque.start_from.$error,
              }"
              type="number"
              class="form-control"
            />

            <div class="invalid-feedback">{{$t('bank.chequeFromError')}}</div>
          </div>
          <div class="form-group required">
            <label class="control-label">{{$t('bank.chequeTo')}}</label>
            <input
              v-model="v$.cheque.end_at.$model"
              :class="{
                'is-invalid': v$.cheque.end_at.$error,
              }"
              type="number"
              class="form-control"
            />

            <div class="invalid-feedback">{{$t('bank.chequeToError')}}</div>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-danger btn-block"
              name="login-button"
              v-if="!isSubmitting"
            >
              {{$t('bank.receive')}}
            </button>
            <button
              class="btn btn-danger btn-block submitButton loading"
              disabled
              v-else
            >
              <div class="spinner-border text-warning"></div>
              <div>{{$t('loading')}}</div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      cheque: {
        start_from: null,
        end_at: null,
      },
    };
  },
  props: {
    bankId: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      cheque: {
        start_from: { required },
        end_at: { required },
      },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.cheque) {
          formData.set(key, this.cheque[key]);
        }
        formData.set("bankId", this.bankId);
        await axios
          .post("cheque/receive", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/"+this.$i18n.locale+"/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Cheque successfully received.");
                this.cheque.start_from = null;
                this.cheque.end_at = null;
                this.v$.$reset();
                this.$emit('addChequeList',{list:res.data.list,type:2});
                this.isSubmitting =false;
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
<style>
.cheque-request-form-container {
  display: flex;
  justify-content: center;
  align-items: end;
}

.cheque-request-form-container > div:nth-child(2) {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
