<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('designation.designation') }}
        <small>{{ $t('designation.addNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :designation="designation" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      designation: {
        designation: null,
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
