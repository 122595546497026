<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t("holidaySetup.title") }}</label>
      <input
        v-model="v$.holiday.heading.$model"
        :class="{
          'is-invalid': v$.holiday.heading.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("holidaySetup.titleError") }}</div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{
        $t("holidaySetup.maximumHoliday")
      }}</label>
      <input
        v-model="v$.holiday.maximum_holiday.$model"
        :class="{
          'is-invalid': v$.holiday.maximum_holiday.$error,
        }"
        type="number"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t("holidaySetup.maximumHolidayError") }}
      </div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("holidaySetup.accumulatedStatus") }}</label>
      <div
        class="input-group"
        :class="{
          'is-invalid': v$.holiday.accumulated_status.$error,
        }"
      >
        <select
          class="form-select"
          v-model="v$.holiday.accumulated_status.$model"
          :class="{
            'is-invalid': v$.holiday.accumulated_status.$error,
          }"
        >
          <option value="1">{{ $t("yes") }}</option>
          <option value="0">{{ $t("no") }}</option>
        </select>
      </div>

      <div class="invalid-feedback">
        {{ $t("holidaySetup.accumulatedStatusError") }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label">{{ $t("holidaySetup.description") }}</label>
      <textarea
        v-model="holiday.description"
        type="text"
        class="form-control"
        rows="5"
      />
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  props: {
    holiday: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      holiday: {
        heading: { required },
        maximum_holiday: { required },
        accumulated_status: { required },
      },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.holiday) {
          formData.set(key, this.holiday[key]);
        }
        var url =
          this.type == 1
            ? "payrollholidaysetup/create"
            : "payrollholidaysetup/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Holiday setup successfully added."
                    : "Holiday setup successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/payroll/holiday/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
