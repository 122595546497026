<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{$t('receipt.detail')}}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
            <div class="table-responsive">
                <table class="table table-bordered table-stripped">
                    <tbody>
                        <tr>
                            <td><strong>{{$t('receipt.approvedDate')}}</strong></td>
                            <td>{{convertedDate(data.date)}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{$t('receipt.preparedBy')}}</strong></td>
                            <td>{{data.preparedBy}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{$t('receipt.submittedBy')}}</strong></td>
                            <td>{{data.submittedBY}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{$t('receipt.approvedBy')}}</strong></td>
                            <td>{{data.approvedBy}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedDate(date){
      if(this.$i18n.locale=="np"){
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      }
      else
        return date;
    },

    close() {
      this.$emit("closeDetail");
    },
  },
};
</script>
