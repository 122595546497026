<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.type') }}</label>
          <select
            class="form-select"
            v-model="v$.payer.type.$model"
            :class="{
              'is-invalid': v$.payer.type.$error,
            }"
          >
            <option value="1">Contractor</option>
            <option value="2">Institutional</option>
            <option value="3">Consumer Committee</option>
            <option value="4">Personal</option>
            <option value="5">Supplier</option>
            <option value="6">Consultant</option>
            <option value="7">Internal Body</option>
            <option value="8">Office Bearers</option>
            <option value="9">Apparent Letter</option>
            <option value="10">Personal</option>
          </select>

          <div class="invalid-feedback">{{ $t('party.typeError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.dtsType') }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payer.dts_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.payer.dts_id.$model"
              :class="{
                'is-invalid': v$.payer.dts_id.$error,
              }"
            >
              <option
                v-for="(dts, index) in dtsList"
                :key="index"
                :value="dts.id"
              >
                {{ dts.dts_type }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t('party.dtsTypeError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.name') }}</label>
          <input
            v-model="v$.payer.name.$model"
            :class="{
              'is-invalid': v$.payer.name.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t('party.nameError') }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.address') }}</label>
          <input
            v-model="v$.payer.address.$model"
            :class="{
              'is-invalid': v$.payer.address.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t('party.addressError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.panVat') }}</label>
          <input
            v-model="v$.payer.pan_vat.$model"
            :class="{
              'is-invalid': v$.payer.pan_vat.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.panVatError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{
           $t('party.citizenship')
          }}</label>
          <input
            v-model="v$.payer.citizenship_registration_no.$model"
            :class="{
              'is-invalid': v$.payer.citizenship_registration_no.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.citizenshipError') }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t('party.phone') }}</label>
          <input v-model="payer.phone" type="number" class="form-control" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.mobile') }}</label>
          <input
            v-model="v$.payer.mobile.$model"
            :class="{
              'is-invalid': v$.payer.mobile.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t('party.mobileError') }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t("party.email") }}</label>
          <input v-model="payer.email" type="text" class="form-control" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.bank') }}</label>
          <input
            v-model="v$.payer.bank_name.$model"
            :class="{
              'is-invalid': v$.payer.bank_name.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.bankError') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.branch') }}</label>
          <input
            v-model="v$.payer.branch.$model"
            :class="{
              'is-invalid': v$.payer.branch.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.branchError') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.accNumber') }}</label>
          <input
            v-model="v$.payer.account_no.$model"
            :class="{
              'is-invalid': v$.payer.account_no.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.accNumberError') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.accName') }}</label>
          <input
            v-model="v$.payer.account_name.$model"
            :class="{
              'is-invalid': v$.payer.account_name.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.accNameError') }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.contactPersonName') }}</label>
          <input
            v-model="v$.payer.cperson.name.$model"
            :class="{
              'is-invalid': v$.payer.cperson.name.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.contactPersonNameError') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.designation') }}</label>
          <input
            v-model="v$.payer.cperson.designation.$model"
            :class="{
              'is-invalid': v$.payer.cperson.designation.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.designationError') }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t('party.contactPersonPhone') }}</label>
          <input
            v-model="payer.cperson.phone"
            type="number"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.contactPersonMobile') }}</label>
          <input
            v-model="v$.payer.cperson.mobile.$model"
            :class="{
              'is-invalid': v$.payer.cperson.mobile.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t('party.contactPersonMobileError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t('party.contactPersonEmail') }}</label>
          <input
            v-model="payer.cperson.email"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.advanceStatus') }}</label>
          <select
            class="form-select"
            v-model="v$.payer.advance_status.$model"
            :class="{
              'is-invalid': v$.payer.advance_status.$error,
            }"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>

          <div class="invalid-feedback">
            {{ $t('party.advanceStatusError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.bailStatus') }}</label>
          <select
            class="form-select"
            v-model="v$.payer.bail_status.$model"
            :class="{
              'is-invalid': v$.payer.bail_status.$error,
            }"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>

          <div class="invalid-feedback">
            {{ $t('party.bailStatusError') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t('party.approvedStatus') }}</label>
          <select
            class="form-select"
            v-model="v$.payer.approved_status.$model"
            :class="{
              'is-invalid': v$.payer.approved_status.$error,
            }"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>

          <div class="invalid-feedback">
            {{ $t('party.approveStatusError') }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>Loading...</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      payer: {
        type: { required },
        pan_vat: { required },
        dts_id: { required },
        name: { required },
        citizenship_registration_no: { required },
        mobile: { required },
        address: { required },
        advance_status: { required },
        bail_status: { required },
        approved_status: { required },
        bank_name: { required },
        branch: { required },
        account_no: { required },
        account_name: { required },
        cperson: {
          name: { required },
          designation: { required },
          mobile: { required },
        },
      },
    };
  },
  data() {
    return {
      isDropDownLoading: true,
      dtsList: [],
      isSubmitting: false,
    };
  },
  props: {
    payer: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    pageType: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getPayerDropDown();
  },
  methods: {
    getPayerDropDown() {
      axios
        .post(
          "helper/payerdropdown",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.dtsList = res.data.list;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.payer) {
          formData.set(key, this.payer[key]);
        }
        formData.set("depositerType",this.pageType);
        formData.set("cperson", JSON.stringify(this.payer.cperson));
        var url = this.type == 1 ? "bailpayer/create" : "bailpayer/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Payer successfully added."
                    : "Payer successfully updated."
                );
                if (this.pageType == 0)
                  this.$router.push(
                    "/" + this.$i18n.locale + "/dashboard/appropriation/party/list"
                  );
                else
                  this.$router.push(
                    "/" + this.$i18n.locale + "/dashboard/bail/payer/list"
                  );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
