<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{$t('workingarea.head')}}</label>
      <input
        v-model="v$.workingarea.working_area.$model"
        :class="{
          'is-invalid': v$.workingarea.working_area.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{$t('workingarea.headError')}}</div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{type==1?$t('add'):$t('update')}}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{$t('loading')}}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  props: {
    workingarea: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      workingarea: {
        working_area: { required },
      },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.workingarea) {
          formData.set(key, this.workingarea[key]);
        }
        var url = this.type == 1 ? "workingarea/create" : "workingarea/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/"+this.$i18n.locale+"/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(this.type==1?"Working area successfully added.":"Working area successfully updated.");
                this.$router.push("/"+this.$i18n.locale+"/dashboard/project/workingarea/list");
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
