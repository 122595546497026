<template>
  <div class="login-body">
    <div class="container">
      <div class="login_container">
        <div class="find_account_container">
          <div class="find_account_form">
            <h2 class="text-center">{{$t('changepassword').changePassword}}</h2>
            <p style="color: #de6262">{{$t('changepassword').note}}</p>
            <div class="row">
              <div class="col-lg-12">
                <form @submit.prevent="submitForm">
                  <div class="form-group">
                    <label for="passwordresetrequestform-email"
                      >{{$t('changepassword').new}}</label
                    >
                    <input
                      v-model="v$.password.$model"
                      type="password"
                      autofocus="true"
                      class="form-control"
                      :class="{
                        'is-invalid': v$.password.$error,
                      }"
                    />

                    <div class="invalid-feedback">
                      {{$t('changepassword').newError}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="passwordresetrequestform-email"
                      >{{$t('changepassword').confirm}}</label
                    >
                    <input
                      v-model="v$.conpassword.$model"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': v$.conpassword.$error,
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!v$.conpassword.required.$response"
                    >
                      {{$t('changepassword').confirmError}}
                    </div>
                    <div class="invalid-feedback">
                      {{$t('changepassword').confirmIdenticalError}}
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-danger btn-block submitButton"
                      name="login-button"
                      v-if="!isSubmitting"
                    >
                      {{$t('changepassword').change}}
                    </button>
                    <button
                      class="btn btn-danger btn-block submitButton loading"
                      disabled
                      v-else
                    >
                      <div class="spinner-border text-warning"></div>
                      <div>{{$t('loading')}}</div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from 'axios';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      password: null,
      conpassword: null,
      email: this.$route.params.email,
    };
  },
  validations() {
    return {
      password: { required },
      conpassword: {
        required,
        sameAsPassword: (value) => {
          if (this.password === value) return true;
          else return false;
        },
      },
    };
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("password", this.password);
        formData.set("email", this.email);
        await axios
          .post("organization/changepassword", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              this.$toastr.s("Password successfully changed.");
              this.$router.push("/"+this.$i18n.locale+"/login");
            } else {
              this.$toastr.e(
                "Alert!",
                "No organization with this email address."
              );
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
