<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="setupUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/payroll/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'setup' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('payheads.setup') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/category/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.category') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/payheads/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.payheads') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/tax/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.tax') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/inaccessible/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.inaccessibleAllowance') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/holiday/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.holiday') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/mapping/list`"
                  class="dropdown-item"
                  >{{ $t('payheads.employeeMapping') }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'setting' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('payheads.setting') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/setting/weekendlist`"
                  class="dropdown-item"
                  >{{ $t('payheads.weekend') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/setting/holidaylist`"
                  class="dropdown-item"
                  >{{ $t('payheads.holidays') }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'attendance' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('payheads.attendance') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/take`"
                  class="dropdown-item"
                  >{{ $t('payheads.take') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/report`"
                  class="dropdown-item"
                  >{{ $t('payheads.report') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/attendance/individualreport`"
                  class="dropdown-item"
                  >{{ $t('payheads.indReport') }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'report' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('payheads.report') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/report/attendance`"
                  class="dropdown-item"
                  >{{ $t('payheads.attendance') }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/payroll/report/payroll`"
                  class="dropdown-item"
                  >{{ $t('payheads.payroll') }}</router-link
                >
              </li>
            </ul>
          </li>

        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  methods: {
    close() {
      this.$refs.setupUl.children.forEach((item, index) => {
        item.childNodes[0].classList.remove("show");
        if (index > 0) {
          item.childNodes[1].classList.remove("show");
        }
      });
      this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      var currentTab = e.target.parentNode;
      this.$refs.setupUl.children.forEach((item, index) => {
        if (!item.contains(currentTab)) {
          item.childNodes[0].classList.remove("show");
          if (index > 0) {
            item.childNodes[1].classList.remove("show");
          }
        }
      });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
