<template>
  <div class="link-home-page">
    <h1>Welcome to Report Module</h1>
  </div>
</template>
<script>
export default {
  created() {
    this.$emit("close");
  },
};
</script>
