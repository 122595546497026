<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("bank.head") }}
        <small>{{ $t("bank.updateNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :bank="bank" :type="2" :bankType="0" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      id: this.$route.params.id,
      bank: this.$store.state.bankList.filter((item) => {
        return item.id==this.$route.params.id;
      })[0],
    };
  },
};
</script>
