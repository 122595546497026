<template>
  <div>
    <navbar />
    <info />
    <promo/>
  </div>
</template>
<script>
import Navbar from "../promo/navbar.vue";
import Info from "../promo/info.vue";
import Promo from "../promo/promo.vue";

export default {
  components: {
    Navbar,
    Info,
    Promo,  
  },
};
</script>
