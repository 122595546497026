<template>
  <div class="table-container">
    <div class="export-container">
      <button class="btn btn-info btn-sm" @click="exportPdf">
        {{ $t("printDownload") }}
      </button>
    </div>
    <div id="toprint" style="font-size: 12px">
      <div class="report-heading">
        <h2>{{ $t("projectreport.staffReport") }}</h2>
        <div>
          {{
            $t("projectreport.from") +
            " : " +
            (date.from == null ? "" : date.from) +
            " " +
            $t("projectreport.to") +
            " : " +
            (date.to == null ? "" : date.to)
          }}
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm">
          <thead>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("projectreport.staff") }}</th>
              <th>{{ $t("projectreport.totalDays") }}</th>
              <th>{{ $t("projectreport.presentDays") }}</th>
              <th>{{ $t("projectreport.totalTask") }}</th>
              <th>{{ $t("projectreport.completedTask") }}</th>
              <th>{{ $t("projectreport.pendingTask") }}</th>
            </tr>
          </thead>
          <tbody v-if="list.length > 0">
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ ++index }}</td>
              <td>
                {{ item.full_name }}
              </td>
              <td>{{ item.totalDays }}</td>
              <td>{{ countPresentdays(item.tasks) }}</td>
              <td>{{ item.tasks.length }}</td>
              <td>{{ countTask(item.tasks, 1) }}</td>
              <td>{{ countTask(item.tasks, 0) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span class="no-record">{{ $t("norecord") }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },
  methods: {
    countPresentdays(data) {
      var list = data.filter((item) => {
        return item.completion_status == 1;
      });
      let group = list.reduce((r, a) => {
        r[a.date_of_completion] = [...(r[a.date_of_completion] || []), a];
        return r;
      }, {});
      return Object.keys(group).length;
    },

    countTask(data, status) {
      return data.filter((item) => {
        return item.completion_status == status;
      }).length;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf()
        .set({
          margin: 10,
          filename: "Voucher_" + new Date().getTime(),
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            imageTimeout: 0,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "landscape",
            compress: true,
          },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
  },
};
</script>
