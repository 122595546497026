<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("staff").staff }}
        <small>{{ $t("staff").enrollNote }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/setup/staff/list`"
          class="btn btn-info btn-sm"
          >{{ $t("list") }}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="staff-form">
        <Form :staff="staff" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../staff/form.vue";

export default {
  data() {
    return {
      isSubmitting: false,
      staff: {
        full_name: null,
        permanent_address: null,
        temporary_address: null,
        pan: null,
        email: null,
        phone: null,
        designation: null,
        date_of_appointment: null,
        date_of_join: null,
        password: null,
        department_id: null,
        shift_id: null,
        marital_status: null,
        disabled_status: null,
        gender: null,
        employee_type: null,
        identity_number: null,
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
