<template>
  <div>
    <div class="promotion">
      <div class="promotion_text">
        <div class="promotion_slogan">Plan Project And</div>
        <div class="promotion_slogan">Monitor Team Progress</div>
        <div class="promotion_title">Project Management Software...</div>
        <router-link :to="'/'+$i18n.locale+'/login'" class="btn btn-primary btn-md"
          >Get Started</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style>
.promotion {
  position: relative;
  height: 325px;
  width: 100%;
  background: radial-gradient(
      136% 485px at 50% -41px,
      #dc4146 74%,
      transparent 75%
    )
    no-repeat;
}

.promotion_text {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}

.promotion_slogan {
  font-weight: bold;
  font-size: 25px;
  text-shadow: 3px 3px 4px #b38d8d;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-size: 100%;
  background-repeat: repeat;
  background-image: linear-gradient(
    45deg,
    #e7eb4f 16.666%,
    #d7d95d 16.666%,
    #adf2fd 33.333%,
    #6bffff 33.333%,
    #a1fb7b 50%,
    #baef81 50%,
    #dbf58b 66.666%,
    #e2f168 66.666%,
    #e2e544 83.333%,
    #fbff0a 83.333%
  );
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
  cursor: default;
}

.promotion_slogan {
  font-weight: bold;
  font-size: 25px;
  text-shadow: 3px 3px 4px #b38d8d;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-size: 100%;
  background-repeat: repeat;
  background-image: linear-gradient(
    45deg,
    #e7eb4f 16.666%,
    #d7d95d 16.666%,
    #adf2fd 33.333%,
    #6bffff 33.333%,
    #a1fb7b 50%,
    #baef81 50%,
    #dbf58b 66.666%,
    #e2f168 66.666%,
    #e2e544 83.333%,
    #fbff0a 83.333%
  );
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
  cursor: default;
}

.promotion_title {
  color: whitesmoke;
  margin-bottom: 25px;
  margin-top: 5px;
}
</style>
