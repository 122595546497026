<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="reportUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/workareareport`"
              class="nav-link"
              :class="activeLink == 'workareareport' ? 'active' : ''"
              >{{ $t("leftnav.workingAreaReport") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/budgetreport`"
              class="nav-link"
              :class="activeLink == 'budgetreport' ? 'active' : ''"
              >{{ $t("leftnav.budgetSourceReport") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/appropriationreport`"
              class="nav-link"
              :class="activeLink == 'appropriationreport' ? 'active' : ''"
              >{{ $t("leftnav.appropriationReport") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/projectreport`"
              class="nav-link"
              :class="activeLink == 'projectreport' ? 'active' : ''"
              >{{ $t("leftnav.planReport") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/incomexpense`"
              class="nav-link"
              :class="activeLink == 'incomexpense' ? 'active' : ''"
              >{{ $t("leftnav.incomexpense") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/bank`"
              class="nav-link"
              :class="activeLink == 'bank' ? 'active' : ''"
              >{{ $t("report.bank") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/expenses`"
              class="nav-link"
              :class="activeLink == 'expenses' ? 'active' : ''"
              >{{ $t("report.kharcha") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/report/expensesdetail`"
              class="nav-link"
              :class="activeLink == 'expensesdetail' ? 'active' : ''"
              >{{ $t("report.expensesDetail") }}</router-link
            >
          </li>
          <li class="nav-item dropdown" v-if="loggedInUser.type == '2'">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'branch' ? 'active' : ''"
              role="button"
              @click.prevent="toggleSetupDropdown"
              href="#"
              >{{ $t("report.branchReport") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/workarea`"
                  class="dropdown-item"
                  >{{ $t("leftnav.workingAreaReport") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/budget`"
                  class="dropdown-item"
                  >{{ $t("leftnav.budgetSourceReport") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/appropriation`"
                  class="dropdown-item"
                  >{{ $t("leftnav.appropriationReport") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/project`"
                  class="dropdown-item"
                  >{{ $t("leftnav.planReport") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/incomexpense`"
                  class="dropdown-item"
                  >{{ $t("leftnav.incomexpense") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/bank`"
                  class="dropdown-item"
                  >{{ $t("report.bank") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/report/branch/expenses`"
                  class="dropdown-item"
                  >{{ $t("report.kharcha") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  watch: {
    $route(to) {
      this.$store.state.activeLink = to.path.split("/")[4];
      this.activeLink = to.path.split("/")[4];
      this.close();
    },
  },
  methods: {
    close() {
      console.log("I am triggered");
      //   this.$refs.setupUl.children.forEach((item, index) => {
      //     item.childNodes[0].classList.remove("show");
      //     if (index > 0) {
      //       item.childNodes[1].classList.remove("show");
      //     }
      //   });
      //   this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      // var currentTab = e.target.parentNode;
      // this.$refs.reportUl.children.forEach((item, index) => {
      //   if (!item.contains(currentTab)) {
      //     item.childNodes[0].classList.remove("show");
      //     if (index > 0) {
      //       item.childNodes[1].classList.remove("show");
      //     }
      //   }
      // });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
