<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("task.task") }}
        <small>{{ $t("task.updateNote") }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :task="task" :type="2" :autoSuppliedFlag="2" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../task/form.vue";
import moment from "moment";

export default {
  data() {
    return {
      id: this.$route.params.id,
      task: {},
    };
  },
  created() {
    var taskItem = this.$store.state.taskList.filter((item) => {
      return item.id==this.$route.params.id;
    })[0];
    this.task.task_name = taskItem.title;
    this.task.description = taskItem.description;
    this.task.date_of_start = moment(taskItem.start).format("yyyy-MM-DDThh:mm");
    this.task.date_of_end = moment(taskItem.end).format("yyyy-MM-DDThh:mm");
    this.task.task_id = taskItem.task_id;
    this.task.staff_id = taskItem.staff_id;
    this.task.id = this.$route.params.id;
  },
  components: {
    Form,
  },
};
</script>
