<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.fiscal") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.fiscal_year_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.expensesdetermination.fiscal_year_id.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.fiscal_year_id.$error,
              }"
            >
              <option
                v-for="(fiscal, index) in fiscalyears"
                :key="index"
                :value="fiscal.id"
              >
                {{ fiscal.fiscal_year }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("kharcha.fiscalError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("voucher.expensesType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.type.$error,
            }"
          >
            <select
              class="form-select"
              @change="getExpensesHeading"
              v-model="v$.expensesdetermination.type.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.type.$error,
              }"
            >
              <option value="1">
                {{ $t("voucher.current") }}
              </option>
              <option value="2">
                {{ $t("voucher.capital") }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("voucher.expensesTypeError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.ehead") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.expenses_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              @change="loadExpensesMeta"
              v-model="v$.expensesdetermination.expenses_id.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.expenses_id.$error,
              }"
            >
              <option
                v-for="(expense, index) in expenses"
                :key="index"
                :value="expense.id"
              >
                {{ expense.headings }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("kharcha.eheadError") }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ "Expenses Sub Headings" }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.expenses_meta_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.expensesdetermination.expenses_meta_id.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.expenses_meta_id.$error,
              }"
            >
              <option
                v-for="(meta, index) in expensesMetaList"
                :key="index"
                :value="meta.id"
              >
                {{ meta.headings }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("kharcha.eheadError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.project") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.task_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              :disabled="expensesdetermination.type != 2"
              class="form-select"
              v-model="v$.expensesdetermination.task_id.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.task_id.$error,
              }"
              @change="changeProject"
            >
              <option
                v-for="(project, index) in projects"
                :key="index"
                :value="project.id"
              >
                {{ project.task_title }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("kharcha.projectError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.task") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.expensesdetermination.task_meta_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              :disabled="expensesdetermination.type != 2"
              class="form-select"
              v-model="v$.expensesdetermination.task_meta_id.$model"
              :class="{
                'is-invalid': v$.expensesdetermination.task_meta_id.$error,
              }"
            >
              <option
                v-for="(task, index) in tasks"
                :key="index"
                :value="task.id"
              >
                {{ task.task_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("kharcha.taskError") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.qty") }}</label>
          <input
            @keyup="findTotal"
            v-model="v$.expensesdetermination.qty.$model"
            :class="{
              'is-invalid': v$.expensesdetermination.qty.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("kharcha.qtyError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ "Unit" }}</label>
          <input
            @keyup="findTotal"
            v-model="v$.expensesdetermination.unit.$model"
            :class="{
              'is-invalid': v$.expensesdetermination.unit.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ "Unit cannot be blank." }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.rate") }}</label>
          <input
            @keyup="findTotal"
            v-model="v$.expensesdetermination.rate.$model"
            :class="{
              'is-invalid': v$.expensesdetermination.rate.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("kharcha.rateError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("kharcha.amount") }}</label>
          <input
            readonly
            v-model="v$.expensesdetermination.amount.$model"
            :class="{
              'is-invalid': v$.expensesdetermination.amount.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("kharcha.amountError") }}</div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="3">{{ $t("kharcha.budget") }}</th>
          </tr>
          <tr>
            <th>{{ $t("kharcha.source") }}</th>
            <th>{{ $t("kharcha.amount") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(source, index) in expensesdetermination.source"
            :key="index"
          >
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.expensesdetermination.source.$each.$response.$errors[
                        index
                      ].source_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    @change="displayRevenue(index)"
                    v-else
                    class="form-select"
                    v-model="source.source_id"
                  >
                    <option
                      v-for="(source, index) in sources"
                      :key="index"
                      :value="source.id"
                    >
                      {{ source.headings }}
                    </option>
                  </select>
                </div>
                <div :id="'revenue' + index"></div>
                <div class="invalid-feedback">
                  {{ $t("kharcha.sourceError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="source.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.expensesdetermination.source.$each.$response.$errors[
                        index
                      ].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("kharcha.amountError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteClone(index)"
                  v-if="index != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!fundExpenseBalance">
          <tr>
            <td colspan="3" class="text-red">
              {{ $t("kharcha.note") }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="form-group">
      <label class="control-label">{{ $t("kharcha.description") }}</label>
      <textarea
        v-model="expensesdetermination.description"
        rows="5"
        type="text"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      expensesdetermination: {
        fiscal_year_id: { required },
        task_id: {
          required: requiredIf((value, item) => {
            return item.type == 2;
          }),
        },
        task_meta_id: {
          required: requiredIf((value, item) => {
            return item.type == 2;
          }),
        },
        expenses_id: { required },
        expenses_meta_id: { required },
        qty: { required },
        unit: { required },
        rate: { required },
        amount: { required },
        type: { required },
        source: {
          $each: helpers.forEach({
            source_id: { required },
            amount: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      isDropDownLoading: true,
      fiscalyears: [],
      projects: [],
      tasks: [],
      expenses: [],
      expensesList: [],
      expensesMetaList: [],
      sources: [],
      isSubmitting: false,
      fundExpenseBalance: true,
    };
  },
  props: {
    expensesdetermination: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getDropDown();
  },
  methods: {
    cloneItem() {
      this.expensesdetermination.source.push({
        source_id: null,
        amount: null,
      });
    },

    deleteClone(index) {
      this.expensesdetermination.source.splice(index, 1);
      this.calculate();
    },

    getExpensesHeading() {
      if (this.expensesdetermination.type) {
        this.expenses = this.expensesList.filter((item) => {
          return item.type == this.expensesdetermination.type;
        });
      }
    },

    displayRevenue(index) {
      if (this.expensesdetermination.source[index].source_id) {
        var amount = this.sources.filter(
          (item) =>
            item.id == this.expensesdetermination.source[index].source_id
        )[0].amount;
        document.getElementById("revenue" + index).innerText =
          this.$t("kharcha.estimatedRevenue") +
          " : " +
          this.$t("rs") +
          " " +
          amount;
      }
    },

    loadExpensesMeta() {
      if (this.expensesdetermination.expenses_id) {
        this.expensesMetaList = this.expensesList.filter(
          (item) => item.id == this.expensesdetermination.expenses_id
        )[0].meta;
      }
    },

    findTotal() {
      var qty = this.expensesdetermination.qty
        ? this.expensesdetermination.qty
        : 0;
      var rate = this.expensesdetermination.rate
        ? this.expensesdetermination.rate
        : 0;
      this.expensesdetermination.amount = qty * rate;
      this.calculate();
    },

    calculate() {
      var totalSourceAmount = 0;
      this.expensesdetermination.source.forEach((item) => {
        totalSourceAmount += parseInt(item.amount);
      });
      var totalExpenses = this.expensesdetermination.amount
        ? this.expensesdetermination.amount
        : 0;
      if (totalSourceAmount == totalExpenses) {
        this.fundExpenseBalance = true;
      } else {
        this.fundExpenseBalance = false;
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/getexpensesdropdown",
          { type: this.type },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            if (!res.data.fiscalFlag)
              this.$router.push(
                "/" + this.$i18n.locale + "/budget/fiscalyear/create"
              );
            this.fiscalyears = res.data.fiscal;
            this.expensesList = res.data.expenses;
            this.projects = res.data.project;
            this.sources = res.data.source;
            if (this.type == 2) {
              this.changeProject();
              this.getExpensesHeading();
              this.loadExpensesMeta();
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeProject() {
      if (this.expensesdetermination.task_id) {
        this.tasks = [];
        this.tasks = this.projects.filter((item) => {
          return item.id.includes(this.expensesdetermination.task_id);
        })[0].tasks;
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.fundExpenseBalance) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.expensesdetermination) {
          formData.set(key, this.expensesdetermination[key]);
        }
        formData.append(
          "sources",
          JSON.stringify(this.expensesdetermination.source)
        );
        var url =
          this.type == 1
            ? "budgetprojectexpenses/create"
            : "budgetprojectexpenses/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Budget expenses source successfully allocated."
                    : "Budget expenses source successfully updated."
                );
                this.$router.push(
                  "/" +
                    this.$i18n.locale +
                    "/dashboard/budget/expensesdetermination/list/1"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
