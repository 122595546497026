<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('attendance.attendance') }}
        <small>{{ $t('attendance.add') }}</small>
      </h2>
    </div>
    <div class="body">
      <form @submit.prevent="submitForm">
        <div class="attendance-div">
          <div class="form-group required" v-if="$i18n.locale == 'np'">
            <label class="control-label">{{ $t('attendance.date') }}</label>
            <VNepaliDatePicker
              @change="supplyDate($event)"
              :class="{
                'is-invalid': v$.attendance.date.$error,
              }"
              classValue="form-control"
              calenderType="Nepali"
              placeholder="YYYY/MM/DD"
              format="YYYY/MM/DD"
              :yearSelect="true"
              :monthSelect="true"
            />
            <div class="invalid-feedback">
              {{ $t('attendance.dateError') }}
            </div>
          </div>
          <div class="form-group required" v-else>
            <label class="control-label">{{ $t('attendance.date') }}</label>
            <input
              v-model="v$.attendance.date.$model"
              :class="{
                'is-invalid': v$.attendance.date.$error,
              }"
              type="date"
              class="form-control"
            />

            <div class="invalid-feedback">
              {{ $t('attendance.dateError') }}
            </div>
          </div>
          <div>
            <label class="control-label">{{ $t('attendance.showStaff') }}</label>
            <select
              class="form-select"
              @change="filterUser"
              v-model="attendance.displayUser"
            >
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="10000">All</option>
            </select>
          </div>
        </div>
        <div class="attendanceCounter">
          <div>{{$t('attendance.total')}} : {{ totalRow }}</div>
          <div>{{$t('attendance.showing')}} : {{ showing }}</div>
          <div>{{$t('attendance.selected')}} : {{ selected }}</div>
        </div>
        <table class="table table-bordered table-stripped">
          <thead>
            <tr>
              <th><input type="checkbox" @click="parentCheckbox" /></th>
              <th>{{$t('attendance.name')}}</th>
              <th>{{$t('attendance.checkIn')}}</th>
              <th>{{$t('attendance.checkOut')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(staff, index) in attendance.staff" :key="index">
              <td>
                <input
                  type="checkbox"
                  v-model="staff.flag"
                  @click="selectStaff(index)"
                />
              </td>
              <td>{{ staff.full_name }}</td>
              <td>
                <div class="form-group required">
                  <input
                    v-model="staff.check_in"
                    type="time"
                    :class="{
                      'is-invalid':
                        v$.attendance.staff.$each.$response.$errors[index]
                          .check_in.length,
                    }"
                  />

                  <div class="invalid-feedback">
                    {{ $t('attendance.checkInError') }}
                  </div>
                </div>
              </td>
              <td><input type="time" v-model="staff.check_out" /></td>
            </tr>
          </tbody>
        </table>
        <div
          :class="'pagination-theme-' + $store.state.skin"
          v-if="Math.ceil(totalRow / perPage) > 1"
        >
          <ul class="pagination justify-content-end">
            <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
              <a
                class="page-link"
                href="#"
                @click.prevent="setPage(currentPage - 1)"
                >{{ $t("prev") }}</a
              >
            </li>
            <li
              class="page-item"
              v-for="j in Math.ceil(totalRow / perPage)"
              :key="j"
              :class="currentPage == j ? 'active' : ''"
            >
              <a class="page-link" href="#" @click.prevent="setPage(j)">{{
                j
              }}</a>
            </li>
            <li
              class="page-item"
              :class="
                currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
              "
            >
              <a
                class="page-link"
                href="#"
                @click.prevent="setPage(currentPage + 1)"
                >{{ $t("next") }}</a
              >
            </li>
          </ul>
        </div>
        <button
          type="submit"
          class="btn btn-danger btn-block submitButton"
          name="login-button"
          v-if="!isSubmitting"
        >
          {{$t('attendance.takeAttendance')}}
        </button>
        <button
          class="btn btn-danger btn-block submitButton loading"
          disabled
          v-else
        >
          <div class="spinner-border text-warning"></div>
          <div>{{ $t("loading") }}</div>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";
import moment from "moment";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      attendance: {
        date: { required },
        staff: {
          $each: helpers.forEach({
            check_in: {
              required: requiredIf((value, staff) => staff.flag),
            },
            staff_id: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      isLoading: true,
      staffList: [],
      searchQuery: null,
      currentPage: 1,
      perPage: 20,
      totalRow: 0,
      showing: 0,
      selected: 0,
      isSubmitting: false,
      attendance: {
        date: moment(new Date()).format("YYYY-MM-DD"),
        displayUser: 20,
        staff: [],
      },
    };
  },
  created() {
    this.$emit('close');
    this.getData(null);
  },
  methods: {
    supplyDate(e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      this.attendance.date = formatedDate;
    },

    filterUser() {
      this.staffList = [];
      this.attendance.staff = [];
      this.perPage = this.attendance.displayUser;
      this.getData(null);
    },

    async getData(query) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
      };
      await axios
        .post("staff/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            res.data.list.forEach((item) => {
              this.attendance.staff.push({
                staff_id: item.id,
                full_name: item.full_name,
                check_in: null,
                check_out: null,
                flag: false,
              });
            });
            this.staffList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.showing =
              this.totalRow < this.perPage ? this.totalRow : this.perPage;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    parentCheckbox() {
      this.attendance.staff.forEach((item, index) => {
        this.selectStaff(index);
      });
    },

    selectStaff(index) {
      this.attendance.staff[index].flag = !this.attendance.staff[index].flag;
      if (this.attendance.staff[index].flag) {
        this.selected += 1;
      } else {
        this.selected -= 1;
      }
    },

    setPage(j) {
      this.staffList = [];
      this.attendance.staff = [];
      this.currentPage = j;
      this.getData(this.searchQuery);
    },

    changePage(data) {
      this.staffList = [];
      this.attendance.staff = [];
      this.currentPage = data.page;
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.attendance) {
          formData.set(key, this.attendance[key]);
        }
        formData.append("staff", JSON.stringify(this.attendance.staff));
        // var url = this.type == 1 ? "attenance/take" : "attendance/update";
        await axios
          .post("attendance/take", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s("Attendance successfully taken.");
                this.$router.push(
                  "/" +
                    this.$i18n.locale +
                    "/dashboard/payroll/attendance/report"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
