<template>
  <div class="login-body">
    <div class="container">
      <div class="login_container">
        <div class="content-container">
          <div class="login_form">
            <h2 class="text-center">{{ $t("login").login }}</h2>

            <div class="row">
              <div class="col-lg-12">
                <form
                  @submit.prevent="submitForm"
                  :class="'form-theme-' + $store.state.skin"
                >
                  <div class="form-group">
                    <label for="loginform-username">{{
                      $t("login").email
                    }}</label>
                    <input
                      type="text"
                      id="loginform-username"
                      class="form-control"
                      autofocus="true"
                      placeholder="abc@example.com"
                      v-model="v$.organization.email.$model"
                      :class="{
                        'is-invalid': v$.organization.email.$error,
                      }"
                    />

                    <div class="invalid-feedback">
                      {{ $t("login").emailError }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="loginform-password">{{
                      $t("login").password
                    }}</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="v$.organization.password.$model"
                      :class="{
                        'is-invalid': v$.organization.password.$error,
                      }"
                    />

                    <div class="invalid-feedback">
                      {{ $t("login").passwordError }}
                    </div>
                  </div>
                  <div class="form-group field-loginform-rememberme">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="organization.rememberMe"
                        class="custom-control-input"
                        name="LoginForm[rememberMe]"
                        checked=""
                      />
                      <label
                        class="custom-control-label"
                        for="loginform-rememberme"
                        >{{ $t("login").remember }}</label
                      >
                      <div class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div style="color: #999; margin: 1em 0">
                    {{ $t("login").forget }}
                    <router-link :to="`/${$i18n.locale}/finduser/1`">{{
                      $t("login").reset
                    }}</router-link>
                    <br />
                    {{ $t("login").newpin }}
                    <router-link :to="`/${$i18n.locale}/finduser/2`">{{
                      $t("login").resend
                    }}</router-link>
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-danger btn-block submitButton"
                      name="login-button"
                      v-if="!isSubmitting"
                    >
                      {{ $t("login").login }}
                    </button>
                    <button
                      class="btn btn-danger btn-block submitButton loading"
                      disabled
                      v-else
                    >
                      <div class="spinner-border text-warning"></div>
                      <div>{{ $t("loading") }}</div>
                    </button>
                  </div>
                </form>
                <div class="text-center">
                  <router-link
                    :to="`/${$i18n.locale}/signup`"
                    class="login_signup_link"
                    >{{ $t("login").register }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="login_slider">
            <div
              id="work-space-carousel"
              class="carousel slide loginCarousel"
              data-ride="carousel"
              v-on:mouseover="autoSlideFlag = false"
              v-on:mouseleave="autoSlideFlag = true"
            >
              <ul class="carousel-indicators" id="carousel-indicators">
                <li
                  data-target="#work-space-carousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#work-space-carousel"
                  data-slide-to="1"
                  class=""
                ></li>
                <li
                  data-target="#work-space-carousel"
                  class=""
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#work-space-carousel"
                  class=""
                  data-slide-to="3"
                ></li>
              </ul>

              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../assets/report2.jpg" alt="Graphical Chart" />
                </div>
                <div class="carousel-item">
                  <img src="../../assets/report1.jpg" alt="Chicago" />
                </div>
                <div class="carousel-item">
                  <img src="../../assets/report0.jpg" alt="New York" />
                </div>
                <div class="carousel-item">
                  <img src="../../assets/report3.jpg" alt="New York" />
                </div>
              </div>
              <a
                @click="slideSlider(-1)"
                class="carousel-control-prev"
                data-slide="prev"
              >
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a
                @click="slideSlider(1)"
                class="carousel-control-next"
                data-slide="next"
              >
                <span class="carousel-control-next-icon"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      organization: {
        email: null,
        password: null,
        rememberMe: true,
      },
      autoSlideFlag: true,
    };
  },
  validations() {
    return {
      organization: {
        email: { required },
        password: { required },
      },
    };
  },
  mounted() {
    this.sliderInterval = window.setInterval(() => {
      if (this.autoSlideFlag) {
        this.autoSlide();
      }
    }, 5000);
  },
  destroyed() {
    clearInterval(this.sliderInterval);
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.organization) {
          formData.set(key, this.organization[key]);
        }
        formData.set("rememberMe", this.organization.rememberMe ? 1 : 0);
        await axios
          .post("organization/login", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              localStorage.setItem(
                "accessToken",
                res.data.userinfo.accessToken
              );
              localStorage.setItem(
                "loggedInInfo",
                JSON.stringify(res.data.userinfo)
              );
              this.$router.push("/" + this.$i18n.locale + "/dashboard/home");
            } else {
              this.$toastr.e("Username or password do not match.");
            }
            this.isSubmitting = false;
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },

    autoSlide() {
      var sliderLength;
      var controller;
      document
        .getElementById("work-space-carousel")
        .childNodes[1].childNodes.forEach((value, index) => {
          if (value.classList.contains("active")) {
            controller = index;
            value.classList.remove("active");
            document
              .getElementById("carousel-indicators")
              .childNodes[index].classList.remove("active");
            controller += 1;
          }
          sliderLength = index;
        });
      controller = controller > sliderLength ? 0 : controller;
      document
        .getElementById("work-space-carousel")
        .childNodes[1].childNodes[controller].classList.add("active");
      document
        .getElementById("carousel-indicators")
        .childNodes[controller].classList.add("active");
    },

    slideSlider(controller) {
      var sliderLength;
      document
        .getElementById("work-space-carousel")
        .childNodes[1].childNodes.forEach((value, index) => {
          if (value.classList.contains("active")) {
            value.classList.remove("active");
            document
              .getElementById("carousel-indicators")
              .childNodes[index].classList.remove("active");
            controller += index;
          }
          sliderLength = index;
        });
      controller = controller < 0 ? 0 : controller;
      controller = controller > sliderLength ? sliderLength : controller;
      document
        .getElementById("work-space-carousel")
        .childNodes[1].childNodes[controller].classList.add("active");
      document
        .getElementById("carousel-indicators")
        .childNodes[controller].classList.add("active");
    },
  },
};
</script>
<style>
.login-body {
  background-image: linear-gradient(to bottom, #ffb88c, #de6262);
}

.login_container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.login_container .content-container {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  box-shadow: 15px 20px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  display: flex;
}

.login_container .content-container .login_form {
  padding: 2%;
  flex: 1;
}

.login_container .content-container .login_slider {
  flex: 2;
}

.login_container .content-container .login_form > h2 {
  margin-bottom: 15px;
  font-weight: 800;
  font-size: 30px;
  color: #de6262;
}

.login_container .content-container .login_form > h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #feb58a;
  display: block;
  margin-top: 6px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center !important;
}

.loginCarousel {
  height: 100%;
}

.loginCarousel .carousel-inner {
  height: 100%;
}

.loginCarousel .carousel-inner .carousel-item {
  height: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  cursor: pointer;
}

.login_slider .carousel-inner img {
  width: 100%;
  height: 100%;
  border-radius: 0px 15px 15px 0px;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (min-width: 768px) {
  .login_slider {
    display: block;
  }
}

@media (max-width: 767px) {
  .login_slider {
    display: none;
  }

  .login_container .signup-content-container {
    position: initial !important;
    transform: translateY(0%) !important;
  }

  .login_container .signup-content-container .signup-contents {
    flex-direction: column;
  }
}
</style>
