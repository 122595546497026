<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t("bank.head")}}
            <small>{{$t("bank.addNote")}}</small>
          </h2>
          <div>
              <router-link :to="`/${$i18n.locale}/dashboard/bail/bank/list`" class="btn btn-info btn-sm">{{$t('bail.bankList')}}</router-link>
          </div>
        </div>
        <div class="body">
          <div class="source-heading-form">
              <Form :bank="bank" :type="1" :bankType="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "../../appropriation/bank/form.vue";

export default {
  data() {
    return {
      bank: {
        bank_name: null,
        branch: null,
        account_no: null,
        account_name: null,
        phone: null,
        email: null,
        account_type: null,
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
