<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('staffbank.staffBank') }}
        <small>{{ $t('staffbank.updateNote') }}</small>
      </h2>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :bank="bank" :type="2" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      id: this.$route.params.id,
      bank: this.$store.state.staffBankList.filter((item) => {
        return item.id==this.$route.params.id;
      })[0],
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
