var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.fiscal")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.receipt.fiscal_year_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.fiscal_year_id.$model),expression:"v$.receipt.fiscal_year_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.receipt.fiscal_year_id.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.receipt.fiscal_year_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.fiscalyears),function(fiscal,a){return _c('option',{key:a,domProps:{"value":fiscal.id}},[_vm._v(" "+_vm._s(fiscal.fiscal_year)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.fiscalError")))])])]),_c('div',{staticClass:"col-md-3"},[(_vm.$i18n.locale == 'np')?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.date")))]),_c('VNepaliDatePicker',{class:{
            'is-invalid': _vm.v$.receipt.date.$error,
          },attrs:{"classValue":"form-control","calenderType":"Nepali","placeholder":"YYYY/MM/DD","format":"YYYY/MM/DD","value":_vm.type == 2 ? _vm.date : '',"yearSelect":true,"monthSelect":true},on:{"change":function($event){return _vm.supplyValue(1, $event)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.dateError")))])],1):_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.date")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.date.$model),expression:"v$.receipt.date.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.receipt.date.$error,
          },attrs:{"type":"date"},domProps:{"value":(_vm.v$.receipt.date.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.receipt.date, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.dateError")))])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.refNo")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.reference_no.$model),expression:"v$.receipt.reference_no.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.receipt.reference_no.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.v$.receipt.reference_no.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.receipt.reference_no, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.refNoError")))])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.mode")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.receipt.payment_mode.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.payment_mode.$model),expression:"v$.receipt.payment_mode.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.receipt.payment_mode.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.receipt.payment_mode, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("receipt.cheque")))]),_c('option',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t("receipt.cash")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.modeError")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("voucher.expensesType")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.receipt.expenses_type.$error,
          }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.expenses_type.$model),expression:"v$.receipt.expenses_type.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.receipt.expenses_type.$error,
            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.receipt.expenses_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.getExpenses]}},[_c('option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("voucher.current")))]),_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("voucher.capital")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("voucher.expensesTypeError"))+" ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.expenses")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.receipt.expenses_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.expenses_id.$model),expression:"v$.receipt.expenses_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.receipt.expenses_id.$error,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.receipt.expenses_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.expenses),function(exp,c){return _c('option',{key:c,domProps:{"value":exp.id}},[_vm._v(" "+_vm._s(exp.headings)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("receipt.expensesError"))+" ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.project")))]),_c('div',{staticClass:"input-group",class:{
            'is-invalid': _vm.v$.receipt.task_id.$error,
          }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.task_id.$model),expression:"v$.receipt.task_id.$model"}],staticClass:"form-select",class:{
              'is-invalid': _vm.v$.receipt.task_id.$error,
            },attrs:{"disabled":_vm.receipt.expenses_type == 0},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.receipt.task_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.projects),function(project,b){return _c('option',{key:b,domProps:{"value":project.id}},[_vm._v(" "+_vm._s(project.task_title)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.projectError")))])])])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered voucherTable"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("receipt.type")))]),_c('th',[_vm._v(_vm._s(_vm.$t("voucher.type")))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("receipt.particular")))]),_c('th',[_vm._v(_vm._s(_vm.$t("receipt.amount")))]),_c('th',[_vm._v(_vm._s(_vm.$t("actions")))])])]),_c('tbody',_vm._l((_vm.receipt.dr),function(bank,c){return _c('tr',{key:'dr' + c},[_c('td',[_vm._v(_vm._s(_vm.$t("receipt.dr")))]),_c('td',[_c('div',{staticClass:"form-group clone-form-group required"},[_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(bank.type),expression:"bank.type"}],staticClass:"form-select",class:{
                    'is-invalid':
                      _vm.v$.receipt.dr.$each.$response.$errors[c].type.length,
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(bank, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("voucher.bank")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("voucher.typeError"))+" ")])])]),_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"form-group clone-form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.receipt.dr.$each.$response.$errors[c].dr_id.length,
                }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(bank.dr_id),expression:"bank.dr_id"}],staticClass:"form-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(bank, "dr_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.banks),function(bnk,d){return _c('option',{key:d,domProps:{"value":bnk.id}},[_vm._v(" "+_vm._s(bnk.bank_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("receipt.bankError"))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group clone-form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(bank.amount),expression:"bank.amount"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.receipt.dr.$each.$response.$errors[c].amount.length,
                },attrs:{"type":"number"},domProps:{"value":(bank.amount)},on:{"keyup":_vm.calculate,"input":function($event){if($event.target.composing){ return; }_vm.$set(bank, "amount", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("receipt.amountError"))+" ")])])]),_c('td',[_c('div',{staticClass:"voucher-dynamic-form-button-container"},[_c('div',{staticClass:"btn btn-success btn-xs",on:{"click":_vm.cloneDrItem}},[_vm._v("+")]),(c != 0)?_c('div',{staticClass:"btn btn-warning btn-xs",on:{"click":function($event){return _vm.deleteDrClone(c)}}},[_vm._v(" - ")]):_vm._e()])])])}),0),_c('tbody',_vm._l((_vm.receipt.cr),function(source,e){return _c('tr',{key:e},[_c('td',[_vm._v(_vm._s(_vm.$t("receipt.cr")))]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.receipt.cr.$each.$response.$errors[e].type.length,
                }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(source.type),expression:"source.type"}],staticClass:"form-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(source, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t("voucher.release")))]),_c('option',{attrs:{"value":"3"}},[_vm._v(_vm._s(_vm.$t("voucher.incomeReturn")))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("voucher.typeError"))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.receipt.cr.$each.$response.$errors[e].cr_id.length,
                }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(source.cr_id),expression:"source.cr_id"}],staticClass:"form-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(source, "cr_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.updateMethod(e)}]}},_vm._l((_vm.sources),function(src,f){return _c('option',{key:f,domProps:{"value":src.id}},[_vm._v(" "+_vm._s(src.headings)+" ")])}),0)]),_c('div',{staticClass:"font-red font-small",attrs:{"id":'receipt-dr-info-' + e}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("receipt.sourceError"))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"input-group",class:{
                  'is-invalid':
                    _vm.v$.receipt.cr.$each.$response.$errors[e].source_method_id
                      .length,
                }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(source.source_method_id),expression:"source.source_method_id"}],staticClass:"form-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(source, "source_method_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.methods[e]),function(mthd,f){return _c('option',{key:'Cr' + f,domProps:{"value":mthd.id}},[_vm._v(" "+_vm._s(mthd.headings)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("voucher.sourceAndMethodError"))+" ")])])]),_c('td',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(source.amount),expression:"source.amount"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.v$.receipt.cr.$each.$response.$errors[e].amount.length,
                },attrs:{"type":"number"},domProps:{"value":(source.amount)},on:{"keyup":_vm.calculate,"input":function($event){if($event.target.composing){ return; }_vm.$set(source, "amount", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("receipt.amountError"))+" ")])])]),_c('td',[_c('div',{staticClass:"voucher-dynamic-form-button-container"},[_c('div',{staticClass:"btn btn-success btn-xs",on:{"click":_vm.cloneCrItem}},[_vm._v("+")]),(e != 0)?_c('div',{staticClass:"btn btn-warning btn-xs",on:{"click":function($event){return _vm.deleteCrClone(e)}}},[_vm._v(" - ")]):_vm._e()])])])}),0),(!_vm.tallyFlag)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-red",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t("receipt.drcrbalance"))+" ")])])]):_vm._e()])]),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("receipt.narration")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.receipt.narration.$model),expression:"v$.receipt.narration.$model"}],staticClass:"form-control",class:{
        'is-invalid': _vm.v$.receipt.narration.$error,
      },attrs:{"rows":"5","type":"text"},domProps:{"value":(_vm.v$.receipt.narration.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.receipt.narration, "$model", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("receipt.narrationError")))])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("add") : _vm.$t("update"))+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }