<template>
  <div>
    <div class="alert alert-warning" v-if="loggedInUser.trial_status==1 || loggedInUser.trial_status==2">
      <strong>INFO!</strong> {{loggedInUser.trial_status==1?('Your trial version expires in '+remainingDays+' days.'):('Your trial version has expired.')}} Contact
      <a
        href="https://www.kasturisanjaal.com"
        target="_blank"
        class="alert-link"
        >Kasturi Sanjaal Pvt. Ltd.</a
      >
      or dial : +977-9855052636.
    </div>
    <div class="dashboard-header">
      <div class="branding">
        <div class="logo">
          <img
            :src="$hostname + loggedInUser.logo"
            :alt="loggedInUser.organization_name"
          />
        </div>
        <div class="content">
          <div class="name">{{ loggedInUser.organization_name }}</div>
          <div class="address">{{ loggedInUser.organization_address }}</div>
          <div class="email">{{ loggedInUser.email }}</div>
          <div class="phone">{{ loggedInUser.phone1 }}</div>
        </div>
      </div>
      <div class="icon-container">Fiscal Year {{ fiscal.fiscal_year }}</div>
    </div>
    <div class="dashboard-body">
      <div class="downward-animation">
        <span class="material-icons">keyboard_arrow_down</span>
        <span class="material-icons">keyboard_arrow_down</span>
      </div>
      <div class="summary-links">
        <div class="row">
          <div class="col-md-4">
            <a href="#">
              <div class="info-box bg-pink hover-expand-effect">
                <div class="icon">
                  <i class="material-icons">playlist_add_check</i>
                </div>
                <div class="content">
                  <div class="text">{{ $t("dashboard.totalProject") }}</div>
                  <div class="number count-to">{{ data.totalProject }}</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="#">
              <div class="info-box bg-orange hover-expand-effect">
                <div class="icon">
                  <i class="material-icons">playlist_add_check</i>
                </div>
                <div class="content">
                  <div class="text">{{ $t("dashboard.pendingProject") }}</div>
                  <div class="number count-to">{{ data.pendingProject }}</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="#">
              <div class="info-box bg-light-green hover-expand-effect">
                <div class="icon">
                  <i class="material-icons">playlist_add_check</i>
                </div>
                <div class="content">
                  <div class="text">
                    {{ $t("dashboard.completedProject") }}
                  </div>
                  <div class="number count-to">
                    {{ data.completedProject }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-graph">
      <div class="card">
        <div class="card-header">{{ $t("report.workingarea") }}</div>
        <div class="card-body" v-if="workAreaSeries.length > 0">
          <apexcharts
            v-if="!isLoading"
            width="100%"
            type="pie"
            :options="workAreaOptions"
            :series="workAreaSeries"
          ></apexcharts>
        </div>
        <div class="card-body" v-else>
          <div class="no-record">{{ $t("norecord") }}</div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">{{ $t("report.budget") }}</div>
        <div class="card-body" v-if="budgetSeries.length > 0">
          <apexcharts
            v-if="!isLoading"
            width="100%"
            type="pie"
            :options="budgetOptions"
            :series="budgetSeries"
          ></apexcharts>
        </div>
        <div class="card-body" v-else>
          <div class="no-record">{{ $t("norecord") }}</div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">{{ $t("report.appropriation") }}</div>
        <div class="card-body" v-if="appropriationSeries.length > 0">
          <apexcharts
            v-if="!isLoading"
            width="100%"
            type="pie"
            :options="appropriationOptions"
            :series="appropriationSeries"
          ></apexcharts>
        </div>
        <div class="card-body" v-else>
          <div class="no-record">{{ $t("norecord") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      workAreaSeries: [],
      workAreaOptions: null,
      budgetSeries: [],
      budgetOptions: null,
      appropriationSeries: [],
      appropriationOptions: null,
      fiscal: {},
      data: {
        totalProject: 0,
        completedProject: 0,
        pendingProject: 0,
      },
      remainingDays: 0,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
    this.getRemainingDays();
    this.getData();
  },
  methods: {
    getRemainingDays() {
      const date1 = new Date(this.loggedInUser.date);
      var today = new Date();
      const date2 = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
      const diffTime = Math.abs(date2 - date1);
      const diffDays = 15-Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.remainingDays = diffDays>0?diffDays:-1;
    },

    getData() {
      axios
        .post(
          "report/dashboard",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            if (!res.data.fiscalFlag) {
              this.$router.push(
                "/" + this.$i18n.locale + "/dashboard/budget/fiscal/create"
              );
            }
            this.workAreaSeries = res.data.workarea.series;
            this.workAreaOptions = {
              plotOptions: {
                pie: {
                  customScale: 0.8,
                  expandOnClick: true,
                },
              },
              labels: res.data.workarea.label,
            };
            this.budgetSeries = res.data.budget.series;
            this.budgetOptions = {
              plotOptions: {
                pie: {
                  customScale: 0.8,
                  expandOnClick: true,
                },
              },
              labels: res.data.budget.label,
            };
            this.appropriationSeries = res.data.appropriation.series;
            this.appropriationOptions = {
              plotOptions: {
                pie: {
                  customScale: 0.8,
                  expandOnClick: true,
                },
              },
              labels: res.data.appropriation.label,
            };
            this.data.totalProject = res.data.totalProject;
            this.data.completedProject = res.data.completedProject;
            this.data.pendingProject = res.data.pendingProject;
            this.fiscal = res.data.fiscal;
            this.isLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
