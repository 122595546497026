<template>
  <div class="table-container">
    <div class="table-responsive">
      <table id="gridView" class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th v-for="i in heading.length" :key="i">{{ heading[i - 1] }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ item.fiscal_year }}</td>
            <td>
              {{ item.type == "1" ? $t("income.current") : $t("income.asset") }}
            </td>
            <td>
                {{ item.eMetaHeading }}
                <div class="small">{{'( '+item.headings+' )'}}</div>
            </td>
            <td>
              {{ item.type==1?'-':(item.task_name) }}
              <div class="small" v-if="item.type==2">{{item.task_title}}</div>
            </td>
            <td>{{ $t('rs')+item.amount }}</td>
            <td>
              <button
                class="btn btn-info btn-sm actionButton"
                @click="view(index)"
              >
                {{ $t("view") }}
              </button>
              <button
                v-if="item.approve_status == 0 && type == 2"
                class="btn btn-success btn-sm actionButton"
                @click="approveBudget(item.id, index)"
              >
                {{ $t("kharcha.approve") }}
              </button>
              <router-link
                :to="
                  '/' +
                  $i18n.locale +
                  '/dashboard/budget/expensesdetermination/update/' +
                  item.id
                "
                class="btn btn-warning btn-sm actionButton"
                v-if="type == 1 && item.approve_status == 0"
                >{{ $t("update") }}</router-link
              >
              <button
                v-if="type == 1 && item.approve_status == 0"
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm actionButton"
              >
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <span class="no-record">{{ $t("norecord") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="'pagination-theme-' + $store.state.skin"
        v-if="Math.ceil(totalRow / perPage) > 1"
      >
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="currentPage == 1 ? 'disabled' : ''">
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage - 1)"
              >{{ $t("prev") }}</a
            >
          </li>
          <li
            class="page-item"
            v-for="j in Math.ceil(totalRow / perPage)"
            :key="j"
            :class="currentPage == j ? 'active' : ''"
          >
            <a class="page-link" href="#" @click.prevent="setPage(j)">{{
              j
            }}</a>
          </li>
          <li
            class="page-item"
            :class="
              currentPage == Math.ceil(totalRow / perPage) ? 'disabled' : ''
            "
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="setPage(currentPage + 1)"
              >{{ $t("next") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <Detailview
      v-if="modalFlag"
      :modalFlag="modalFlag"
      :data="modalData"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import Detailview from "./view.vue";
export default {
  components: { Detailview },
  data() {
    return {
      modalFlag: false,
      modalData: null,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    heading: {
      type: Array,
      required: true,
    },
    totalRow: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  methods: {
    setPage(j) {
      this.$emit("changePage", { page: j, query: null });
    },

    deleteItem(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("deleteItem", { id: id, index: index });
      }
    },

    approveBudget(id, index) {
      if (confirm("Are you sure?")) {
        this.$emit("approveBudget", { id: id, index: index });
      }
    },

    view(index) {
      this.modalData = this.$store.state.projectExpensesList[index];
      this.modalFlag = true;
    },

    closeModal() {
      this.modalFlag = false;
    },
  },
};
</script>
