<template>
  <div class="modal show fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="'bg-' + $store.state.skin">
          <h4 class="modal-title">{{$t('bank.cheques')}}</h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <Form :bankId="parseInt(data.id)" @addChequeList="addChequeList" />
          <br />
          <Chequelist
            :bankId="parseInt(data.id)"
            :chequeList="chequeList"
            @addChequeList="addChequeList"
            @resetCheque="resetCheque"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chequelist from "./list.vue";
import Form from "./form.vue";

export default {
  components: { Chequelist, Form },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chequeList: [],
    };
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },

    addChequeList(data) {
      data.list.forEach((item) => {
        if (data.type == 1) {
          this.chequeList.push(item);
          this.$store.state.chequeList.push(item);
        } else {
          this.chequeList.unshift(item);
          this.$store.state.chequeList.unshift(item);
        }
      });
    },

    resetCheque() {
      this.chequeList = [];
      this.$store.state.chequeList = [];
    },
  },
};
</script>
<style>
.modalSpinner {
  text-align: center;
}
</style>
