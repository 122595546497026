<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t("project.dpr") }}
        <small>{{ $t("project.dpr") }}</small>
      </h2>
    </div>
    <div class="body">
      <Dprform :id="parseInt(id)" @updateList="updateList" />
      <hr />
      <div class="card imageCard">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>{{ $t("dpr.dpr") }}</h2>
        </div>
        <div class="body">
          <table class="table table-striped table-bordered">
            <thead>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("dpr.date") }}</th>
              <th>{{ $t("dpr.head") }}</th>
              <th>{{ $t("action") }}</th>
            </thead>
            <tbody v-if="dprList.length > 0">
              <tr v-for="(dpr, index) in dprList" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ convertedDate(dpr.date) }}</td>
                <td>{{ dpr.dpr_title }}</td>
                <td>
                  <router-link
                    :to="'/' + $i18n.locale + '/dpr/view/' + dpr.id"
                    class="btn btn-info btn-sm"
                    target="_blank"
                    >{{ $t("view") }}</router-link
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="no-record">
                  {{ $t("norecord") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dprform from "./form.vue";
import axios from "axios";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    Dprform,
  },
  data() {
    return {
      id: this.$route.params.id,
      visible: false,
      currentIndex: null,
      dprList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    convertedDate(date) {
      if (this.$i18n.locale == "np") {
        var converter = adbs.ad2bs(moment(date).format("YYYY/MM/DD")).ne;
        return converter.year + "-" + converter.month + "-" + converter.day;
      } else return date;
    },

    async getData() {
      await axios
        .post(
          "project/getdpr",
          { id: this.id },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.dprList = res.data.list;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateList(data) {
      this.dprList.push(data);
    },
  },
};
</script>
