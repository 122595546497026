<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.fiscal") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payment.fiscal_year_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.payment.fiscal_year_id.$model"
              :class="{
                'is-invalid': v$.payment.fiscal_year_id.$error,
              }"
            >
              <option
                v-for="(fiscal, a) in fiscalyears"
                :key="a"
                :value="fiscal.id"
              >
                {{ fiscal.fiscal_year }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.fiscalError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t("receipt.date") }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, $event)"
            :class="{
              'is-invalid': v$.payment.date.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="type == 2 ? date : ''"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("receipt.dateError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("receipt.date") }}</label>
          <input
            v-model="v$.payment.date.$model"
            type="date"
            class="form-control"
            :class="{
              'is-invalid': v$.payment.date.$error,
            }"
          />

          <div class="invalid-feedback">{{ $t("receipt.dateError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.refNo") }}</label>
          <input
            v-model="v$.payment.reference_no.$model"
            type="number"
            class="form-control"
            :class="{
              'is-invalid': v$.payment.reference_no.$error,
            }"
          />

          <div class="invalid-feedback">{{ $t("receipt.refNoError") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("voucher.expensesType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payment.expenses_type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.payment.expenses_type.$model"
              @change="changeExpensesType"
              :class="{
                'is-invalid': v$.payment.expenses_type.$error,
              }"
            >
              <option value="0">{{ $t("voucher.current") }}</option>
              <option value="1">{{ $t("voucher.capital") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("voucher.expensesTypeError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.project") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payment.task_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              :disabled="payment.expenses_type == 0"
              v-else
              class="form-select"
              v-model="v$.payment.task_id.$model"
              @change="changeProject"
              :class="{
                'is-invalid': v$.payment.task_id.$error,
              }"
            >
              <option
                v-for="(project, b) in projects"
                :key="b"
                :value="project.id"
              >
                {{ project.task_title }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.projectError") }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("receipt.task") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.payment.task_meta_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              :disabled="payment.expenses_type == 0"
              class="form-select"
              v-model="v$.payment.task_meta_id.$model"
              :class="{
                'is-invalid': v$.payment.task_meta_id.$error,
              }"
            >
              <option
                v-for="(task, index) in tasks"
                :key="index"
                :value="task.id"
              >
                {{ task.task_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("receipt.taskError") }}</div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered voucherTable">
        <thead>
          <tr>
            <th>{{ $t("receipt.type") }}</th>
            <th>{{ $t("voucher.type") }}</th>
            <th colspan="2">{{ $t("receipt.particular") }}</th>
            <th>{{ $t("receipt.amount") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dr, c) in payment.dr" :key="c">
            <td>{{ $t("receipt.dr") }}</td>
            <td>
              <div class="form-group required">
                <div class="input-group">
                  <select
                    class="form-select"
                    v-model="dr.type"
                    :class="{
                      'is-invalid':
                        v$.payment.dr.$each.$response.$errors[c].type.length,
                    }"
                    @change="changeDrType(c)"
                  >
                    <option value="1" v-if="payment.expenses_type == 1">
                      {{ $t("voucher.budgetExpenses") }}
                    </option>
                    <option value="4" v-if="payment.expenses_type == 0">
                      {{ $t("voucher.miscellaneous") }}
                    </option>
                    <option value="5" v-if="payment.expenses_type == 1">
                      {{ $t("voucher.advance") }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("voucher.typeError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payment.dr.$each.$response.$errors[c].expenses_id
                        .length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    @change="displayAmountInfo(c)"
                    v-else
                    class="form-select"
                    v-model="dr.expenses_id"
                  >
                    <option
                      v-for="(exp, dz) in expensesList[c]"
                      :key="dz"
                      :value="exp.id"
                    >
                      {{ exp.headings }}
                    </option>
                  </select>
                </div>
                <div :id="'payment-dr-' + c" class="font-red font-small"></div>
                <div class="invalid-feedback">
                  {{ $t("blankError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payment.dr.$each.$response.$errors[c].dr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    :disabled="payment.dr[c].type != 5"
                    v-else
                    class="form-select"
                    v-model="dr.dr_id"
                  >
                    <option
                      v-for="(exp, d) in expenses[c]"
                      :key="d"
                      :value="exp.id"
                    >
                      {{ exp.headings }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("blankError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="dr.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.payment.dr.$each.$response.$errors[c].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.amountError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneDrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteDrClone(c)"
                  v-if="c != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(cr, e) in payment.cr" :key="e">
            <td>{{ $t("receipt.cr") }}</td>
            <td>
              <div class="form-group required">
                <div class="input-group">
                  <select
                    class="form-select"
                    v-model="cr.type"
                    :class="{
                      'is-invalid':
                        v$.payment.cr.$each.$response.$errors[e].type.length,
                    }"
                    @change="changeCrType(e)"
                  >
                    <option value="2">{{ $t("voucher.bank") }}</option>
                    <option value="3">{{ $t("voucher.liabilities") }}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("voucher.typeError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payment.cr.$each.$response.$errors[e].cr_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="cr.cr_id"
                    @change="updateCheque(e)"
                  >
                    <option
                      v-for="(crs, f) in crlist[e]"
                      :key="'CRLIST' + f"
                      :value="crs.id"
                    >
                      {{ crs.heading }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("blankError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.payment.cr.$each.$response.$errors[e].cheque_id.length,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    :disabled="payment.cr[e].type == 3"
                    :id="'cheque_' + e"
                    v-else
                    class="form-select"
                    v-model="cr.cheque_id"
                  >
                    <option
                      v-for="(chk, z) in cheques[e]"
                      :key="z"
                      :value="chk.id"
                    >
                      {{ chk.cheque_number }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("blankError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="form-group required">
                <input
                  v-model="cr.amount"
                  type="number"
                  class="form-control"
                  @keyup="calculate"
                  :class="{
                    'is-invalid':
                      v$.payment.cr.$each.$response.$errors[e].amount.length,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.amountError") }}
                </div>
              </div>
            </td>
            <td>
              <div class="voucher-dynamic-form-button-container">
                <div class="btn btn-success btn-xs" @click="cloneCrItem">+</div>
                <div
                  class="btn btn-warning btn-xs"
                  @click="deleteCrClone(e)"
                  v-if="e != 0"
                >
                  -
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!tallyFlag">
          <tr>
            <td colspan="5" class="text-red">
              {{ $t("receipt.drcrbalance") }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("receipt.narration") }}</label>
      <textarea
        v-model="v$.payment.narration.$model"
        rows="5"
        type="text"
        class="form-control"
        :class="{
          'is-invalid': v$.payment.narration.$error,
        }"
      />
      <div class="invalid-feedback">{{ $t("receipt.narrationError") }}</div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th colspan="4">भुक्तानी प्रयोजनका लागि</th>
          </tr>
          <tr>
            <th>{{ $t("receipt.partyType") }}</th>
            <th>{{ $t("receipt.paymentParty") }}</th>
            <th>{{ $t("receipt.billNo") }}</th>
            <th>{{ $t("receipt.billDate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 25%">
              <div class="form-group">
                <select
                  class="form-select"
                  v-model="payment.payer_type"
                  @change="getPaymentParty"
                >
                  <option value="1">Contractor</option>
                  <option value="2">Institutional</option>
                  <option value="3">Consumer Committee</option>
                  <option value="4">Personal</option>
                  <option value="5">Supplier</option>
                  <option value="6">Consultant</option>
                  <option value="7">Internal Body</option>
                  <option value="8">Office Bearers</option>
                  <option value="9">Apparent Letter</option>
                  <option value="10">Personal</option>
                </select>

                <div class="invalid-feedback">
                  {{ $t("receipt.partyTypeError") }}
                </div>
              </div>
            </td>
            <td style="width: 25%">
              <div
                class="form-group"
                :class="{
                  'is-invalid': v$.payment.payer_id.$error,
                }"
              >
                <div
                  class="input-group"
                  :class="{
                    'is-invalid': v$.payment.payer_id.$error,
                  }"
                >
                  <span class="input-group-text" v-if="isDropDownLoading">
                    <div class="spinner-border"></div>
                  </span>
                  <select class="form-select" v-if="isDropDownLoading" disabled>
                    <option value="null">{{ $t("loading") }}</option>
                  </select>
                  <select
                    v-else
                    class="form-select"
                    v-model="v$.payment.payer_id.$model"
                  >
                    <option
                      v-for="(pyr, g) in payer"
                      :key="'crMetaMeta' + g"
                      :value="pyr.id"
                    >
                      {{ pyr.headings }}
                    </option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ $t("receipt.paymentPartyError") }}
                </div>
              </div>
            </td>
            <td style="width: 25%">
              <div class="form-group">
                <input
                  v-model="payment.bill_no"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.payment.bill_no.$error,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.billNo") }}
                </div>
              </div>
            </td>
            <td style="width: 25%">
              <div class="form-group">
                <input
                  v-model="payment.bill_date"
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.payment.bill_date.$error,
                  }"
                />

                <div class="invalid-feedback">
                  {{ $t("receipt.billDateError") }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("add") : $t("update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      payment: {
        fiscal_year_id: { required },
        date: { required },
        narration: { required },
        amount: { required },
        payment_mode: { required },
        reference_no: { required },
        task_id: {
          required: requiredIf((value, item) => {
            return item.expenses_type == 1;
          }),
        },
        task_meta_id: {
          required: requiredIf((value, item) => {
            return item.expenses_type == 1;
          }),
        },
        expenses_type: { required },
        payer_id: {
          required: requiredIf((value, item) => {
            var flag = false;
            item.cr.forEach((element) => {
              if (element.type == 2) {
                flag = true;
              }
            });
            return flag;
          }),
        },
        bill_no: {
          required: requiredIf((value, item) => {
            var flag = false;
            item.cr.forEach((element) => {
              if (element.type == 2) {
                flag = true;
              }
            });
            return flag;
          }),
        },
        bill_date: {
          required: requiredIf((value, item) => {
            var flag = false;
            item.cr.forEach((element) => {
              if (element.type == 2) {
                flag = true;
              }
            });
            return flag;
          }),
        },
        dr: {
          $each: helpers.forEach({
            type: { required },
            expenses_id: { required },
            dr_id: {
              required: requiredIf((value, item) => item.type == 5),
            },
            amount: { required },
          }),
        },
        cr: {
          $each: helpers.forEach({
            type: { required },
            cr_id: { required },
            cheque_id: {
              required: requiredIf((value, item) => item.type == 2),
            },
            amount: { required },
          }),
        },
      },
    };
  },
  data() {
    return {
      date: "",
      isDropDownLoading: true,
      fiscalyears: [],
      banks: [],
      tasks: [],
      projects: [],
      cheques: [],
      parties: [],
      allexpenses: [],
      expenses: [],
      liability: [],
      crlist: [],
      isSubmitting: false,
      tallyFlag: true,
      chequeIds: [],
      payer: [],
      expensesList: [],
      budgetPlan: {
        budget: null,
        allocatedExpenses: null,
        expenses: null,
        remaining: null,
      },
    };
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.type == 2) {
      this.payment.cr.forEach((element) => {
        if (element.type == 2) {
          this.chequeIds.push(element.cheque_id);
        }
      });
    }
    this.getDropDown();
    if (this.$i18n.locale == "np") {
      this.supplyValue(2, this.payment.date);
    }
  },
  methods: {
    changeExpensesType() {
      if (this.payment.expenses_type) {
        if (this.payment.expenses_type == 0) {
          this.payment.task_id = "";
          this.payment.task_meta_id = "";
        }
      }
    },

    getPaymentParty() {
      this.payer = this.parties.filter(
        (item) => item.type == this.payment.payer_type
      );
    },

    displayAmountInfo(index) {
      if (this.payment.dr[index].expenses_id) {
        var item = this.expensesList[index].filter(
          (item) => item.id == this.payment.dr[index].expenses_id
        )[0];
        document.getElementById("payment-dr-" + index).innerText =
          this.$t("receipt.budget") +
          " : " +
          this.$t("rs") +
          item.budget +' '+
          this.$t("receipt.expenses") +
          " : " +
          this.$t("rs") +
          item.expenses +' '+
          this.$t("receipt.remaining") +
          " : " +
          this.$t("rs") +
          item.remaining;
      }
    },

    updateData() {
      this.changeProject();
      this.payment.dr.forEach((item, index) => {
        this.changeDrType(index);
      });
      var payerFlag = false;
      this.payment.cr.forEach((item, index) => {
        this.changeCrType(index);
        if (item.type == 2) {
          payerFlag = true;
        }
      });
      if (payerFlag) this.getPaymentParty();
    },

    supplyValue(updateType, e) {
      if (updateType == 1) {
        var converter = adbs.bs2ad(e);
        var month =
          converter.month < 10 ? "0" + converter.month : converter.month;
        var day = converter.day < 10 ? "0" + converter.day : converter.day;
        var formatedDate = converter.year + "-" + month + "-" + day;
        this.payment.date = formatedDate;
      } else {
        var converter1 = adbs.ad2bs(moment(e).format("YYYY/MM/DD")).en;
        var month1 =
          converter1.month < 10 ? "0" + converter1.month : converter1.month;
        var day1 = converter1.day < 10 ? "0" + converter1.day : converter1.day;
        var formatedDate1 = converter1.year + "-" + month1 + "-" + day1;
        this.date = formatedDate1;
      }
    },

    cloneDrItem() {
      this.payment.dr.push({
        type: null,
        dr_id: null,
        expenses_id: null,
        amount: null,
      });
    },

    cloneCrItem() {
      this.payment.cr.push({
        type: null,
        cr_id: null,
        cheque_id: null,
        amount: null,
      });
    },

    deleteDrClone(index) {
      this.payment.dr.splice(index, 1);
      this.calculate();
    },

    deleteCrClone(index) {
      this.payment.cr.splice(index, 1);
      this.calculate();
    },

    changeDrType(index) {
      this.expenses[index] = [];
      if (this.payment.dr[index].type) {
        this.expensesList[index] = this.allexpenses.filter(
          (item) => item.type == (this.payment.dr[index].type == 4 ? 1 : 2)
        );
        if (
          this.payment.dr[index].type == 1 ||
          this.payment.dr[index].type == 4
        ) {
          this.expenses[index] = [];
        } else {
          this.expenses[index] = this.parties;
        }
      }
    },

    changeCrType(index) {
      this.crlist[index] = [];
      this.cheques[index] = [];
      if (this.type == 1) this.payment.cr[index].cr_id = null;
      if (this.payment.cr[index].type) {
        if (this.payment.cr[index].type == 2) {
          this.crlist[index] = this.banks;
        } else {
          this.crlist[index] = this.liability;
        }
        if (this.type == 2) this.updateCheque(index);
      }
    },

    calculate() {
      var totalDrAmount = 0;
      var totalCrAmount = 0;
      this.payment.dr.forEach((item) => {
        totalDrAmount += parseInt(item.amount);
      });
      this.payment.cr.forEach((item) => {
        totalCrAmount += parseInt(item.amount);
      });
      if (totalDrAmount == totalCrAmount) {
        this.tallyFlag = true;
        this.payment.amount = totalDrAmount;
      } else {
        this.tallyFlag = false;
      }
    },

    getDropDown() {
      axios
        .post(
          "helper/paymentvoucherdropdown",
          { type: this.type, chequeIds: JSON.stringify(this.chequeIds) },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.fiscalyears = res.data.fiscal;
            this.banks = res.data.bank;
            this.parties = res.data.party;
            this.projects = res.data.project;
            this.allexpenses = res.data.expenses;
            this.liability = res.data.liability;
            if (this.type == 2) {
              this.updateData();
            } else {
              this.payment.reference_no = res.data.referenceNo
                ? parseInt(res.data.referenceNo.reference_no) + 1
                : null;
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeProject() {
      if (this.payment.task_id) {
        var item = this.projects.filter(
          (item) => item.id == this.payment.task_id
        )[0];
        this.tasks = item.task;
      }
    },

    updateCheque(index) {
      if (this.payment.cr[index].cr_id && this.payment.cr[index].type == 2) {
        var cheques = this.banks.filter(
          (item) => item.id == this.payment.cr[index].cr_id
        )[0].cheque;
        this.cheques.splice(index, 1, cheques);
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.tallyFlag) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.payment) {
          formData.set(key, this.payment[key]);
        }
        formData.append("dr", JSON.stringify(this.payment.dr));
        formData.append("cr", JSON.stringify(this.payment.cr));
        var url = this.type == 1 ? "voucher/payment" : "voucher/paymentupdate";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Payment voucher successfully created."
                    : "Payment voucher successfully updated."
                );
                this.$router.push(
                  "/" +
                    this.$i18n.locale +
                    "/dashboard/appropriation/voucherapproval/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
<style>
.voucherTable > tbody > tr > td {
  vertical-align: top;
}

.voucher-dynamic-form-button-container > button:first-child {
  margin-right: 5px;
}
</style>
