<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("task.project") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.task.task_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.task.task_id.$model"
              :class="{
                'is-invalid': v$.task.task_id.$error,
              }"
            >
              <option
                v-for="(project, index) in projectList"
                :key="index"
                :value="project.id"
              >
                {{ project.task_title }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("task.projectError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">{{ $t("task.staff") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.task.staff_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.task.staff_id.$model"
              :class="{
                'is-invalid': v$.task.staff_id.$error,
              }"
            >
              <option
                v-for="(staff, index) in staffList"
                :key="index"
                :value="staff.id"
              >
                {{ staff.full_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("task.staffError") }}</div>
        </div>
      </div>
    </div>
    <div class="form-group required">
      <label class="control-label">{{ $t("task.name") }}</label>
      <input
        v-model="v$.task.task_name.$model"
        :class="{
          'is-invalid': v$.task.task_name.$error,
        }"
        type="text"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("task.nameError") }}</div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t("task.dos") }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, 1, $event)"
            :class="{
              'is-invalid': v$.task.date_of_start.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="dos"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("task.dosError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("task.dos") }}</label>
          <input
            v-model="v$.task.date_of_start.$model"
            :class="{
              'is-invalid': v$.task.date_of_start.$error,
            }"
            :readonly="autoSuppliedFlag == 1 ? true : false"
            type="datetime-local"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("task.dosError") }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t("task.doe") }}</label>
          <VNepaliDatePicker
            @change="supplyValue(2, 1, $event)"
            :class="{
              'is-invalid': v$.task.date_of_end.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="doe"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("task.doeError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("task.doe") }}</label>
          <input
            v-model="v$.task.date_of_end.$model"
            :class="{
              'is-invalid': v$.task.date_of_end.$error,
            }"
            :readonly="autoSuppliedFlag == 1 ? true : false"
            type="datetime-local"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("task.doeError") }}</div>
        </div>
      </div>
    </div>

    <div class="form-group required">
      <label class="control-label">{{ $t("task.description") }}</label>
      <textarea
        v-model="v$.task.description.$model"
        :class="{
          'is-invalid': v$.task.description.$error,
        }"
        type="text"
        class="form-control"
        rows="5"
      />

      <div class="invalid-feedback">{{ $t("task.descriptionError") }}</div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("task.assign") : $t("task.update") }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      doe: "",
      dos: "",
      isDropDownLoading: true,
      isSubmitting: false,
      staffList: [],
      projectList: [],
    };
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    autoSuppliedFlag: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      task: {
        staff_id: { required },
        task_name: { required },
        description: { required },
        date_of_start: { required },
        date_of_end: { required },
        task_id: { required },
      },
    };
  },
  created: function () {
    if (this.$i18n.locale == "np") {
      this.supplyValue(1, 2, this.task.date_of_start);
      this.supplyValue(2, 2, this.task.date_of_end);
    }
    this.getDropDown();
  },
  methods: {
    supplyValue(type, updateType, e) {
      if (updateType == 1) {
        var converter = adbs.bs2ad(e);
        var month =
          converter.month < 10 ? "0" + converter.month : converter.month;
        var day = converter.day < 10 ? "0" + converter.day : converter.day;
        var formatedDate = converter.year + "-" + month + "-" + day;
        if (type == 1) this.task.date_of_start = formatedDate;
        else this.task.date_of_end = formatedDate;
      } else {
        var converter1 = adbs.ad2bs(moment(e).format("YYYY/MM/DD")).en;
        var month1 =
          converter1.month < 10 ? "0" + converter1.month : converter1.month;
        var day1 = converter1.day < 10 ? "0" + converter1.day : converter1.day;
        var formatedDate1 = converter1.year + "-" + month1 + "-" + day1;
        if (type == 1) this.dos = formatedDate1;
        else this.doe = formatedDate1;
      }
    },

    getDropDown() {
      axios
        .post(
          "task/getdropdowndata",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.projectList = res.data.project;
            this.staffList = res.data.staff;
            this.isDropDownLoading = false;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.task) {
          formData.set(key, this.task[key]);
        }
        formData.set("admin_status", 1);
        var url = this.type == 1 ? "task/create" : "task/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Task successfully assigned."
                    : "Task successfully updated."
                );
                this.$store.state.taskList = [];
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/project/task/taskcalendar"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            } else {
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      } else {
        console.log(this.v$.$errors);
      }
    },
  },
};
</script>
