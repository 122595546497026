<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        Cheque Printing
        <small>List of all cheque to be printed...</small>
      </h2>
    </div>
    <div class="body">
      <Tableshimmer :heading="tableHeading" v-if="isLoading" />
      <Table
        :list="printingList"
        :currentPage="currentPage"
        :perPage="perPage"
        :heading="tableHeading"
        :totalRow="totalRow"
        @changePage="changePage"
        @completedPrinting="completedPrinting"
        v-else
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Tableshimmer from "../../helper/tableshimmer.vue";
import Table from "./table.vue";

const tableHeading = ["SN", "Date", "Project", "Party", "Bank", "Amount"];
export default {
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      printingList: [],
      tableHeading,
    };
  },
  components: {
    Tableshimmer,
    Table,
  },
  created() {
    this.$emit('close');
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        type:2,       //type 1 is for printing payment cheque and 2 is for printing bail cheque
      };
      await axios
        .post("printing/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.printingList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    completedPrinting(data) {
      this.printingList.splice(data.index, 1);
    },

    changePage(data) {
      this.printingList = [];
      this.currentPage = data.page;
      this.getData();
    },
  },
};
</script>
