<template>
  <div class="card kasturi_workspace_card">
    <div class="header" :class="'bg-' + $store.state.skin">
      <h2>
        {{ $t('weekend.weekend') }}
        <small>{{ $t('weekend.addNote') }}</small>
      </h2>
      <div>
        <router-link
          :to="`/${$i18n.locale}/dashboard/payroll/setting/weekendlist`"
          class="btn btn-info btn-sm"
          >{{ $t("weekend.list") }}</router-link
        >
      </div>
    </div>
    <div class="body">
      <div class="department-form">
        <Form :weekend="weekend" :type="1" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      weekend: {
        no_of_weekend: null,
        days: [],
      },
    };
  },
  created() {
    this.$emit("close");
  },
  components: {
    Form,
  },
};
</script>
