<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="setupUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/appropriation/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'bank' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.bank") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/bank/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/bank/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'cheque' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.chequeSetting") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/cheque/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/cheque/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'party' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.party") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/dts/list`"
                  class="dropdown-item"
                  >{{ 'DTS' }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/party/list`"
                  class="dropdown-item"
                  >{{ $t("leftnav.party") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'voucher' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.voucher") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/voucher/receiptcreate`"
                  class="dropdown-item"
                  >{{ $t("leftnav.receipt") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/voucher/paymentcreate`"
                  class="dropdown-item"
                  >{{ $t("leftnav.payment") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'voucherapproval' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.voucherApproval") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/voucherapproval/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'printing' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.print") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/printing/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'chequecancel' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.cancel") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/appropriation/chequecancel/cancel`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  methods: {
    close() {
      this.$refs.setupUl.children.forEach((item, index) => {
        item.childNodes[0].classList.remove("show");
        if (index > 0) {
          item.childNodes[1].classList.remove("show");
        }
      });
      this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      var currentTab = e.target.parentNode;
      this.$refs.setupUl.children.forEach((item, index) => {
        if (!item.contains(currentTab)) {
          item.childNodes[0].classList.remove("show");
          if (index > 0) {
            item.childNodes[1].classList.remove("show");
          }
        }
      });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
