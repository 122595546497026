<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="setupUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/budget/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'fiscal' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.fiscal") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/fiscal/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/fiscal/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'budgethead' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.revenuesource") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/budgethead/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/budgethead/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'source' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.revenuesourcetopic") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/source/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/source/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'incomesource' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t('leftnav.revenuecollectionmethod') }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/incomesource/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/incomesource/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li> -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'expenses' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.expenses") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/expenses/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/expenses/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'liability' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("liability.liability") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/liability/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/liability/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'sourcedetermination' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.sourceDetermination") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/sourcedetermination/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/sourcedetermination/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/sourcedetermination/approval`"
                  class="dropdown-item"
                  >{{ $t("revenueestimation.acceptance") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'expensesdetermination' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.expensesDetermination") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/expensesdetermination/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/expensesdetermination/list/1`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/budget/expensesdetermination/list/2`"
                  class="dropdown-item"
                  >{{ $t("revenueestimation.acceptance") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  methods: {
    close() {
      this.$refs.setupUl.children.forEach((item, index) => {
        item.childNodes[0].classList.remove("show");
        if (index > 0) {
          item.childNodes[1].classList.remove("show");
        }
      });
      this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      var currentTab = e.target.parentNode;
      this.$refs.setupUl.children.forEach((item, index) => {
        if (!item.contains(currentTab)) {
          item.childNodes[0].classList.remove("show");
          if (index > 0) {
            item.childNodes[1].classList.remove("show");
          }
        }
      });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
