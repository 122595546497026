<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('liability.list')}}
            <small>{{$t('liability.listNote')}}</small>
          </h2>
        </div>
        <div class="body">
          <Search
            @search="search"
            @changePage="changePage"
            @resetSearch="resetSearch"
          />
          <Tableshimmer :heading="tableHeading" v-if="isLoading" />
          <Table
            :list="liabilityList"
            :currentPage="currentPage"
            :perPage="perPage"
            :heading="tableHeading"
            :totalRow="totalRow"
            @changePage="changePage"
            @deleteItem="deleteItem"
            v-else
          />
        </div>
      </div>
</template>
<script>
import axios from "axios";
import Table from "./table.vue";
import Search from "../../helper/search.vue";
import Tableshimmer from "../../helper/tableshimmer.vue";

export default {
  components: { Search, Tableshimmer,Table },
  data() {
    return {
      isLoading: true,
      perPage: 20,
      currentPage: 1,
      liabilityList: [],
      tableHeading:[this.$t('sn'),this.$t('liability.heading')],
      searchQuery: null,
    };
  },
  created() {
    this.$emit('close');
    this.getData(null);
  },
  methods: {
    async getData(query) {
      this.isLoading = true;
      const argument = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        query: query,
      };
      await axios
        .post("liability/list", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            this.liabilityList = res.data.list;
            this.$store.state.liabilityList = res.data.list;
            this.totalRow = res.data.totalRow;
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    changePage(data) {
      this.liabilityList = [];
      this.currentPage = data.page;
      this.getData(this.searchQuery);
    },

    resetSearch() {
      this.searchQuery = null;
      this.liabilityList = [];
      this.currentPage = 1;
      this.getData(this.searchQuery);
    },

    search(query) {
      this.currentPage = 1;
      this.searchQuery = query;
      this.getData(this.searchQuery);
    },

    async deleteItem(data) {
      const argument = {
        id: data.id,
      };
      await axios
        .post("liability/delete", argument, {
          headers: {
            Authorization:
              "Kasturiworkspace " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.loginFlag) {
            if (res.data.flag) {
              this.$toastr.s("Successfully deleted.");
              this.liabilityList.splice(data.index, 1);
            } else {
              this.$toastr.e("Something went wrong. Try again later.");
            }
            this.isLoading = false;
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/"+this.$i18n.locale+"/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
