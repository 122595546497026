<template>
  <div>
    <div class="card kasturi_workspace_card">
      <div class="header" :class="'bg-' + $store.state.skin">
        <h2>
          {{ $t("project.project") }}
          <small>{{ $t("project.image") }}</small>
        </h2>
      </div>
      <div class="body">
        <div class="department-form">
          <Imageform :id="parseInt(id)" @updateImage="updateImage" />
          <hr />
          <div
            class="card imageCard"
            v-for="(task, index) in imagesList"
            :key="index"
          >
            <div class="header" :class="'bg-' + $store.state.skin">
              <h2>{{ task.title }}</h2>
            </div>
            <div class="body">
              <div
                class="project-image-container"
                v-if="task.images.length > 0"
              >
                <div
                  class="project-image"
                  v-for="(img, jindex) in task.images"
                  :key="'i' + jindex"
                >
                  <span @click="deleteImage(img.id, index, jindex)">X</span>
                  <img
                    @click="displayLightBox(jindex, index)"
                    :src="$hostname + img.image"
                    alt="Project Image"
                  />
                </div>
              </div>
              <div class="no-record" v-else>
                {{ $t("norecord") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="currentIndex"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>
<script>
import Imageform from "./form.vue";
import axios from "axios";

export default {
  components: {
    Imageform,
  },
  data() {
    return {
      id: this.$route.params.id,
      visible: false,
      currentIndex: null,
      imagesList: [],
      imgs: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios
        .post(
          "project/getimages",
          { id: this.id },
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.imagesList = res.data.list;
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateImage(data) {
      var existenceFlag = false;
      this.imagesList.forEach((element) => {
        if (element.id == data.id) {
          data.images.forEach((item) => {
            element.images.push(item);
          });
          existenceFlag = true;
        }
      });
      if (!existenceFlag) {
        this.imagesList.push(data);
      }
    },

    displayLightBox(jindex, index) {
      this.imgs = [];
      this.imagesList[index].images.forEach((element) => {
        this.imgs.push(this.$hostname + element.image);
      });
      this.currentIndex = jindex;
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },

    deleteImage(id, index, jindex) {
      if (confirm("Are you sure?")) {
        axios
          .post(
            "project/deleteimage",
            { id: id },
            {
              onUploadProgress: (uploadEvent) => {
                console.log(
                  "Upload progress" +
                    Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                    "%"
                );
              },
              headers: {
                Authorization:
                  "Kasturiworkspace " + localStorage.getItem("accessToken"),
              },
            }
          )
          .then((res) => {
            if (res.data.loginFlag) {
              this.$toastr.s("Successfully deleted.");
              this.imagesList[index].images.splice(jindex, 1);
            } else {
              this.$toastr.e("Login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
.imageCard {
  margin-bottom: 10px;
}

.project-image-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.project-image {
  width: 150px;
  height: 150px;
  position: relative;
  box-shadow: 0px 0px 3px 1px grey;
  margin: 10px;
}

.project-image:hover {
  box-shadow: 0px 0px 3px 5px grey;
  transition: 1s;
}

.project-image > img {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-image > span {
  position: absolute;
  right: 2px;
  top: 2px;
  background: red;
  padding: 5px;
  z-index: 1;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}
</style>
