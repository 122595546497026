<template>
  <form @submit.prevent="submitForm">
    <div class="form-group required">
      <label class="control-label">{{ $t('weekend.numberWeekend') }}</label>
      <input
        v-model="v$.weekend.no_of_weekend.$model"
        @keyup="cloneday"
        :class="{
          'is-invalid': v$.weekend.no_of_weekend.$error,
        }"
        type="number"
        class="form-control"
      />

      <div class="invalid-feedback">
        {{ $t('weekend.numberWeekendError') }}
      </div>
    </div>

    <div class="table-responsive" v-if="weekend.days.length > 0">
      <table class="table table-responsive table-bordered">
        <thead>
          <tr>
            <th>{{$t('weekend.days')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(head, index) in weekend.days" :key="'days' + index">
            <td>
              <div class="form-group required">
                <div
                  class="input-group"
                  :class="{
                    'is-invalid':
                      v$.weekend.days.$each.$response.$errors[index].day.length,
                  }"
                >
                  <select
                    class="form-select"
                    v-model="head.day"
                    :class="{
                      'is-invalid':
                        v$.weekend.days.$each.$response.$errors[index].day
                          .length,
                    }"
                  >
                    <option value="0">{{$t('weekend.sunday')}}</option>
                    <option value="1">{{$t('weekend.monday')}}</option>
                    <option value="2">{{$t('weekend.tuesday')}}</option>
                    <option value="3">{{$t('weekend.wednesday')}}</option>
                    <option value="4">{{$t('weekend.thursday')}}</option>
                    <option value="5">{{$t('weekend.friday')}}</option>
                    <option value="6">{{$t('weekend.saturday')}}</option>
                  </select>
                </div>

                <div class="invalid-feedback">
                  {{ "Day cannot be blank." }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? "Add" : "Update" }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmitting: false,
      isDropDownLoading: false,
    };
  },
  props: {
    weekend: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      weekend: {
        no_of_weekend: { required },
        days: {
          $each: helpers.forEach({
            day: { required },
          }),
        },
      },
    };
  },
  methods: {
    cloneday(){
        this.weekend.days = [];
        if(this.weekend.no_of_weekend){
            for(var i=0;i<this.weekend.no_of_weekend;i++){
                this.weekend.days.push({
                    day:null,
                });
            }
        }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.weekend) {
          formData.set(key, this.weekend[key]);
        }
        formData.append("days", JSON.stringify(this.weekend.days));
        var url =
          this.type == 1 ? "payrollweekend/create" : "payrollweekend/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (!res.data.loginFlag) {
              this.$toastr.e("Alert", "Your login session expired.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            } else {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Weekend successfully added."
                    : "Weekend successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/payroll/setting/weekendlist"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  "Something went wrong. Try again later."
                );
                this.isSubmitting = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
