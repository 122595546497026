<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('income.income')}}
            <small>{{$t('income.addNote')}}</small>
          </h2>
        </div>
        <div class="body">
          <div class="source-heading-form">
              <Form :source="source" :type="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  created() {
    this.$emit('close');
  },
  data() {
    return {
      source: {
        headings: null,
        description: null,
        head_id:null,
        method: [
          {
            type: null,
          },
        ],
      },
    };
  },
};
</script>
