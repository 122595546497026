<template>
  <div>
    <div class="list-search-form">
      <form ref="searchForm" @submit.prevent="submitForm">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(1, $event)"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="startDate"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("payroll.fromError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.from.$model"
            :class="{
              'is-invalid': v$.search.from.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">{{ $t("payroll.fromError") }}</div>
        </div>
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <VNepaliDatePicker
            @change="supplyDate(2, $event)"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("payroll.toError") }}</div>
        </div>
        <div class="form-group required" v-else>
          <input
            v-model="v$.search.to.$model"
            :class="{
              'is-invalid': v$.search.to.$error,
            }"
            type="date"
            class="form-control"
            :placeholder="$t('search') + '...'"
          />

          <div class="invalid-feedback">{{ $t("payroll.toError") }}</div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-info">{{ $t("search") }}</button>
        </div>
      </form>
    </div>
    <div v-if="!isLoading">
      <div class="export-container">
        <button class="btn-primary" @click.prevent="exportAsXls('xlsx')">
          EXPORT
        </button>
      </div>

      <div class="table-responsive">
        <table
          ref="exportable_table"
          class="table table-bordered table-striped payrollTable"
        >
          <thead>
            <tr>
              <th rowspan="2">{{ $t("sn") }}</th>
              <th rowspan="2">{{ $t("payroll.sanket") }}</th>
              <th rowspan="2">{{ $t("payroll.staffName") }}</th>
              <th rowspan="2">{{ $t("payroll.maritalStatus") }}</th>
              <th rowspan="2">{{ $t("payroll.gender") }}</th>
              <th rowspan="2">{{ $t("payroll.designation") }}</th>
              <th rowspan="2">{{ $t("payroll.pan") }}</th>
              <th rowspan="2">{{ $t("payroll.basicSalary") }}</th>
              <th rowspan="2">{{ $t("payroll.gradeRate") }}</th>
              <th rowspan="2">{{ $t("payroll.gradeNumber") }}</th>
              <th rowspan="2">{{ $t("payroll.gradeAmount") }}</th>
              <th rowspan="2">{{ $t("payroll.mainSalary") }}</th>
              <th :colspan="monthlyAdd.length" v-if="monthlyAdd.length > 0">
                {{ $t("payroll.monthlyGet") }}
              </th>
              <th rowspan="2">{{ $t("payroll.totalSalary") }}</th>
              <th :colspan="monthlyDeduct.length + 1">
                {{ $t("payroll.monthlyDeduct") }}
              </th>
              <th rowspan="2">{{ $t("payroll.taxable") }}</th>
              <th colspan="3">{{ $t("payroll.tax") }}</th>
              <th rowspan="2">{{ $t("payroll.netSalary") }}</th>
              <th rowspan="2">{{ $t("payroll.bankAndAccount") }}</th>
              <th rowspan="2">{{ $t("payroll.remarks") }}</th>
            </tr>
            <tr>
              <th v-for="(madd, index) in monthlyAdd" :key="index">
                {{ madd.headings }}
              </th>
              <th
                v-for="(mded, index) in monthlyDeduct"
                :key="'deduct' + index"
              >
                {{ mded.headings }}
              </th>
              <th>{{ $t("payroll.absentSalaryCut") }}</th>
              <th>{{ $t("payroll.sst") }}</th>
              <th>{{ $t("payroll.tds") }}</th>
              <th>{{ $t("payroll.taxAmount") }}</th>
            </tr>
          </thead>
          <tbody v-if="searchFlag">
            <tr v-for="(data, index) in report" :key="'Report' + index">
              <td>{{ incrementIndex(index) }}</td>
              <td>{{ data.identity_number }}</td>
              <td>{{ data.full_name }}</td>
              <td>
                {{
                  data.marital_status == 0
                    ? $t("payhead.single")
                    : $t("payhead.married")
                }}
              </td>
              <td>{{ getGender(index) }}</td>
              <td>
                {{
                  data.salary
                    ? data.salary.designation + " (" + data.salary.grade + ")"
                    : "-"
                }}
              </td>
              <td>{{ data.pan }}</td>
              <td>{{ data.salary ? data.salary.basicSalary : "-" }}</td>
              <td>{{ data.salary ? data.salary.grade_rate : "-" }}</td>
              <td>{{ data.salary ? data.salary.grade_no : "-" }}</td>
              <td>
                {{
                  data.salary
                    ? data.salary.grade_rate * data.salary.grade_no
                    : "-"
                }}
              </td>
              <td>{{ data.salary ? data.salary.mainSalary : 0 }}</td>
              <td v-for="(madd, jindex) in monthlyAdd" :key="'madd' + jindex">
                {{ data.payheads ? getPayheadValue(madd.id, index) : "-" }}
              </td>
              <td>{{ data.salary ? data.salary.totalSalary : 0 }}</td>
              <td
                v-for="(mded, kindex) in monthlyDeduct"
                :key="'mdeduct' + kindex"
              >
                {{ data.payheads ? getPayheadValue(mded.id, index) : "-" }}
              </td>
              <td>{{ data.salary ? data.salary.absentSalaryCut : 0 }}</td>
              <td>{{ data.salary ? data.salary.taxableAmount : 0 }}</td>
              <td>{{ data.salary ? data.salary.taxAmount.sst : 0 }}</td>
              <td>{{ data.salary ? data.salary.taxAmount.tds : 0 }}</td>
              <td>{{ data.salary ? data.salary.taxAmount.taxAmount : 0 }}</td>
              <td>{{ data.salary ? data.salary.netSalary : 0 }}</td>
              <td>{{ data.bankinfo }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    VNepaliDatePicker,
  },
  data() {
    return {
      isLoading: true,
      report: [],
      headings: [],
      monthlyAdd: [],
      monthlyDeduct: [],
      searchFlag: false,
      currentPage: 1,
      perPage: 100000,
      startDate: "",
      endDate: "",
      search: {
        from: null,
        to: null,
      },
    };
  },
  validations() {
    return {
      search: {
        from: { required },
        to: { required },
      },
    };
  },
  created() {
    this.$emit("close");
  },
  methods: {
    exportAsXls(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              this.$t("payheads.payroll") +
                " (" +
                new Date() +
                ")." +
                (type || "xlsx")
          );
    },

    getGender(index) {
      switch (this.report[index].gender) {
        case "1":
          return this.$t("payroll.male");
          break;
        case "0":
          return this.$t("payroll.female");
          break;
        default:
          return this.$t("payroll.notDefined");
          break;
      }
    },

    incrementIndex(index) {
      return ++index;
    },

    getPayheadValue(id, index) {
      if (this.report[index].payheads) {
        return this.report[index].payheads.filter((item) => {
          return item.id == id;
        })[0].value;
      }
      return 0;
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        const argument = {
          from: this.search.from,
          to: this.search.to,
        };
        await axios
          .post("payrollreport/payroll", argument, {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (res.data.loginFlag) {
              this.headings = res.data.payheadList;
              this.monthlyAdd = this.headings.filter((item) => {
                return item.type == 1;
              });
              this.monthlyDeduct = this.headings.filter((item) => {
                return item.type == 0;
              });
              this.report = res.data.staff;
              this.searchFlag = true;
              this.isLoading = false;
            } else {
              this.$toastr.e("Your login session expire.");
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    },

    supplyDate(type, e) {
      var converter = adbs.bs2ad(e);
      var month =
        converter.month < 10 ? "0" + converter.month : converter.month;
      var day = converter.day < 10 ? "0" + converter.day : converter.day;
      var formatedDate = converter.year + "-" + month + "-" + day;
      if (type == 1) this.search.from = formatedDate;
      else this.search.to = formatedDate;
    },

    exportPdf() {
      var element = document.getElementById("toprint");
      html2pdf(element, {
        margin: 10,
        filename: "General_attendance_report__" + new Date().getTime(),
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      });
    },
  },
};
</script>
<style>
.payrollTable {
  font-size: 10px;
}
</style>
