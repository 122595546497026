<template>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff").name }}</label>
          <input
            v-model="v$.staff.full_name.$model"
            :class="{
              'is-invalid': v$.staff.full_name.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("staff").nameError }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.identity_number") }}</label>
          <input
            v-model="v$.staff.identity_number.$model"
            :class="{
              'is-invalid': v$.staff.identity_number.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">
            {{ $t("staff.identityNumberError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label class="control-label">{{ $t("staff").doj }}</label>
          <VNepaliDatePicker
            @change="supplyValue(2, 1, $event)"
            :class="{
              'is-invalid': v$.staff.date_of_join.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="type == 2 ? doj : ''"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("staff").dojError }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("staff").doj }}</label>
          <input
            v-model="v$.staff.date_of_join.$model"
            type="date"
            class="form-control"
          />
          <div class="invalid-feedback">{{ $t("staff").dojError }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff").paddress }}</label>
          <input
            v-model="v$.staff.permanent_address.$model"
            :class="{
              'is-invalid': v$.staff.permanent_address.$error,
            }"
            type="text"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("staff").paddressError }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t("staff").taddress }}</label>
          <input
            v-model="staff.temporary_address"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.gender") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.gender.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.staff.gender.$model"
              :class="{
                'is-invalid': v$.staff.gender.$error,
              }"
            >
              <option value="1">{{ $t("staff.male") }}</option>
              <option value="0">{{ $t("staff.female") }}</option>
              <option value="2">{{ $t("staff.thirdGender") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("staff.genderError") }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff").phone }}</label>
          <input
            v-model="v$.staff.phone.$model"
            :class="{
              'is-invalid': v$.staff.phone.$error,
            }"
            type="number"
            class="form-control"
          />

          <div class="invalid-feedback">{{ $t("staff").phoneError }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff").email }}</label>
          <input
            v-model="v$.staff.email.$model"
            :class="{
              'is-invalid': v$.staff.email.$error,
            }"
            type="text"
            class="form-control"
          />

          <div
            class="invalid-feedback"
            v-if="!v$.staff.email.required.$response"
          >
            {{ $t("staff").emailError }}
          </div>
          <div class="invalid-feedback" v-if="!v$.staff.email.email.$response">
            {{ $t("staff").emailInvalidError }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">{{ $t("staff").pan }}</label>
          <input v-model="staff.pan" type="number" class="form-control" />
        </div>
      </div>
    </div>

    <div class="form-group required" v-if="type == 1">
      <label class="control-label">{{ $t("staff").password }}</label>
      <input
        v-model="v$.staff.password.$model"
        :class="{
          'is-invalid': v$.staff.password.$error,
        }"
        type="password"
        class="form-control"
      />

      <div class="invalid-feedback">{{ $t("staff").passwordError }}</div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.maritalStatus") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.marital_status.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.staff.marital_status.$model"
              :class="{
                'is-invalid': v$.staff.marital_status.$error,
              }"
            >
              <option value="1">{{ $t("staff.married") }}</option>
              <option value="0">{{ $t("staff.single") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("staff.maritalStatusError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.disabledStatus") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.disabled_status.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.staff.disabled_status.$model"
              :class="{
                'is-invalid': v$.staff.disabled_status.$error,
              }"
            >
              <option value="1">{{ $t("yes") }}</option>
              <option value="0">{{ $t("no") }}</option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("staff.disabledStatusError") }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group required" v-if="$i18n.locale == 'np'">
          <label for="doa-date" class="control-label">{{
            $t("staff.doa")
          }}</label>
          <VNepaliDatePicker
            @change="supplyValue(1, 1, $event)"
            :class="{
              'is-invalid': v$.staff.date_of_appointment.$error,
            }"
            classValue="form-control"
            calenderType="Nepali"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
            :value="type == 2 ? doa : ''"
            :yearSelect="true"
            :monthSelect="true"
          />
          <div class="invalid-feedback">{{ $t("staff").doaError }}</div>
        </div>
        <div class="form-group required" v-else>
          <label class="control-label">{{ $t("staff").doa }}</label>
          <input
            v-model="v$.staff.date_of_appointment.$model"
            :class="{
              'is-invalid': v$.staff.date_of_appointment.$error,
            }"
            type="date"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff").department }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.department_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              @change="getShift"
              v-model="v$.staff.department_id.$model"
              :class="{
                'is-invalid': v$.staff.department_id.$error,
              }"
            >
              <option
                v-for="(department, index) in departmentList"
                :key="index"
                :value="department.id"
              >
                {{ department.department_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("staff").departmentError }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.shift") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.shift_id.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.staff.shift_id.$model"
              :class="{
                'is-invalid': v$.staff.shift_id.$error,
              }"
            >
              <option
                v-for="(shift, index) in shiftList"
                :key="index"
                :value="shift.id"
              >
                {{ shift.shift_name }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">{{ $t("staff.shiftError") }}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.designation") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.designation.$error,
            }"
          >
            <span class="input-group-text" v-if="isDropDownLoading">
              <div class="spinner-border"></div>
            </span>
            <select class="form-select" v-if="isDropDownLoading" disabled>
              <option value="null">{{ $t("loading") }}</option>
            </select>
            <select
              v-else
              class="form-select"
              v-model="v$.staff.designation.$model"
              :class="{
                'is-invalid': v$.staff.designation.$error,
              }"
            >
              <option
                v-for="(des, index) in designationList"
                :key="index"
                :value="des.id"
              >
                {{ des.designation }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("staff.designationError") }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group required">
          <label class="control-label">{{ $t("staff.employeeType") }}</label>
          <div
            class="input-group"
            :class="{
              'is-invalid': v$.staff.employee_type.$error,
            }"
          >
            <select
              class="form-select"
              v-model="v$.staff.employee_type.$model"
              :class="{
                'is-invalid': v$.staff.employee_type.$error,
              }"
            >
              <option value="1">
                {{ $t("staff.permanent") }}
              </option>
              <option value="2">
                {{ $t("staff.temporary") }}
              </option>
              <option value="3">
                {{ $t("staff.serviceAgreement") }}
              </option>
            </select>
          </div>

          <div class="invalid-feedback">
            {{ $t("staff.employeeTypeError") }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        type="submit"
        class="btn btn-danger btn-block submitButton"
        name="login-button"
        v-if="!isSubmitting"
      >
        {{ type == 1 ? $t("staff").enroll : $t("staff").update }}
      </button>
      <button
        class="btn btn-danger btn-block submitButton loading"
        disabled
        v-else
      >
        <div class="spinner-border text-warning"></div>
        <div>{{ $t("loading") }}</div>
      </button>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";
import VNepaliDatePicker from "v-nepalidatepicker";
import adbs from "ad-bs-converter";
import moment from "moment";

export default {
  components: {
    VNepaliDatePicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      doa: "",
      doj: "",
      isDropDownLoading: true,
      isSubmitting: false,
      departmentList: [],
      shiftList: [],
      designationList: [],
    };
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  validations() {
    return {
      staff: {
        full_name: { required },
        permanent_address: { required },
        email: { required, email },
        phone: { required },
        designation: { required },
        date_of_appointment: { required },
        date_of_join: { required },
        password: { required },
        department_id: { required },
        marital_status: { required },
        disabled_status: { required },
        gender: { required },
        shift_id: { required },
        employee_type: { required },
        identity_number: { required },
      },
    };
  },
  created: function () {
    this.getStaffDropDown();
    if (this.type == 2 && this.$i18n.locale == "np") {
      this.supplyValue(1, 2, this.staff.date_of_appointment);
      this.supplyValue(2, 2, this.staff.date_of_join);
    }
  },
  methods: {
    supplyValue(type, updateType, e) {
      if (updateType == 1) {
        var converter = adbs.bs2ad(e);
        var month =
          converter.month < 10 ? "0" + converter.month : converter.month;
        var day = converter.day < 10 ? "0" + converter.day : converter.day;
        var formatedDate = converter.year + "-" + month + "-" + day;
        if (type == 1) this.staff.date_of_appointment = formatedDate;
        else this.staff.date_of_join = formatedDate;
      } else {
        var converter1 = adbs.ad2bs(moment(e).format("YYYY/MM/DD")).en;
        var month1 =
          converter1.month < 10 ? "0" + converter1.month : converter1.month;
        var day1 = converter1.day < 10 ? "0" + converter1.day : converter1.day;
        var formatedDate1 = converter1.year + "-" + month1 + "-" + day1;
        if (type == 1) this.doa = formatedDate1;
        else this.doj = formatedDate1;
      }
    },

    getStaffDropDown() {
      axios
        .post(
          "helper/staffdropdown",
          {},
          {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              //   "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            this.departmentList = res.data.department;
            this.designationList = res.data.designation;
            if (this.type == 2) {
              this.getShift();
            }
          } else {
            this.$toastr.e("Login session expired.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
          this.isDropDownLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getShift() {
      if (this.staff.department_id != null) {
        this.shiftList =
          this.departmentList.filter((item) => {
            return item.id == this.staff.department_id;
          }).length > 0
            ? this.departmentList.filter((item) => {
                return item.id == this.staff.department_id;
              })[0].shift
            : [];
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        for (var key in this.staff) {
          formData.set(key, this.staff[key]);
        }
        var url = this.type == 1 ? "staff/enroll" : "staff/update";
        await axios
          .post(url, formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            if (res.data.loginFlag) {
              if (res.data.flag) {
                this.$toastr.s(
                  this.type == 1
                    ? "Staff successfully enrolled."
                    : "Staff info successfully updated."
                );
                this.$router.push(
                  "/" + this.$i18n.locale + "/dashboard/setup/staff/list"
                );
              } else {
                this.$toastr.e(
                  "Alert!",
                  res.data.error.hasOwnProperty("phone")
                    ? res.data.error.phone[0]
                    : res.data.error.email[0]
                );
                this.isSubmitting = false;
              }
            } else {
              this.$router.push("/" + this.$i18n.locale + "/login");
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
