<template>
  <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t("party.payer")}}
            <small>{{$t("party.addPayerNote")}}</small>
          </h2>
          <div>
              <router-link :to="`/${$i18n.locale}/dashboard/bail/payer/list`" class="btn btn-info btn-sm">{{$t("party.payerList")}}</router-link>
          </div>
        </div>
        <div class="body">
          <div class="source-heading-form">
              <Form :payer="payer" :type="1" :pageType="1"/>
          </div>
        </div>
      </div>
</template>
<script>
import Form from "./form.vue";

export default {
  data() {
    return {
      payer: {
        type: null,
        pan_vat:null,
        dts_id:null,
        name:null,
        citizenship_registration_no:null,
        phone:null,
        mobile:null,
        email:null,
        address:null,
        advance_status:null,
        bail_status:null,
        approved_status:null,
        bank_name:null,
        branch:null,
        account_no:null,
        account_name:null,
        cperson:{
            name:null,
            designation:null,
            mobile:null,
            phone:null,
            email:null
        }
      },
    };
  },
  created() {
    this.$emit('close');
  },
  components: {
    Form,
  },
};
</script>
