<template>
  <div class="setup-container">
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-pills" ref="projectUl">
          <li class="nav-item">
            <router-link
              :to="`/${$i18n.locale}/dashboard/project/home`"
              class="nav-link"
              :class="activeLink == 'home' ? 'active' : ''"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'workingarea' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.workingarea") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/workingarea/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/workingarea/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'dpr' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("dpr.dpr") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/dpr/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/dpr/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'project' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("project.project") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/project/create`"
                  class="dropdown-item"
                  >{{ $t("add") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/project/list`"
                  class="dropdown-item"
                  >{{ $t("list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'task' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("leftnav.task") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="
                    $store.state.calendarFlag == 1
                      ? `/${$i18n.locale}/dashboard/project/task/taskcalendar`
                      : `/${$i18n.locale}/dashboard/project/task/tasklist`
                  "
                  class="dropdown-item"
                  >{{ $t("leftnav.task") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :class="activeLink == 'report' ? 'active' : ''"
              role="button"
              href="#"
              @click.prevent="toggleSetupDropdown"
              >{{ $t("projectreport.report") }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/report/taskreport`"
                  class="dropdown-item"
                  >{{ $t("projectreport.taskReport") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="`/${$i18n.locale}/dashboard/project/report/staffreport`"
                  class="dropdown-item"
                  >{{ $t("projectreport.staffReport") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <router-view @close="close"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeLink: window.location.pathname.split("/")[4],
      currentIndex: 0,
    };
  },
  created() {
    this.$store.state.activeLink = window.location.pathname.split("/")[3];
  },
  methods: {
    close() {
      this.$refs.projectUl.children.forEach((item, index) => {
        item.childNodes[0].classList.remove("show");
        if (index > 0) {
          item.childNodes[1].classList.remove("show");
        }
      });
      this.activeLink = window.location.pathname.split("/")[4];
    },

    toggleSetupDropdown(e) {
      var currentTab = e.target.parentNode;
      this.$refs.projectUl.children.forEach((item, index) => {
        if (!item.contains(currentTab)) {
          item.childNodes[0].classList.remove("show");
          if (index > 0) {
            item.childNodes[1].classList.remove("show");
          }
        }
      });
      e.currentTarget.classList.toggle("show");
      e.target.parentNode.childNodes[1].classList.toggle("show");
    },
  },
};
</script>
