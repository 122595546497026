var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[(_vm.type == 1)?_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.bank")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.cheque.bank_id.$error,
      }},[(_vm.isDropDownLoading)?_c('span',{staticClass:"input-group-text"},[_c('div',{staticClass:"spinner-border"})]):_vm._e(),(_vm.isDropDownLoading)?_c('select',{staticClass:"form-select",attrs:{"disabled":""}},[_c('option',{attrs:{"value":"null"}},[_vm._v(_vm._s(_vm.$t("loading")))])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.bank_id.$model),expression:"v$.cheque.bank_id.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.cheque.bank_id.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.cheque.bank_id, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.banks),function(bank,index){return _c('option',{key:index,domProps:{"value":bank.id}},[_vm._v(" "+_vm._s(bank.bank_name)+" ")])}),0)]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("csetting.bankError")))])]):_vm._e(),_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.chequeType")))]),_c('div',{staticClass:"input-group",class:{
        'is-invalid': _vm.v$.cheque.type.$error,
      }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.type.$model),expression:"v$.cheque.type.$model"}],staticClass:"form-select",class:{
          'is-invalid': _vm.v$.cheque.type.$error,
        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.v$.cheque.type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Without tearable sides")]),_c('option',{attrs:{"value":"0"}},[_vm._v("With tearable sides")])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("bank.chequeTypeError")))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.height")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.height.$model),expression:"v$.cheque.height.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.height.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.height.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.height, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("csetting.heightError")))])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.width")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.width.$model),expression:"v$.cheque.width.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.width.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.width.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.width, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("csetting.widthError")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.dtm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.date_top.$model),expression:"v$.cheque.date_top.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.date_top.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.date_top.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.date_top, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.dtmError"))+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.dlm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.date_left.$model),expression:"v$.cheque.date_left.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.date_left.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.date_left.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.date_left, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.dlmError"))+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.ntm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.benificiary_top.$model),expression:"v$.cheque.benificiary_top.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.benificiary_top.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.benificiary_top.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.benificiary_top, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.ntmError"))+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.nlm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.benificiary_left.$model),expression:"v$.cheque.benificiary_left.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.benificiary_left.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.benificiary_left.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.benificiary_left, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.nlmError"))+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.atm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.amount_number_top.$model),expression:"v$.cheque.amount_number_top.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.amount_number_top.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.amount_number_top.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.amount_number_top, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("csetting.atmError")))])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.alm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.amount_number_left.$model),expression:"v$.cheque.amount_number_left.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.amount_number_left.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.amount_number_left.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.amount_number_left, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t("csetting.almError")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.awtm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.amount_letter_top.$model),expression:"v$.cheque.amount_letter_top.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.amount_letter_top.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.amount_letter_top.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.amount_letter_top, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.awtmError"))+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group required"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t("csetting.awlm")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.v$.cheque.amount_letter_left.$model),expression:"v$.cheque.amount_letter_left.$model"}],staticClass:"form-control",class:{
            'is-invalid': _vm.v$.cheque.amount_letter_left.$error,
          },attrs:{"step":"0.1","placeholder":"in cm","type":"number"},domProps:{"value":(_vm.v$.cheque.amount_letter_left.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.v$.cheque.amount_letter_left, "$model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t("csetting.awlmError"))+" ")])])])]),_c('div',{staticClass:"form-group"},[(!_vm.isSubmitting)?_c('button',{staticClass:"btn btn-danger btn-block submitButton",attrs:{"type":"submit","name":"login-button"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.$t("csetting.set") : _vm.$t("update"))+" ")]):_c('button',{staticClass:"btn btn-danger btn-block submitButton loading",attrs:{"disabled":""}},[_c('div',{staticClass:"spinner-border text-warning"}),_c('div',[_vm._v(_vm._s(_vm.$t("loading")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }