<template>
  <div>
    <nav
      class="navbar navbar-expand-md navbar-dark"
      :class="'bg-' + $store.state.skin"
    >
      <div class="container-fluid">
        <router-link
          :to="`/${$i18n.locale}/dashboard/home`"
          class="navbar-brand"
          >{{ loggedInUser.organization_name }}</router-link
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span class="material-icons">import_export</span>
        </button>

        <button
          @click.prevent="toggleLeftNav($event)"
          class="navbar-toggler"
          type="button"
          id="left-nav-toggler"
        ></button>
        <div
          class="collapse navbar-collapse workspace-navbar-collapse"
          id="mynavbar"
        >
          <ul class="navbar-nav responsiveRightNav">
            <li class="nav-item">
              <a
                v-if="$i18n.locale != 'np'"
                @click.prevent="setLocale('np')"
                class="nav-link"
                role="button"
                >ने</a
              >
              <a
                v-if="$i18n.locale != 'en'"
                @click.prevent="setLocale('en')"
                class="nav-link"
                role="button"
                >En</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                @click.prevent="settingFlag = !settingFlag"
                role="button"
                ><i class="material-icons md-36">settings</i></a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <Rightnav
      v-if="settingFlag"
      class="right-nav-container"
      :class="settingFlag ? 'rightNavToggled' : ''"
    />
    <Leftnav />
  </div>
</template>
<script>
import Rightnav from "../dashboard/rightnav.vue";
import Leftnav from "../dashboard/leftnav.vue";

export default {
  data() {
    return {
      settingFlag: false,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  components: {
    Rightnav,
    Leftnav,
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
      // this.hideDropdown()
    },

    toggleLeftNav(e) {
      e.target.classList.toggle("active");
      document.getElementById("leftsidebar").classList.toggle("show");
    },
  },
};
</script>
<style>
.navbar {
  z-index: 1001;
  position: fixed;
  width: 100%;
}

#left-nav-toggler {
  position: absolute;
  left: 0px;
  top: 10px;
}

#left-nav-toggler:after,
#left-nav-toggler:before {
  font-family: "Material Icons";
  font-size: 24px;
  position: absolute;
  top: 5px;
  left: 5px;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#left-nav-toggler:before {
  content: "";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

#left-nav-toggler.active:after {
  content: "";
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

#left-nav-toggler.active:before {
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-dark .navbar-toggler {
  color: white;
  border-color: transparent;
}

@media (max-width: 767px) {
  .navbar-brand {
    margin-left: 30px;
  }

  .nav-link {
    padding: 0px;
    text-align: right;
    margin-right: 10px;
  }
}

.show {
  display: block !important;
}
</style>
