<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
    };
  },
  created() {
    if (this.loggedInUser) {
      this.checkTrialStatus();
    }
  },
  methods: {
    async checkTrialStatus() {
      await axios
        .post(
          "organization/trialcheck",
          {},
          {
            headers: {
              Authorization:
                "Kasturiworkspace " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          if (res.data.loginFlag) {
            localStorage.setItem(
              "loggedInInfo",
              JSON.stringify(res.data.userinfo)
            );
          } else {
            this.$toastr.e("Your login session expire.");
            this.$router.push("/" + this.$i18n.locale + "/login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
@import "./assets/styles/index.css";
</style>
