<template>
  <div>
    <Topnav />
    <div class="info-card">
      <div class="card kasturi_workspace_card">
        <div class="header" :class="'bg-' + $store.state.skin">
          <h2>
            {{$t('confirmNewEmail').heading}}
            <small>{{$t('confirmNewEmail').note}}</small>
          </h2>
        </div>
        <div class="body">
          <div class="department-form">
            <div class="text-center">
              {{$t('confirmNewEmail').alert}}
            </div>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="control-label" for="department-department_name"
                  >{{$t('confirmNewEmail').pin}}</label
                >
                <input
                  v-model="v$.verificationPin.$model"
                  :class="{
                    'is-invalid': v$.verificationPin.$error,
                  }"
                  type="number"
                  class="form-control"
                />

                <div class="invalid-feedback">
                  {{$t('confirmNewEmail').pinError}}
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-danger btn-block submitButton"
                  name="login-button"
                  v-if="!isSubmitting"
                >
                  {{$t('confirmNewEmail').verify}}
                </button>
                <button
                  class="btn btn-danger btn-block submitButton loading"
                  disabled
                  v-else
                >
                  <div class="spinner-border text-warning"></div>
                  <div>{{$t('loading')}}</div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topnav from "../dashboard/topnav.vue";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      verificationPin: { required },
    };
  },
  data() {
    return {
      isSubmitting: false,
      verificationPin: null,
      loggedInUser: JSON.parse(localStorage.getItem("loggedInInfo")),
      newemail: this.$route.params.email,
    };
  },
  components: {
    Topnav,
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        var formData = new FormData();
        formData.set("email", this.loggedInUser.email);
        formData.set("newemail", this.newemail);
        formData.set("verificationPin", this.verificationPin);
        await axios
          .post("organization/changeorgnaizationemail", formData, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload progress" +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.flag) {
              localStorage.setItem(
                "loggedInInfo",
                JSON.stringify(res.data.userinfo)
              );
              this.$toastr.s(
                "Success",
                "Your organization email has been successfully changed."
              );
              this.$router.push("/");
            } else {
              this.$toastr.e("Verification pin do not match");
              this.isSubmitting = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
